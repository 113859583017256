import React from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { IconButton } from "@mui/material";
import { ReactComponent as HeartEmpty } from "assets/icons/heart_empty.svg";
import { ReactComponent as HeartFull } from "assets/icons/heart_full.svg";
import { LabelCount } from "../LabelCount/LabelCount";

interface Props {
  favorites: number;
  supporters: number;
  isFavorite: boolean;
  onClick?: () => void;
}

const Followers = ({ favorites, supporters, isFavorite, onClick }: Props) => {
  return (
    <>
      <Stack direction={{ xs: "column", sm: "row" }} spacing={4}>
        <LabelCount label="favorites" count={favorites} />
        <LabelCount label="supporters" count={supporters} />
      </Stack>
      <Box>
        {onClick && (
          <IconButton
            onClick={onClick}
            sx={{
              border: "solid 1px",
              borderColor: "error.main",
              borderRadius: "8px",
            }}
          >
            {isFavorite ? (
              <HeartFull aria-label="is favorite" />
            ) : (
              <HeartEmpty aria-label="is not favorite" />
            )}
          </IconButton>
        )}
      </Box>
    </>
  );
};

export default Followers;
