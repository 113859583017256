import React from "react";
import Typography from "@mui/material/Typography";
import "./NoResults.css";
import { Link } from "react-router-dom";
function NoResults() {
  return (
    <Typography
      fontWeight="600"
      component={'div'}
      className="headerNoResults"
      textAlign={"center"}
      marginTop={2}
    >
      <div>
        <Typography
          padding={0}
          color="secondary.dark"
          fontSize={25}
          fontWeight="700"
          textAlign={"left"}
          marginTop={2}
        >
          No Results Found.
        </Typography>
        <div className="headerNoResults unMarging">
          <Typography
            color="secondary.dark"
            fontWeight="300"
            textAlign={"left"}
            fontSize={15}
            marginTop={2}
          >
            Please try the following :
          </Typography>
          <ul className="unMarging">
            <li>
              <Typography
                color="secondary.dark"
                fontWeight="300"
                fontSize={15}
                textAlign={"left"}
                marginTop={2}
              >
                Check your spelling
              </Typography>
            </li>
            <li>
              <Typography
                color="secondary.dark"
                fontWeight="300"
                fontSize={15}
                textAlign={"left"}
                marginTop={2}
              >
                Adjust your filters
              </Typography>
            </li>
            <li>
              <Typography
                color="primary.main"
                fontWeight="300"
                fontSize={15}
                textAlign={"left"}
                marginTop={2}
              >
                <Link to="/missingAthlete">
                  Report athlete missing
                </Link>
              </Typography>
            </li>
            {/* <li color="primary.main">
              <Typography
                color="primary.main"
                fontWeight="300"
                fontSize={15}
                textAlign={"left"}
                marginTop={2}
              >
                <a href={SUPPORTLINK_URL} target="/">
                  Contact supports
                </a>
              </Typography>
            </li> */}
          </ul>
        </div>
      </div>
    </Typography>
  );
}

export default NoResults;
