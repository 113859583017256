/* eslint-disable @typescript-eslint/no-explicit-any */
import Box from "@mui/material/Box";
import React, { useEffect, useState } from "react";
import Header from "components/Header";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { useNotifications } from "providers/Notification";
import { useMutation } from "@apollo/client";
import { useForm, UseFormReturn } from "react-hook-form";
import BackButton from "components/BackButton/BackButton";
import { ONBOARD_ATHLETE } from "queries/athlete";
import { EMAIL_REGEX } from "constant";
import Typography from "@mui/material/Typography";
import MenuItem from "@mui/material/MenuItem";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { differenceInDays, differenceInYears, format, isValid, parse } from "date-fns";
import { states } from "./stateData";
import background from "assets/images/SignUp-Successv2.png";
import moment from "moment";
import { insertApiResponseDataDog, insertApiResponseDataDogError } from "utils";
import { Grid } from "semantic-ui-react";
import { documentType } from "./documentType";
import { FileUpload } from "../../../components/FileUpload/fileupload";
import { Post, Put } from "providers/Fetch";
import useUser from "hooks/useUser";
import CircularProgress from "@mui/material/CircularProgress";

interface PersonalDetailsFormValues {
  firstName: string;
  lastName: string;
  zip: string;
  dob: string;
  personalIdentificationNumber: string;
  email: string;
  payoutType: string;
  bankAccountType: string;
  nameOnAccount: string;
  phone: string;
  routingNumber: string;
  state: string;
  city: string;
  bankAddress: string;
  bankZip: string;
  addressLine1: string;
  addressLine2: string;
  accountNumber: string;
  documentType: string;
  documentFileFront: File | null;
  documentFileBack: File | null;
  bankFile: File | null
}

function PersonalDetails() {
  const notify = useNotifications();
  const [step, setStep] = useState(0);
  // const [onboardAthlete] = useMutation(ONBOARD_ATHLETE);
  const [LoginUserObject] = useState(JSON.parse(localStorage.getItem('LoginUserObject') || ""))
  // const [city, setCity] = useState("")
  // const myHeaders = new Headers();
  const user = useUser()
  const [loading, setLoading] = useState(false);

  const form = useForm<PersonalDetailsFormValues>({
    defaultValues: {
      firstName: "",
      lastName: "",
      addressLine1: "",
      addressLine2: "",
      zip: "",
      dob: "",
      phone: "",
      personalIdentificationNumber: "",
      email: (LoginUserObject.email || ""),
      payoutType: "ACH",
      bankAccountType: "",
      routingNumber: "",
      state: "",
      city: "",
      accountNumber: "",
      documentType: "",
      documentFileFront: null,
      documentFileBack: null,
      bankFile: null
    },
    mode: "all",
  });

  const bankBInfoPrefilling = () => {
    form.setValue('bankAccountType', user?.ckoBankAccountType as any
      ? user?.ckoBankAccountType === 'current' ? 'CHECKING' : user?.ckoBankAccountType as any
      : '');
    form.setValue('routingNumber', user?.ckoRoutingNumber as any | '');
  }
  const profileInfoPrefilling = () => {
    form.setValue('firstName', user?.ckoFirstName as any || '');
    form.setValue('lastName', user?.ckoLastName as any | '');
    form.setValue('addressLine1', user?.ckoAddressLine1 as any || '');
    form.setValue('addressLine2', user?.ckoAddressLine2 as any || '');
    form.setValue('zip', user?.ckoZip as any || '');
    form.setValue('dob', user?.ckoDob ? moment(user?.ckoDob).format('MM/DD/YYYY') as any : '');
    form.setValue('phone', user?.ckoPhone as any || '');
    form.setValue('state', user?.ckoState as any || '');
    form.setValue('city', user?.ckoCity as any || '');
    form.setValue('personalIdentificationNumber', '');
  }
  const profilePrefilingBluesnap = () => {
    form.setValue('firstName', user?.firstName as any || '');
    form.setValue('lastName', user?.lastName as any | '');
    form.setValue('zip', user?.zipCode as any || '');
    form.setValue('dob', user?.birthday ? moment(user?.birthday).format('MM/DD/YYYY') as any : '');
    form.setValue('phone', user?.phoneNumber as any || '');
  }
  const individualBankInfoAndProfileInfo = async (FormObject: PersonalDetailsFormValues) => {
    const formdata = new FormData();
    if (
      user?.ckoSubEntityDdStatus == null ||
      ['requirements_due', 'rejected'].includes(user?.ckoSubEntityDdStatus as any)
    ) {
      formdata.append("addressLine1", FormObject.addressLine1);
      formdata.append("addressLine2", FormObject.addressLine2);
      formdata.append("city", FormObject.city);
      formdata.append("dob", FormObject.dob);
      formdata.append("firstName", FormObject.firstName);
      formdata.append("lastName", FormObject.lastName);
      formdata.append("personalIdentificationNumber", FormObject.personalIdentificationNumber);
      formdata.append("phone", FormObject.phone);
      formdata.append("routingNumber", FormObject.routingNumber);
      formdata.append("state", FormObject.state);
      formdata.append("zip", FormObject.zip);
      formdata.append("identityVerificationDocumentType", FormObject.documentType);
      formdata.append("identityVerificationDocumentFileFront", FormObject.documentFileFront as any);
      formdata.append("identityVerificationDocumentFileBack", FormObject.documentType === 'passport' ? null : FormObject.documentFileBack as any);

      Post(`${process.env.REACT_APP_SERVER_URI}/checkout/athlete/profile-info`, formdata, true)
        .then(async (response) => response)
        .then((result: any) => {
          setLoading(false);
          insertApiResponseDataDog("onboardAthleteWithCkoApi(success)", result, "info");
          if (result.status > 300) {
            try {
              const responsAry: [{ code: string, errorName: string, description: string }] = JSON.parse(result.data) || []
              const errorMessage = `${result.status + " : " + (typeof responsAry === 'string' || responsAry instanceof String ? responsAry : responsAry[responsAry.length - 1].description)}`
              notify.error(errorMessage);
            } catch (e) {
              const responsAry: { status: string, data: string } = result;
              const errorMessage = `${responsAry.status + " : " + responsAry.data}`
              insertApiResponseDataDog("onboardAthleteWithCkoApi(error)", errorMessage, "error");
              insertApiResponseDataDogError(errorMessage, "onboardAthleteWithCkoApi(error)");
              notify.error(errorMessage);
              if (responsAry.data == 'User already onboarded with Checkout account') {
                window.location.replace('/home')
              }
            }
          } else {
            if (result) {
              user?.refetch();
              setStep(3);
            }
          }
        }
        ).catch((error) => {
          setLoading(false);
          insertApiResponseDataDogError(error, "onboardAthleteWithCkoApi(error)");
          insertApiResponseDataDog("onboardAthleteWithCkoApi(error)", error, "error")
          notify.error(error);
        })
    } else if (!['verified', 'pending'].includes(user?.ckoSubEntityBankStatus as any)) {
      formdata.append("bankStatementDocumentFileFront", FormObject.bankFile as any);
      formdata.append("bankAccountType", FormObject.bankAccountType);
      formdata.append("accountNumber", FormObject.accountNumber);
      formdata.append("routingNumber", FormObject.routingNumber);
      Put(`${process.env.REACT_APP_SERVER_URI}/checkout/athlete/bank-info`, formdata, true)
        .then(async (response) => response)
        .then((result: any) => {
          setLoading(false);
          insertApiResponseDataDog("updateAthleteBankAccountInfoApi(success)", result, "info");
          if (result.status > 300) {
            try {
              const responsAry: [{ code: string, errorName: string, description: string }] = JSON.parse(result.data) || []
              const errorMessage = `${result.status + " : " + (typeof responsAry === 'string' || responsAry instanceof String ? responsAry : responsAry[responsAry.length - 1].description)}`
              notify.error(errorMessage);
            } catch (e) {
              const responsAry: { status: string, data: string } = result;
              const errorMessage = `${responsAry.status + " : " + responsAry.data}`
              insertApiResponseDataDog("updateAthleteBankAccountInfoApi(error)", errorMessage, "error");
              insertApiResponseDataDogError(errorMessage, "updateAthleteBankAccountInfoApi(error)");
              notify.error(errorMessage);
            }
          } else {
            notify.success(result?.data || "");
            user?.refetch();
            setStep(3);
          }
        }
        ).catch((error) => {
          setLoading(false);
          insertApiResponseDataDogError(error, "updateAthleteBankAccountInfoApi(error)");
          insertApiResponseDataDog("updateAthleteBankAccountInfoApi(error)", error, "error")
          notify.error(error);
        })
    }

  }
  const onSubmit = async (FormObject: PersonalDetailsFormValues) => {

    FormObject.firstName = (FormObject.firstName).trim()
    FormObject.lastName = (FormObject.lastName).trim()
    FormObject.addressLine1 = (FormObject.addressLine1).trim()
    FormObject.addressLine2 = (FormObject.addressLine2).trim()
    FormObject.phone = (FormObject.phone).trim()
    FormObject.city = (FormObject.city).trim()
    FormObject.state = (FormObject.state).trim()
    FormObject.zip = (FormObject.zip).trim()
    FormObject.email = (FormObject.email).trim()
    FormObject.accountNumber = (FormObject.accountNumber).trim()
    FormObject.dob = moment(FormObject.dob).format('DD-MM-YYYY');

    if (!user?.athleteCheckoutOnboarded && !user?.ckoSubEntityDdStatus && !user?.ckoSubEntityBankStatus) {
      console.warn('Calling On-Boarding API');
    } else if (user?.ckoSubEntityDdStatus == null || ['requirements_due', 'rejected'].includes(user?.ckoSubEntityDdStatus as any) || (!['verified', 'pending'].includes(user?.ckoSubEntityBankStatus as any))) {
      setLoading(true);
      individualBankInfoAndProfileInfo(FormObject)
      return;
    }
    if (FormObject.dob == "Invalid date") {
      form.setValue('dob', "", { shouldValidate: false, shouldTouch: false })
      form.setError("dob", { type: "focus", message: 'Invalid Date Of Birth' }, { shouldFocus: true })
      notify.error("Invalid Date Of Birth");
      setStep(1)
    } else {
      const formdata = new FormData();
      formdata.append("accountNumber", FormObject.accountNumber);
      formdata.append("addressLine1", FormObject.addressLine1);
      formdata.append("addressLine2", FormObject.addressLine2);
      formdata.append("bankAccountType", FormObject.bankAccountType);
      formdata.append("city", FormObject.city);
      formdata.append("dob", FormObject.dob);
      formdata.append("firstName", FormObject.firstName);
      formdata.append("lastName", FormObject.lastName);
      formdata.append("personalIdentificationNumber", FormObject.personalIdentificationNumber);
      formdata.append("phone", FormObject.phone);
      formdata.append("routingNumber", FormObject.routingNumber);
      formdata.append("state", FormObject.state);
      formdata.append("zip", FormObject.zip);
      formdata.append("identityVerificationDocumentType", FormObject.documentType);
      formdata.append("identityVerificationDocumentFileFront", FormObject.documentFileFront as any);
      formdata.append("identityVerificationDocumentFileBack", FormObject.documentType === 'passport' ? null : FormObject.documentFileBack as any);
      formdata.append("bankStatementDocumentFileFront", FormObject.bankFile as any);

      setLoading(true);
      Post(`${process.env.REACT_APP_SERVER_URI}/checkout/athlete/onboard`, formdata, true)
        .then(async (response) => response)
        .then((result: any) => {
          setLoading(false);
          user?.refetch();
          insertApiResponseDataDog("onboardAthleteWithCkoApi(success)", result, "info");
          if (result.status > 300) {
            try {
              const responsAry: [{ code: string, errorName: string, description: string }] = JSON.parse(result.data) || []
              const errorMessage = `${result.status + " : " + (typeof responsAry === 'string' || responsAry instanceof String ? responsAry : responsAry[responsAry.length - 1].description)}`
              notify.error(errorMessage);
            } catch (e) {
              const responsAry: { status: string, data: string } = result;
              const errorMessage = `${responsAry.status + " : " + responsAry.data}`
              insertApiResponseDataDog("onboardAthleteWithCkoApi(error)", errorMessage, "error");
              insertApiResponseDataDogError(errorMessage, "onboardAthleteWithCkoApi(error)");
              notify.error(errorMessage);
              if (responsAry.data == 'User already onboarded with Checkout account') {
                window.location.replace('/home')
              }
            }
          } else {
            if (result) {
              setStep(3);
              user?.refetch();
            }
          }
        }
        ).catch((error) => {
          user?.refetch();
          setLoading(false);
          insertApiResponseDataDogError(error, "onboardAthleteWithCkoApi(error)");
          insertApiResponseDataDog("onboardAthleteWithCkoApi(error)", error, "error")
          notify.error(error);
        })
    }
  };
  useEffect(() => {
    if (step === 0) {
      if (!user?.athleteCheckoutOnboarded && !user?.ckoSubEntityDdStatus && !user?.ckoSubEntityBankStatus) {
        setStep(1); // we show both PersonalInfo & Banking Info Form
        if (user?.athleteOnboarded) {
          profilePrefilingBluesnap()
        }
      } else if (user?.ckoSubEntityDdStatus == null || ['requirements_due', 'rejected'].includes(user?.ckoSubEntityDdStatus as any)) {
        profileInfoPrefilling();
        setStep(1); // ONly show Personal Info Form
      }
      else if (!['verified', 'pending'].includes(user?.ckoSubEntityBankStatus as any)) {
        bankBInfoPrefilling();
        setStep(2); // ONly show Bank Info Form
      }
    }
  }, [user])
  useEffect(() => {
    user?.refetch();
  }, [])

  if (user?.loading) return <CircularProgress />;

  const props = {
    form,
    setStep,
    loading,
    user
  };
  if (step === 3) {
    return (
      <Stack spacing={2} sx={{
        position: "absolute",
        top: "0px",
        bottom: "0px",
        left: "0px",
        right: "0px",
        backgroundImage: `url(${background})`,
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        zIndex: "0",
        paddingTop: "100px"

      }}>
        <Grid style={{ width: 400, margin: '0 auto' }}
        >

          <Typography
            variant="h4"
            component="h1"
            align="left"
            sx={{ color: '#fff', fontSize: "2rem", marginBottom: 5 }}
          >{`Welcome, ${form.getValues('firstName')}!`}</Typography>

          <Typography sx={{
            color: '#fff', textShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)', marginBottom: 3
          }} fontSize={16} fontWeight={200} align="left">
            WHAT’S NEXT?        </Typography>

          <Typography sx={{ color: '#fff', textShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)', marginBottom: 3 }} fontSize={16} fontWeight={200} align="left">
            The processor needs to approve your account information and then you will begin receiving payments.
          </Typography>
          <Typography sx={{ color: '#fff', textShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)' }} fontSize={16} fontWeight={200} align="left">
            This is a one-time process and shouldn’t take longer than 24-48 hours.      </Typography>
          <Box style={{ margin: '0 auto' }}>
            <Button variant="contained" href="/home" sx={{ background: "#FFF", marginTop: 10, color: '#592EE5', width: '400px', ":hover": { backgroundColor: "#f0ebff" } }} >
              Back To Home         </Button>
          </Box>
        </Grid>

      </Stack>
    );
  }

  return (
    <form onSubmit={form.handleSubmit(onSubmit)} autoComplete="off">
      {step === 1 && (
        <Step1 {...props} />
      )}
      {step === 2 && (
        <>
          {!user?.athleteCheckoutOnboarded && !user?.ckoSubEntityDdStatus && !user?.ckoSubEntityBankStatus
            ?
            <BackButton onClick={() => setStep(1)} />
            :
            ''}
          <Step2 {...props} />
        </>

      )}
    </form>
  );
}
interface FormStepProps {
  form: UseFormReturn<PersonalDetailsFormValues>;
  setStep: React.Dispatch<React.SetStateAction<number>>;
  loading: boolean;
  user: any
}

const Step1 = ({ form, setStep, user, loading }: FormStepProps) => {
  const [dateValue, setDateValue] = React.useState<any>(form.getValues('dob') || "");
  const { isDirty, errors, isSubmitting, touchedFields } = form.formState;
  const [zipCode, setZipCode] = useState(form.getValues('zip') || "")
  const [firstName, setFirstName] = useState(form.getValues('firstName') || "")
  const [lastName, setLastName] = useState(form.getValues('lastName') || "")
  const [LastFourDigits, setLastFourDigits] = useState(form.getValues('personalIdentificationNumber') || "")
  const EmailEditable = (process.env.REACT_APP_ATHLETE_ONBORDING_EMAIL_EDITABLE || "true") === "true" ? false : true;
  const [phone, setPhoneNumber] = useState(form.getValues('phone') || "")

  const currentDate = new Date();
  const DateValidation = currentDate.setFullYear(currentDate.getFullYear() - 18)
  const minDateValidation = currentDate.setFullYear(currentDate.getFullYear() - 82)
  const minformateDate = moment(minDateValidation).format('MM/DD/YYYY')
  const formateDate = moment(DateValidation).format('yyyy-MM-DD');
  const [error, setError] = useState(false);
  const [ageError, setAgeError] = useState(false);
  const [maxAgeError, setMaxAgeError] = useState(false);
  const [city, setCity] = useState(form.getValues('city') || '');
  const [frontFile, setFrontFile] = useState<File | null>(form.getValues('documentFileFront') || null);
  const [backFile, setBackFile] = useState<File | null>(form.getValues('documentFileFront') || null);

  const handleChangeZip = (e: any) => {
    const regex = /^[0-9\b]+$/;
    if (e.target.value === "" || regex.test(e.target.value)) {
      if (e.target.value.length <= 5) {
        setZipCode(e.target.value);
      }
    }
  }
  const handleMaxDateChange = (newValue: Date | null) => {
    setDateValue(newValue);

    if (newValue) {
      const currentDate = new Date();
      const age = differenceInDays(currentDate, newValue);
      const years = 100;
      const daysInYear = 365.25;
      const days = years * daysInYear;
      if (age > days) {
        setMaxAgeError(true);
      } else {
        setMaxAgeError(false);
      }
    } else {
      setMaxAgeError(false);
    }
  };
  const handleDateChange = (newValue: Date | null) => {
    setDateValue(newValue);
    if (newValue) {
      const currentDate = new Date();
      const age = differenceInYears(currentDate, newValue);

      if (age < 18) {
        setAgeError(true);
      } else {
        setAgeError(false);
      }
    } else {
      setAgeError(false);
    }
  };

  const handleManualDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputDate = event.target.value;
    const parsedDate = parse(inputDate, 'MM/dd/yyyy', new Date());

    if (inputDate.length === 10 && isValid(parsedDate)) {
      setDateValue(parsedDate);
      setError(false);
      handleDateChange(parsedDate);
      handleMaxDateChange(parsedDate)
    } else {
      setDateValue(null);
      setError(true);
      handleDateChange(null);
      handleMaxDateChange(null)
    }
  };
  const handleChangeLastFourDigits = (e: any) => {
    const regex = /^[0-9\b]+$/;
    if (e.target.value === "" || regex.test(e.target.value)) {
      if (e.target.value.length <= 9) {
        setLastFourDigits(e.target.value);
      }
    }
  }
  const isFirstStepValid = () => {
    const noErrors = () => {
      const { firstName,
        lastName,
        // address,
        zip,
        dob,
        phone,
        personalIdentificationNumber,
        email, } = errors;
      return !firstName && !lastName && !zip && !dob && !phone && !email && !personalIdentificationNumber;
    };
    const noEmptyValues = () => {
      const {
        firstName,
        addressLine1,
        lastName,
        zip,
        phone,
        dob,
        personalIdentificationNumber
      } = form.getValues();
      return firstName?.trim() && lastName?.trim() && zip?.trim() && dob?.trim() && phone?.trim() && personalIdentificationNumber?.trim() && addressLine1?.trim();
    };
    return Boolean(
      noErrors() && noEmptyValues()
    );

  }
  const handleChangePhoneNumber = (e: any) => {
    const arr = Array.from(e.target.value)
    const regex = /^[0-9\b]+$/;
    if (arr[0] !== "0" || e.target.value === "" || regex.test(e.target.value)) {
      if (e.target.value.length <= 10) {
        setPhoneNumber(e.target.value);
      }
    }
  };


  return (
    <Box className="ath-personal-details">
      <Header text="Personal Details" />

      <Box sx={{ mt: 4 }} textAlign={"left"} className="personal-details__form">
        <Stack sx={{ mt: 2 }} direction="column" alignItems="flex-start">
          <TextField
            margin="dense"
            label="First Name"
            placeholder="From your Drivers License or State ID"
            InputLabelProps={{
              shrink: true,
            }}
            type="text"
            fullWidth
            autoComplete="none"
            value={firstName}
            inputProps={{
              "data-testid": "firstName",
            }}
            error={Boolean(isDirty && errors.firstName)}
            disabled={isSubmitting}
            helperText={(isDirty && errors.firstName?.message) || " "}
            {...form.register("firstName", {
              required: "First Name is required",
              minLength: { value: 2, message: "First Name should be minimum 2 or above characters." },
              maxLength: { value: 50, message: "First Name should be maximum 50 or below characters." }
            })}
            onChange={(e) => {
              const regex = /^[A-z\s]+$/;
              if (e.target.value === "" || regex.test(e.target.value)) {
                setFirstName(e.target.value);
              }
            }}
          />
          <TextField sx={{ mt: 3 }}
            margin="dense"
            data-testid="Last Name"
            label="Last Name"
            placeholder="From your Drivers License or State ID"
            InputLabelProps={{
              shrink: true,
            }}
            type="text"
            fullWidth
            value={lastName}
            autoComplete="none"
            inputProps={{
              "data-testid": "lastName",
            }}
            error={isDirty && Boolean(errors.lastName)}
            disabled={isSubmitting}
            helperText={(isDirty && errors.lastName?.message) || " "}
            {...form.register("lastName", {
              required: "Last Name is required",
              minLength: { value: 2, message: "Last Name should be minimum 2 or above characters." },
              maxLength: { value: 50, message: "Last Name should be maximum 50 or below characters." }
            })}
            onChange={(e) => {
              const regex = /^[A-z\s]+$/;
              if (e.target.value === "" || regex.test(e.target.value)) {
                setLastName(e.target.value);
              }
            }}
          />


          <TextField sx={{ mt: 3 }}
            margin="dense"
            data-testid="Address Line1"
            label="Address Line1"
            placeholder="From your Drivers License or State ID"
            InputLabelProps={{
              shrink: true,
            }}
            type="text"
            fullWidth
            autoComplete="none"
            inputProps={{
              "data-testid": "address",
            }}
            error={Boolean(isDirty && errors.addressLine1)}
            disabled={isSubmitting}
            helperText={(isDirty && errors.addressLine1?.message) || " "}
            {...form.register("addressLine1", {
              required: "AddressLine1 is required",
              minLength: { value: 5, message: "Please enter 5 minimum characters" }

            })}
          />
          <TextField sx={{ mt: 3 }}
            margin="dense"
            data-testid="Address Line2"
            label="Address Line2"
            placeholder="From your Drivers License or State ID"
            InputLabelProps={{
              shrink: true,
            }}
            type="text"
            fullWidth
            autoComplete="none"
            inputProps={{
              "data-testid": "address",
            }}
            {...form.register("addressLine2")}
          />

          <TextField sx={{ mt: 3 }}
            select
            margin="dense"
            data-testid="State"
            label="State"
            placeholder="From your Drivers License or State ID"
            InputLabelProps={{
              shrink: true
            }}
            type="text"
            fullWidth
            autoComplete="none"
            defaultValue={form?.getValues('state') || ''}
            inputProps={{
              "data-testid": "state",
            }}
            error={Boolean(isDirty && errors.state)}
            disabled={isSubmitting}
            helperText={(isDirty && errors.state?.message) || " "}
            {...form.register("state", {
              required: "State is required"
            })}
          >
            {states()?.map((val, key) =>
              <MenuItem key={key} value={val.value}>{val.name}</MenuItem>
            )}
          </TextField>
          <TextField sx={{ mt: 3 }}
            margin="dense"
            data-testid="City"
            label="City"
            placeholder="From your Drivers License or State ID"
            InputLabelProps={{
              shrink: true,
            }}
            type="text"
            fullWidth
            autoComplete="none"
            value={city}
            inputProps={{
              "data-testid": "city",
            }}
            error={Boolean(isDirty && errors.city)}
            disabled={isSubmitting}
            helperText={(isDirty && errors.city?.message) || " "}
            {...form.register("city", {
              required: "City is required"
            })}
            onChange={(e) => {
              const regex = /^[A-z\s]+$/;
              if (e.target.value === "" || regex.test(e.target.value)) {
                setCity(e.target.value);
              }
            }}
          />
          <TextField sx={{ mt: 3 }}
            margin="dense"
            data-testid="zip"
            label="Zip Code"
            placeholder="From your Drivers License or State ID"
            InputLabelProps={{
              shrink: true,
            }}
            value={zipCode}
            type="text"
            fullWidth
            autoComplete="none"
            inputProps={{
              "data-testid": "zip",
            }}
            error={Boolean(isDirty && errors.zip)}
            disabled={isSubmitting}
            helperText={(isDirty && errors.zip?.message) || " "}
            {...form.register("zip", {
              required: "Zip Code is required",
              minLength: { value: 5, message: "Please enter 5 minimum digits number" }
            })}
            onChange={(e) => handleChangeZip(e)}
          />
          <Box component={'span'} width={"100%"}
            onClick={() => {
              if (!form.getValues('dob')) {
                form.setError("dob", { type: "focus", message: 'Date of birth is required' }, { shouldFocus: true })
              } else {
                form.clearErrors("dob")
              }
            }}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DesktopDatePicker
                inputFormat={"MM/DD/YYYY"}
                disabled={isSubmitting}
                value={dateValue}
                minDate={minformateDate}
                maxDate={formateDate}
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                onChange={(newValue: any) => {
                  localStorage.setItem("fanBirthDate", newValue)
                  setDateValue(newValue);
                  setError(false);
                  setMaxAgeError(false);
                  setAgeError(false);
                  // form.setValue('birthday', format(new Date(newValue), "MM-dd-yyyy"), { shouldValidate: true })
                  form.setValue('dob', format(new Date(newValue), "MM/dd/yyyy"), { shouldValidate: true })
                }}
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                renderInput={(params: any) => {
                  return <TextField
                    error={isDirty && Boolean(errors.dob) || error || ageError || maxAgeError}
                    label="Birthday"
                    inputProps={{
                      placeholder: "From your Drivers License or State ID",
                      "data-testid": "birthday",
                      readOnly: false
                    }}
                    InputProps={params.InputProps}
                    onError={() => isDirty && Boolean(errors.dob)}
                    inputRef={params.inputRef}
                    {...form.register("dob", {
                      required: "Date of birth is required",
                    })}
                    fullWidth
                    autoComplete="none"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    //helperText={(isDirty && errors.birthday?.message) || error ? 'Date should be MM\DD\YYYY' : ''}
                    helperText={(isDirty && errors.dob?.message) || error ? ' Date format should be MM/DD/YYYY' : ageError ? ' Must be at least 18 years old' : maxAgeError ? ' Age should not be greater than 100 years' : ''}
                    onChange={handleManualDateChange}
                  />
                }}
              />
            </LocalizationProvider>
          </Box>
          <TextField sx={{ mt: 3 }}
            margin="dense"
            data-testid="Last four digits of social security number"
            label="Social Security Number"
            placeholder="Social Security Number"
            InputLabelProps={{
              shrink: true,
            }}
            value={LastFourDigits}
            type="text"
            fullWidth
            autoComplete="none"
            inputProps={{
              "data-testid": "personalIdentificationNumber",
            }}
            error={Boolean(isDirty && errors.personalIdentificationNumber)}
            disabled={isSubmitting}
            helperText={(isDirty && errors.personalIdentificationNumber?.message) || " "}
            {...form.register("personalIdentificationNumber", {
              required: "Last nine digits of social security number is required",
              minLength: { value: 9, message: "Last nine digits of social security number is required" }

            })}
            onChange={handleChangeLastFourDigits}
          />
          <TextField sx={{ mt: 3 }}
            margin="dense"
            data-testid="Email"
            label="Email"
            placeholder="Email"
            InputLabelProps={{
              shrink: true
            }}
            InputProps={{
              readOnly: EmailEditable
            }}
            type="text"
            fullWidth
            autoComplete="none"
            inputProps={{
              "data-testid": "email",
            }}
            error={Boolean(isDirty && errors.email)}
            disabled={process.env.REACT_APP_ENV === "prod" ? true : isSubmitting}
            helperText={(isDirty && errors.email?.message) || " "}
            {...form.register("email", {
              required: "Email is required",
              pattern: {
                value: EMAIL_REGEX,
                message: "Invalid email address",
              },
            })}
          />
          <TextField
            margin="dense"
            inputProps={{ "data-testid": "phone" }}
            label="Phone Number"
            placeholder="XXXXXXXXXX"
            InputLabelProps={{
              shrink: true,
            }}
            type="text"
            value={phone}
            fullWidth
            autoComplete="none"
            error={Boolean(isDirty && errors.phone)}
            disabled={isSubmitting}
            helperText={
              (isDirty && errors.phone?.message) || " "
            }
            {...form.register("phone", {
              required: "Phone number required",
              minLength: {
                value: 10,
                message: "Phone number should be 10 digits"
              },
            })}
            onChange={(e) => handleChangePhoneNumber(e)}
          />
          <TextField sx={{ mt: 3 }}
            select
            margin="dense"
            data-testid="Document-Type"
            label="Document Type"
            placeholder="Select"
            InputLabelProps={{
              shrink: true
            }}
            type="text"
            fullWidth
            autoComplete="none"
            defaultValue={form?.getValues('documentType') || ''}
            inputProps={{
              "data-testid": "documentType",
            }}
            error={Boolean(isDirty && errors.documentType)}
            disabled={isSubmitting}
            helperText={(isDirty && errors.documentType?.message) || " "}
            {...form.register("documentType", {
              required: "Document Type is required"
            })}
            onChange={(e) => {
              form.setValue("documentType", e.target.value);
              setBackFile(null);
              form.setValue('documentFileBack', null)
            }}
          >
            {documentType()?.map((val, key) =>
              <MenuItem key={key} value={val.value}>{val.name}</MenuItem>
            )}
          </TextField>

          <FileUpload
            acceptFileType=".jpg,.jpeg,.png"
            setFile={(f) => {
              setFrontFile(f);
              form.setValue('documentFileFront', f)
            }}
            file={frontFile}
            maxFileSize={4000}
            formatErrorMessage="JPEG, JPG or PNG"
            btnName="Upload file (Front)"
          />
          {form?.watch('documentType') !== 'passport' ? <FileUpload
            acceptFileType=".jpg,.jpeg,.png"
            setFile={(f) => {
              setBackFile(f);
              form.setValue('documentFileBack', f)
            }}
            file={backFile}
            maxFileSize={4000}
            formatErrorMessage="JPEG, JPG or PNG"
            btnName="Upload file (Back)"
          /> : ""}
        </Stack>
      </Box>
      {!user?.athleteCheckoutOnboarded && !user?.ckoSubEntityDdStatus && !user?.ckoSubEntityBankStatus
        ?
        <Button sx={{ mt: 8 }}
          type="button"
          fullWidth
          color={"primary"}
          variant="contained"
          onClick={() => setStep(2)}
          disabled={!isFirstStepValid() || error || ageError || maxAgeError || isSubmitting || !frontFile || (form?.watch('documentType') !== 'passport' && !backFile)}
        >
          Next
        </Button>
        :
        <Button
          startIcon={loading ? <CircularProgress size={20} /> : null}
          sx={{ mt: 8 }}
          type="submit"
          fullWidth
          color={"primary"}
          variant="contained"
          disabled={!isFirstStepValid() || error || ageError || maxAgeError || isSubmitting || !frontFile || (form?.watch('documentType') !== 'passport' && !backFile) || loading}
        >
          Submit Request
        </Button>}
    </Box>
  )
}
const Step2 = ({ form, loading, user }: FormStepProps) => {
  const { isDirty, errors, isSubmitting, isValid } = form.formState;
  const [zipCode, setZipCode] = useState(form.getValues('bankZip') || "")
  const [nameOnAccount, setNameOnAccount] = useState(form.getValues('nameOnAccount') || "")
  const [accountNumber, setAccountNumber] = useState(form.getValues('accountNumber') || "")
  const [routingNumber, setRoutingNumber] = useState(form.getValues('routingNumber') || "")
  const handleChangeZip = (e: any) => {
    const regex = /^[0-9\b]+$/;
    if (e.target.value === "" || regex.test(e.target.value)) {
      if (e.target.value.length <= 5) {
        setZipCode(e.target.value);
      }
    }
  }
  const [bankFile, setBankFile] = useState<File | null>(form.getValues('bankFile') || null);

  return (
    <Box className="ath-personal-details">
      <Typography fontSize="17px" fontWeight="600" color="red" marginBottom={4}> After you submit your banking information, you will need to complete verification by our bank processor
      </Typography>
      <Header text="Payout info" />
      <Box sx={{ mt: 4 }} textAlign={"left"} className="personal-details__form">
        <Stack sx={{ mt: 2 }} direction="column" alignItems="flex-start">
          <TextField sx={{ mt: 3 }}
            select
            margin="dense"
            data-testid="NameOnAccount"
            label="Payout Type"
            placeholder="Payout Type"
            InputLabelProps={{
              shrink: true,
            }}
            fullWidth
            autoComplete="none"
            defaultValue={form.getValues('payoutType') || ''}
            inputProps={{
              "data-testid": "payoutType",
            }}
            error={Boolean(isDirty && errors.payoutType)}
            disabled={isSubmitting}
            helperText={(isDirty && errors.payoutType?.message) || " "}
            {...form.register("payoutType", {
              required: "Payout Type is required"
            })}
          >
            <MenuItem value={'ACH'}>ACH</MenuItem>

          </TextField>
          <TextField sx={{ mt: 3 }}
            select
            margin="dense"
            data-testid="NameOnAccount"
            label="Bank Account Type"
            placeholder="Bank Account Type"
            defaultValue={form.getValues('bankAccountType') || ''}
            InputLabelProps={{
              shrink: true,
            }}
            fullWidth
            autoComplete="none"
            inputProps={{
              "data-testid": "bankAccountType",
            }}
            error={Boolean(isDirty && errors.bankAccountType)}
            disabled={isSubmitting}
            helperText={(isDirty && errors.bankAccountType?.message) || " "}
            {...form.register("bankAccountType", {
              required: "Bank Account Type is required"
            })}
          >
            <MenuItem value={'CHECKING'}>CHECKING</MenuItem>
            <MenuItem value={'SAVINGS'}>SAVINGS</MenuItem>
          </TextField>
          <TextField sx={{ mt: 3 }}
            margin="dense"
            data-testid="routingNumber"
            label="Routing Number"
            placeholder="Routing Number"
            InputLabelProps={{
              shrink: true,
            }}
            fullWidth
            autoComplete="none"
            value={routingNumber}
            inputProps={{
              "data-testid": "routingNumber",
            }}
            error={Boolean(isDirty && errors.routingNumber)}
            disabled={isSubmitting}
            helperText={(isDirty && errors.routingNumber?.message) || " "}
            {...form.register("routingNumber", {
              required: "Routing Number is required",
              minLength: { value: 9, message: "Please Enter 9 minimum number" },
            })}
            onChange={(e) => {
              const regex = /^[0-9\b]+$/;
              if (e.target.value === "" || regex.test(e.target.value)) {
                if (e.target.value.length < 10) {
                  setRoutingNumber(e.target.value);
                }
              }
            }}
          />

          <TextField sx={{ mt: 3 }}
            margin="dense"
            data-testid="Bank Account Id"
            label="Bank Account Number"
            placeholder="Bank Account Number"
            InputLabelProps={{
              shrink: true,
            }}
            value={accountNumber}
            fullWidth
            autoComplete="none"
            inputProps={{
              "data-testid": "accountNumber",
            }}
            error={Boolean(isDirty && errors.accountNumber)}
            disabled={isSubmitting}
            helperText={(isDirty && errors.accountNumber?.message) || " "}
            {...form.register("accountNumber", {
              required: "Bank Account Number is required",
              minLength: { value: 6, message: "Please enter 6 minimum digits number" },
              maxLength: { value: 34, message: "Please enter 34 max digits number" }

            })}
            onChange={(e) => {
              const regex = /^[0-9\b]+$/;
              if (e.target.value === "" || regex.test(e.target.value)) {
                setAccountNumber(e.target.value);
              }
            }}
          />
          <Box component={'span'} sx={{ mt: 3, width: "100%" }}>
            <Typography
              style={{ color: '#592EE5', width: '100%', display: 'flex' }}
              component={'label'}
            >
              Upload bank statement
            </Typography>

            <FileUpload
              acceptFileType=".jpg,.jpeg,.png,.pdf"
              setFile={(f) => {
                setBankFile(f);
                form.setValue('bankFile', f);
              }}
              file={bankFile}
              maxFileSize={4000}
              formatErrorMessage="JPEG, JPG, PNG, or PDF"
            />
          </Box>
        </Stack>
      </Box>
      <Button
        startIcon={loading ? <CircularProgress size={20} /> : null}
        sx={{ mt: 8 }}
        type="submit"
        fullWidth
        color={"primary"}
        variant="contained"
        disabled={!isValid || !bankFile || loading}
      >
        Submit Request
      </Button>
    </Box>
  )
}
export default PersonalDetails;

