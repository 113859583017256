import React from "react";
import { ApolloError, useQuery } from "@apollo/client";
import { CircularProgress, Stack } from "@mui/material";
import { ME, MeResponse, } from "queries";
import { useNotifications } from "providers/Notification";
import Box from "@mui/material/Box";
import UserInfo from "./UserInfo";
import useUserProfile from "hooks/useUserProfile";
import { useNavigate } from "react-router-dom";
import { insertApiResponseDataDog, insertApiResponseDataDogError } from "utils";
import { useLocation } from 'react-router-dom';

const PersonalInfoChange = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const notify = useNotifications();
    const { loading, data } = useQuery<MeResponse>(ME, {
        onError: (error: ApolloError) => {
            notify.error(error.message);
            insertApiResponseDataDog("MeApi(error)", error, "error")
            insertApiResponseDataDogError(error, "MeApi(error)");
        },
        onCompleted(data) {
            insertApiResponseDataDog("MeApi(success)", data, "info")

        },
    });

    const id = String(data?.me?.id)
    const { loading: loadingInfo, data: Info } = useUserProfile(id);
    const userType = data?.me.userType;
    const isAthlete = userType === "athlete";
    if (loading) return <CircularProgress />;
    if (loadingInfo) return <CircularProgress />;
    const onHandler = () => {
        navigate('/personalinformation')
    }

    return (
        <Box sx={{ position: 'absolute' }}>
            <Stack direction="row" alignItems="flex-start" marginBottom={2} color="secondary.dark" spacing={2}>
                Personal Information
            </Stack>
            {data && isAthlete &&
                <UserInfo
                    {...data?.me}
                    jerseyNumber={Info?.userProfile.athleteSport.jerseyNumber || "0"}
                    athlete_class={Info?.userProfile.athleteSport.sportDetails.athlete_class || ""}
                    sport={String(Info?.userProfile.athleteSport.sport.name || '')}
                    position={Info?.userProfile.athleteSport.sportDetails.position || ''}
                    homeTown={Info?.userProfile.hometown || ''}
                    highSchool={Info?.userProfile.highSchool || ''}
                    school={String(Info?.userProfile.school.name || '')}
                    onHandler={onHandler}
                />
            }
        </Box>
    );
};

export default PersonalInfoChange;
