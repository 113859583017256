import React from "react";
import { RequireAuth } from ".";
import { Route } from "react-router-dom";
import Reports from "pages/Universities/Reports/Reports";

export const UniversitiesRoutes = () => (
    <Route element={<RequireAuth userTypes={["school_faculty"]} />}>
        <Route path="/reports" element={<Reports />} />
    </Route>
);
