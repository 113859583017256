import * as React from 'react';
import Button from '@mui/material/Button';


export interface DialogTitleProps {
    id: string;
    children?: React.ReactNode;
    onClose: () => void;
}
function TermsAndCondition() {

    return (
        <div>
            Please accept the {" "}
            <Button variant="text" onClick={() => { window.open('https://www.mynilpay.org/termsandconditions', '_blank'); }}>
                terms &amp; conditions.
            </Button>
            {" "} before completing your profile and verifying your identity.

        </div>
    );
}

export default TermsAndCondition;