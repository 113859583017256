
import ReactGA from "react-ga4"

const useGAEventTracker = (category = "Event Category") => {
    const trackEvent = (action = "actions", label = "label") => {
        ReactGA.event({ category, action, label });
    };
    return trackEvent;
};
export default useGAEventTracker;
