import React, { useState, useEffect } from "react";
import { FeedbackModal } from "components/FeedbackModal/FeedbackModal";
import { Navigate, useSearchParams } from "react-router-dom";
import { useAppState } from "providers/AppState";
import Box from "@mui/material/Box";
// import Header from "components/Header";
import SearchAthlete from "components/SearchAthlete";
function Home() {

  const [searchParams] = useSearchParams();
  const [showFeedbackModal, setShowFeedbackModal] = useState(false);
  const {
    searchString,
    setSearchString,
    publicProfile,
  } = useAppState();


  useEffect(() => {
    const transaction = searchParams.get("transaction");
    if (transaction === "completed") {
      setShowFeedbackModal(true);
    }
  }, [searchParams]);

  if (publicProfile) {
    // if the user comes from a public profile
    // redirect to the same profile after they register
    return <Navigate to={`/fanhome/athlete/${publicProfile}`} replace />;
  }


  return (
    <>
      <Box>
        {showFeedbackModal && (
          <FeedbackModal
            open={showFeedbackModal}
            setOpen={setShowFeedbackModal}
            multi={searchParams.get("multi")}
          />
        )}
        {/* <Header text="My Home" /> */}
        <SearchAthlete
          searchString={searchString}
          setSearchString={setSearchString}
        />
      </Box>

    </>
  );
}

export default Home;
