/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import { ATHLETE_EMAIL_SEARCH } from "queries";
import { athleteEmailSearchResponse } from "types";
import { Divider } from "@mui/material";
import { useForm } from "react-hook-form";
import { useLazyQuery } from "@apollo/client";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { SearchResult } from "./index";
import { useNavigate } from "react-router-dom";
import { athleteEmailValidationRegex, insertApiResponseDataDog, insertApiResponseDataDogError } from "utils";

interface SearchForm {
  email: string;
}

export interface SearchStepProps {
  result: SearchResult | null;
  setEmail: React.Dispatch<React.SetStateAction<string>>;
  setResult: React.Dispatch<React.SetStateAction<SearchResult | null>>;
  setShowRegister: React.Dispatch<React.SetStateAction<boolean>>;
  backToLogin: () => void;
}

const SearchStep = ({
  result,
  setResult,
  setEmail,
  setShowRegister,
  backToLogin,
}: SearchStepProps) => {
  const navigate = useNavigate();
  const [athleteEmailSearch, { error }] = useLazyQuery<athleteEmailSearchResponse>(ATHLETE_EMAIL_SEARCH, {
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      insertApiResponseDataDog("athleteEmailSearchApi(success)", data, "info")
      setResult(data.athleteEmailSearch);
    },
    onError(error) {
      insertApiResponseDataDogError(error, "athleteEmailSearchApi(error)");
      insertApiResponseDataDog("athleteEmailSearchApi(error)", error, "error")
    }
  });

  const { register, handleSubmit, formState, reset } = useForm<SearchForm>({
    defaultValues: {
      email: "",
    },
    mode: "all",
  });

  const onSubmit = async ({ email }: SearchForm) => {
    try {
      await athleteEmailSearch({ variables: { email } });
      setEmail(email);
    } catch (error: any) {
      insertApiResponseDataDogError(error, "athleteEmailSearchApi(error)");
      insertApiResponseDataDog("athleteEmailSearchApi(error)", error, "error")
      //console.log(error);

    }
  };

  const handleRegister = () => {
    navigate("/register/athlete/request");
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack direction="column" alignItems="flex-start" spacing={1}>
        <TextField
          autoFocus
          margin="dense"
          label="Email"
          placeholder="Enter email"
          InputLabelProps={{
            shrink: true,
          }}
          type="text"
          fullWidth
          autoComplete="none"
          error={Boolean(formState.isDirty && formState.errors.email)}
          disabled={formState.isSubmitting}
          helperText={
            (formState.isDirty && formState.errors.email?.message) || " "
          }
          {...register("email", {
            required: "Email is required",
            pattern: {
              value: athleteEmailValidationRegex(),
              message: "Invalid email address",
            },
          })}
        />
      </Stack>

      {error?.message == "This email id is already registered, Try login with your credentials." && !result && (
        <Box marginBottom={6}>
          <Typography fontSize={20} fontWeight={600} marginBottom={"24px"}>
            This email id is already registered, Try login with your credentials.
          </Typography>

          <Button variant="text" onClick={backToLogin}>
            <Typography fontSize={16} fontWeight={500}>
              Sign in
            </Typography>
          </Button>
        </Box>
      )}
      {error?.message == "Athlete not found, verify that the email is correct" && !result && (
        <Box marginBottom={6}>
          <Typography fontSize={20} fontWeight={600} marginBottom={"24px"}>
            EMAIL NOT CONNECTED TO PROFILE
          </Typography>
          <Typography fontSize={16} fontWeight={500} marginBottom={"24px"} color={"#808080"}>
            We don’t have your school email. Click “Claim your Account” and get the money waiting for you.
          </Typography>
          <Button variant="text" onClick={handleRegister}>
            <Typography fontSize={16} fontWeight={500}>
              Claim your Account
            </Typography>
          </Button>

          <Typography fontSize={16} fontWeight={500}>
            or
          </Typography>

          <Button variant="text" onClick={backToLogin}>
            <Typography fontSize={16} fontWeight={500}>
              Sign in
            </Typography>
          </Button>
        </Box>
      )}

      {result && (
        <Stack textAlign="left" spacing={2}>
          <Typography fontSize={20} fontWeight={600} marginBottom={"24px"}>
            Is this you?
          </Typography>

          <Stack spacing={1}>
            <Typography fontSize={12} fontWeight={300}>
              Name
            </Typography>
            <Typography>
              {result.firstName} {result.lastName}
            </Typography>
          </Stack>
          <Divider />

          <Stack spacing={1}>
            <Typography fontSize={12} fontWeight={300}>
              School
            </Typography>
            <Typography>{result.school.name}</Typography>
          </Stack>
          <Divider />

          <Stack spacing={1}>
            <Typography fontSize={12} fontWeight={300}>
              Sport
            </Typography>
            <Typography>
              {result.sports.map((sport) => sport.name).join(",")}
            </Typography>
          </Stack>
          <Divider />
          <Stack direction={"row"} spacing={2} justifyContent="space-around">
            <Button
              data-testid="no-button"
              onClick={() => {
                setResult(null);
                setEmail("");
                reset({ email: "" });
              }}
            >
              No, Not Me
            </Button>
            <Button
              data-testid="yes-button"
              onClick={() => setShowRegister(true)}
              variant="contained"
              sx={{
                padding: "15px 30px",
              }}
            >
              Yes
            </Button>
          </Stack>
        </Stack>
      )}

      {!result && (
        <Box>
          <Button
            startIcon={
              formState.isSubmitting ? <CircularProgress size={20} /> : null
            }
            data-testid="submit-button"
            type="submit"
            variant="contained"
            disabled={!formState.isValid || formState.isSubmitting}
            fullWidth
          >
            Submit
          </Button>
        </Box>
      )}

    </form>
  );
};

export default SearchStep;
