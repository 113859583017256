import React from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import DoneImage from "assets/images/done_all.png";
import { useNavigate } from "react-router-dom";

function Success() {
  const navigate = useNavigate();

  return (
    <Box marginTop={{ xs: "10px", md: "90px" }} padding="20px">
      <Stack spacing={2}>
        <Typography>Your sponsorship request has been submitted</Typography>
        <Box margin="auto">
          <img src={DoneImage} alt="check mark" height={126} width={126}></img>
        </Box>
        <Box padding={4}>
          <Button variant="contained" onClick={() => navigate("/home")}>
            Back to Home
          </Button>
        </Box>
      </Stack>
    </Box>
  );
}

export default Success;
