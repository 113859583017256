import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Box from "@mui/material/Box";
import FanHome from './FanHome';
import { useNotifications } from "providers/Notification";
import { SSOModel } from "components/SSOModel";
import { ME, MeResponse } from "queries";
import { ApolloError, useQuery } from "@apollo/client";
import { CircularProgress } from "@mui/material";
import { insertApiResponseDataDog, insertApiResponseDataDogError } from "utils";

function FanHomePage() {
  const params = useParams();
  const notify = useNotifications()
  const navigate = useNavigate()
  const { loading, data } = useQuery<MeResponse>(ME, {
    onError: (error: ApolloError) => {
      insertApiResponseDataDog("MEApi(error)", error, "error")
      insertApiResponseDataDogError(error, "MEApi(error)");
      notify.error(error.message);
    },
    onCompleted(data) {
      insertApiResponseDataDog("MEApi(success)", data, "info")
    },
  });
  const onGotoAthelete = () => {
    navigate('/fanhome');
    localStorage.setItem('setClearData', "clearData")

    // localStorage.setItem('transactionStatus', "")

  }
  const [termsModel, setTermsModel] = React.useState(true);

  useEffect(() => {
    localStorage.setItem("errCode", "");
    if (params.errorCode == "200") {
      notify.error("Something went wrong. Try again later");
    } else if (params.errorCode == "100") {
      notify.error("Payment Session expired");
    }
  }, [params])

  if (loading) { return <CircularProgress />; }
  return (
    <>
      {data?.me.termsAccepted === null && <SSOModel
        open={termsModel}
        setOpen={setTermsModel} />}
      <Box>
        <FanHome onGotoAthelete={onGotoAthelete} />
      </Box>
    </>
  );
}

export default FanHomePage;
