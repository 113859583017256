/* eslint-disable @typescript-eslint/no-explicit-any */
import { ApolloError } from "@apollo/client";
import { useNotifications } from "../providers/Notification";
import { useQuery } from "@apollo/client/react/hooks";
import { ADMIN_ImageSingleData } from "../queries";
import { ImageSingleDataResponse } from "../types";

function useSingleData(imageid: any) {
    const notify = useNotifications();

    const { loading, data, error } = useQuery<ImageSingleDataResponse>(ADMIN_ImageSingleData, {
        variables: { id: imageid?.id },
        onError: (error: ApolloError) => {
            notify.error(error.message || "Something went wrong. Try again later");
        },
    });

    if (data) return data;
    if (loading || error) return null;
    return null;
}

export default useSingleData;