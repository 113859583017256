import React, { useState } from "react";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";

interface ReadMoreProps {
  text: string;
  characterLimit?: number;
}

const ReadMore = ({ text, characterLimit = 250 }: ReadMoreProps) => {
  const [open, setOpen] = useState(false);
  const isLong = text.length > characterLimit;
  const summary = isLong ? text.slice(0, characterLimit) + "..." : null;

  const handleReadMore = () => {
    setOpen(!open);
  };

  if (!isLong) {
    return (
      <Typography className="readMoreContent" fontSize={12} fontWeight={400}>
        {text}
      </Typography>
    );
  }

  return (
    <>
      <Typography className="readMoreContent" fontSize={12} fontWeight={400}>
        {open ? text : summary}
      </Typography>
      <Button className="readMoreButton" onClick={handleReadMore}>Read {open ? "less" : "more"}</Button>
    </>
  );
};

export default ReadMore;
