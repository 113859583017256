import React from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

function Home() {
  return (
    <Box>
      <Stack direction="row" spacing={2}>
        <Typography fontSize="20" fontWeight="600">
          Admin home page coming soon
        </Typography>
      </Stack>
    </Box>
  );
}

export default Home;
