import React from "react";
// import { ApolloError, useQuery } from "@apollo/client";
import { CircularProgress, Stack } from "@mui/material";
// import { ME, MeResponse, } from "queries";
// import { useNotifications } from "providers/Notification";
import Box from "@mui/material/Box";
import Form from "./Form";
import SchoolForm from './SchoolForm';
import UserInfo from "./UserInfo";
import useUserProfile from "hooks/useUserProfile";
import { Navigate, useNavigate } from "react-router-dom";
import moment from "moment";
// import { insertApiResponseDataDog, insertApiResponseDataDogError } from "utils";
import useUser from "hooks/useUser";
import AccountInfo from "pages/Athlete/accountInfo/info";

const PersonalInfo = () => {

  // const notify = useNotifications();
  const userInfo = useUser();
  if (!userInfo) return <CircularProgress />;

  const { refetch, ...data } = userInfo
  // const { loading, data, refetch } = useQuery<MeResponse>(ME, {
  //   onError: (error: ApolloError) => {
  //     notify.error(errormessage);
  //     insertApiResponseDataDogError(error, "MEApi(error)");
  //     insertApiResponseDataDog("MEApi(error)", error, "error")
  //   },
  //   onCompleted(data) {
  //     insertApiResponseDataDog("MEApi(success)", data, "info")
  //   },
  // });

  const id = String(data?.id)
  const { loading: loadingInfo, data: Info } = useUserProfile(id);
  const userType = data?.userType;
  const isAthlete = userType === "athlete";
  const isFan = userType === "fan";
  const isAdmin = userType === "admin"
  const isSchool = userType === "school_faculty"
  const navigate = useNavigate();
  const navigation = () => {
    navigate('requestchanges')
  }

  if (isAthlete && !data?.athleteCheckoutOnboarded && !data?.ckoSubEntityDdStatus && !data?.ckoSubEntityBankStatus)
    return <Navigate to="/home/payment" replace />;
  if (isAthlete && (
    data?.ckoSubEntityDdStatus == null ||
    ['requirements_due', 'rejected'].includes(data?.ckoSubEntityDdStatus as any) ||
    (!['verified', 'pending'].includes(data?.ckoSubEntityBankStatus as any))
  )) return <Navigate to="/home/PersonalDetails" replace />;
  if (isAthlete && (data?.ckoSubEntityBankStatus === 'pending' || data?.ckoSubEntityDdStatus === 'pending')) {
    return <AccountInfo />
  }
  if (loadingInfo) return <CircularProgress />;

  return (
    <Box sx={{ position: 'absolute', padding: '0 8px' }}>
      <Stack direction="row" alignItems="flex-start" marginBottom={2} color="secondary.dark" spacing={2}>
        Personal Information
      </Stack>
      {data && isSchool &&
        <SchoolForm
          {...data}
          dob={moment(data?.birthday).format('YYYY-DD-MM')}
          school={data?.school}
          refetch={refetch} />}
      {data && isFan &&
        <Form
          {...data}
          dob={moment(data?.birthday).format('YYYY-DD-MM')}
          refetch={refetch} />}
      {data && isAdmin &&
        <Form
          {...data}
          dob={moment(data?.birthday).format('YYYY-DD-MM')}
          refetch={refetch} />}

      {data && isAthlete &&
        <UserInfo
          {...data}
          jerseyNumber={Info?.userProfile.athleteSport.jerseyNumber || "0"}
          athlete_class={Info?.userProfile.athleteSport.sportDetails.athlete_class || ""}
          sport={String(Info?.userProfile.athleteSport.sport.name || '')}
          position={Info?.userProfile.athleteSport.sportDetails.position || ''}
          hometown={Info?.userProfile.hometown || ''}
          highSchool={Info?.userProfile.highSchool || ''}
          school={String(Info?.userProfile.school.name || '')}
          onHandel={navigation}
        />
      }
    </Box>
  );
};

export default PersonalInfo;
