export const Post = (
    url: string,
    body: any = {},
    isJson = false
) => {
    return fetchApi(
        `${url}`,
        {
            ...defaultRequestInit("post"),
            body: body,
        },
        isJson
    );
}

export const Put = (
    url: string,
    body: any = {},
    isJson = false
) => {
    return fetchApi(
        `${url}`,
        {
            ...defaultRequestInit("put"),
            body: body,
        },
        isJson
    );
}

const defaultRequestInit = (method: string): RequestInit => {
    return {
        method,
        headers: new Headers({
            "X-Requested-With": "XMLHttpRequest",
            "Authorization": `Bearer ${localStorage.getItem("token")}`,
            "append": 'application/json'
        }),
    };
}

const fetchApi = async (
    input: RequestInfo,
    init?: RequestInit,
    isJson = false
): Promise<any> => {
    return await new Promise((resolve, reject) => {
        fetch(input, init)
            .then(async (res: Response) => await handleResponse(res, isJson))
            .then(async (it) => {
                resolve(it);
            }).catch((err) => {
                reject(err)
            })
    })

}

const handleResponse = async (
    res: Response,
    isJson = false
) => {
    const response = isJson ? await res.json() : res;
    if (isJson && response.status === 401) {
        window.location.assign("/login");
        throw res;
    } else {
        return response;
    }
};