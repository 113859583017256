import { gql } from "@apollo/client";

export const SEARCH_ALL_SCHOOLS = gql`
  query SearchSchools(
    $searchString: String! = ""
    $limit: Int = 50
    $page: Int = 1
  ) {
    searchSchools(searchString: $searchString, limit: $limit, page: $page) {
      itemTotal
      pageTotal
      pageNumber
      limit
      items {
        id
        nickname
        mascot
        name
        stateAbbrev
      }
    }
  }
`;


export const SEARCH_ALL_SCHOOLS_LIST = gql`
  query SearchSchools(
    $searchString: String! = ""
    $limit: Int = 1500
    $page: Int = 1
  ) {
    searchSchools(searchString: $searchString, limit: $limit, page: $page) {
      itemTotal
      pageTotal
      pageNumber
      limit
      items {
        id
        nickname
        mascot
        name
        stateAbbrev
      }
    }
  }
`;

export const FILTER_SCHOOL = gql`
  query filterSchools(
    $filterParams: SchoolFilterParams!
    $limit: Int = 5
    $page: Int = 1
  ) {
    filterSchools(filterParams: $filterParams, limit: $limit, page: $page) {
      itemTotal
      pageTotal
      pageNumber
      limit
      items {
        id
        nickname
        mascot
        name
        stateAbbrev
      }
    }
  }
`;

export const SCHOOL = gql`
  query school($id: String!) {
    school(id: $id) {
      id
      name
    }
  }
`;
