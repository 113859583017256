/* eslint-disable @typescript-eslint/no-explicit-any */
import { Box, CircularProgress, Stack, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import Header from "components/Header";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import { Controller, useForm } from "react-hook-form";
import './bank.css'
import { ApolloError, useQuery } from "@apollo/client";
import { athletesBankAccountDetailsResponse } from "types/bankAccount";
import { bankAccountDetails } from "queries/bankAccount";
import { insertApiResponseDataDog, insertApiResponseDataDogError } from "utils";
import { useNotifications } from "providers/Notification";
import AddAccountButton from "../AddAccountButton";
import useUser from "hooks/useUser";
import { Navigate } from "react-router-dom";
import { FileUpload } from "../../../components/FileUpload/fileupload";
import { Put } from "providers/Fetch";
import AccountInfo from "../accountInfo/info";

interface PersonalDetailsFormValues {
    bankAccountType: string;
    routingNumber: string;
    accountNumber: string;
    bankStatementDocumentFileFront: File | null
}

const BankDetails = () => {
    const notify = useNotifications();

    const user = useUser();
    if (user === null) return <CircularProgress />;

    if (!user.athleteCheckoutOnboarded && !user?.ckoSubEntityDdStatus && !user?.ckoSubEntityBankStatus)
        return <Navigate to="/home/payment" replace />;
    if (
        user?.ckoSubEntityDdStatus == null ||
        ['requirements_due', 'rejected'].includes(user?.ckoSubEntityDdStatus as any) ||
        (!['verified', 'pending'].includes(user?.ckoSubEntityBankStatus as any))
    ) return <Navigate to="/home/PersonalDetails" replace />;

    if (user.ckoSubEntityBankStatus === 'pending' || user.ckoSubEntityDdStatus === 'pending') {
        return <AccountInfo />
    }

    const { loading, data, refetch } = useQuery<athletesBankAccountDetailsResponse>(bankAccountDetails, {
        onError: (error: ApolloError) => {
            const err: any = error
            insertApiResponseDataDog("bankAccountDetailsWithCko(error)", err, "error")
            insertApiResponseDataDogError(error, "bankAccountDetailsWithCko(error)");
            notify.error(error.message);
        }, onCompleted: (data) => {
            insertApiResponseDataDog("bankAccountDetailsWithCko(success)", data, "info")
        }
    });

    const form = useForm<PersonalDetailsFormValues>({
        defaultValues: {
            bankAccountType: "",
            accountNumber: "",
            routingNumber: "",
            bankStatementDocumentFileFront: null
        },
        mode: "all",
    });
    const { isDirty, errors } = form.formState;
    const [accountTypeBg, setBankAccounttypeBg] = useState(false);
    const [routerBg, setRouterBg] = useState(false);
    const [athleteStatus, setAthleteStatus] = useState<any>('')
    const [bankAccountBg, setBankAccountBg] = useState(false);
    const [bankFile, setBankFile] = useState<File | null>(form.getValues('bankStatementDocumentFileFront') || null);
    const [apiLoading, setApiLoading] = useState(false);

    useEffect(() => {
        if (data) {
            if (data.getAthleteAccountDetailsCko.status === 400) {
                setAthleteStatus(data.getAthleteAccountDetailsCko.status)
            } else if (data.getAthleteAccountDetailsCko.status === 200) {
                const res = JSON.parse(String(data.getAthleteAccountDetailsCko.data));
                if (res?.accountNumber) {
                    setBankAccountBg(true)
                }
                form.setValue('accountNumber', res?.accountNumber || '');
                form.setValue('bankAccountType', res?.bankAccountType || '');
                form.setValue('routingNumber', res?.routingNumber || '');
            } else if (data.getAthleteAccountDetailsCko.status === 201) {
                notify.success(data.getAthleteAccountDetailsCko.data)
            }

        }

    }, [data])

    useEffect(() => {
        refetch();
        user.refetch();
    }, []);

    if (loading) { return <CircularProgress />; }

    const onSubmit = async (form: PersonalDetailsFormValues) => {
        if (user?.ckoSubEntityBankStatus === 'pending') {
            notify.info('Your previous bank account submission is under review.')
            return;
        }
        const formdata = new FormData();
        formdata.append("bankStatementDocumentFileFront", form.bankStatementDocumentFileFront as any);
        formdata.append("bankAccountType", form.bankAccountType);
        formdata.append("accountNumber", form.accountNumber);
        formdata.append("routingNumber", form.routingNumber);
        setApiLoading(true);
        Put(`${process.env.REACT_APP_SERVER_URI}/checkout/athlete/bank-info`, formdata, true)
            .then(async (response) => response)
            .then((result: any) => {
                setApiLoading(false);
                insertApiResponseDataDog("updateAthleteBankAccountInfoApi(success)", result, "info");
                if (result.status > 300) {
                    try {
                        const responsAry: [{ code: string, errorName: string, description: string }] = JSON.parse(result.data) || []
                        const errorMessage = `${result.status + " : " + (typeof responsAry === 'string' || responsAry instanceof String ? responsAry : responsAry[responsAry.length - 1].description)}`
                        notify.error(errorMessage);
                    } catch (e) {
                        const responsAry: { status: string, data: string } = result;
                        const errorMessage = `${responsAry.status + " : " + responsAry.data}`
                        insertApiResponseDataDog("updateAthleteBankAccountInfoApi(error)", errorMessage, "error");
                        insertApiResponseDataDogError(errorMessage, "updateAthleteBankAccountInfoApi(error)");
                        notify.error(errorMessage);
                    }
                } else {
                    notify.success(result?.data || "");
                    refetch();
                    setBankAccounttypeBg(false);
                    setRouterBg(false);
                    setBankFile(null);
                }
            }
            ).catch((error) => {
                setApiLoading(false);
                insertApiResponseDataDogError(error, "updateAthleteBankAccountInfoApi(error)");
                insertApiResponseDataDog("updateAthleteBankAccountInfoApi(error)", error, "error")
                notify.error(error);
            })
    }
    return (<>
        {athleteStatus && Number(athleteStatus) === 400 ?
            <Box marginTop={{ xs: "10px", md: "30px" }} padding="20px">
                <Stack textAlign="left">
                    <Typography fontSize="20px" fontWeight="600">
                        Please add this account
                    </Typography>
                    <AddAccountButton />
                </Stack>
            </Box> :
            <form onSubmit={form.handleSubmit(onSubmit)}>
                <Box className="ath-personal-details">
                    <Header text="Athlete payment info" />
                    <Typography fontSize="17px" fontWeight="600" color="red">
                        If you update your banking information, you will need to complete verification again by our bank processor
                    </Typography>
                    <Box sx={{ mt: 4 }} textAlign={"left"} className="personal-details__form">
                        <Stack sx={{ mt: 2 }} direction="column" alignItems="flex-start" className="bankinfo">
                            <Controller
                                name="bankAccountType"
                                control={form.control}
                                defaultValue=""
                                rules={{
                                    required: "Bank Account Type is required",
                                }}
                                render={({ field }) => (
                                    <TextField sx={{ mt: 3 }}
                                        select
                                        margin="dense"
                                        data-testid="NameOnAccount"
                                        label="Bank Account Type"
                                        placeholder="Bank Account Type"
                                        className={accountTypeBg ? "dropMenu" : ""}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        fullWidth
                                        autoComplete="none"
                                        inputProps={{
                                            "data-testid": "bankAccountType",
                                        }}
                                        error={Boolean(isDirty && errors.bankAccountType)}
                                        disabled={apiLoading}
                                        {...field}
                                    >
                                        <MenuItem value={'CHECKING'}>CHECKING</MenuItem>
                                        <MenuItem value={'SAVINGS'}>SAVINGS</MenuItem>

                                    </TextField>
                                )}
                            />
                            <Controller
                                name="routingNumber"
                                control={form.control}
                                defaultValue=""
                                rules={{
                                    required: "Routing Number is required",
                                    minLength: { value: 9, message: "Please Enter 9 minimum number" }
                                }}
                                render={({ field: { onChange, value, name, ref } }) => (
                                    <TextField sx={{ mt: 3 }}
                                        margin="dense"
                                        data-testid="BankId"
                                        label="Routing Number"
                                        placeholder="Routing Number"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        fullWidth
                                        autoComplete="none"
                                        inputProps={{
                                            "data-testid": "bankId",
                                        }}
                                        error={Boolean(isDirty && errors.routingNumber)}
                                        disabled={apiLoading}
                                        helperText={(isDirty && errors.routingNumber?.message) || " "}
                                        className={routerBg ? "textFiled_bg" : ""}
                                        name={name}
                                        value={value}
                                        ref={ref}
                                        onChange={(e) => {
                                            const regex = /^[0-9\b]+$/;
                                            const isNumber = regex.test(e.target.value);
                                            if ((e.target.value && !isNumber) || e.target.value.length > 9) {
                                                return;
                                            }
                                            onChange(e);
                                        }}
                                    />
                                )}
                            />
                            <Controller
                                name="accountNumber"
                                control={form.control}
                                defaultValue=""
                                rules={{
                                    required: "Bank Account Number is required",
                                    minLength: { value: 6, message: "Please enter 6 minimum digits number" },
                                    maxLength: { value: 34, message: "Please enter 34 max digits number" }
                                }}
                                render={({ field: { onChange, value, name, ref } }) => (
                                    <TextField sx={{ mt: 3 }}
                                        margin="dense"
                                        data-testid="Bank Account Id"
                                        label="Bank Account Number"
                                        placeholder="Bank Account Number"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        className={bankAccountBg ? "textFiled_bg" : ""}
                                        fullWidth
                                        autoComplete="none"
                                        inputProps={{
                                            "data-testid": "accountNumber",
                                        }}
                                        error={Boolean(isDirty && errors.accountNumber)}
                                        disabled={apiLoading}
                                        helperText={(isDirty && errors.accountNumber?.message) || " "}
                                        name={name}
                                        value={value}
                                        ref={ref}
                                        onChange={(e) => {
                                            const regex = /^[0-9\b]+$/;
                                            const isNumber = regex.test(e.target.value);
                                            if ((e.target.value && !isNumber)) {
                                                return;
                                            }
                                            setBankAccountBg(true);
                                            onChange(e);
                                        }}
                                    />
                                )}
                            />
                            <Box component={'span'} sx={{ mt: 3, width: "100%" }}>
                                <Typography
                                    style={{ color: '#592EE5', width: '100%', display: 'flex' }}
                                    component={'label'}
                                >
                                    Upload bank statement
                                </Typography>
                                <FileUpload
                                    disabled={apiLoading}
                                    acceptFileType=".jpg,.jpeg,.png,.pdf"
                                    setFile={(f) => {
                                        setBankFile(f);
                                        form.setValue('bankStatementDocumentFileFront', f);
                                    }}
                                    file={bankFile}
                                    maxFileSize={4000}
                                    formatErrorMessage="JPEG, JPG, PNG, or PDF"
                                />
                            </Box>
                        </Stack>
                    </Box>
                    <Button
                        sx={{ mt: 8 }}
                        startIcon={apiLoading ? <CircularProgress size={20} /> : null}
                        type="submit"
                        fullWidth
                        color={"primary"}
                        variant="contained"
                        disabled={Boolean(errors.accountNumber || errors.bankAccountType || errors.routingNumber || !bankFile || apiLoading)}
                    >
                        Save
                    </Button>
                </Box >
            </form>
        }
    </>
    )
}

export default BankDetails;
