/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { Box, Button, CircularProgress, Typography, useMediaQuery } from "@mui/material";
import { useForm } from "react-hook-form";
import { EMAIL_REGEX } from "constant";
import { ApolloError, useMutation, useQuery } from "@apollo/client";
import { GET_ATHELETS_PROFILE, requestPersonalInfoChange } from "queries";
import { useNotifications } from "providers/Notification";
import { insertApiResponseDataDog, insertApiResponseDataDogError } from "utils";
import { datadogLogs } from "@datadog/browser-logs";
import AthleteUpdateProfile from "../PersonalInfo/AthleteUpdateProfile";
import { athleteDetailsProfile } from "queries/athleteProfile";

export interface ConfirmRequestPersonalInfoResponse {
  requestPersonalInfoChange: boolean
}

interface Props {
  firstName: string;
  lastName: string;
  email: string;
  nickname: string;
  jerseyNumber: string;
  sport: string;
  position: string;
  school: string;
  athlete_class: string;
  highSchool: string;
  homeTown: string;
  onHandler: () => void;
  athleteProfileDetails?: any
}

interface personalInfoProps {
  firstName: string;
  lastName: string;
  email: string;
  nickname: string;
  jersyNumber: string;
  sport: string;
  position: string;
  school: string;
  class: string;
  highSchool: string;
  homeTown: string;
  comment: string;
  athleteProfileDetails?: any
}
interface updateAthlete {
  AddressLine1: string,
  AddressLine2: string,
  city: string,
  state: string,
  zip: string,
  phone: string
}


const UserInfo = ({ firstName, lastName, email, jerseyNumber, sport, position, school, athlete_class, highSchool, homeTown, onHandler }: Props) => {
  const isMobile = useMediaQuery("(max-width:600px)");
  const { data: atheleteDetails } = useQuery<athleteDetailsProfile>(GET_ATHELETS_PROFILE, {
    onError: (error: ApolloError) => {
      insertApiResponseDataDogError(error, "getAthleteProfileDetailsCko(error)");
      insertApiResponseDataDog("getAthleteProfileDetailsCko(error)", error, "error")
    },
    onCompleted(data) {
      insertApiResponseDataDog("getAthleteProfileDetailsCko(success)", data, "info")
    }
  });

  let athleteProfileDetails;
  if (typeof (atheleteDetails?.getAthleteProfileDetailsCko.data) === "string") {
    if (atheleteDetails?.getAthleteProfileDetailsCko.data == "" || atheleteDetails?.getAthleteProfileDetailsCko.status == "400") {
      athleteProfileDetails = "empty"
    } else {
      athleteProfileDetails = JSON.parse(atheleteDetails?.getAthleteProfileDetailsCko.data);

    }
  }

  const [personalInformation, setPersonalInformation] = React.useState({
    firstName: '',
    lastName: '',
    email: email,
    jersyNumber: '',
    sport: '',
    position: '',
    school: '',
    class: '',
    highSchool: '',
    homeTown: '',
    comment: '',
    nickname: '',

  })


  const form = useForm<personalInfoProps>({
    mode: 'all',
    defaultValues: {
      firstName: firstName,
      lastName: lastName,
      email: email,
      jersyNumber: jerseyNumber,
      sport: sport,
      position: position,
      school: school,
      class: athlete_class,
      homeTown: homeTown,
      highSchool: highSchool == "" ? undefined : highSchool,
      comment: undefined
    }
  })


  const userChangedValue = (evt: any) => {
    const value = evt.target.value;

    if (value === "") {
      setPersonalInformation(
        {
          ...personalInformation,
          [evt.target.name]: "removed"
        })
    } else {
      setPersonalInformation(
        {
          ...personalInformation,
          [evt.target.name]: value
        })
    }
  }
  const notify = useNotifications();
  const [reqPersonalInfoChang] = useMutation<ConfirmRequestPersonalInfoResponse, personalInfoProps>(requestPersonalInfoChange);
  const onSubmit = async (form: personalInfoProps) => {
    personalInformation.comment = form.comment;
    try {
      const result = await reqPersonalInfoChang({
        variables: {
          ...personalInformation,
        },
      })
      if (!result) {
        notify.error("Something went wrong. Try again later");
      }
      if (result.data?.requestPersonalInfoChange == true) {
        datadogLogs.logger.log("requestPersonalInfoChangeApi(success)", result.data, "info");
        notify.success("Your Request Changes has been Submitted");
        onHandler()
      } else {
        notify.info("Your Request Changes Failed!")
      }
    } catch (error) {
      insertApiResponseDataDogError(error, "requestPersonalInfoChangeApi(error)");
      insertApiResponseDataDog("requestPersonalInfoChangeApi(error)", error, "error");
      notify.error(error);
    }

  };

  const { isDirty, errors, isSubmitting, isValid } = form.formState;

  return (
    <Box
      sx={{
        '& .MuiTextField-root': { m: 1, width: '25ch' },
      }}
    >
      <form onSubmit={form.handleSubmit(onSubmit)} className="profileInfo">
        <Stack direction="row" alignItems="flex-start" spacing={2}>
          <Stack display={'flex'} position={'relative'} >
            <Typography position={'absolute'} left={76} color='red'>*</Typography>
            <TextField
              inputProps={{ "data-testid": "firstName" }}
              disabled={isSubmitting}
              label="First Name"
              type="text"
              margin="dense"
              variant="standard"
              error={Boolean(isDirty && Boolean(errors.firstName))}
              helperText={(isDirty && errors.firstName?.message) || " "}
              fullWidth
              autoComplete="none"
              {...form.register("firstName", {
                required: "First Name is required",
              })}
              InputLabelProps={{
                shrink: true,
              }}
              name="firstName"
              onChange={userChangedValue}
            />
          </Stack>
          <Stack display={'flex'} position={'relative'} marginLeft={'0 !important'} >
            <Typography position={'absolute'} left={76} color='red'>*</Typography>

            <TextField
              inputProps={{ "data-testid": "lastName" }}
              disabled={isSubmitting}
              margin="dense"
              label="Last Name"
              type="text"
              variant="standard"
              fullWidth
              autoComplete="none"
              helperText={(isDirty && errors.lastName?.message) || " "}

              error={Boolean(isDirty && Boolean(errors.lastName))}

              {...form.register("lastName", {
                required: "Last Name is required",
              })}
              InputLabelProps={{
                shrink: true,
              }}
              name="lastName"
              onChange={userChangedValue}

            />
          </Stack>
        </Stack>
        <Stack>
          <TextField
            inputProps={{ "data-testid": "email" }}
            disabled
            margin="dense"
            label="Email"
            type="text"
            InputLabelProps={{
              shrink: true,
            }}
            error={Boolean(isDirty && Boolean(errors.email))}
            helperText={(isDirty && errors.email?.message) || " "}
            {...form.register("email", {
              required: "Email is required",
              pattern: {
                value: EMAIL_REGEX,
                message: "Invalid email address",
              },
            })}
            variant="standard"
            fullWidth
            autoComplete="none"
          />
        </Stack>
        <br />
        <Stack direction="row" alignItems="flex-start" spacing={2}>
          Athlete Information
        </Stack>
        <Stack direction="row" alignItems="flex-start" spacing={2}>
          <TextField
            inputProps={{ "data-testid": "school" }}
            disabled={isSubmitting}
            margin="dense"
            label="School"
            type="text"
            InputLabelProps={{
              shrink: true,
            }}

            {...form.register("school")}
            variant="standard"
            fullWidth
            autoComplete="none"
            name="school"
            onChange={userChangedValue}
          />
          <TextField
            inputProps={{ "data-testid": "sport" }}
            disabled={isSubmitting}
            margin="dense"
            label="Sport"
            type="text"
            InputLabelProps={{
              shrink: true,
            }}
            {...form.register("sport")}
            variant="standard"
            fullWidth
            autoComplete="none"
            name="sport"
            onChange={userChangedValue}

          />
        </Stack>
        <Stack direction="row" alignItems="flex-start" spacing={2}>
          <TextField
            inputProps={{ "data-testid": "position" }}
            disabled={isSubmitting}
            margin="dense"
            label="Position"
            type="text"
            InputLabelProps={{
              shrink: true,
            }}
            {...form.register("position")}
            variant="standard"
            fullWidth
            autoComplete="none"
            name="position"
            onChange={userChangedValue}

          />
          <TextField
            inputProps={{ "data-testid": "jerseyNumber" }}
            disabled={isSubmitting}
            margin="dense"
            label="Jersey Number"
            type="text"
            InputLabelProps={{
              shrink: true,
            }}
            {...form.register("jersyNumber")}
            variant="standard"
            fullWidth
            autoComplete="none"
            name="jersyNumber"
            onChange={userChangedValue}

          />
        </Stack>
        <Stack direction="row" alignItems="flex-start" spacing={2}>
          <TextField
            inputProps={{ "data-testid": "athlete_class" }}
            disabled={isSubmitting}
            margin="dense"
            label="Class"
            type="text"
            InputLabelProps={{
              shrink: true,
            }}
            {...form.register("class")}
            variant="standard"
            fullWidth
            autoComplete="none"
            name="class"
            onChange={userChangedValue}
          />
          <TextField
            inputProps={{ "data-testid": "hometown" }}
            disabled={isSubmitting}
            margin="dense"
            label="Home Town"
            type="text"
            InputLabelProps={{
              shrink: true,
            }}
            {...form.register("homeTown")}
            variant="standard"
            fullWidth
            autoComplete="none"
            name="homeTown"
            onChange={userChangedValue}

          />
        </Stack>
        <Stack>
          <TextField
            inputProps={{ "data-testid": "highSchool" }}
            disabled={isSubmitting}
            margin="dense"
            label="High School"
            type="text"
            InputLabelProps={{
              shrink: true,
            }}
            {...form.register("highSchool")}
            variant="standard"
            fullWidth
            autoComplete="none"
            onChange={userChangedValue}
            name="highSchool"

          />
        </Stack>
        <Stack display={'flex'} position={'relative'} >
          <Typography position={'absolute'} left={72} color='red'>*</Typography>
          <TextField
            inputProps={{ "data-testid": "comment" }}
            disabled={isSubmitting}
            margin="dense"
            label="Comment"
            type="textarea"
            InputLabelProps={{
              shrink: true,
            }}
            error={Boolean(isDirty && Boolean(errors.comment))}
            helperText={(isDirty && errors.comment?.message) || " "}
            {...form.register("comment", {
              required: "Comment is Required",
            })}
            variant="standard"
            fullWidth
            autoComplete="none"
            name="comment"
            onChange={userChangedValue}

          />
        </Stack>

        <Button
          data-testid={"requestbutton"}
          style={{ width: isMobile ? "85%" : "400px" }}
          type="submit"
          variant="contained"
          startIcon={isSubmitting ? <CircularProgress size={20} /> : null}
          disabled={!isValid || isSubmitting}
        >
          Submit Request
        </Button>

        <AthleteUpdateProfile
          AddressLine1={athleteProfileDetails?.addressLine1}
          AddressLine2={athleteProfileDetails?.addressLine2}
          city={athleteProfileDetails?.city}
          state={athleteProfileDetails?.state}
          zip={athleteProfileDetails?.zip}
          phone={athleteProfileDetails?.phone}
        />

      </form>
    </Box>

  )
};

export default UserInfo;
