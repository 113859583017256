import React from "react";
import Box from "@mui/material/Box";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import SportIcon from "../SportIcon";
import { Sport } from "../../types";

interface Props {
  sport: Sport;
  handleClick: (x: Sport) => void;
}

export const SportCard = ({ sport, handleClick }: Props) => (
  <ListItem
    sx={{
      paddingRight: 0,
      paddingLeft: 0,
    }}
  >
    <ListItemButton
      onClick={() => handleClick(sport)}
      sx={{
        backgroundColor: "background.paper",
        padding: "11px 24px",
        "&:hover": {
          backgroundColor: "secondary.light",
        },
      }}
    >
      <Stack
        direction="row"
        spacing={2}
        justifyContent="space-between"
        sx={{
          width: "100%",
        }}
      >
        <Stack direction="row" alignItems={"center"}>
          <SportIcon sportName={sport.name} />
          <Box marginLeft={2}>
            <Typography fontSize={14} fontWeight="600" color="primary.main">
              {sport.name}
            </Typography>
          </Box>
        </Stack>
      </Stack>
    </ListItemButton>
  </ListItem>
);
