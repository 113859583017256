import { Athlete, PaginatedResponse } from ".";

export interface School {
  id: string;
  mascot: string;
  name: string;
  nickname: string;
  stateAbbrev: string;
  __typename: "School";
}

export enum SchoolDisplay {
  mascot = "mascot",
  name = "name",
  nickname = "nickname",
}

interface SchoolItems extends PaginatedResponse {
  items: School[];
}
export interface SchoolsResponse {
  searchSchools: SchoolItems;
}

export interface SchoolResponse {
  school: { id: string; name: string };
}

export interface FilterSchoolsResponse {
  filterSchools: SchoolItems;
}

export interface AthletesByTeamResponse {
  athletesByTeam: Athlete[];
}
