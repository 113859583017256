
/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { useLazyQuery, useMutation } from '@apollo/client';
import { ADMIN_Sponsor_Data, ADMIN_Sponsor_Update } from 'queries';
import { useNotifications } from 'providers/Notification';
import { apolloClient } from 'providers/Apollo';
import { Button, CircularProgress, Dialog, DialogActions, InputAdornment, MenuItem, Pagination, Stack, TextField, Typography, useMediaQuery } from '@mui/material';
import './style.css';
import { insertApiResponseDataDog, insertApiResponseDataDogError } from 'utils';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import moment from 'moment';



// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default function SponsorshipDetailsData(props: any) {

    const [open, setOpen] = React.useState(false);
    const [sponsorshipID, setSponsorshipID] = React.useState("");
    const [searchTerm, setSearchTerm] = React.useState('');
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [searchCheck, setSearchCheck] = React.useState(false);
    const [page, setPage] = React.useState(0);
    const [dateCheck, setDateCheck] = React.useState(false);
    const [currentDateCheck, setCurrentDateCheck] = React.useState(false);
    const [typeValue, setTypeValue] = React.useState(null);
    const [amountText, setAmountText] = React.useState('');
    const [statusValue, setStatusValue] = React.useState('');
    const notify = useNotifications();
    const [selectedDate, setSelectedDate] = React.useState(null);
    const isMobile = useMediaQuery("(max-width:600px)");

    const handleDateChange = (date: any) => {
        setSelectedDate(date);
    };

    const [getSponsorshipData, { data, loading }] = useLazyQuery(ADMIN_Sponsor_Data, {
        onCompleted(data) {
            insertApiResponseDataDog("SearchfansListApi(success)", data, "info");
        },
        onError(error) {
            insertApiResponseDataDogError(error, "SearchfansListApi(error)");
            insertApiResponseDataDog("SearchfansListApi(error)", error, "error");
            notify.error(error.message)
        }
    });
    const typeOfSponsorship = [
        "All",
        "MVP Tier NFT",
        "Autograph",
        "Display NIL on custom content",
        "Personal Appearance",
        "Product Endorsement",
        "Post on social media",
        "Record a video"
    ];
    React.useEffect(() => {
        let tempTypeValue: any = ""
        if (typeValue === "MVP Tier NFT") {
            tempTypeValue = "MVP"
        } else {
            tempTypeValue = typeValue
        }
        getSponsorshipData({
            variables: {
                limit: 5,
                page: 1,
                filterParams: {
                    searchquery: searchTerm,
                    type: tempTypeValue
                }
            },
        })
    }, [typeValue, searchTerm])

    const handleClose = () => {
        setOpen(false);
        setSelectedDate(null);
        setAmountText('');
        setDateCheck(false);
    };

    const handleClickOpen = (id: any) => {
        setOpen(true);
        setSelectedDate(null);
        setAmountText('');
        setSponsorshipID(id)
        setStatusValue("Approved")
        setDateCheck(false);
    };
    const handleSearchChange = (event: any) => {
        setSearchTerm(event.target.value);
        setSearchCheck(true)
    };
    const typeHandle = (e: any) => {
        setTypeValue(e)
    }
    const amountHandler = (e: any) => {
        if (e.target.value === 0) {
            setAmountText("")
        } else {
            setAmountText(e.target.value)
        }
    }
    const [updateSposorship, { loading: DataLoading }] = useMutation(
        ADMIN_Sponsor_Update
    );

    const onApprove = async () => {
        try {
            const res: any = await updateSposorship({
                variables: {
                    status: statusValue,
                    amountReceived: amountText,
                    amountReceivedDate: selectedDate,
                    id: sponsorshipID
                }
            });
            insertApiResponseDataDog("SponsorshipRequestApproved(success)", res, "info")
            notify.success("Approved Successfully")
        } catch (error) {
            insertApiResponseDataDogError(error, "SponsorshipRequestApproved(error)");
            insertApiResponseDataDog("SponsorshipRequestApproved", error, "error")
            notify.error(error);
        }
        apolloClient.cache.reset();
        setOpen(false)
    }

    const handleChangePage = (event: unknown, newPage: number) => {
        let tempTypeValue: any = ""
        if (typeValue === "MVP Tier NFT") {
            tempTypeValue = "MVP"
        } else {
            tempTypeValue = typeValue
        }
        getSponsorshipData({
            variables: {
                limit: 5,
                page: newPage,
                filterParams: {
                    searchquery: searchTerm,
                    type: tempTypeValue
                }
            },
        })
        setPage(newPage)
    }
    const currentDate = dayjs();

    return (<>
        <Stack className="mainTableContainer">
            <Stack direction="column" alignItems="flex-start" spacing={2} marginBottom={2}>
                <Typography marginBottom={2} fontWeight={700}>
                    Sponsorship Details
                </Typography>
            </Stack>
            <Typography marginBottom={2} fontWeight={500} color="#592EE5" fontSize={17}>Search by Name or Email and Sponsorship type </Typography>
            <Stack direction={isMobile ? "column" : "row"} alignItems="flex-start" spacing={1}>
                <TextField
                    variant="outlined"
                    value={searchTerm}
                    onChange={handleSearchChange}
                    style={{ marginBottom: '1rem' }}
                    fullWidth
                    placeholder="Search by Name or Email"
                />
                <TextField
                    select
                    value={typeValue || ''}
                    margin="dense"
                    inputProps={{ "data-testid": "SponsorshipType" }}
                    placeholder="Enter Sponsorship Type"
                    InputLabelProps={{
                        shrink: true,
                    }}
                    sx={{ textAlign: "right" }}
                    fullWidth
                    autoComplete="none"
                    onChange={(e) => {
                        const selectedValue = e.target.value;
                        if (selectedValue === "All") {
                            typeHandle(null); // Reset the value to null or any other default value
                        }
                        else {
                            typeHandle(selectedValue);
                        }
                    }}
                >
                    {typeOfSponsorship.map((name) => (
                        <MenuItem key={name} value={name}>
                            {name}
                        </MenuItem>
                    ))}
                </TextField>
            </Stack>
        </Stack>
        <Stack direction={'column'} className="mainTableContainer" sx={{ ...(isMobile ? { width: '100%', position: 'absolute' } : {}) }}>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                sx={{
                    height: "480px",
                    maxwidth: "0px",
                    margin: "auto",
                    padding: "5px"
                }}
            >
                <Typography marginBottom={2} fontWeight={200} color="blue" fontSize={17} marginLeft={4} marginRight={4} marginTop={5}>  Are you sure, want to approve?</Typography>
                <Stack direction="column" alignItems="flex-start" spacing={3} marginLeft={4} marginRight={4}>
                    <TextField
                        value={amountText}
                        margin="dense"
                        inputProps={{ "data-testid": "Vendor" }}
                        label="Amount Recieved (optional)"
                        placeholder="$0.00"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    $
                                </InputAdornment>
                            )
                        }}
                        type="number"
                        fullWidth
                        autoComplete="none"
                        onChange={(e) => { amountHandler(e) }}
                    />
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                            value={selectedDate}
                            maxDate={currentDate}
                            onError={(error) => {
                                if (error === 'invalidDate') {
                                    setDateCheck(true);
                                } else {
                                    const currentDate = dayjs(); // Get the current date using the dayjs library or any other date library
                                    const selected = dayjs(selectedDate); // Parse the selected date using the same library

                                    if (selected.isAfter(currentDate)) {
                                        setDateCheck(false);
                                        setCurrentDateCheck(true);
                                    } else {
                                        setDateCheck(false);
                                        setCurrentDateCheck(false)
                                    }
                                }
                            }}
                            onChange={handleDateChange}
                            renderInput={(params: any) => <TextField {...params}
                                fullWidth
                                label="Payment Received Date (optional)"
                                InputLabelProps={{
                                    // readOnly: true,
                                    shrink: true,
                                }}
                                error={dateCheck || currentDateCheck}
                                helperText={dateCheck ? 'Date format should be MM/DD/YYYY' : currentDateCheck ? 'Date should not be more than current date' : ''}
                            />}
                        />
                    </LocalizationProvider>
                </Stack>
                <Stack alignItems="center">
                    {DataLoading ? <CircularProgress /> : ""}
                </Stack>
                <Stack direction="row" alignItems="flex-start" spacing={16} marginBottom={5} marginTop={5} marginLeft={4} marginRight={4} >
                    <DialogActions>
                        <Button onClick={handleClose} type="submit"
                            variant="contained" style={{ width: 120, fontSize: 10 }}>Cancel</Button>
                        <Button onClick={() => onApprove()} type="submit" disabled={dateCheck || currentDateCheck}
                            variant="contained" style={{ width: 120, fontSize: 10 }}>
                            Confirm
                        </Button>
                    </DialogActions>
                </Stack>
            </Dialog>
            <Paper sx={{ width: '100%', marginTop: '20px', marginBottom: '20px' }}>
                {loading ? <CircularProgress /> : ""}
                <TableContainer sx={{ maxHeight: 'auto' }}>
                    <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Sponsor Name</TableCell>
                                <TableCell>Company Name</TableCell>
                                <TableCell>Company Email ID</TableCell>
                                <TableCell>Phone Number </TableCell>
                                <TableCell>Sponsorship Type  </TableCell>
                                <TableCell>Amount</TableCell>
                                <TableCell>Created Date</TableCell>
                                <TableCell></TableCell>

                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data && data?.getSponsorShip?.items
                                .map((items: any) => {
                                    return (<TableRow key={items.id}>
                                        <TableCell>{items.name}</TableCell>
                                        <TableCell>{items.companyName}</TableCell>
                                        <TableCell>{items.email}</TableCell>
                                        <TableCell>{items.phoneNumber}</TableCell>
                                        <TableCell>{items.type === "MVP" ? "MVP Tier NFT" : items.type}</TableCell>
                                        <TableCell>{items.amount}</TableCell>
                                        <TableCell>{moment(items.createdAt).format("MM-DD-YYYY HH:mm:ss")}</TableCell>
                                        <TableCell >
                                            {items.type === "MVP" ?
                                                <Button disabled={items.status === "Approved"} onClick={() => handleClickOpen(items.id)} type="submit"
                                                    variant="contained" style={{ width: 120, fontSize: 10 }}>
                                                    {items.status === "Approved" ?
                                                        "Approved" : "Approve"}
                                                </Button> : ""}
                                        </TableCell>
                                    </TableRow>
                                    );
                                })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
            {data && data?.getSponsorShip?.items.length === 0 &&
                <Typography fontWeight={300}>
                    No Records Found
                </Typography>
            }
            <Pagination count={Math.ceil(data?.getSponsorShip?.itemTotal / 5) || 0} page={page} onChange={handleChangePage} />

        </Stack>
    </>
    );
}
