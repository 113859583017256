/* eslint-disable @typescript-eslint/no-explicit-any */

import React, { useState } from "react";
import { athleteEmailSearchResponse } from "types";
import { LogoHeader } from "../LogoHeader";
import { useNavigate } from "react-router-dom";
// import { useNotifications } from "providers/Notification";
import Grid from "@mui/material/Grid";
import RegisterStep, { RegisterForm } from "./RegisterStep";
import SearchStep from "./SearchStep";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { Box, Button } from "@mui/material";


export type SearchResult = athleteEmailSearchResponse["athleteEmailSearch"];

const AthleteSignUp = () => {
  const navigate = useNavigate();
  const [registerData, setRegisterData] = useState<RegisterForm | null>(null);
  const [showRegister, setShowRegister] = useState(false);
  const [email, setEmail] = useState("");
  const [result, setResult] = useState<SearchResult | null>(null);



  const backToLogin = () => navigate("/login");


  return (
    <><Grid container spacing={2} justifyContent="center">
      <LogoHeader />
      <Grid item xs={10} md={4} lg={3} marginTop={8}>
        <Stack marginBottom="30px">
          <Typography fontSize={14} fontWeight={300}>
            Enter your .edu email so we can find your profile and verify your
            identity
          </Typography>
        </Stack>

        {!showRegister && (
          <SearchStep
            result={result}
            setResult={setResult}
            setEmail={setEmail}
            setShowRegister={setShowRegister}
            backToLogin={backToLogin} />
        )}

        {result && showRegister && (
          <RegisterStep
            result={result}
            email={email}
            setRegisterData={setRegisterData} />
        )}
      </Grid>
    </Grid>
      <Stack
        marginTop="10px"
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing={0}
        width="100%"
      >
        <Box marginBottom="0px" display="flex" alignItems="center">
          <Typography>Trouble signing up?</Typography>
          <Button variant="text" onClick={() => window.zE.activate()}>
            <Typography fontSize={16} fontWeight={500}>
              Chat with us
            </Typography>
          </Button>
        </Box>
      </Stack>

    </>
  );
};

export default AthleteSignUp;
