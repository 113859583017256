import React from "react";
import { Athlete, FavoriteAthletesResponse } from "types";
import { AthleteCard } from "components/AthleteCard/AthleteCard";
import { FAVORITES_ATHLETES } from "queries";
import { useNavigate } from "react-router-dom";
import { useNotifications } from "providers/Notification";
import { useQuery } from "@apollo/client";
import Box from "@mui/material/Box";
import InfiniteScroll from "components/InfiniteScroll";
import List from "@mui/material/List";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import MNPLogo from "assets/images/crown_logo.svg";
import { CircularProgress, Grid } from "@mui/material";
import { insertApiResponseDataDog, insertApiResponseDataDogError } from "utils";

export function FavoritesAthletes() {
  const notify = useNotifications();
  const navigate = useNavigate();

  const { data, loading, fetchMore } = useQuery<FavoriteAthletesResponse>(
    FAVORITES_ATHLETES,
    {
      fetchPolicy: "no-cache",
      onError: (error) => {
        insertApiResponseDataDog("favoriteAthletesApi(error)", error, "error");
        insertApiResponseDataDogError(error, "favoriteAthletesApi(error)");
        notify.error("Something went wrong. Try again later")
      },
      onCompleted(data) {
        insertApiResponseDataDog("favoriteAthletesApi(success)", data, "info")

      },
    }
  );

  const handleClick = (x: Athlete) => navigate(`/favorites/athlete/${x.id}`);

  if (loading) return <CircularProgress />;

  return (
    <Box>
      {data && (
        <>
          <Typography fontWeight="600" textAlign={"left"} marginTop={2}>
            Favorite Athletes ({data.favoriteAthletes.itemTotal})
          </Typography>
          {data.favoriteAthletes.itemTotal == 0 ?
            <Grid container justifyContent="center">
              <Grid item >
                <Box marginTop={6} >
                  <img src={MNPLogo} alt="myNILpay logo" />
                </Box>
                <Typography component={'h3'} fontWeight="900" color='secondary.dark' textAlign={"center"} marginTop={1}>
                  Setup Favorites.
                </Typography>
                <Typography component={'h3'} fontWeight="400" color='secondary.dark' textAlign={"center"} marginTop={1}>
                  You haven’t favorited any athletes yet.
                </Typography>
              </Grid>
            </Grid> : ''}
          <Box textAlign="left" marginTop={2}>
            <InfiniteScroll
              fetchMore={fetchMore}
              page={data.favoriteAthletes.pageNumber + 1}
            >
              <List>
                {data.favoriteAthletes.items.map((item) => (
                  <AthleteCard
                    key={item.id}
                    isFavorite
                    athlete={item}
                    handleClick={handleClick}
                  />
                ))}
              </List>
            </InfiniteScroll>
            {data.favoriteAthletes.pageNumber <
              data.favoriteAthletes.pageTotal && (
                <Stack spacing={1} marginBottom={4}>
                  <Skeleton variant="rectangular" height={50} />
                  <Skeleton variant="rectangular" height={50} />
                  <Skeleton variant="rectangular" height={50} />
                </Stack>
              )}
          </Box>
        </>
      )}
    </Box>
  );
}
