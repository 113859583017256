import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Box, CircularProgress, TextField } from "@mui/material";
import { useNotifications } from "providers/Notification";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import DoneImage from "assets/icons/athlete-verification-email-check.png"
import { useMutation } from "@apollo/client";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { insertApiResponseDataDog, insertApiResponseDataDogError } from "utils";
import { ATHLETE_EMAIL_CODE_VERIFY, ATHLETE_RESEND_EMAIL_VERIFY_CODE } from '../../../queries';
import { useForm, Controller } from "react-hook-form";
import mnpLogo from "assets/images/mnp_logo.svg";

import './style.css';
interface FormData {
    verifyCode: string
}
function AtheleteEmailVerification() {
    const navigate = useNavigate();
    const location = useLocation();
    const notify = useNotifications();
    const { formState, setValue, control } = useForm<FormData>({
        defaultValues: {
            verifyCode: "",
        },
        mode: "all",
    });

    const [verficationNumber, setVerificationNumber] = React.useState('')
    const [athleteEmail, { loading }] = useMutation(
        ATHLETE_EMAIL_CODE_VERIFY
    );
    const [athleteResendCode, { loading: resend }] = useMutation(
        ATHLETE_RESEND_EMAIL_VERIFY_CODE
    );

    // eslint-disable-next-line
    const athlete_Email: any = location.state;
    // eslint-disable-next-line
    const handleVerificationCodeChange = (event: any) => {
        const sanitizedValue = event.target.value.replace(/\D/g, '');
        setVerificationNumber(sanitizedValue)
        setValue('verifyCode', sanitizedValue);

    };

    const onclickVerify = async () => {
        try {
            const response = await athleteEmail({
                variables: {
                    code: verficationNumber,
                    email: athlete_Email.email
                }
            });
            if (response.data.confirmRequestedAthlete) {
                insertApiResponseDataDog("confirmRequestedAthlete(success)", response.data, "info")
                navigate('/register/athlete')
            } else {
                notify.error("Unable to verify user. please check code again");
            }
        } catch (error) {
            insertApiResponseDataDogError(error, "confirmRequestedAthlete(error)");

            insertApiResponseDataDog("confirmRequestedAthlete(error)", error, "error")
            notify.error(error);
        }
    }
    const onResendCode = async () => {
        try {
            const response = await athleteResendCode({
                variables: {
                    email: athlete_Email.email
                }
            });
            if (response.data.resendRequestedAthleteCode) {
                notify.success("Successfully sent");

                insertApiResponseDataDog("resendRequestedAthleteCode(success)", response.data, "info")
            }
        } catch (error) {
            insertApiResponseDataDogError(error, "resendRequestedAthleteCode(error)");
            insertApiResponseDataDog("resendRequestedAthleteCode(error)", error, "error")
            notify.error(error);
        }
    }

    if (loading) return <CircularProgress />;

    return (
        <Grid container spacing={2} justifyContent="center">
            <Grid item xs={12}>
                <Box textAlign="left" marginTop={3} marginLeft={{ xs: 6, md: 15 }}>

                    <img src={mnpLogo} alt="myNILpay logo" />
                </Box>
            </Grid>
            <div className="atheletImg">
                <Box
                    sx={{ color: '#592ee5', height: '90vh' }}
                >
                    <Grid
                        container
                        height={"100%"}
                        justifyContent={"center"}
                        alignContent={"center"}
                        sx={{
                            padding: "20px 20px 0px 20px",
                        }}
                    >
                        <Grid item xs={12} md={6} maxWidth="800px">
                            <Stack spacing={2} sx={{ mt: 0 }}>

                                <Box margin="auto">
                                    <img
                                        src={DoneImage}
                                        alt="check mark"
                                        height={126}
                                        width={126}
                                    ></img>
                                </Box>
                                <Typography fontSize={16} fontWeight={600}>
                                    We have matched your details with our records
                                </Typography>
                                <Typography fontSize={12} fontWeight={400}>
                                    Verification code Sent to your email
                                </Typography>
                                <Box margin="auto">
                                    <form>
                                        <Controller
                                            name="verifyCode"
                                            control={control}
                                            defaultValue=""
                                            rules={{
                                                required: 'Verification code is required',
                                                pattern: {
                                                    value: /^\d{6}$/, // Regular expression to allow only 6 numeric characters
                                                    message: 'Verification code must be 6 numbers'
                                                }
                                            }}
                                            render={({ field }) => (
                                                <TextField
                                                    sx={{ width: '50%' }}
                                                    label="VerificationCode"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    type="text"
                                                    fullWidth
                                                    autoComplete="none"
                                                    margin="dense"
                                                    inputProps={{ "data-testid": "lastName" }}
                                                    error={Boolean(formState.isDirty && formState.errors.verifyCode)}
                                                    disabled={formState.isSubmitting}
                                                    helperText={(formState.isDirty && formState.errors.verifyCode?.message) || ' '}
                                                    {...field}
                                                    onChange={handleVerificationCodeChange}
                                                />
                                            )}
                                        />

                                        <Stack margin="auto" sx={{ width: 300, padding: '0px !important', marginTop: '-11px !important' }}>
                                            <span style={{ textAlign: 'right', cursor: 'pointer' }} onClick={onResendCode}>Resend</span>
                                        </Stack>
                                        <Box margin="auto" sx={{ width: 300, padding: 2 }}>
                                            <Button
                                                fullWidth
                                                color={"primary"}
                                                variant="contained"
                                                onClick={onclickVerify}
                                                disabled={!formState.isValid || formState.isSubmitting}
                                            >
                                                Continue
                                            </Button>
                                        </Box>
                                    </form>
                                </Box>

                            </Stack>
                        </Grid>
                    </Grid>
                </Box>
            </div>
        </Grid>

    )
}

export default AtheleteEmailVerification;