import React, { useState } from "react";
import { NumericFormat, NumberFormatValues } from "react-number-format";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import { MAX_LIMIT } from '../../constant'

interface CurrencyInputProps {
  onChange: (value: number) => void;
  lightStyle?: boolean;
  previousValue?: number | null;
}

export const CurrencyInput = ({
  previousValue,
  lightStyle,
  onChange,
}: CurrencyInputProps) => {
  const [values, setValues] = React.useState<NumberFormatValues>({
    floatValue: undefined,
    formattedValue: "",
    value: "",
  });
  const maxNumber = useState(10)

  React.useEffect(() => {
    if (previousValue && values.floatValue !== previousValue) {
      const newValues = { ...values };
      newValues.floatValue = previousValue;
      setValues(newValues);
    }
  }, [previousValue]);

  return (
    <FormControl
      sx={{
        "& .MuiOutlinedInput-root": {
          background: "transparent",
        },
        "& .MuiInputBase-input": {
          textAlign: "center",
          color: lightStyle ? "primary.main" : "#FFF",
          fontSize: "60px",
          fontWeight: "600",
          "::placeholder": {
            fontWeight: "600",
          },
        },
      }}
    >
      <NumericFormat
        prefix={"$"}
        value={values.floatValue}
        allowLeadingZeros={false}
        decimalScale={2}
        customInput={TextField}
        placeholder={"$20.00"}
        allowNegative={false}
        inputProps={{ maxLength: maxNumber }}
        isAllowed={(values) => {
          const number = Number(values.floatValue?.toFixed(2)) || 0;

          return number <= MAX_LIMIT;
        }}
        onValueChange={(value) => {
          onChange(Number(value.floatValue));
          setValues(value);
        }

        }
        /* eslint-disable  @typescript-eslint/no-explicit-any */
        onPaste={(pasteValue: any) => {
          pasteValue.preventDefault()
          return false;
        }}
      />
    </FormControl>
  );
};
