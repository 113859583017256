/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import { useForm } from "react-hook-form";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { useNotifications } from "providers/Notification";
import { useNavigate, useLocation } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { saveCouponCodeResponse, saveCouponCodes } from "types/promoCode";
import { ADMIN_saveCouponcode } from "queries";
import { insertApiResponseDataDog, insertApiResponseDataDogError } from "utils";
import { apolloClient } from "providers/Apollo";
import { Typography } from "@mui/material";
interface FormValues {
    name: string;
    promocode: string;
    email: string;
    mnpShare: string;
    fan: string;
    vendorShare: string;
}

function PromoCodeEdit() {
    const navigate = useNavigate();
    const location = useLocation();
    const item: any = location.state;
    const notify = useNotifications()
    const [saveCouponCodes] = useMutation<saveCouponCodeResponse, saveCouponCodes>(
        ADMIN_saveCouponcode
    );

    const [mnpShares, setmnpShares] = React.useState<any>(item.item.mnpShare);
    const [vendorShares, setVendorShares] = React.useState<any>(item.item.vendorShare);

    const { register, handleSubmit, formState, reset } = useForm<FormValues>({
        defaultValues: {
            name: item.item.firstName + "" + item.item.lastName,
            promocode: item.item.coupon,
            email: item.item.email,
            mnpShare: item.item.mnpShare,
            vendorShare: item.item.vendorShare

        },
        mode: "all",
    });
    const { isDirty, errors, isSubmitting, isValid } = formState;

    const onSubmit = async (form: FormValues) => {
        const vendorPrice = vendorShares.toString();
        const mnpPrice = mnpShares.toString();
        try {
            const res = await saveCouponCodes({
                variables: {
                    fanId: item.item.fanId,
                    mnpShare: mnpPrice,
                    vendorShare: vendorPrice,
                    coupon: form.promocode,
                    email: item.iitem.email
                },
            });
            insertApiResponseDataDog("saveCouponCodesApi(success)", res, "info")
            notify.success("save coupon Successfully");
            apolloClient.cache.reset();
            navigate('/promocode');
        } catch (error) {
            insertApiResponseDataDogError(error, "addorUpdateSplashImageDetailsApi(error)");
            insertApiResponseDataDog("saveCouponCodesApi(error)", error, "error")
            notify.error(error);
        }
        reset();
    };



    const onChangemnpShareShare = (e: any) => {
        // const arr = Array.from(e.target.value)

        const regex = /^[0-9\b]+$/;
        if (e.target.value === "" || regex.test(e.target.value)) {
            if (e.target.value <= 100) {
                setVendorShares(100 - e.target.value);
                setmnpShares(e.target.value);

            }
        }
    }

    const onchangeVendorPrice = (e: any) => {
        // const arr = Array.from(e.target.value)
        const regex = /^[0-9\b]+$/;
        if (e.target.value === "" || regex.test(e.target.value)) {
            if (e.target.value <= 100) {
                setmnpShares(100 - e.target.value);
                setVendorShares(e.target.value);

            }
        }
    }
    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Stack direction="column" alignItems="flex-start" spacing={2}>
                <Typography marginBottom={2}>
                    Edit Coupons
                </Typography>
                <TextField
                    margin="dense"
                    inputProps={{ "data-testid": "PromoCode" }}
                    label="Name"
                    placeholder="Enter name"
                    InputLabelProps={{
                        shrink: true,
                    }}
                    type="text"
                    fullWidth
                    autoComplete="none"
                    error={Boolean(isDirty && errors.name)}
                    disabled
                    helperText={(isDirty && errors.name?.message) || ""}
                    {...register("name", {
                        required: "Name is required",
                    })}
                />

                <TextField
                    margin="dense"
                    inputProps={{ "data-testid": "PromoCode" }}
                    label="Promo Code"
                    placeholder="Enter Promo Code"
                    InputLabelProps={{
                        shrink: true,
                    }}
                    type="text"
                    fullWidth
                    autoComplete="none"
                    error={Boolean(isDirty && errors.promocode)}
                    disabled={isSubmitting}
                    helperText={(isDirty && errors.promocode?.message) || ""}
                    {...register("promocode", {
                        required: "Promo Code is required",
                    })}
                />

                <TextField
                    margin="dense"
                    value={vendorShares || ""}
                    inputProps={{ "data-testid": "PromoCode" }}
                    label="Vendor Share"
                    placeholder="Enter Vendor Share"
                    InputLabelProps={{
                        shrink: true,
                    }}
                    type="text"
                    fullWidth
                    autoComplete="none"
                    error={Boolean(isDirty && errors.vendorShare)}
                    disabled={isSubmitting}
                    helperText={(isDirty && errors.vendorShare?.message) || ""}
                    {...register("vendorShare", {
                        required: "vendorShare is required",
                    })}
                    onChange={(e) => onchangeVendorPrice(e)}
                />
                <TextField
                    value={mnpShares || ""}
                    margin="dense"
                    inputProps={{ "data-testid": "PromoCode" }}
                    label="Mnp Share"
                    placeholder="Enter Mnp Share"
                    InputLabelProps={{
                        shrink: true,
                    }}
                    type="text"
                    fullWidth
                    autoComplete="none"
                    error={Boolean(isDirty && errors.mnpShare)}
                    disabled={isSubmitting}
                    helperText={(isDirty && errors.mnpShare?.message) || ""}
                    {...register("mnpShare", {
                        required: "Mnp is required",
                    })}
                    onChange={(e) => onChangemnpShareShare(e)}

                />
                <Button
                    startIcon={isSubmitting ? <CircularProgress size={20} /> : null}
                    data-testid="submitButton"
                    type="submit"
                    variant="contained"
                    fullWidth
                    disabled={!isValid || !isDirty || isSubmitting}
                >
                    Save Changes
                </Button>


            </Stack>
        </form>
    );
}

export default PromoCodeEdit;
