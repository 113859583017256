/* eslint-disable @typescript-eslint/no-explicit-any */
import { useLazyQuery, useMutation } from "@apollo/client";
import { Autocomplete, Box, Button, CircularProgress, Pagination, Paper, Stack, TextField, Typography, useMediaQuery } from "@mui/material";
import PasswordField from "components/PasswordField";
import { PASSWORD_REGEX } from "constant";
import { useNotifications } from "providers/Notification";
import { SEARCH_ALL_SCHOOLS } from "queries";
import { getSchoolFacultiesList, schoolFacultySignUp } from "queries/schoolFacultySignUp";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import React, { useState } from "react";
import {
    useForm,
    Controller
} from "react-hook-form";
import { SchoolsResponse } from "types";
import { athleteEmailValidationRegex, insertApiResponseDataDog, insertApiResponseDataDogError } from "utils";
interface SignUpFormValues {
    firstName: string;
    lastName: string;
    birthday: string;
    email: string;
    // password: string;
    // confirmPassword: string;
    school: string,

}

export default function ManageSchoolUser() {
    const isMobile = useMediaQuery("(max-width:600px)");
    const [error] = useState(false);
    const { register, handleSubmit, formState, watch, control, reset } = useForm<SignUpFormValues>({
        defaultValues: {
            firstName: "",
            lastName: "",
            birthday: "",
            email: "",
            // password: "",
            // confirmPassword: "",
            school: ""
        },
        mode: "all",
    });
    const [schoolsList, setSchoolsList] = useState<any>([])
    const [page, setPage] = React.useState(0);

    const [schoolName, setSchoolName] = React.useState('');
    const [schoolId, setSchoolId] = React.useState('')
    // const [sportInputValue, setSportInputValue] = React.useState('');
    // const [sportName, setSportName] = React.useState('');
    const { isDirty, errors, isSubmitting } = formState;
    // const passwordValue = watch("password");
    // const confirmPasswordValue = watch("confirmPassword");

    const notify = useNotifications();

    const [searchSchool, { loading: schoolLoader }] = useLazyQuery<SchoolsResponse>(SEARCH_ALL_SCHOOLS, {
        onCompleted(data) {
            insertApiResponseDataDog("SearchSchoolsApi(success)", data, "info");
        },
        onError(error) {
            insertApiResponseDataDogError(error, "SearchSchoolsApi(error)");
            insertApiResponseDataDog("SearchSchoolsApi(error)", error, "error");
        },
    });



    const handleSearch = async (value: any) => {
        setSchoolName(schoolName)
        const res = await searchSchool({
            variables: {
                searchString: value
            }
        }
        )
        setSchoolsList(res?.data?.searchSchools?.items)

    };

    const [signUpSchoolFaculty, { loading }] = useMutation(
        schoolFacultySignUp
    );

    const [getSchollsList, { data, loading: schoolListLoader, refetch }] = useLazyQuery(getSchoolFacultiesList, {
        onCompleted(data) {
            insertApiResponseDataDog("SearchfansListApi(success)", data, "info");
        },
        onError(error) {
            insertApiResponseDataDogError(error, "SearchfansListApi(error)");
            insertApiResponseDataDog("SearchfansListApi(error)", error, "error");
            notify.error(error.message)
        }

    });

    React.useEffect(() => {
        getSchollsList({
            variables: {
                limit: 5,
                page: 1,
                type: "school_faculty"
            }
        })
        reset({
            firstName: "",
            lastName: "",
            birthday: "",
            email: "",
            // password: "",
            // confirmPassword: "",
            school: ""
        })

    }, [])

    const handleChangePage = (event: unknown, newPage: number) => {

        getSchollsList({
            variables: {
                limit: 5,
                page: newPage,
                type: "school_faculty"
            }
        })

        setPage(newPage)
    }



    const onSubmit = async ({ firstName, lastName, email }: SignUpFormValues) => {
        try {
            const res: any = await signUpSchoolFaculty({
                variables: {
                    data: {
                        firstName: firstName,
                        lastName: lastName,
                        email: email,
                        // password: password,
                        role: "school_faculty",
                        schoolId: schoolId
                    }
                }
            });
            notify.success("School User Updated Successfully")

            refetch()
            reset({
                firstName: "",
                lastName: "",
                birthday: "",
                email: "",
                // password: "",
                // confirmPassword: "",
                school: ""
            })
            insertApiResponseDataDog("SponsorshipRequestApproved(success)", res, "info")

        } catch (error) {
            reset({
                firstName: "",
                lastName: "",
                birthday: "",
                email: "",
                // password: "",
                // confirmPassword: "",
                school: ""
            })
            insertApiResponseDataDogError(error, "SponsorshipRequestApproved(error)");
            insertApiResponseDataDog("SponsorshipRequestApproved", error, "error")
            notify.error(error);
        }


        setPage(1)

    }

    if (loading) return <CircularProgress />

    return (<div>
        <form onSubmit={handleSubmit(onSubmit)}>

            <Stack direction="column" alignItems="flex-start" spacing={1}>
                <Typography marginBottom={2} fontWeight="light">
                    Enter your info below to get started!
                </Typography>

                <TextField

                    margin="dense"
                    data-testid="username"
                    label="FirstName"
                    placeholder="Enter FirstName"
                    InputLabelProps={{
                        shrink: true,
                    }}
                    type="text"
                    fullWidth
                    error={Boolean(isDirty && formState.errors.firstName)}
                    disabled={formState.isSubmitting}
                    helperText={
                        (formState.isDirty && formState.errors.firstName?.message) || " "
                    }
                    {...register("firstName", {
                        required: "FirstName is required",

                    })}
                />
                <TextField

                    margin="dense"
                    data-testid="username"
                    label="LastName"
                    placeholder="Enter LastName"
                    InputLabelProps={{
                        shrink: true,
                    }}
                    type="text"
                    fullWidth
                    error={Boolean(isDirty && formState.errors.lastName)}
                    disabled={formState.isSubmitting}
                    helperText={
                        (formState.isDirty && formState.errors.lastName?.message) || " "
                    }
                    {...register("lastName", {
                        required: "LastName is required",

                    })}
                />

                <TextField

                    margin="dense"
                    label="Email"
                    placeholder="Enter email"
                    InputLabelProps={{
                        shrink: true,
                    }}
                    type="text"
                    fullWidth
                    autoComplete="none"
                    error={Boolean(isDirty && errors.email)}
                    disabled={isSubmitting}
                    helperText={(isDirty && errors.email?.message) || " "}
                    {...register("email", {
                        required: error,
                        pattern: {
                            value: athleteEmailValidationRegex(),
                            message: "Invalid email address",
                        }

                    })}

                />
                {/* <Controller
                    name="password"
                    control={control}
                    rules={{
                        validate: (value) =>
                            !confirmPasswordValue ||
                            value === confirmPasswordValue ||
                            "The passwords do not match",
                        required: "Password is required",
                        minLength: {
                            value: 8,
                            message: "Minimum password length 8 characters",
                        },
                        pattern: {
                            value: PASSWORD_REGEX,
                            message:
                                "Please use uppercase letters, lowercase letters, special characters, and numbers",
                        },
                    }}
                    render={({ field }) => (
                        <PasswordField
                            inputProps={{ "data-testid": "password" }}
                            label="Password"
                            placeholder="Enter password"
                            error={isDirty && Boolean(errors.password)}
                            disabled={isSubmitting}
                            helperText={(isDirty && errors.password?.message) || " "}
                            {...field}
                            ref={null}

                        />
                    )}
                />
                <Stack width="100%">
                    <Controller
                        name="confirmPassword"
                        control={control}
                        rules={{
                            required: "Confirmed Password is required",
                            validate: (value) =>
                                value === passwordValue || "The passwords do not match",
                        }}
                        render={({ field }) => (
                            <PasswordField
                                inputProps={{ "data-testid": "confirmPassword" }}
                                label="Confirm Password"
                                placeholder="Enter password"
                                error={isDirty && Boolean(errors.confirmPassword)}
                                disabled={isSubmitting}
                                helperText={(isDirty && errors.confirmPassword?.message) || " "}
                                {...field}
                                ref={null}

                            />
                        )}
                    />

                </Stack> */}

                <Autocomplete
                    loading={schoolLoader}
                    id="combo-box-demo"
                    options={schoolsList || []}
                    sx={{ width: '100%' }}
                    onChange={(event: any, newValue: any | null) => {
                        setSchoolId(newValue?.id);
                    }}
                    getOptionLabel={(option: any) => option.name}
                    renderOption={(props, option) => (
                        <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props} key={option.id}>
                            <Typography align="left"> {option.name} </Typography>
                        </Box>
                    )}
                    renderInput={(params) =>
                        <TextField {...params} error={Boolean(isDirty && errors.school)}
                            disabled={isSubmitting}
                            value={schoolsList || ""}
                            helperText={isDirty && errors.school?.message || ""}
                            {...register("school", {
                                required: "Athlete's school is required",

                            })}
                            onChange={(e) => handleSearch(e.target.value)}
                            label="Search schools" />
                    }
                />



                <Button

                    data-testid="submit-button"
                    type="submit"
                    variant="contained"
                    disabled={!formState.isValid || formState.isSubmitting}
                    fullWidth
                >
                    Save
                </Button>



            </Stack>
            <Stack direction="column" alignItems="flex-start" spacing={1}>
                <Paper sx={{ width: '100%', marginTop: '20px', marginBottom: '20px', ...(isMobile ? { position: 'absolute' } : {}) }}>
                    {schoolListLoader ? <CircularProgress /> : ""}

                    <TableContainer sx={{ maxHeight: 'auto' }}>
                        <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>First Name</TableCell>
                                    <TableCell>Last Name</TableCell>
                                    <TableCell>Email</TableCell>
                                    <TableCell>School Name</TableCell>


                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {data && data?.users.items.map((items: any) => {
                                    return (<TableRow key={items.id}>
                                        <TableCell>{items.firstName}</TableCell>
                                        <TableCell>{items.lastName}</TableCell>
                                        <TableCell>{items.email}</TableCell>
                                        <TableCell>{items.highSchool}</TableCell>
                                    </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>

                    {data && data?.users?.items.length === 0 &&
                        <Typography fontWeight={300}>
                            No Records Found
                        </Typography>
                    }
                    <Pagination count={Math.ceil(data?.users?.itemTotal / 5) || 0} page={page} onChange={handleChangePage} />

                </Paper>
            </Stack>
        </form>
    </div>)

}