import { useState } from 'react';
import React, { useEffect } from "react";
import { Box, Typography } from '@mui/material';
import message from "assets/icons/identity/message.svg";
import useUserType from 'hooks/useUserType';
declare global {
  interface Window {
    zE: any;
  }
}

function Chat() {
  const userType = useUserType();
  const [isChatbotOpen, setIsChatbotOpen] = useState(false);
  const toggleChatbot = () => {
    if (typeof window.zE !== 'undefined') {
      window.zE.activate();
      if (isChatbotOpen) {
        window.zE('webWidget', 'show');
      } else {
        // window.zE('webWidget', 'hide');
        window.zE.activate();
      }
      setIsChatbotOpen(!isChatbotOpen);
    }
  };
  return (
    <>
      {userType === "school_faculty" ? '' :
        <div>
          <Typography className="contactHeader">
            Recommended contact option
          </Typography>
          <Box className="messagebutton" onClick={toggleChatbot}>
            <img src={message} width={20} height={20} className="imgIcon" />
            <Typography color={'#fff'} align={'center'} className="messageText" >
              Chat With Us
            </Typography>
          </Box>
        </div>
      }
    </>
  );
}

export default Chat;






