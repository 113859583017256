import React from "react";
import { ApolloError, useQuery } from "@apollo/client";
import { ATHLETE_PUBLIC_PROFILE } from "queries";
import { AthleteImage } from "./AthleteImage";
import { AthleteNotFound } from "components/AthleteNotFound/AthleteNotFound";
import { Dot } from "components/TextDot/TextDot";
import { LabelCount } from "components/LabelCount/LabelCount";
import { PayListButton } from "./PayListButton";
import { PublicProfileResponse } from "types";
import { useAppState } from "providers/AppState";
import { useNotifications } from "providers/Notification";
import { useParams, useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import List from "@mui/material/List";
import ReadMore from "components/ReadMore";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { insertApiResponseDataDog, insertApiResponseDataDogError } from "utils";

export const PublicProfile = () => {
  const navigate = useNavigate();
  const notify = useNotifications();
  const params = useParams();
  const athleteId = params.id || "";
  const appState = useAppState();

  const { data, loading } = useQuery<PublicProfileResponse>(
    ATHLETE_PUBLIC_PROFILE,
    {
      variables: { id: athleteId },
      onError: (error: ApolloError) => {
        notify.error(error.message || "Something went wrong. Try again later");
        insertApiResponseDataDog("athleteProfilePublicApi(error)", error, "error")
        insertApiResponseDataDogError(error, "athleteProfilePublicApi(error)");
      },
      onCompleted(data) {
        insertApiResponseDataDog("athleteProfilePublicApi(success)", data, "info")

      }
    }
  );

  if (loading) return <CircularProgress />;

  if (!data)
    return (
      <Box marginTop={4}>
        <AthleteNotFound handleBack={() => navigate("/login")} />
      </Box>
    );

  const { imageUrl, firstName, lastName, school } = data.athleteProfilePublic;
  const athleteSport = data.athleteProfilePublic.athleteSport;
  const athleteSports = data.athleteProfilePublic.athleteSports;
  const favorites = data.athleteProfilePublic.athleteProfileStats.favorites;
  const supporters = data.athleteProfilePublic.athleteProfileStats.supporters;

  const handleClick = () => {
    appState.setPublicProfile(athleteId);
    navigate("/register/fan");
  };

  return (
    <Stack
      maxWidth="600px"
      padding="10px"
      marginTop={{
        xs: "40px",
        sm: "90px",
      }}
      margin="auto"
    >
      <Stack
        direction="row"
        width="100%"
        justifyContent="start"
        alignItems="center"
      >
        <Box
          sx={{
            borderRadius: "50%",
            margin: "10px",
            overflow: "hidden",
            border: "solid 1px",
            borderColor: "text.disabled",
            height: "140px",
            minWidth: "140px",
          }}
        >
          <AthleteImage imageUrl={imageUrl} sport={athleteSport.sport.name} />
        </Box>
        <Stack
          direction={{ xs: "column", sm: "row" }}
          justifyContent="center"
          spacing={4}
          width="100%"
        >
          <LabelCount label="favorites" count={favorites} />
          <LabelCount label="supporters" count={supporters} />
        </Stack>
      </Stack>
      <Stack textAlign="left">
        <Typography fontSize={24} fontWeight={600} marginBottom={"16px"}>
          {firstName} {lastName}
        </Typography>

        <Typography fontSize={14} fontWeight={500} marginBottom={"4px"}>
          {athleteSport.sport.name}
          <Dot />
          {athleteSport.sportDetails.position}
          <Dot />
          &#35;{athleteSport.jerseyNumber}
        </Typography>

        <Stack direction="row" marginBottom={"16px"}>
          <Typography fontSize={14} fontWeight={600} textTransform="uppercase">
            {school.name}
          </Typography>
          <Dot />
          <Typography fontSize={14} fontWeight={500}>
            Student
          </Typography>
        </Stack>

        <Box marginBottom={"44px"}>
          <ReadMore text={athleteSport.sportDetails.bio} />
        </Box>
      </Stack>
      <Stack alignItems="center" width="100%" spacing={4}>
        <Button variant="contained" onClick={handleClick} fullWidth>
          Direct-pay Athlete
        </Button>
      </Stack>
      <List sx={{ width: "100%" }}>
        {athleteSports.map((item) => (
          <PayListButton
            key={item.id}
            title={`Direct-pay ${item.sport.name} Team`}
            subtitle={"Direct-pay multiple athletes or the whole team!"}
            onClick={handleClick}
          />
        ))}
        <PayListButton
          title={"Sponsorship"}
          subtitle={"Request Athlete Sponsorship"}
          onClick={handleClick}
        />
      </List>
    </Stack>
  );
};
