import React from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import DoneImage from "../../../assets/images/done_all.png";
import { useMutation } from "@apollo/client";
import { useNotifications } from "../../../providers/Notification";
import { IDENTITY_VERIFICATION } from "../../../queries";
import {
  identityVerificationResponse,
  identityVerificationArgs,
} from "../../../types";
import { insertApiResponseDataDog, insertApiResponseDataDogError } from "utils";

function BeginVerification() {
  const notify = useNotifications();

  const [initiateIdentityVerification] = useMutation<
    identityVerificationResponse,
    identityVerificationArgs
  >(IDENTITY_VERIFICATION);

  const handleClick = async () => {
    const BASE_URL = process.env.REACT_APP_URL;
    const returnUrl = `${BASE_URL}/home/identity`;

    try {
      const response = await initiateIdentityVerification({
        variables: { returnUrl },
      });
      insertApiResponseDataDog("initiateIdentityVerificationApi(success)", response.data, "info")
      const url = response.data?.initiateIdentityVerification;

      if (url) {
        window.location.replace(url);
      } else {
        notify.error("Something went wrong. Try again later");
      }
    } catch (error) {
      insertApiResponseDataDogError(error, "initiateIdentityVerificationApi(error)");
      insertApiResponseDataDog("initiateIdentityVerificationApi(error)", error, "error")
      notify.error(error);
    }
  };

  return (
    <Box marginTop={{ xs: "10px", md: "90px" }} padding="20px">
      <Stack spacing={2}>
        <Typography variant="h4" component="h1">
          CONGRATS
        </Typography>
        <Typography>Your account has been created</Typography>

        <Box margin="auto">
          <img src={DoneImage} alt="check mark" height={126} width={126}></img>
        </Box>
        <Typography fontSize={16} fontWeight={600}>
          Now, let’s verify your identity. Once started, this process cannot be
          restarted
        </Typography>
        <Box padding={4}>
          <Button variant="contained" onClick={handleClick}>
            Begin Verification
          </Button>
        </Box>
      </Stack>
    </Box>
  );
}

export default BeginVerification;
