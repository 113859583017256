import React, { useState } from "react";

import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
// import CircularProgress from "@mui/material/CircularProgress";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import AddAccountButton from "../AddAccountButton";
const PaymentAccount = () => {
  const navigationn = useNavigate()
  // const [loading] = useState(false);
  return (<>
    <Box marginTop={{ xs: "10px", md: "30px" }} padding="20px">
      <Stack textAlign="left">
        <Typography fontSize="20px" fontWeight="600">
          Your Payment Receiving Account
        </Typography>
        <Typography fontSize="14px" fontWeight="300">
          This is the account that will receive all payments from fans in the
          app
        </Typography>
        <AddAccountButton
        // loading={loading}
        />
      </Stack>
    </Box>
  </>
  );
};

export default PaymentAccount;
