import { Box, Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import React from 'react';
import './style.css';
import terms from 'assets/icons/terms.svg'



function Terms() {
    const onGotoTerms = () => {
        window.open('https://www.mynilpay.org/termsandconditions', "_blank")
    }
    const onGotoPrivacy = () => {
        window.open('https://www.mynilpay.org/privacypolicy', "_blank")
    }

    return (<>
        <Box>
            <Typography>
                Legal / Privacy
            </Typography>
        </Box>
        <Box className='mainContainer'>
            <Grid container>
                <Grid item xs={12}>
                    <Grid container>
                        <Grid xs={1}><img src={terms} /></Grid>
                        <Grid xs={11} className="temsText"> <Typography sx={{
                            paddingLeft: '15px'
                        }} align={'left'}>Terms and Conditions</Typography></Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} className="temsInner">
                    For the current version of the Terms and Conditions applicable to your use of this app, please go to  <a href="#" onClick={onGotoTerms}>https://www.mynilpay.org/termsandconditions</a>
                </Grid>

            </Grid>
        </Box>
        <Box className='temsSeprator'>
        </Box>

        <Box className='mainContainer'>
            <Grid container>
                <Grid item xs={12}>
                    <Grid container>
                        <Grid xs={1}><img src={terms} /></Grid>
                        <Grid xs={11} className="temsText"> <Typography sx={{
                            paddingLeft: '15px'
                        }} align={'left'}>Privacy Policy</Typography></Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} className="temsInner">
                    For the current version of the Terms and Conditions applicable to your use of this app, please go to <a href="#" onClick={onGotoPrivacy}>https://www.mynilpay.org/privacypolicy</a> </Grid>

            </Grid>
        </Box>
        <Box className='temsSeprator'>
        </Box>





    </>)

}

export default Terms;