import { useCallback } from "react";
import throttle from "lodash/throttle";

// eslint-disable-next-line
function useThrottle(callback: (...args: any[]) => void, seconds = 1000) {
  const throttled = useCallback(
    throttle(callback, seconds, { trailing: false }),
    []
  );

  return throttled;
}

export default useThrottle;
