import React from "react";
import { CurrencyInput } from "../CurrencyInput/CurrencyInput";
import { MAIN_GRADIENT } from "providers/Theme";
import { MINIMUM_PAYMENT } from "constant";
import { useNotifications } from "providers/Notification";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  textAlign: "center",
  width: "500px",
  background: MAIN_GRADIENT,
  minHeight: 400,
  color: "#FFF",
  borderRadius: "8px",
  padding: 4,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
};

interface Props {
  previousValue: number | null;
  onSubmit: (x: number) => void;
  lightStyle?: boolean;
}

function AmountInput({ previousValue, onSubmit, lightStyle }: Props) {
  const notify = useNotifications();
  const [value, setValue] = React.useState(0);
  const [minAmt, setMinAmt] = React.useState(false);

  const handleReview = (event: React.FormEvent) => {
    event.preventDefault();

    if (value < MINIMUM_PAYMENT) {
      notify.error(
        "Oops! myNILpay requires a minimum payment of $20",
        "Minimum Amount"
      );
    } else {
      onSubmit(value);
    }
  };
  React.useEffect(() => {
    if (previousValue) {
      setValue(previousValue)
    }
  }, [previousValue]);

  const handleChange = (newValue: number) => {
    if (newValue < 20) {
      setMinAmt(true)
    } else if (newValue > 19) {
      setMinAmt(false)

    } else if (Number.isNaN(newValue)) {
      setMinAmt(false)
    }
    setValue(newValue)
  }
  return (
    <Box
      data-testid="amountInputId"
      sx={{
        ...style,
        ...(lightStyle && {
          background: "#FFF",
          color: "primary.main",
        }),
        maxWidth: { xs: "98vw", sm: "500px" },
      }}
    >
      <form onSubmit={handleReview} style={{ width: "100%" }}>
        <Box width={"100%"}>
          <Box>
            {minAmt ?
              <Typography fontSize={14} fontWeight={500} margin={6}>
                *myNILpay requires a minimum payment of $20 to each player
              </Typography> : "Enter amount"}

            {value > 5000 ?
              <Typography fontSize={14} fontWeight={500} margin={6}>
                *myNILpay maximum in-app payment is $5,000. To send a higher amount contact: MVP@mynilpay.com
              </Typography> : ""
            }
            <Stack
              marginTop={4}
              marginBottom={4}
              direction={"row"}
              justifyContent={"center"}
              alignItems={"center"}
              textAlign={"center"}
            >
              <CurrencyInput
                onChange={handleChange}
                previousValue={previousValue}
                lightStyle={Boolean(lightStyle)}
              />
            </Stack>
          </Box>
          <Button
            type="submit"
            fullWidth
            disabled={value >= MINIMUM_PAYMENT && value <= 5000 ? false : true}
            onClick={handleReview}
            color={lightStyle ? "primary" : "secondary"}
            variant="contained"
          >
            Review
          </Button>
        </Box>
      </form>
    </Box>
  );
}

export default AmountInput;
