import React from "react";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { Box, Button, useMediaQuery } from "@mui/material";
import { useForm } from "react-hook-form";
import { UPDATE_ATHLETE_PROFILE } from "queries";
import { useMutation } from "@apollo/client";
import { getUpdateAthleteResposne } from "queries/athleteProfile";
import { insertApiResponseDataDog, insertApiResponseDataDogError } from "utils";
import { useNavigate } from "react-router-dom";
import { useNotifications } from "providers/Notification";
interface updateAthlete {
    AddressLine1: string,
    AddressLine2: string,
    city: string,
    state: string,
    zip: string,
    phone: string
}
const AthleteUpdateProfile = ({ AddressLine1, AddressLine2, city, state, zip, phone }: updateAthlete) => {
    const isMobile = useMediaQuery("(max-width:600px)");
    const navigate = useNavigate()
    const notify = useNotifications();

    const form = useForm<updateAthlete>({
        mode: 'all',
        defaultValues: {
            AddressLine1: AddressLine1,
            AddressLine2: AddressLine2,
            city: city,
            state: state,
            zip: zip,
            phone: phone
        }
    })
    const [zipCode, setZipCode] = React.useState(zip || "")
    const { isDirty, errors, isSubmitting, isValid } = form.formState;
    const [phoneNumber, setPhoneNumber] = React.useState(phone || "")
    const [updateAtheleteDetails, { loading }] = useMutation<getUpdateAthleteResposne>(UPDATE_ATHLETE_PROFILE, {

        onCompleted(data) {
            notify.success(data.updateAthleteProfileInfoForCko.data || "");
            insertApiResponseDataDog("updateAthleteProfileInfoForCkoApi(success)", data, "info")
        },
        onError(error) {
            notify.success(error.message);

            insertApiResponseDataDog("updateAthleteProfileInfoForCkoApi(error)", error, "error");
            insertApiResponseDataDogError(error, "updateAthleteProfileInfoForCkoApi(error)");
        },
    }
    );




    const onSubmit = async () => {
        try {
            const res = await updateAtheleteDetails({
                variables: {
                    data: {
                        addressLine1: form.getValues('AddressLine1'),
                        addressLine2: form.getValues('AddressLine2'),
                        city: form.getValues('city'),
                        state: form.getValues('state'),
                        zip: form.getValues('zip'),
                        phone: form.getValues('phone')
                    }
                }
            })

            insertApiResponseDataDog("updateAthleteProfileInfoCkoApi(success)", res, "info")
            navigate('/personalinformation')


        } catch (error: any) {
            insertApiResponseDataDogError(error, "updateAthleteProfileInfoCkoApi(error)");
            insertApiResponseDataDog("updateAthleteProfileInfoCkoApi(error)", error, "error")
            notify.error(error.message)
        }
    };


    const handleChangeZip = (e: any) => {
        const regex = /^[0-9\b]+$/;
        if (e.target.value === "" || regex.test(e.target.value)) {
            if (e.target.value.length <= 5) {
                setZipCode(e.target.value);
            }
        }
    }

    const handleChangePhoneNumber = (e: any) => {
        const arr = Array.from(e.target.value)
        if (arr[0] !== "0") {
            const regex = /^[0-9\b]+$/;
            if (e.target.value === "" || regex.test(e.target.value)) {
                if (e.target.value.length <= 10) {
                    setPhoneNumber(e.target.value)
                }
            }
        }
    };

    return (<>


        <Stack direction="row" alignItems="flex-start" spacing={2} marginTop={3} marginBottom={3}>
            Update Athlete Profile      </Stack>
        <Stack direction="row" alignItems="flex-start" spacing={2}>
            <TextField
                inputProps={{ "data-testid": "AddressLine1" }}
                margin="dense"
                label="AddressLine1"
                type="text"
                InputLabelProps={{
                    shrink: true,
                }}
                variant="standard"
                fullWidth
                autoComplete="none"
                disabled={isSubmitting}
                error={Boolean(isDirty && Boolean(errors.AddressLine1))}
                helperText={(isDirty && errors.AddressLine1?.message) || " "}
                {...form.register("AddressLine1", {
                    required: "AddressLine1  is required",
                    minLength: { value: 5, message: "Please enter 5 minimum characters" }

                })}

            />
            <TextField
                inputProps={{ "data-testid": "AddressLine2" }}
                margin="dense"
                label="AddressLine2"
                type="text"
                InputLabelProps={{
                    shrink: true,
                }}
                variant="standard"
                fullWidth
                autoComplete="none"
                error={Boolean(isDirty && Boolean(errors.AddressLine2))}
                helperText={(isDirty && errors.AddressLine2?.message) || " "}
                {...form.register("AddressLine2")}
            />
        </Stack>
        <Stack direction="row" alignItems="flex-start" spacing={2}>
            <TextField
                inputProps={{ "data-testid": "highSchool" }}
                margin="dense"
                label="City"
                type="text"
                InputLabelProps={{
                    shrink: true,
                }}
                variant="standard"
                fullWidth
                autoComplete="none"
                disabled={isSubmitting}
                error={Boolean(isDirty && Boolean(errors.city))}
                helperText={(isDirty && errors.city?.message) || " "}
                {...form.register("city", {
                    required: "City Name is required",
                })}

            />
            <TextField
                inputProps={{ "data-testid": "highSchool" }}
                margin="dense"
                label="State"
                type="text"
                InputLabelProps={{
                    shrink: true,
                }}
                variant="standard"
                fullWidth
                autoComplete="none"
                disabled={isSubmitting}
                error={Boolean(isDirty && Boolean(errors.state))}
                helperText={(isDirty && errors.state?.message) || " "}
                {...form.register("state", {
                    required: "State Name is required",
                })}

            />
        </Stack>
        <Stack direction="row" alignItems="flex-start" spacing={2}>
            <TextField
                inputProps={{ "data-testid": "highSchool" }}
                margin="dense"
                label="Phone"
                value={phoneNumber}
                type="text"
                InputLabelProps={{
                    shrink: true,
                }}
                variant="standard"
                fullWidth
                autoComplete="none"
                disabled={isSubmitting}
                error={Boolean(isDirty && Boolean(errors.phone))}
                helperText={(isDirty && errors.phone?.message) || " "}
                {...form.register("phone", {
                    required: "Phone number is required",
                    minLength: {
                        value: 10,
                        message: "Phone number should be 10 digits"
                    }
                })}
                onChange={(e) => handleChangePhoneNumber(e)}

            />
            <TextField
                inputProps={{ "data-testid": "highSchool" }}
                value={zipCode}
                margin="dense"
                label="Zip"
                type="text"
                InputLabelProps={{
                    shrink: true,
                }}
                variant="standard"
                fullWidth
                autoComplete="none"
                disabled={isSubmitting}
                error={Boolean(isDirty && Boolean(errors.zip))}
                helperText={(isDirty && errors.zip?.message) || " "}
                {...form.register("zip", {
                    required: "Zip Name is required",
                    minLength: { value: 5, message: "Please enter 5 minimum digits number" }

                })}
                onChange={(e) => handleChangeZip(e)}
            />
        </Stack>
        <Button
            data-testid={"requestbutton"}
            style={{ width: isMobile ? "85%" : "400px" }}
            type="submit"
            variant="contained"
            onClick={onSubmit}

            // startIcon={isSubmitting ? <CircularProgress size={20} /> : null}
            disabled={!isValid || isSubmitting || loading}
        >
            Submit
        </Button>

    </>)

}

export default AthleteUpdateProfile
