import React, { useEffect } from "react";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { Box, Button, useMediaQuery } from "@mui/material";
import { ApolloError, useQuery } from "@apollo/client";
import { athleteDetailsProfile } from "queries/athleteProfile";
import { GET_ATHELETS_PROFILE } from "queries";
import { insertApiResponseDataDog, insertApiResponseDataDogError } from "utils";

interface Props {
  firstName: string;
  lastName: string;
  email: string;
  nickname: string;
  jerseyNumber: string;
  sport: string;
  position: string;
  school: string;
  athlete_class: string;
  highSchool: string;
  hometown: string;
  athleteProfileDetails?: any;
  onHandel: () => void
}

const UserInfo = ({ firstName, lastName, email, jerseyNumber, sport, position, school, athlete_class, highSchool, hometown, onHandel }: Props) => {
  const isMobile = useMediaQuery("(max-width:600px)");

  const { data: atheleteDetails, refetch } = useQuery<athleteDetailsProfile>(GET_ATHELETS_PROFILE, {
    onError: (error: ApolloError) => {
      insertApiResponseDataDogError(error, "getAthleteProfileDetailsCko(error)");
      insertApiResponseDataDog("getAthleteProfileDetailsCko(error)", error, "error")
    },
    onCompleted(data) {
      insertApiResponseDataDog("getAthleteProfileDetailsCko(success)", data, "info")
    }
  });


  let athleteProfileDetails;
  if (typeof (atheleteDetails?.getAthleteProfileDetailsCko.data) === "string") {
    if (atheleteDetails?.getAthleteProfileDetailsCko.data == "" || atheleteDetails?.getAthleteProfileDetailsCko.status == "400") {
      athleteProfileDetails = "empty"
    } else {
      athleteProfileDetails = JSON.parse(atheleteDetails?.getAthleteProfileDetailsCko.data);

    }
  }

  useEffect(() => {
    refetch()
  }, [])
  return (
    <Box
      component="form"
      sx={{
        '& .MuiTextField-root': { m: 1, width: '25ch' },
      }}
      autoComplete="off"
      className="profileInfo"
    >
      <Stack direction="row" alignItems="flex-start" spacing={2}>
        <TextField
          value={firstName}
          inputProps={{ "data-testid": "firstName" }}
          disabled
          label="First Name"
          type="text"
          margin="dense"
          variant="standard"
          fullWidth
          autoComplete="none"
        />
        <TextField
          value={lastName}
          inputProps={{ "data-testid": "lastName" }}
          disabled
          margin="dense"
          label="Last Name"
          type="text"
          variant="standard"
          fullWidth
          autoComplete="none"
        />
      </Stack>
      <Stack>
        <TextField
          value={email}
          inputProps={{ "data-testid": "email" }}
          disabled
          margin="dense"
          label="Email"
          type="text"
          InputLabelProps={{
            shrink: true,
          }}
          variant="standard"
          fullWidth
          autoComplete="none"
        />
      </Stack>
      <br />
      <Stack direction="row" alignItems="flex-start" spacing={2}>
        Athlete Information
      </Stack>
      <Stack direction="row" alignItems="flex-start" spacing={2}>
        <TextField
          value={school}
          inputProps={{ "data-testid": "school" }}
          disabled
          margin="dense"
          label="School"
          type="text"
          InputLabelProps={{
            shrink: true,
          }}
          variant="standard"
          fullWidth
          autoComplete="none"
        />
        <TextField
          value={sport}
          inputProps={{ "data-testid": "sport" }}
          disabled
          margin="dense"
          label="Sport"
          type="Sport"
          InputLabelProps={{
            shrink: true,
          }}
          variant="standard"
          fullWidth
          autoComplete="none"
        />
      </Stack>
      <Stack direction="row" alignItems="flex-start" spacing={2}>
        <TextField
          value={position}
          inputProps={{ "data-testid": "position" }}
          disabled
          margin="dense"
          label="Position"
          type="text"
          InputLabelProps={{
            shrink: true,
          }}
          variant="standard"
          fullWidth
          autoComplete="none"
        />
        <TextField
          value={jerseyNumber}
          inputProps={{ "data-testid": "jerseyNumber" }}
          disabled
          margin="dense"
          label="Jersey Number"
          type="text"
          InputLabelProps={{
            shrink: true,
          }}
          variant="standard"
          fullWidth
          autoComplete="none"
        />
      </Stack>
      <Stack direction="row" alignItems="flex-start" spacing={2}>
        <TextField
          value={athlete_class}
          inputProps={{ "data-testid": "athlete_class" }}
          disabled
          margin="dense"
          label="Class"
          type="text"
          InputLabelProps={{
            shrink: true,
          }}
          variant="standard"
          fullWidth
          autoComplete="none"
        />
        <TextField
          value={hometown}
          inputProps={{ "data-testid": "hometown" }}
          disabled
          margin="dense"
          label="Home Town"
          type="text"
          InputLabelProps={{
            shrink: true,
          }}
          variant="standard"
          fullWidth
          autoComplete="none"
        />
      </Stack>
      <Stack>
        <TextField
          value={highSchool}
          inputProps={{ "data-testid": "highSchool" }}
          disabled
          margin="dense"
          label="High School"
          type="text"
          InputLabelProps={{
            shrink: true,
          }}
          variant="standard"
          fullWidth
          autoComplete="none"
        />
      </Stack>
      <div>
        <Button
          data-testid={"requestbutton"}
          style={{ width: isMobile ? "85%" : "400px", marginTop: 10 }}
          type="button"
          variant="contained"
          onClick={onHandel}
        >
          Request Changes
        </Button>
      </div>

      <Stack direction="row" alignItems="flex-start" spacing={2} marginTop={3} marginBottom={3}>
        Update Athlete Profile      </Stack>
      <Stack direction="row" alignItems="flex-start" spacing={2}>
        <TextField
          value={athleteProfileDetails?.addressLine1 || ""}
          inputProps={{ "data-testid": "AddressLine1" }}
          disabled
          margin="dense"
          label="AddressLine1"
          type="text"
          InputLabelProps={{
            shrink: true,
          }}
          variant="standard"
          fullWidth
          autoComplete="none"
        />
        <TextField
          value={athleteProfileDetails?.addressLine2 || ""}
          inputProps={{ "data-testid": "AddressLine2" }}
          disabled
          margin="dense"
          label="AddressLine2"
          type="text"
          InputLabelProps={{
            shrink: true,
          }}
          variant="standard"
          fullWidth
          autoComplete="none"
        />
      </Stack>
      <Stack direction="row" alignItems="flex-start" spacing={2}>
        <TextField
          value={athleteProfileDetails?.city || ""}
          inputProps={{ "data-testid": "highSchool" }}
          disabled
          margin="dense"
          label="City"
          type="text"
          InputLabelProps={{
            shrink: true,
          }}
          variant="standard"
          fullWidth
          autoComplete="none"
        />
        <TextField
          value={athleteProfileDetails?.state || ""}
          inputProps={{ "data-testid": "highSchool" }}
          disabled
          margin="dense"
          label="State"
          type="text"
          InputLabelProps={{
            shrink: true,
          }}
          variant="standard"
          fullWidth
          autoComplete="none"
        />
      </Stack>
      <Stack direction="row" alignItems="flex-start" spacing={2}>
        <TextField
          value={athleteProfileDetails?.phone || ""}
          inputProps={{ "data-testid": "highSchool" }}
          disabled
          margin="dense"
          label="Phone"
          type="text"
          InputLabelProps={{
            shrink: true,
          }}
          variant="standard"
          fullWidth
          autoComplete="none"
        />
        <TextField
          value={athleteProfileDetails?.zip || ""}
          inputProps={{ "data-testid": "highSchool" }}
          disabled
          margin="dense"
          label="Zip"
          type="text"
          InputLabelProps={{
            shrink: true,
          }}
          variant="standard"
          fullWidth
          autoComplete="none"
        />
      </Stack>

      <Button
        data-testid={"requestbutton"}
        style={{ width: isMobile ? "85%" : "400px", marginTop: 10 }}
        type="button"
        variant="contained"
        onClick={onHandel}
      >
        Request Changes
      </Button>
    </Box>

  )
};

export default UserInfo;
