import { gql } from "@apollo/client";

export const ALL_NFTS = gql`
  query listNFTs($limit: Int = 9, $page: Int = 1) {
    listNFTs(limit: $limit, page: $page) {
      itemTotal
      pageTotal
      pageNumber
      limit
      items {
        id
        imageUrl
        transactionType
      }
    }
  }
`;

export const NFT_DETAILS = gql`
  query nft($fanMemorabiliaId: String!) {
    nft(fanMemorabiliaId: $fanMemorabiliaId) {
      id
      createdAt
      imageUrl
      tokenId
      transaction {
        paymentGatewayTransactionId
        id
        amount
        receiptUrl
        athletes {
          id
        }
      }
    }
  }
`;
export const NFT_Sponsor = gql`
query nftSponsorship($fanMemorabiliaId: String!) {
   nftSponsorship(fanMemorabiliaId: $fanMemorabiliaId) {
   id
   createdAt
   imageUrl
    amount 
  }}
`;

export const PURCHASE_MEMORABILIA = gql`
  mutation purchaseMemorabilia(
    $athleteUserId: String!
    $cancelUrl: String!
    $purchaseAmount: Float!
    $successUrl: String!
  ) {
    purchaseMemorabilia(
      athleteUserId: $athleteUserId
      cancelUrl: $cancelUrl
      purchaseAmount: $purchaseAmount
      successUrl: $successUrl
    )
  }
`;

export const PURCHASE_MEMORABILIA_MULTI = gql`
  mutation purchaseMemorabiliaMulti(
    $athleteUserIds: [String!]!
    $cancelUrl: String!
    $purchaseAmount: Float!
    $successUrl: String!
  ) {
    purchaseMemorabiliaMulti(
      athleteUserIds: $athleteUserIds
      cancelUrl: $cancelUrl
      purchaseAmount: $purchaseAmount
      successUrl: $successUrl
    )
  }
`;

export const CANCEL_PAYMENT = gql`
  mutation cancelPendingPaymentFan($paymentIntentId: String!) {
    cancelPendingPaymentFan(paymentIntentId: $paymentIntentId)
  }
`;
