import React from "react";
import { EMAIL_REGEX } from "constant";
import { useForm } from "react-hook-form";
import { useNotifications } from "providers/Notification";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

interface SearchForm {
  email: string;
}

interface Props {
  setEmail: (x: string) => void;
  sendCode: (userEmail: string) => Promise<true | undefined>;
}

const SubmitRequest = ({ setEmail, sendCode }: Props) => {
  // const notify = useNotifications();

  const { register, handleSubmit, formState } = useForm<SearchForm>({
    defaultValues: {
      email: "",
    },
    mode: "all",
  });

  const onSubmit = async ({ email }: SearchForm) => {
    const response = await sendCode(email);
    if (response) {
      setEmail(email);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Typography>
        Enter your email so that we can verify your identity and reset your
        password
      </Typography>
      <Stack
        direction="column"
        alignItems="flex-start"
        spacing={1}
        marginTop="30px"
      >
        <TextField
          autoFocus
          margin="dense"
          label="Email"
          placeholder="Enter email"
          InputLabelProps={{
            shrink: true,
          }}
          type="text"
          fullWidth
          autoComplete="none"
          error={Boolean(formState.isDirty && formState.errors.email)}
          disabled={formState.isSubmitting}
          helperText={
            (formState.isDirty && formState.errors.email?.message) || " "
          }
          {...register("email", {
            required: "Email is required",
            pattern: {
              value: EMAIL_REGEX,
              message: "Invalid email address",
            },
          })}
        />
      </Stack>

      <Box>
        <Button
          startIcon={
            formState.isSubmitting ? <CircularProgress size={20} /> : null
          }
          data-testid="submit-request"
          type="submit"
          variant="contained"
          disabled={!formState.isValid || formState.isSubmitting}
          fullWidth
        >
          Submit Request
        </Button>
      </Box>
    </form>
  );
};

export default SubmitRequest;
