import React from "react";
import { ReactComponent as Back } from "../../assets/icons/back.svg";
import { useNavigate } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

interface Props {
  text: string;
  showBackButton?: boolean;
}

const Header = ({ text, showBackButton }: Props) => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(-1);
  };

  return (
    <header>
      <Stack marginBottom={2} direction="row" alignItems={"center"}>
        {showBackButton && (
          <IconButton aria-label="go back" onClick={handleClick} style={{ color: '#592EE5' }}>
            <Back />
          </IconButton>
        )}
        <Typography
          fontSize="20"
          fontWeight="600"
          flex={{
            xs: "auto",
            md: "none",
          }}
          // color="secondary.dark"
          style={{ color: '#592EE5' }}
        >
          {text}
        </Typography>
      </Stack>
    </header>
  );
};

export default Header;
