import React from "react";
import { ReactComponent as PayIcon } from "assets/icons/pay_team.svg";
import { ReactComponent as ArrowRight } from "assets/icons/chevron_right.svg";

import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";

interface Props {
  title: string;
  subtitle: string;
  onClick: () => void;
}

export const PayListButton = ({ title, subtitle, onClick }: Props) => (
  <ListItem sx={{ margin: 0, padding: 0 }}>
    <ListItemButton onClick={() => onClick()}>
      <Stack
        width="100%"
        direction={"row"}
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        <Stack direction={"row"} alignItems={"center"}>
          <PayIcon />
          <Box margin={2}>
            <Typography fontSize={14} fontWeight={500} color="primary.main">
              {title}
            </Typography>
            <Typography fontSize={12} fontWeight={300}>
              {subtitle}
            </Typography>
          </Box>
        </Stack>
        <ArrowRight />
      </Stack>
    </ListItemButton>
  </ListItem>
);
