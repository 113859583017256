import React from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Icon from "../../../assets/icons/identity/contact_support.svg";
import background from "../../../assets/images/background_waves.png";
import { useNavigate } from "react-router-dom";

const Success = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate("/home/payment");
  };

  return (
    <Box marginTop={{ xs: "10px", md: "90px" }} padding="20px">
      <Box
        sx={{
          position: "absolute",
          top: "0px",
          bottom: "0px",
          left: "0px",
          right: "0px",
          backgroundImage: `url(${background})`,
          backgroundColor: "primary.main",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "0px 608px",
          backgroundSize: "cover",
          zIndex: "-1",
        }}
      ></Box>
      <Stack
        spacing={2}
        sx={{
          color: "#FFF",
        }}
      >
        <Typography fontSize={34} fontWeight={600}>
          SUCCESS
        </Typography>
        <Typography fontSize={16} fontWeight={500}>
          Your identity verification was successful and is now complete
        </Typography>

        <Box margin="auto" padding={4}>
          <img src={Icon} alt="warning icon" height={126} width={126}></img>
        </Box>
        <Typography fontSize={16} fontWeight={600}>
          You can now set up your payment receiving account or go to straight to
          your myNILpay athlete profile
        </Typography>

        <Box padding={4}>
          <Button variant="contained" color="secondary" onClick={handleClick}>
            Next
          </Button>
        </Box>
      </Stack>
    </Box>
  );
};

export default Success;
