import { gql } from "@apollo/client";

export const ADMIN_ImageUpload = gql`
mutation addorUpdateSplashImageDetails($imageUrl: String!,$carouselImage: String,$screen:String!, $startDate: String!,$expiryDate: String!,$group: String!) {
addorUpdateSplashImageDetails(imageUrl: $imageUrl,carouselImage: $carouselImage, screen:$screen, startDate: $startDate,expiryDate: $expiryDate,group: $group ){ imageUrl }
}
`;
export const ADMIN_ImageEdit = gql`
mutation addorUpdateSplashImageDetails($imageUrl: String!,$screen:String!, $startDate: String!,$expiryDate: String!,$id: String!,$group: String!) {
addorUpdateSplashImageDetails(imageUrl: $imageUrl,screen:$screen, startDate: $startDate, expiryDate: $expiryDate,id:$id,group: $group ){ imageUrl }
}
`;
export const ADMIN_ImageData = gql`
query splashImages{splashImages{ imageUrl startDate expiryDate updatedAt screen id group carouselImage}}
`;
export const ADMIN_ImageSingleData = gql`
query splashImageDetails($id:String!){ splashImageDetails(id:$id){ imageUrl carouselImage startDate expiryDate updatedAt screen group}}
`;
export const ADMIN_ImageDelete = gql`
query deleteSplashImageDetails($id:String!){ deleteSplashImageDetails(id:$id)\n}
`;
export const ADMIN_saveCouponcode = gql`
mutation couponCode($email: String!,$fanId: String!, $coupon: String!, $mnpShare: String!, $vendorShare:String!){
  couponCode(email:$email,fanId: $fanId ,coupon: $coupon,mnpShare:$mnpShare,vendorShare:$vendorShare)
  }
`;
export const ADMIN_Search_fans_List = gql`
query filterFans($filterParams: UserFilterParams!, $limit: Int = 25, $page: Int = 1) { 
    filterFans(filterParams: $filterParams, limit: $limit, page: $page){
      itemTotal pageTotal pageNumber limit items { id firstName lastName email } 
    }
  }
`;
export const ADMIN_Get_Coupons_List = gql`
query getCouponCodes($limit: Int!,$page: Int!){ 
getCouponCodes(limit: $limit,page: $page)
{ pageNumber pageTotal limit itemTotal items { coupon firstName lastName email mnpShare vendorShare id  isActive activatedDate deactivatedDate}
 }}
`;

export const ADMIN_Delete_Coupon_Code = gql`
mutation deleteCouponCode($couponCodeId: String!){deleteCouponCode(couponCodeId: $couponCodeId)}
`;

export const ADMIN_Active_inActive_Coupon_Code = gql`
mutation toggleCouponCode($couponCodeId:String!,$isActive:Boolean!)
{toggleCouponCode(couponCodeId:$couponCodeId,isActive:$isActive)}`;
export const ADMIN_Sponsor_Data = gql`
query getSponsorShip($filterParams: SponserShipFilterParams!, $limit: Int = 25, $page: Int = 1) 
{ getSponsorShip(filterParams: $filterParams, limit: $limit, page: $page) 
  {
    itemTotal pageTotal pageNumber limit items {
      id name createdAt type timeCommitment amount 
      status amountReceived amountReceivedDate 
      sponsorshipDuration sponsorshipDetails 
      email athleteFirstName athleteLastName 
      athleteEmail 
      name 
      email 
      phoneNumber companyName
  }
}
}`;

export const ADMIN_Sponsor_Update = gql`
mutation updateSponsorship($status: String,$id: String,$amountReceived:String, $amountReceivedDate: String)
 {updateSponsorship(status: $status,amountReceived:$amountReceived, amountReceivedDate: $amountReceivedDate,id:$id)}`;