/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect } from "react";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import useUserType from "hooks/useUserType";
import './fanSign.css'
import { Auth } from "aws-amplify";
import { LogoHeader } from "./LogoHeader";
import { useLocation, useNavigate } from "react-router-dom";
import { useNotifications } from "providers/Notification";
import { ApolloError, useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { resendResponse, roleByResponse } from "types/resendVerification";
import { resentmail } from "queries/resendEmail";
import { CircularProgress } from "@mui/material";
import { insertApiResponseDataDog, insertApiResponseDataDogError } from "utils";
import { FORGOT_PASSWORD, ME, MeResponse, schoolUserGetRoleByEmail } from "queries";
import { forgotPasswordArgs, forgotPasswordResponse } from "types";

const VerifyEmail = () => {
    const location = useLocation();
    const navigator = useNavigate();
    const [refetch, { data }] = useLazyQuery<MeResponse>(ME, {
        onError: (error: ApolloError) => {
            notify.error(error.message);
            insertApiResponseDataDogError(error, "MeApi(error)");
            insertApiResponseDataDog("MeApi(error)", error, "error")
        },
        onCompleted(data) {
            insertApiResponseDataDog("MeApi(success)", data, "info")
        },
    });

    const notify = useNotifications();
    const loginDetails: any = location.state;
    const email = loginDetails.email;

    const [email_verify, { loading }] = useLazyQuery<resendResponse>(resentmail, {
        variables: {
            email: email
        },
        fetchPolicy: "network-only",
        onCompleted: (data) => {
            insertApiResponseDataDog(`resendMailApi(success) ${email}`, data, "info")
            notify.success(data?.resendMail?.message)
        },
        onError: (error) => {
            notify.error(error.message);
            insertApiResponseDataDogError(error, `resendMailApi(error) ${email} , ${error.message}`);
            insertApiResponseDataDog(`resendMailApi(error) ${email}`, error, "error");
        }
    });

    const { data: school_user } = useQuery<roleByResponse>(schoolUserGetRoleByEmail, {
        variables: {
            email: email,

        },
        onCompleted: (data) => {
            insertApiResponseDataDog(`resendMailApi(success) ${email}`, data, "info")
            // notify.success(data?.getRoleByEmail?.message)
        },
        onError: (error) => {
            notify.error(error.message);
            insertApiResponseDataDogError(error, `resendMailApi(error) ${email} , ${error.message}`);
            insertApiResponseDataDog(`resendMailApi(error) ${email}`, error, "error");
        }
    });

    const onClickVerify = () => {
        email_verify();
    }
    const [forgotPassword, { loading: forgotLoader }] = useMutation<
        forgotPasswordResponse,
        forgotPasswordArgs
    >(FORGOT_PASSWORD);

    const sendCode = async (userEmail: string) => {
        try {
            const response = await forgotPassword({
                variables: { email: userEmail },
            });
            insertApiResponseDataDog("forgotPasswordApi(success)", response, "info")

            if (response.data?.forgotPassword) {
                navigator('/schoolsForgotPassword', { state: { email: email } });
            } else {
                notify.error("Something went wrong. Try again later");
            }
        } catch (error) {
            insertApiResponseDataDogError(error, "forgotPasswordApi(error)");
            insertApiResponseDataDog("forgotPasswordApi(error)", error, "error")
            notify.error(error);
        }
    };


    const handleClick = async () => {
        try {
            const res = await Auth.signIn({ username: loginDetails.email, password: loginDetails.password });
            const session = await Auth.currentSession();
            const token = session.getIdToken().getJwtToken();
            insertApiResponseDataDog(`resendMailApi(success) ${email}`, res, "info")

            if (token) {
                window.localStorage.setItem("token", token);
                const res = await refetch();

                if (res.data?.me?.forceResetPassword) {
                    sendCode(email);

                } else {
                    navigator("/home", { replace: true });

                }

            } else {
                notify.error("Login Failed");
            }

        } catch (error: any) {
            insertApiResponseDataDog(`resendMailApi(error) ${email} , ${error.message}`, error, "error")
            insertApiResponseDataDogError(error, `resendMailApi(error) ${email} , ${error.message}`);
            if (error?.name == "UserNotConfirmedException") {
                notify.error("To complete your registration, check your email and click the 'Verify Account' button.");
            } else {
                notify.error(error.message);
            }

        }
    }

    useEffect(() => {
        if (school_user?.getRoleByEmail.userType === "school_faculty") {
            email_verify()
        }

    }, [school_user])

    if (loading) { return <CircularProgress />; }



    return (
        <Grid container spacing={2} justifyContent="center">
            <Grid item xs={12}>
                <LogoHeader />

            </Grid>
            <Grid item xs={12} md={4} lg={3} >

                <Stack spacing={2} className="maincontainer">
                    <Typography className="mainHeader" style={{ marginTop: '-40px', width: 260, margin: '0 auto' }}>
                        WHAT’S NEXT?
                    </Typography>
                    <Typography className="verifyText" style={{ width: 260, margin: '0 auto' }}>

                        <span className="checkemail">Check your email </span>and click the ‘Verify Account’ button to complete registration.
                    </Typography>

                    <Typography className="bottomText" style={{ width: 260, margin: '0 auto' }}>

                        Then return to the App to sign in and continue.      </Typography>
                    <Box className="button-login">
                        <Button variant="contained" fullWidth onClick={handleClick} className="continueButton" >
                            Click After Verifying Email
                        </Button>
                        <Button fullWidth className="reSendButton" onClick={onClickVerify}>
                            Resend Verification Email
                        </Button>
                    </Box>
                </Stack>
            </Grid>
        </Grid>
    );
};

export default VerifyEmail;
