import React, { useEffect } from "react";
import { ImagePlaceholder } from "./ImagePlaceholder";

interface Props {
  imageUrl: string | null;
  sport: string;
}
export const AthleteImage = ({ imageUrl, sport }: Props) => {
  const [img, setImage] = React.useState(imageUrl);
  useEffect(() => {
    if (imageUrl || imageUrl == null) {
      setImage(imageUrl)
    }
  }, [imageUrl])
  const onError = () => {
    setImage(ImagePlaceholder(sport))
  }
  return (<> <img
    width={140}
    height={140}
    className="imgProfile"
    src={img || ImagePlaceholder(sport)}
    onError={onError}
    alt="Athlete profile image"
  /></>)

};
