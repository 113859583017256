import { gql } from "@apollo/client";

export const SPORTS = gql`
  query Sports {
    sports {
      name
      id
    }
  }
`;

export const SPORTS_BY_SCHOOL = gql`
  query sportsBySchool($schoolId: String!) {
    sportsBySchool(schoolId: $schoolId) {
      id
      name
    }
  }
`;
