import { gql } from "@apollo/client";

export const createFiledTokens = gql`
mutation getPrePaymentDetailsCko($amount : String!) {
  getPrePaymentDetailsCko(amount: $amount) {
    tax
    callBackAppUrl 
    callBackApiUrl
    ckoPublicKey
}
}
`;