import { ApolloError } from "@apollo/client";
import { notifcationsCountResponse } from "types";
import { getNotificationsCount } from '../queries'
import { useNotifications } from "providers/Notification";
import { useQuery } from "@apollo/client/react/hooks";
import { insertApiResponseDataDog, insertApiResponseDataDogError } from "utils";

function useNotificationsCount(deviceType: string) {
    const notify = useNotifications();
    const data = useQuery<notifcationsCountResponse>(
        getNotificationsCount,
        {
            variables: {
                "deviceType": deviceType,
            },
            onError: (error: ApolloError) => {
                insertApiResponseDataDog("customConfirmSignupApi(error)", error, "error")
                insertApiResponseDataDogError(error, "customConfirmSignupApi(error)");

                notify.error(error.message || "Something went wrong. Try again later");
            },
            onCompleted: (data) => {
                insertApiResponseDataDog("getNotificationsCountApi(success)", data, "info")

            }
        }
    );

    return data;
}

export default useNotificationsCount;
