import Baseball from "assets/profile/Baseball.svg";
import Basketball from "assets/profile/Basketball.svg";
import BeachVolleyball from "assets/profile/BeachVolleyball.svg";
import Bowling from "assets/profile/Bowling.svg";
import CrossCountry from "assets/profile/CrossCountry.svg";
import Fencing from "assets/profile/Fencing.svg";
import FieldHockey from "assets/profile/FieldHockey.svg";
import Football from "assets/profile/Football.svg";
import Golf from "assets/profile/Golf.svg";
import Gymnastics from "assets/profile/Gymnastics.svg";
import Hockey from "assets/profile/Hockey.svg";
import Lacrosse from "assets/profile/Lacrosse.svg";
import Rifle from "assets/profile/Rifle.svg";
import Rowing from "assets/profile/Rowing.svg";
import Skiing from "assets/profile/Skiing.svg";
import Soccer from "assets/profile/Soccer.svg";
import Softball from "assets/profile/Softball.svg";
import SwimmingDiving from "assets/profile/SwimmingDiving.svg";
import Tennis from "assets/profile/Tennis.svg";
import TrackField from "assets/profile/TrackField.svg";
import Volleyball from "assets/profile/Volleyball.svg";
import WaterPolo from "assets/profile/WaterPolo.svg";
import Wrestling from "assets/profile/Wrestling.svg";
import Multi from "assets/profile/Multi.svg";

export function ImagePlaceholder(sportName: string) {
  let src = "";

  switch (true) {
    case /BASEBALL/i.test(sportName):
      src = Baseball;
      break;

    case /BASKETBALL/i.test(sportName):
      src = Basketball;
      break;

    case /BEACH VOLLEYBALL/i.test(sportName):
      src = BeachVolleyball;
      break;

    case /BOWLING/i.test(sportName):
      src = Bowling;
      break;

    case /CROSS COUNTRY/i.test(sportName):
      src = CrossCountry;
      break;

    case /FENCING/i.test(sportName):
      src = Fencing;
      break;

    case /FIELD HOCKEY/i.test(sportName):
      src = FieldHockey;
      break;

    case /FOOTBALL/i.test(sportName):
      src = Football;
      break;
    case /GOLF/i.test(sportName):
      src = Golf;
      break;

    case /GYMNASTICS/i.test(sportName):
      src = Gymnastics;
      break;

    case /HOCKEY/i.test(sportName):
      src = Hockey;
      break;

    case /LACROSSE/i.test(sportName):
      src = Lacrosse;
      break;

    case /RIFLE/i.test(sportName):
      src = Rifle;
      break;

    case /ROWING/i.test(sportName):
      src = Rowing;
      break;

    case /SKIING/i.test(sportName):
      src = Skiing;
      break;

    case /SOCCER/i.test(sportName):
      src = Soccer;
      break;

    case /SOFTBALL/i.test(sportName):
      src = Softball;
      break;

    case /SWIMMING|DIVING/i.test(sportName):
      src = SwimmingDiving;
      break;

    case /TENNIS/i.test(sportName):
      src = Tennis;
      break;

    case /TRACK & FIELD/i.test(sportName):
      src = TrackField;
      break;

    case /VOLLEYBALL/i.test(sportName):
      src = Volleyball;
      break;

    case /WATER POLO/i.test(sportName):
      src = WaterPolo;
      break;

    case /WRESTLING/i.test(sportName):
      src = Wrestling;
      break;

    default:
      src = Multi;
      break;
  }

  return src;
}
