import React from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import "./Home.css"
import MNPLogo from "assets/images/crown_logo.svg";

function Home() {
  return (
    <Box sx={{ paddingTop: '15px' }}>
      <Typography
        fontSize="40px"
        fontWeight="600"
        marginTop="-57px"
        flex={{
          xs: "auto",
          md: "none",
        }}
        color="secondary.dark"
      >
        {'What is myNILpay?'}
      </Typography>
      <Box marginTop={10}>
        <img src={MNPLogo} alt="myNILpay logo" />
        <Typography component={"span"} sx={{ marginLeft: 0, position: 'relative', bottom: 6, fontWeight: 700 }}>{'myNILpay'}</Typography>
        <br />
        <Typography>myNILpay is a first-of-its-kind software platform that allows any fan an NCAA-compliant method to direct-pay college student-athletes.</Typography>

        <br />
        <Typography>In exchange for the payment to the athlete, the fan will receive a unique digital asset featuring the athlete’s name and digital signature.  By digitally signing the asset, the athlete has fulfilled the NCAA quid-pro-quo requirement and does not need to take any other action.</Typography>
        <br />
        <Typography>myNILpay is a new way for your alumni and fans to engage with student athletes like they have not been able to before.</Typography>
        <br />
        <Typography> myNILpay will donate 10% of all net profits on payments made to your student athletes to the athlete school Athletic Fund.
          <a href='https://www.mynilpay.org/toolkit' target='_blank' rel='noopener noreferrer' className='link'>View more info
          </a>
        </Typography>
      </Box>
    </Box >

  )
}

export default Home;
