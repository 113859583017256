/* eslint-disable @typescript-eslint/no-explicit-any*/
import React, { useState } from "react";
import { apolloClient } from "../../providers/Apollo";
import { Auth } from "aws-amplify";
import { ReactComponent as ChevronUp } from "../../assets/icons/chevron_up.svg";
import { useNavigate } from "react-router-dom";
import { useNotifications } from "../../providers/Notification";
import Box from "@mui/material/Box";
import StyledButton from "./StyledButton";
import ListItem from "@mui/material/ListItem";
import Stack from "@mui/material/Stack";
import DeleteAccountModal from "../DeleteAccountModal";
import settings from '../../assets/icons/inactive/Settings.svg';
import { insertApiResponseDataDog, insertApiResponseDataDogError } from "utils";
import useUserType from "hooks/useUserType";


interface Props {
  onNavigate?: () => void;
}

const Settings = ({ onNavigate }: Props) => {
  const userType = useUserType();

  const [openModal, setOpenModal] = useState(false);
  const [show, setShow] = useState(false);
  const navigate = useNavigate();
  const notify = useNotifications();
  // const 

  // settingsOptions
  const settingsFan = [
    { menuItem: "Personal information", icon: "" },
    { menuItem: "About myNILpay", icon: "" },
    { menuItem: "Contact support", icon: "" },
    { menuItem: "Legal/Privacy", icon: "" },
    { menuItem: "Logout", icon: "" },
    { menuItem: "Delete account", icon: "" },
  ];


  const settingsAthlete = [
    { menuItem: "Personal information", icon: "" },
    { menuItem: "Bank account information", icon: "" },
    { menuItem: "About myNILpay", icon: "" },
    { menuItem: "Contact support", icon: "" },
    { menuItem: "Legal/Privacy", icon: "" },
    { menuItem: "Logout", icon: "" },
    { menuItem: "Delete account", icon: "" },
  ];
  const settingsSchollUser = [
    { menuItem: "Personal information", icon: "" },
    { menuItem: "About myNILpay", icon: "" },
    { menuItem: "Contact support", icon: "" },
    { menuItem: "Legal/Privacy", icon: "" },
    { menuItem: "Logout", icon: "" },
  ];



  let settingsOptions: any = []

  if (userType === "athlete") {
    settingsOptions = settingsAthlete
  } else if (userType === "school_faculty") {
    settingsOptions = settingsSchollUser
  } else if (userType === "fan") {
    settingsOptions = settingsFan
  } else {
    settingsOptions = settingsFan
  }

  async function logout() {
    localStorage.setItem("setClearData", "clearData")
    const role = JSON.parse(localStorage.getItem('LoginUserObject') || "");
    try {
      const res = await Auth.signOut();
      window.localStorage.removeItem("token");
      window.localStorage.removeItem("LoginUserObject");
      window.localStorage.removeItem("loginUserId");
      window.localStorage.removeItem("loginUserName");
      window.localStorage.removeItem("loginUserEmail");
      localStorage.setItem("schoolYear", "")
      localStorage.setItem("schoolMonth", "");
      apolloClient.cache.reset();
      insertApiResponseDataDog("authSignOut(error)", res, "info");
      if (role.userType === "school_faculty") {
        return navigate("/reporting/login", { replace: true });

      } else if (role.userType !== "school_faculty") {
        return navigate("/login", { replace: true });

      }
    } catch (error) {
      insertApiResponseDataDog("authSignOut(error)", error, "error");
      insertApiResponseDataDogError(error, "authSignOut(error)");

      notify.error("error signing out");
      // console.log("error signing out: ", error);
    }
  }
  const isSelected = (page: string) => {
    if (page === "Legal/Privacy") {
      page = "privacy"
    }
    const path = location.pathname;
    const first = path?.split("/")[1];
    const navUrl = first.toLocaleLowerCase();
    const selected = page.toLocaleLowerCase().split(' ').join('');
    return selected === navUrl;
  };
  const handleClick = (page: string) => {

    switch (page) {
      case "Personal information":
        // Close side nav on mobile
        if (onNavigate) onNavigate();
        navigate("/personalinformation");
        break;
      case "Legal/Privacy": navigate('/privacy')
        break
      case "Logout":
        logout();
        break;
      case "Delete account":
        setOpenModal(true);
        break;
      case "Contact support":
        navigate("/contactsupport");
        // window.open(SUPPORTLINK_URL, "_blank");
        break;
      case "About myNILpay":
        navigate("/aboutmynilpay");
        // window.open(SUPPORTLINK_URL, "_blank");
        break;
      case "Bank account information":
        navigate("/bankAccountUpdate");
        // window.open(SUPPORTLINK_URL, "_blank");
        break;


      default:
        // NOTE: Privacy, FAQs, and About links where not provided by myNILpay
        // so we are redirecting to their website instead
        window.open("https://www.mynilpay.com/", "_blank");
        break;
    }
  };


  return (
    <>
      <DeleteAccountModal open={openModal} setOpen={setOpenModal} />
      <ListItem disablePadding>
        <StyledButton page={{ icon: settings, menuItem: "Account settings", isactive: true }} onClick={() => setShow(!show)}>
          <Box
            sx={{
              transition: "transform 300ms",
              transform: show ? "rotate(0deg)" : "rotate(180deg)",
            }}
          > <ChevronUp />
          </Box>
        </StyledButton>
      </ListItem>
      {show && (
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        <Stack sx={{ marginLeft: 2, marginBottom: 5 }} spacing={1}>
          {/* eslint-disable-next-line */}
          {settingsOptions.map((name: any) => (
            <StyledButton
              key={name.menuItem}
              page={name}
              onClick={() => handleClick(name.menuItem)}
              isSelected={isSelected(name.menuItem)}
            />
          ))}


        </Stack>
      )}
    </>
  );
};

export default Settings;
