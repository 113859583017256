import React from "react";
import { ALL_NFTS } from "queries/memorabilia";
import { AllNftsResponse } from "types";
import { useNotifications } from "providers/Notification";
import { useQuery } from "@apollo/client";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import InfiniteScroll from "components/InfiniteScroll";
import NftSideNav from "./NftSideNav";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import FindAthelt from "./findAthlete";
import { insertApiResponseDataDog, insertApiResponseDataDogError } from "utils";



const LoadingBoxes = (props: { count: number }) => {
  const array = [];

  for (let step = 0; step < props.count; step++) {
    array.push(
      <Grid item key={step} xs={1} maxWidth={"300px"}>
        <Skeleton variant="rectangular" height={250} />
      </Grid>
    );
  }

  return (
    <Grid
      container
      columns={{ xs: 1, sm: 2, lg: 3 }}
      spacing={2}
      justifyContent={"center"}
    >
      {array}
    </Grid>
  );
};

const Vault = () => {
  const notify = useNotifications();
  const [id, setId] = React.useState<string | null>(null);
  const [type, setType] = React.useState<string | null>(null);

  const { data, loading, fetchMore } = useQuery<AllNftsResponse>(ALL_NFTS, {
    onError: (error) => {
      notify.error("Something went wrong. Try again later");
      insertApiResponseDataDog("listNFTsApi(error)", error, "error")
      insertApiResponseDataDogError(error, "listNFTsApi(error)");
    },
    onCompleted(data) {
      insertApiResponseDataDog("listNFTsApi(success)", data, "info")
    },
  });

  const handleClick = (item: any) => {
    setId(item.id);
    setType(item.transactionType)
  };

  return (
    <Box>
      <NftSideNav fanMemorabiliaId={id} transactionType={type} onClose={() => { setId(null), setType(null) }} />
      <Typography fontSize={20} textAlign={"center"} marginBottom={2}>
        Vault
      </Typography>
      {data?.listNFTs.itemTotal !== 0 ?
        <Typography textAlign={"left"} marginTop={2} marginBottom={2}>
          All Receipts ({data?.listNFTs.itemTotal})
        </Typography>
        : ""
      }

      {data?.listNFTs.itemTotal == 0 ?
        <Stack>
          <FindAthelt />
        </Stack>
        : ""
      }
      {!data && loading && <LoadingBoxes count={9} />}

      {data && !loading && (
        <Stack>
          <InfiniteScroll
            fetchMore={fetchMore}
            page={data?.listNFTs.pageNumber + 1}
          >
            <Grid
              container
              columns={{ xs: 1, sm: 2, lg: 3 }}
              spacing={2}
              justifyContent={"center"}
            >
              {data?.listNFTs.items.map((item, index) => (
                <Grid key={item.id} item xs={1} maxWidth={"300px"}>
                  <Button
                    aria-label={`nft ${index + 1}`}
                    onClick={() => handleClick(item)}
                  >
                    <img
                      alt={`nft ${index + 1}`}
                      src={item.imageUrl}
                      width={"100%"}
                    />
                  </Button>
                </Grid>
              ))}
            </Grid>
          </InfiniteScroll>
          {data.listNFTs.pageNumber < data.listNFTs.pageTotal && (
            <LoadingBoxes count={3} />
          )}
        </Stack>
      )}
    </Box>
  );
};

export default Vault;
