/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { useNotifications } from "providers/Notification";
import { Autocomplete, Box, Grid, Typography, useMediaQuery } from "@mui/material";
import PromoCodeList from './PromoCodeList'
import { useLazyQuery, useMutation } from "@apollo/client";
import { ADMIN_Get_Coupons_List, ADMIN_Search_fans_List, ADMIN_saveCouponcode } from "queries";
import { insertApiResponseDataDog, insertApiResponseDataDogError } from "utils";
import { FilterFansListResponse, saveCouponCodeResponse, saveCouponCodes } from "types/promoCode";
import { getCoupCodesListResponse } from "types/promoCode";
import { EMAIL_REGEX } from "constant";

interface FormValues {
    name: string;
    promocode: string;
    email: string;
    mnpShare: string;
    fan: string;
    vendorShare: string;
    id: string
}

function PromoCodeForm() {
    const isMobile = useMediaQuery("(max-width:600px)");
    const notify = useNotifications()
    const [fansList, setFansList] = useState<any>('');
    const [page, setPage] = React.useState<any>(1);
    const [emailId, setEmailId] = useState('')
    const [fanId, setFanId] = useState<any>('');
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [searchFan, { data, loading }] = useLazyQuery<FilterFansListResponse>(ADMIN_Search_fans_List, {
        onCompleted(data) {
            insertApiResponseDataDog("SearchfansListApi(success)", data, "info");
        },
        onError(error) {
            insertApiResponseDataDogError(error, "SearchfansListApi(error)");
            insertApiResponseDataDog("SearchfansListApi(error)", error, "error");
        },
    });
    const [getCoupnsData, { data: coupons, loading: couponLoader, refetch }] = useLazyQuery<getCoupCodesListResponse>(ADMIN_Get_Coupons_List, {
        onCompleted(data) {
            insertApiResponseDataDog("SearchfansListApi(success)", data, "info");
        },
        onError(error) {
            insertApiResponseDataDogError(error, "SearchfansListApi(error)");
            insertApiResponseDataDog("SearchfansListApi(error)", error, "error");
            notify.error(error.message)
        }
    });
    const [saveCouponCodes] = useMutation<saveCouponCodeResponse, saveCouponCodes>(
        ADMIN_saveCouponcode
    );
    const { register, handleSubmit, formState, reset } = useForm<FormValues>({
        defaultValues: {
            name: "",
            promocode: "",
            email: "",
            mnpShare: "",
            vendorShare: ""
        },
        mode: "all",
    });
    const { isDirty, errors, isSubmitting, isValid } = formState;
    const [mnpShares, setmnpShares] = React.useState<any>('');
    const [vendorShares, setVendorShares] = React.useState<any>('');
    const handleChangePage = (event: unknown, newPage: number) => {
        getCoupnsData({
            variables: { "limit": 5, "page": newPage },
        })
        setPage(newPage);
    };

    const onSubmit = async (form: FormValues) => {
        try {
            const res = await saveCouponCodes({
                variables: {
                    fanId: form.name ? fanId : "",
                    mnpShare: form.mnpShare,
                    vendorShare: form.vendorShare,
                    coupon: form.promocode,
                    email: form.email
                },
            });
            insertApiResponseDataDog("saveCouponCodesApi(success)", res, "info")
            notify.success("save coupon Successfully")
            reset({
                promocode: "",
            })
            refetch()
            setPage(1)
        } catch (error) {
            insertApiResponseDataDogError(error, "saveCouponCodesApi(error)");
            insertApiResponseDataDog("saveCouponCodesApi(error)", error, "error")
            notify.error(error);
        }

    };

    const handleSearch = async (value: any) => {
        const txt = `${value}`;
        const res = await searchFan({
            variables: { "filterParams": { "searchquery": txt } }
        })
        setFansList(res.data?.filterFans.items);
    };


    const onChangemnpShareShare = (e: any) => {
        const arr = Array.from(e.target.value)

        const regex = /^[0-9\b]+$/;
        if (arr[0] !== "0" && e.target.value !== "" && regex.test(e.target.value)) {
            if (e.target.value <= 100) {
                setmnpShares(e.target.value);
                setVendorShares(100 - e.target.value);
                reset({
                    mnpShare: e.target.value
                });
            }
        } else {
            setmnpShares('');
        }
    }

    const onchangeVendorPrice = (e: any) => {
        const arr = Array.from(e.target.value)
        const regex = /^[0-9\b]+$/;
        if (arr[0] !== "0" && e.target.value !== "" && regex.test(e.target.value)) {
            if (e.target.value <= 100) {
                setVendorShares(e.target.value);
                setmnpShares(100 - e.target.value);
                reset({
                    vendorShare: e.target.value
                });
            }
        } else {
            setVendorShares('');
        }
    }

    const onchangeEmail = (e: any) => {
        setEmailId(e.target.value);
    }
    useEffect(() => {
        getCoupnsData({
            variables: { "limit": 5, "page": 1 },
        })
    }, [])


    return (
        <>
            <Stack padding={'0 10px'}>
                <form onSubmit={handleSubmit(onSubmit)} className="profileInfo">
                    <Grid container spacing={2}>
                        <Typography marginBottom={2}>
                            Save Coupons
                        </Typography>
                        <Grid item xs={12} md={12} sm={12}>
                            <Autocomplete
                                loading={loading}
                                id="combo-box-demo"
                                options={fansList || []}
                                disabled={false}
                                sx={{ width: '100%' }}
                                onChange={(event: any, newValue: any | null) => {
                                    setFanId(newValue?.id);
                                    setEmailId(newValue?.email);
                                    reset({
                                        email: newValue?.email
                                    })

                                }}
                                getOptionLabel={(option: any) => option.firstName + " " + option.lastName + " " + option.email}
                                renderOption={(props, option) => (
                                    <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props} key={option.id}>
                                        <Typography align="left"> {option.firstName} {option.lastName} ({option.email})</Typography>
                                    </Box>
                                )}
                                renderInput={(params) =>
                                    <TextField {...params}
                                        value={fansList || ""}
                                        helperText={(isDirty && errors.name?.message) || ""}
                                        {...register("name")}
                                        onChange={(e) => handleSearch(e.target.value)}
                                        label="Search for fan" />
                                }
                            />
                        </Grid>
                        <Grid item xs={12} md={12} sm={12}>
                            <TextField
                                value={emailId || ""}
                                margin="dense"
                                inputProps={{ "data-testid": "PromoCode" }}
                                label="Email"
                                placeholder="Enter Email"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                type="text"
                                fullWidth
                                autoComplete="none"
                                error={Boolean(isDirty && errors.email)}
                                helperText={(isDirty && errors.email?.message) || ""}
                                {...register("email", {
                                    required: "Email is required",
                                    pattern: {
                                        value: EMAIL_REGEX,
                                        message: "Invalid email address",
                                    }
                                })}
                                onChange={(e) => onchangeEmail(e)}
                            />
                        </Grid>
                        <Grid item xs={12} md={12} sm={12}>
                            <TextField
                                margin="dense"
                                inputProps={{ "data-testid": "PromoCode" }}
                                label="Promo Code"
                                placeholder="Enter Promo Code"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                type="text"
                                fullWidth
                                autoComplete="none"
                                error={Boolean(isDirty && errors.promocode)}
                                disabled={isSubmitting}
                                helperText={(isDirty && errors.promocode?.message) || ""}
                                {...register("promocode", {
                                    required: "Promo Code is required",
                                })}
                            />
                        </Grid>


                        <Grid item xs={12} md={6} sm={12}>

                            <TextField
                                value={vendorShares || ""}
                                margin="dense"
                                inputProps={{ "data-testid": "Vendor" }}
                                label="Vendor Share"
                                placeholder="Enter Vendor Share"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                type="text"
                                fullWidth
                                autoComplete="none"
                                error={Boolean(isDirty && errors.vendorShare)}
                                disabled={isSubmitting}
                                helperText={(isDirty && errors.vendorShare?.message) || ""}
                                {...register("vendorShare", {
                                    required: "vendorShare is required",
                                })}
                                onChange={(e) => onchangeVendorPrice(e)}
                            />

                        </Grid>
                        <Grid item xs={12} md={6} sm={12}>

                            <TextField
                                margin="dense"
                                value={mnpShares || ""}
                                inputProps={{ "data-testid": "PromoCode" }}
                                label="MnpShare"
                                placeholder="Enter mnpShare Share"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                fullWidth
                                autoComplete="none"
                                error={Boolean(isDirty && errors.mnpShare)}
                                disabled={isSubmitting}
                                helperText={(isDirty && errors.mnpShare?.message) || ""}
                                {...register("mnpShare", {
                                    required: "mnpShare Required",
                                })}
                                type="text"
                                onChange={(e) => onChangemnpShareShare(e)}
                            />
                        </Grid>

                        <Grid item xs={12} md={12} sm={12} marginTop={4}>
                            <Button
                                startIcon={isSubmitting ? <CircularProgress size={20} /> : null}
                                data-testid="submitButton"
                                type="submit"
                                variant="contained"
                                fullWidth
                                disabled={!isValid || !isDirty || isSubmitting}
                            >
                                Save Changes
                            </Button>
                        </Grid>
                    </Grid>

                </form>
            </Stack>
            <Stack className="mainTableContainer">
                <Grid container spacing={1} sx={{ justifyContent: 'center' }}>
                    <Grid item xs={12} md={12} sm={12} sx={{ ...(isMobile ? { position: 'absolute' } : {}) }}>
                        <PromoCodeList
                            data={coupons}
                            page={page}
                            handleChangePage={handleChangePage}
                            couponLoader={couponLoader}

                        />
                    </Grid>
                </Grid>
            </Stack>
        </>
    );
}

export default PromoCodeForm;
