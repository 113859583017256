
import React from "react";
import Grid from "@mui/material/Grid";
import { Link } from "react-router-dom";
import DoneAll from "assets/images/done_all.png";
import Button from "@mui/material/Button";
// import { useNavigate } from "react-router-dom";
function MissingAthleteSuccess() {
    // const navigate = useNavigate();
    return (<Grid className="landing-page thank_you_landing" alignItems="center">
        <Grid className="landing-page__info thank_you" container spacing={2} justifyContent="center">
            <Grid item md={8}>
                <strong>Thanks!</strong>
                <small>Your athlete updated request been received</small>
                <img src={DoneAll} alt="myNILpay logo" />
                <span>myNILpay will validate your request and update the athletes information.</span>
                <Button
                    data-testid="submit-button-success"
                    type="submit"
                    variant="contained"
                    fullWidth
                    color={"secondary"}
                    component={Link} to="/home"
                >
                    Back to Home
                </Button>
            </Grid>
        </Grid>
    </Grid>
    )

}
export default MissingAthleteSuccess;