import { gql } from "@apollo/client";

export const FEATURE_FLAGS = gql`
  query featureFlags {
    featureFlags {
      featureName
      enabled
    }
  }
`;
