import React from "react";
import { CircularProgress } from "@mui/material";
import BeginVerification from "./BeginVerification";
import Pending from "./Pending";
import Rejected from "./Rejected";
import Success from "./Success";
import useUser from "../../../hooks/useUser";

function Index() {
  const user = useUser();

  if (user === null) return <CircularProgress />;

  switch (user.identityVerification?.status) {
    case "success":
      return <Success />;

    case "pending":
      return <Pending />;

    case "rejected":
      return <Rejected />;

    default:
      return <BeginVerification />;
  }
}

export default Index;
