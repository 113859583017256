import { createTheme } from "@mui/material/styles";

export const MAIN_GRADIENT =
  "linear-gradient(164.74deg, #8A67FE -13.41%, #592EE5 116.48%)";

export const theme = createTheme({
  palette: {
    primary: {
      main: "#592EE5",
      light: "#EAE6F8",
      dark: "#471BDA",
    },
    secondary: {
      main: "#EAE6F8",
      light: "F8F7FD",
      dark: "#091F82",
    },
    background: {
      default: "#FFF",
      paper: "#FAFAF7",
    },
  },
  typography: {
    fontFamily: "Urbane, Helvetica, sans-serif",
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
        },
        containedPrimary: {
          borderRadius: "46px",
          padding: "15px",
          background: MAIN_GRADIENT,
          "&:disabled": {
            background: "#EAE6F8",
          },
        },
        containedSecondary: {
          borderRadius: "46px",
          padding: "15px 128px",
          "&:hover": {
            background: "#FFF",
            borderColor: "#8A67FE",
          },
          "&:disabled": {
            background: "#FFF",
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: "#592EE5",
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: "8px",
          background: "#FAFAF7",
          "& fieldset": {
            border: "1px solid #EAEAEA",
          },
          "&:hover:not($disabled) fieldset": {
            borderColor: "#592EE5 !important",
            borderWidth: "2px",
          },
          input: {
            "&::placeholder": {
              fontWeight: 300,
            },
          },
        },
      },
    },
  },
});
