import { useQuery } from "@apollo/client";
import { ME, MeResponse } from "../queries";
import { insertApiResponseDataDog, insertApiResponseDataDogError } from "utils";

function useUser() {
  const { loading, data, error, refetch } = useQuery<MeResponse>(ME, {
    onError(error) {
      insertApiResponseDataDog("MEApi(error)", error, "error");
      insertApiResponseDataDogError(error, "MEApi(error)");
    },
    onCompleted(data) {
      insertApiResponseDataDog("MEApi(success)", data, "info");

    }
  });
  if (data) {
    localStorage.setItem("LoginUserObject", JSON.stringify(data.me));
    localStorage.setItem("loginUserId", data.me.id);
    localStorage.setItem("loginUserName", data.me.firstName + " " + data.me.lastName);
    localStorage.setItem("loginUserEmail", data.me.email);

    return { ...data.me, refetch, loading }
  };
  if (loading || error) return null;
  return null;
}

export default useUser;
