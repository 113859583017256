import React from "react";

import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import { useNotifications } from "../../providers/Notification";
import AlertTitle from "@mui/material/AlertTitle";

const alertStyle = {
  width: "100%",
  backgroundColor: "background.paper",
  borderRightWidth: 8,
};

const Notifications = () => {
  const { show, type, text, title, clear } = useNotifications();
  return (
    <>
      <Snackbar
        data-testid={"close"}
        open={show}
        autoHideDuration={6000}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        onClose={() => clear()}
      >
        <Alert severity={type} variant="outlined" sx={alertStyle}>
          <AlertTitle sx={{ textTransform: "capitalize" }}>{title}</AlertTitle>
          {text}
        </Alert>
      </Snackbar>
    </>
  );
};

export default Notifications;
