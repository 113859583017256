import React, { useEffect } from "react";
import AppRoutes from "./router";
import ReactGA from "react-ga4"
ReactGA.initialize("G-C29E918RBQ");
import { datadogRum } from '@datadog/browser-rum';
import { datadogLogs } from '@datadog/browser-logs';

datadogRum.init({
  applicationId: process.env.REACT_APP_DATADOG_APPLICATION_ID || "",
  clientToken: process.env.REACT_APP_DATADOG_CLIENT_TOKEN || "",
  site: 'datadoghq.com',
  service: process.env.REACT_APP_DATADOG_SERVICE,
  env: process.env.REACT_APP_DATADOG_ENV,
  // Specify a version number to identify the deployed version of your application in Datadog     // version: '1.0.0',    sessionSampleRate: 100,
  sessionReplaySampleRate: 20,
  trackUserInteractions: true,
  trackResources: true,
  trackLongTasks: true,
  defaultPrivacyLevel: 'mask-user-input',
  silentMultipleInit: true,
  allowedTracingUrls: [`${process.env.REACT_APP_SERVER_URI}`,
  `${process.env.REACT_APP_DATADOG_TRACE}`, `${process.env.REACT_APP_DATADOG_AWS_TRACE}`, (url) => url.startsWith(`${process.env.REACT_APP_SERVER_URI}`),
  ]
});

datadogRum.startSessionReplayRecording();


datadogLogs.init({
  clientToken: process.env.REACT_APP_DATADOG_CLIENT_TOKEN || "",
  site: 'datadoghq.com',
  forwardErrorsToLogs: true,
  sessionSampleRate: 100,
  env: process.env.REACT_APP_DATADOG_ENV,
  forwardConsoleLogs: "all",
  forwardReports: 'all'
})


function App() {
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: "/my-path" });
    datadogLogs.logger.info('onstartPage', { name: 'success' })

    // ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);
  return (
    <div className="app">
      <AppRoutes />
    </div>
  );
}

export default App;

