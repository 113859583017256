import React from "react";
import { ReactComponent as Back } from "assets/icons/back.svg";
import background from "assets/images/light_wave_background.png";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import MNPLogo from "assets/images/crown_logo.svg";

interface Props {
  handleBack?: () => void;
}

export const AthleteNotFound = ({ handleBack }: Props) => (
  <>
    <Box
      sx={{
        position: "absolute",
        top: "0px",
        bottom: "0px",
        left: "0px",
        right: "0px",
        backgroundImage: `url(${background})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        zIndex: "-1",
      }}
    ></Box>
    <Stack
      direction="column"
      alignItems="flex-start"
      maxWidth={"600px"}
      margin={"auto"}
    >
      {handleBack && (
        <Button startIcon={<Back />} onClick={handleBack}>
          Back
        </Button>
      )}

      <Box width={"100%"} marginTop={6}>
        <Box margin={2}>
          <img src={MNPLogo} alt="myNILpay logo" />
        </Box>
        <Typography
          textAlign={"center"}
          fontSize={24}
          fontWeight={600}
          color="secondary.dark"
        >
          Athlete not found
        </Typography>
      </Box>
    </Stack>
  </>
);
