import React, { useEffect } from "react";
import { CircularProgress } from "@mui/material";
import { Navigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import ReportLogin from "./ReportLogin";
import mnpLogo from "assets/images/mnp_logo.svg";
import Typography from "@mui/material/Typography";
import TermsandConditions from "../TermsandConditions";

function Login() {

    useEffect(() => {
        window.zE('webWidget', 'hide');
    })
    return (
        <Grid container spacing={2} justifyContent="center">
            <Grid item xs={12}>
                <Box textAlign="left" marginTop={1} marginLeft={{ xs: 6, md: 15 }}>
                    <Typography fontSize={13} marginBottom={0.5} fontWeight="light">
                        {"Welcome back to"}
                    </Typography>
                    <img src={mnpLogo} alt="myNILpay logo" />
                </Box>
            </Grid>
            <Grid item xs={12} md={4} lg={3} marginTop={4}>
                <ReportLogin />

            </Grid>
            <Grid item xs={12} md={12} lg={12} marginTop={2}>

                <TermsandConditions />
            </Grid>


        </Grid>
    );
}

export default Login;
