import React from "react";
import { CurrencyInput } from "../CurrencyInput/CurrencyInput";
import { getFormatedCurrency } from "utils";
import { MINIMUM_PAYMENT, MAX_LIMIT } from "constant";
import { useNotifications } from "providers/Notification";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

interface Props {
  athletesCount: number;
  previousValue?: number;
  onSubmit: (x: number) => void;
}

export function MultiAmountInput({
  previousValue,
  athletesCount,
  onSubmit,
}: Props) {
  const notify = useNotifications();
  const [value, setValue] = React.useState(0);
  const [total, setTotal] = React.useState<number>(0);
  const [minAmt, setMinAmt] = React.useState(false);

  React.useEffect(() => {
    if (previousValue && value !== previousValue) setValue(previousValue);
  }, [previousValue]);


  const handleChange = (newValue: number) => {
    if (value < 20) {
      setMinAmt(true)
    } else if (value > 19) {
      setMinAmt(false)
    }

    if (!Number.isNaN(newValue)) {
      const limit = MAX_LIMIT;
      if (newValue <= limit) {
        setTotal(newValue / athletesCount);
        setValue(newValue / athletesCount);
      }
    } else {
      setMinAmt(false)
      setTotal(0);
    }
  };

  const handleReview = (event: React.FormEvent) => {
    event.preventDefault();

    if (value === null) return;

    if (value && value < MINIMUM_PAYMENT) {
      notify.error(
        "Oops! myNILpay requires a minimum payment of $20",
        "Minimum Amount"
      );
    } else {
      onSubmit(value);
    }
  };

  return (
    <form onSubmit={handleReview} style={{ width: "100%" }}>
      <Box width={"100%"}>
        <Box>
          {minAmt && value < 20 ?
            <Typography fontSize={14} fontWeight={500} margin={6}>
              *myNILpay requires a minimum payment of $20 to each player
            </Typography> : "Enter Amount"
          }
          {value > 5000 ?
            <Typography fontSize={14} fontWeight={500} margin={6}>
              *myNILpay maximum in-app payment is $5,000 per athlete. To send a higher amount contact: MVP@mynilpay.com
            </Typography> : ""
          }
          <Stack
            margin={4}
            direction={"row"}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <CurrencyInput
              onChange={handleChange}
              previousValue={previousValue}
            />
          </Stack>
        </Box>
        <Stack direction="row" justifyContent="center" margin={4}>
          <Typography fontSize={16} fontWeight={300}>
            {Number(athletesCount)} Athletes Each :
          </Typography>
          &nbsp;
          <Typography fontSize={16} fontWeight={600}>
            {getFormatedCurrency(total)}
          </Typography>
        </Stack>
        <Button
          type="submit"
          fullWidth
          disabled={value >= MINIMUM_PAYMENT && value <= 5000 ? false : true}
          onClick={handleReview}
          color={"secondary"}
          variant="contained"
        >
          Review
        </Button>
      </Box>
    </form>
  );
}
