
import React from "react"
import { Button, Typography } from "@mui/material"
import RefreshIcon from '@mui/icons-material/Refresh';

const AccountInfo = () => {
    const pageRefresh = () => {
        window.location.reload()
    }
    return (
        <div>
            <Typography fontSize={34} fontWeight={600} sx={{ color: '#88B04B' }}>
                Your account is under review
            </Typography>
            <Typography fontSize={16} fontWeight={500} sx={{ color: 'rgb(157, 157, 157)' }}>
                please check after some time.
            </Typography>
            <Typography
                fontSize={18}
                fontWeight={500}
                sx={{ color: 'rgb(157, 157, 157)', marginTop: '12px', cursor: 'pointer' }}>
                <Button
                    onClick={() => pageRefresh()}
                    startIcon={<RefreshIcon />}
                    type="button"
                    color={"primary"}
                    variant="text"
                >
                    Refresh
                </Button>
            </Typography>
        </div>
    )
}

export default AccountInfo;