import React, { ChangeEvent } from "react";
import { AthleteImage } from "./AthleteImage";
import { AthleteSport } from "../../types";
import { Dot } from "../TextDot/TextDot";
import Box from "@mui/material/Box";
import ReadMore from "../../components/ReadMore";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import EditIcon from '@mui/icons-material/Edit';
import IconButton from "@mui/material/IconButton";
import useUserType from "hooks/useUserType";
import { CircularProgress } from "@mui/material";



interface Props {
  children?: React.ReactNode;
  imageUrl: string | null;
  isAlumni?: boolean | null;
  school: { id: string; __typename: "School"; name: string };
  athleteSport: AthleteSport;
  fullName: string;
  handleChangeImage: (event: ChangeEvent<HTMLInputElement>) => void;
  imageloader: boolean
}

export const AthleteProfileHeader = ({
  children,
  imageUrl,
  isAlumni,
  school,
  athleteSport,
  fullName,
  handleChangeImage,
  imageloader
}: Props) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any


  const userType = useUserType();

  return (<>
    <Stack
      direction="row"
      width="100%"
      justifyContent="space-between"
      alignItems="center"
      sx={{ position: 'relative' }}
    >

      <Box
        sx={{
          borderRadius: "50%",
          margin: "10px",
          overflow: "hidden",
          outline: "solid 1px",
          borderColor: "text.disabled",
          height: "140px",
          width: "140px",
          minWidth: "140px",
        }}
      >

        <Stack sx={{ zIndex: -1 }}>
          {(imageUrl === undefined || imageloader === true) ? <Stack sx={{ position: 'absolute', top: 40, left: 40 }}><CircularProgress /></Stack> : <AthleteImage imageUrl={imageUrl} sport={athleteSport.sport.name} />
          }
        </Stack>

      </Box>

      {userType == "athlete" ? <Stack sx={{ position: 'absolute', left: 125, top: 20, width: 30, height: 30, cursor: 'pointer', borderRadius: 60, backgroundColor: "#592ee5" }}>
        <input accept="image/*" id="icon-button-file"
          type="file" style={{ display: 'none' }} onChange={handleChangeImage} />
        <label htmlFor="icon-button-file">
          <IconButton color="primary" aria-label="upload picture"
            component="span">
            <EditIcon sx={{ width: 30, height: 20, color: '#fff', position: 'absolute', top: 2 }} />
          </IconButton>
        </label>

      </Stack> : ""}
      {children}
    </Stack>

    <Stack textAlign="left">
      <Typography fontSize={24} fontWeight={600} marginBottom={"10px"}>
        {fullName}
      </Typography>

      <Stack direction="row" marginBottom={"10px"}>
        {school.name ? <Typography fontSize={14} fontWeight={600} textTransform="uppercase" align="left">
          {school.name}
        </Typography> : ""}
        {isAlumni ? <Typography fontSize={14} fontWeight={500}> <Dot />
          Alumni</Typography> : <Typography fontSize={14} fontWeight={500}> <Dot />Student</Typography>}
      </Stack>
      <Stack direction="row" marginBottom={"10px"}>

        {athleteSport.sport.name ? <Typography align="left" fontSize={14} fontWeight={500} marginBottom={"4px"} >{athleteSport.sport.name}
        </Typography> : ""}
        {athleteSport.sportDetails.position ? <Typography align="left" fontSize={14} fontWeight={500} marginBottom={"4px"}> <Dot />{athleteSport.sportDetails.position}</Typography> : ""}

        {athleteSport.jerseyNumber ?
          <Typography align="left" fontSize={14} fontWeight={500} marginBottom={"4px"} > <Dot />
            &#35;{athleteSport.jerseyNumber}</Typography>
          : ""}
      </Stack>



      <Box marginBottom={"44px"}>
        <ReadMore text={athleteSport.sportDetails.bio} />
      </Box>
    </Stack>
  </>)
}
