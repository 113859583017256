import React from "react";
import { Athlete } from "types";
import { useAppState } from "providers/AppState";
import { useNavigate } from "react-router-dom";
import AmountInput from "components/AmountInput";
import backgroundImage from "assets/images/background_waves.png";
import Box from "@mui/material/Box";

export interface PayState {
  amount: number;
  athlete?: Athlete;
}

const Pay = () => {
  const navigate = useNavigate();
  const appState = useAppState();

  const handleAmount = (amount: number) => {
    appState.setAmount(amount);
    navigate("/pay/search");
  };

  return (
    <Box
      sx={{
        position: "absolute",
        top: "0px",
        left: "0px",
        right: "0px",
        bottom: "0px",
        display: "flex",
        justifyContent: "center",
        backgroundColor: "primary.main",
        backgroundImage: `url(${backgroundImage})`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "0px 608px",
        backgroundSize: "cover",
      }}
      paddingTop={{ xs: "10px", md: "90px" }}
    >
      <AmountInput
        previousValue={appState.amount}
        onSubmit={handleAmount}
        lightStyle
      />
    </Box>
  );
};

export default Pay;
