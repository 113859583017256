import React, { useEffect, useState } from "react";
import { Athlete, AthletesByTeamResponse } from "types";
import { ATHLETES_BY_TEAM } from "queries";
import { MultiAmountInput } from "components/MultiAmountInput/MultiAmountInput";
import { PlayerCard } from "./PlayerCard";
import { TeamHeader } from "./TeamHeader";
import { useAppState } from "providers/AppState";
import { useNavigate, useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { useSchool } from "hooks/useSchool";
import { useSport } from "hooks/useSport";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import List from "@mui/material/List";
import ModalWrapper from "components/ModalWrapper";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { insertApiResponseDataDog, insertApiResponseDataDogError } from "utils";

export function Team() {
  const params = useParams();
  const navigate = useNavigate();
  const schoolId = params.schoolId || "";
  const sportId = params.sportId || "";
  const school = useSchool(schoolId);
  const sport = useSport(sportId);
  const sportName = sport?.name || "Loading...";
  const schoolName = school?.name || "Loading...";
  const appState = useAppState();
  const [showModal, setShowModal] = useState(false);
  const [selectedAthletes, setSelectedAthletes] = useState<Athlete[]>(
    appState.multiPayAthletes || []
  );
  const isEmpty = !selectedAthletes.length;

  const { loading, data } = useQuery<AthletesByTeamResponse>(ATHLETES_BY_TEAM, {
    variables: { schoolId, sportId },
    onCompleted(data) {
      insertApiResponseDataDog("athletesByTeamApi(success)", data, "info")

    },
    onError(error) {
      insertApiResponseDataDogError(error, "athletesByTeamApi(error)");
      insertApiResponseDataDog("athletesByTeamApi(error)", error, "error")
    },
  });
  useEffect(() => {
    setSelectedAthletes([]);
  }, []);
  if (loading) return <CircularProgress />;

  const handleCheckbox = (x: Athlete) => {
    const index = selectedAthletes.findIndex((athlete) => athlete.id === x.id);
    if (index === -1) {
      setSelectedAthletes([...selectedAthletes, x]);
    } else {
      selectedAthletes.splice(index, 1);
      setSelectedAthletes([...selectedAthletes]);
    }
  };

  const updateAll = () => {
    const allAthletes = data?.athletesByTeam || [];

    if (isEmpty) {
      setSelectedAthletes([...allAthletes]);
    } else {
      setSelectedAthletes([]);
    }
  };

  const handleAmount = (amount: number) => {
    appState.setAmount(amount);
    appState.setMultiPayAthletes(selectedAthletes || []);
    setShowModal(false);
    navigate(`/fanhome/school/${schoolId}/sport/${sportId}/multiPayReview`);
  };

  return (
    <Box textAlign={"left"}>
      <ModalWrapper open={showModal} setOpen={setShowModal} dark>
        <MultiAmountInput
          athletesCount={selectedAthletes?.length || 0}
          onSubmit={handleAmount}
        />
      </ModalWrapper>
      <TeamHeader
        schoolName={schoolName}
        sportName={sportName}
        schoolId={schoolId}
        sportId={sportId}
      />
      {data && !data.athletesByTeam.length && (
        <Typography>No athletes found</Typography>
      )}
      {data && data.athletesByTeam.length && (
        <>
          <Stack
            direction={"row"}
            justifyContent={"space-between"}
            marginBottom={"10px"}
          >
            <Typography fontWeight="600" color="secondary.dark" textAlign={"left"} marginTop={2}>
              Team Members ({data?.athletesByTeam.length})
            </Typography>

            <Button
              variant="contained"
              color="secondary"
              sx={{ padding: "10px 25px" }}
              onClick={updateAll}
            >
              {isEmpty ? "Select All" : "Clear"}
            </Button>
          </Stack>

          <List
            sx={{
              overflowY: "scroll",
              maxHeight: "calc(100vh - 350px)",
            }}
          >
            {data &&
              data.athletesByTeam.map((athlete) => (
                <PlayerCard
                  key={athlete.id}
                  athlete={athlete}
                  sportName={sportName}
                  sportId={sportId}
                  handleCheckbox={handleCheckbox}
                  isSelected={
                    selectedAthletes.findIndex((a) => a.id === athlete.id) > -1
                  }
                />
              ))}
          </List>
          <Stack textAlign={"center"} marginTop={4}>
            <Button
              variant="contained"
              onClick={() => setShowModal(true)}
              disabled={isEmpty}
            >
              {selectedAthletes?.length == 0 ? 'Please Select Athletes' : `Direct-pay Team (${selectedAthletes?.length})`}
            </Button>
          </Stack>
        </>
      )}
    </Box>
  );
}
