import React from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import ErrorIcon from "../../../assets/icons/identity/error.svg";
import background from "../../../assets/images/light_wave_background.png";

const Rejected = () => {
  // const EMAIL = process.env.REACT_APP_EMAIL_SUPPORT;
  //const href = `mailto:${EMAIL}?subject=Identity Verification Support`;

  // TODO add long process animation and subscription to the backend

  return (
    <Box marginTop={{ xs: "10px", md: "90px" }} padding="20px">
      <Box
        sx={{
          position: "absolute",
          top: "0px",
          bottom: "0px",
          left: "0px",
          right: "0px",
          backgroundImage: `url(${background})`,
          zIndex: "-1",
        }}
      ></Box>
      <Stack
        spacing={2}
        sx={{
          color: "primary.main",
        }}
      >
        <Typography fontSize={34} fontWeight={600}>
          OOPS!
        </Typography>
        <Typography>
          There was an error in the identity verification process Please contact
          Support
        </Typography>

        <Box margin="auto">
          <img
            src={ErrorIcon}
            alt="warning icon"
            height={126}
            width={126}
          ></img>
        </Box>
        <Box padding={4}>
          <Button variant="contained" href={"/contactsupport"}>
            Contact Support
          </Button>
        </Box>
      </Stack>
    </Box>
  );
};

export default Rejected;
