import React, { useState, useEffect } from "react";
import { AthleteNotFound } from "components/AthleteNotFound/AthleteNotFound";
import { AthleteProfileHeader } from "components/AthleteProfileHeader/AthleteProfileHeader";
import { PayListButton } from "./PayListButton";
import { ReactComponent as Back } from "assets/icons/back.svg";
import { TOGGLE_FAVORITE } from "queries";
import { useAppState } from "providers/AppState";
import { useMutation } from "@apollo/client";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import AmountInput from "components/AmountInput";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Followers from "components/Followers/Followers";
import List from "@mui/material/List";
import ModalWrapper from "components/ModalWrapper";
import Stack from "@mui/material/Stack";
import useUserProfile from "hooks/useUserProfile";
import { insertApiResponseDataDog, insertApiResponseDataDogError } from "utils";

const AthleteProfile = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  const [showModal, setShowModal] = useState(false);
  const athleteId = params.id || "";
  const appState = useAppState();
  const imageloader = false;


  const { loading, data, refetch } = useUserProfile(athleteId);

  const [toggleFanFavorite] = useMutation(TOGGLE_FAVORITE, {
    onCompleted(data) {
      insertApiResponseDataDog("toggleFanFavoriteApi(success)", data, "info")

    },
    onError(error) {
      insertApiResponseDataDogError(error, "toggleFanFavoriteApi(error)");
      insertApiResponseDataDog("toggleFanFavoriteApi(error)", error, "error")
    },
  });

  useEffect(() => {
    // NOTE: reset public profile state if redirecting from user registration
    if (athleteId === appState.publicProfile) {
      appState.setPublicProfile(null);
    }
  }, []);

  const handleBack = () => {
    const first = location.pathname.split("/")[1];
    const path = first === "pay" ? "/pay/search" : `/${first}`;
    navigate(path);

  };

  const handlePay = () => {
    if (appState?.amount) {
      appState.setFrom(location.pathname);
      navigate(`/pay/search/${athleteId}/review`);
    } else {
      setShowModal(true);
    }
  };

  const handleFavorite = async () => {
    await toggleFanFavorite({ variables: { athleteId } });
    refetch();
  };

  if (loading) return <CircularProgress />;

  if (!data) return <AthleteNotFound handleBack={handleBack} />;

  const { imageUrl, firstName, lastName, school, isAlumni } = data.userProfile;
  const athleteSport = data.userProfile.athleteSport;
  const str = firstName + " " + lastName;
  const words = str.split(" ");

  for (let i = 0; i < words?.length; i++) {
    words[i] = words[i][0] ? words[i][0]?.toUpperCase() + words[i].substring(1) : "";
  }
  const fullName = words.join(' ');

  const handleAmount = (amount: number) => {
    setShowModal(false);
    appState.setAmount(amount);
    appState.setFrom(location.pathname);
    navigate(`/fanhome/athlete/${athleteId}/review`);
  };
  return (
    <Box marginBottom={4}>
      <ModalWrapper open={showModal} setOpen={setShowModal} dark>
        <AmountInput previousValue={null} onSubmit={handleAmount} />
      </ModalWrapper>
      <Stack direction="column" alignItems="flex-start" maxWidth="600px">
        <Button startIcon={<Back />} onClick={handleBack}>
          Back
        </Button>

        <AthleteProfileHeader
          imageUrl={imageUrl}
          isAlumni={isAlumni}
          school={school}
          athleteSport={athleteSport}
          fullName={fullName}
          handleChangeImage={function (): void {
            throw new Error("Function not implemented.");
          }}
          imageloader={imageloader}

        >
          <Followers
            onClick={() => handleFavorite()}
            isFavorite={data.userProfile.isFavorite}
            supporters={data.userProfile.athleteProfileStats.supporters}
            favorites={data.userProfile.athleteProfileStats.favorites}
          />
        </AthleteProfileHeader>

        <Stack alignItems="center" width="100%" spacing={4}>
          <Button variant="contained" onClick={handlePay} fullWidth>
            {"Direct-pay Athlete"}
          </Button>
        </Stack>

        <List sx={{ width: "100%" }}>
          {data.userProfile.athleteSports.map((item) => (
            <PayListButton
              key={item.id}
              title={`Direct-pay ${item.sport.name} Team`}
              subtitle={"Pay multiple players or the whole team"}
              onClick={() =>
                navigate(`/fanhome/school/${school.id}/sport/${item.sport.id}`)
              }
            />
          ))}
          <PayListButton
            title={"Request Sponsorship"}
            subtitle={"Initiate a sponsorship opportunity"}
            onClick={() => navigate(`/sponsorship/${athleteId}`)}
          />
          {data?.userProfile?.school?.donationLink ? <PayListButton
            title={"Donate to School"}
            subtitle={"Contribute to the official athletic dept."}
            onClick={() => window.open(data?.userProfile?.school?.donationLink, '_blank')}
          /> : ""}
        </List>
      </Stack>
    </Box>
  );
};

export default AthleteProfile;
