import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

interface Props {
  count: number;
  label: string;
}

export const LabelCount = ({ count, label }: Props) => (
  <Box>
    <Typography fontSize={16} fontWeight={600}>
      {count}
    </Typography>
    <Typography fontSize={12} fontWeight={300}>
      {label}
    </Typography>
  </Box>
);
