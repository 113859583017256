import React, { Component, ErrorInfo, ReactNode } from "react";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

interface Props {
  children?: ReactNode;
}

interface State {
  hasError: boolean;
}

class ErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false,
  };

  public static getDerivedStateFromError(): State {
    return { hasError: true };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error("Uncaught error:", error, errorInfo);
  }

  public render() {
    if (this.state.hasError) {
      return (
        <Stack direction="column" spacing={2} maxWidth={400} margin="auto">
          <Typography
            variant="h1"
            fontSize={30}
            fontWeight="600"
            textAlign={"center"}
          >
            Sorry, there was an error
          </Typography>
          <Button
            onClick={() =>
              window.location.replace(`${process.env.REACT_APP_URL}/home`)
            }
          >
            Go Back
          </Button>
        </Stack>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
