import React from "react";
import Box from "@mui/material/Box";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import SportIcon from "../../components/SportIcon";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { Athlete } from "../../types";
import { ReactComponent as HeartIcon } from "../../assets/icons/red_heart.svg";
import { ReactComponent as HeartIconEmpty } from "../../assets/icons/heart_empty.svg";
import { apolloClient } from "providers/Apollo";
import useGAEventTracker from "hooks/useGAEventsTracker";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { insertApiResponseDataDog, insertApiResponseDataDogError } from "utils";
import { TOGGLE_FAVORITE } from "queries";


interface Props {
  athlete: Athlete;
  handleClick: (x: Athlete) => void;
  isFavorite?: boolean;
}

const gaEventTracker = useGAEventTracker('Select Athelet');
export function AthleteCard({ athlete, handleClick, isFavorite }: Props) {
  const params = useParams();
  const [favorite, setFavorite] = React.useState(isFavorite); // Initialize isFavorite state

  const [toggleFanFavorite] = useMutation(TOGGLE_FAVORITE, {
    onCompleted(data) {
      insertApiResponseDataDog("toggleFanFavoriteApi(success)", data, "info")
    },
    onError(error) {
      insertApiResponseDataDogError(error, "toggleFanFavoriteApi(error)");
      insertApiResponseDataDog("toggleFanFavoriteApi(error)", error, "error")
    },
  });

  function getSchoolName({ nickname, mascot, name }: Athlete["school"]) {
    if (!nickname || !mascot) return name;
    return name;
  }
  function getFullName(firstName: string, lastName: string) {
    const full = firstName + " " + lastName;
    const arr = full.split(" ");
    for (let i = 0; i < arr.length; i++) {
      arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
    }
    const FullName = arr.join(" ");
    return FullName
  }

  function getSport(sports: Athlete["athleteSport"]) {
    return sports.map((item) => item.sport.name).join(", ");
  }

  const handleFavorite = async (item: any) => {
    setFavorite(!favorite);
    const athleteId = item?.id;
    await toggleFanFavorite({ variables: { athleteId } });
  };

  return (
    <ListItem
      sx={{
        paddingRight: 0,
        paddingLeft: 0,
      }}
    >
      <ListItemButton
        // onClick={() => {
        //   handleClick(athlete),
        //     gaEventTracker('click on Athelet', 'click on Athelet'),
        //     apolloClient.cache.reset();
        // }}
        sx={{
          backgroundColor: "background.paper",
          padding: "11px 24px",
          "&:hover": {
            backgroundColor: "secondary.light",
          },
        }}
      >
        <Stack
          direction="row"
          spacing={2}
          justifyContent="space-between"
          sx={{
            width: "100%",
          }}

        >
          <Stack direction="row" alignItems={"center"}
            onClick={() => {
              handleClick(athlete),
                gaEventTracker('click on Athelet', 'click on Athelet'),
                apolloClient.cache.reset();
            }}
          >
            <SportIcon sportName={athlete.athleteSport[0].sport.name} />
            <Box marginLeft={2}>
              <Typography fontSize={14} fontWeight="600" color="primary.main">
                {getFullName(athlete.firstName, athlete.lastName)}
              </Typography>
              <Typography textTransform="uppercase" fontSize={12}>
                {getSchoolName(athlete.school)}
              </Typography>
              <Typography fontSize={12} fontWeight="300">
                {getSport(athlete.athleteSport)}
              </Typography>
            </Box>
          </Stack>
          <Stack sx={{ paddingTop: 2 }} onClick={() => handleFavorite(athlete)}
          >
            {favorite ? (
              <Box
                textAlign="right"
                display="flex"
                justifyContent="center"
                alignItems="center"

              >
                <HeartIcon />
              </Box>
            ) : <Box
              textAlign="right"
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <HeartIconEmpty />
            </Box>}

          </Stack>

        </Stack>
      </ListItemButton>
    </ListItem>
  );
}
