import { ApolloError } from "@apollo/client";
import React, { useState, useContext } from "react";

enum AlertType {
  error = "error",
  warning = "warning",
  info = "info",
  success = "success",
}

// NOTE: The empty functions are needed to avoid type/lint errors
const notificationAPI = {
  show: false,
  text: "",
  title: "",
  type: AlertType.success,
  error: (text: unknown, title?: string) => {
    text;
    title;
  },
  warning: (text: string, title?: string) => {
    text;
    title;
  },
  info: (text: string, title?: string) => {
    text;
    title;
  },
  success: (text: string, title?: string) => {
    text;
    title;
  },
  clear: () => {
    //
  },
};

const NotificationContext =
  React.createContext<typeof notificationAPI>(notificationAPI);

export const NotificationProvider = (props: { children: React.ReactNode }) => {
  const [type, setType] = useState<AlertType>(AlertType.success);
  const [text, setText] = useState("");
  const [title, setTitle] = useState("");
  const [show, setShow] = useState(false);

  const updateNotification = (type: AlertType, text: string, title = "") => {
    setType(type);
    setText(text);
    setTitle(title || type);
    setShow(true);
  };

  const clear = () => setShow(false);
  const error = (err?: unknown, title?: string) => {
    let text = "Something went wrong. Try again later";
    if (typeof err === "string") {
      text = err;
    } else if (err instanceof ApolloError) {
      text = err.message;
    }
    updateNotification(AlertType.error, text, title);
  };

  const warning = (text: string, title?: string) => {
    updateNotification(AlertType.warning, text, title);
  };
  const info = (text: string, title?: string) => {
    updateNotification(AlertType.info, text, title);
  };
  const success = (text: string, title?: string) => {
    updateNotification(AlertType.success, text, title);
  };

  const value = {
    show,
    type,
    title,
    text,
    error,
    warning,
    info,
    success,
    clear,
  };

  return (
    <>
      <NotificationContext.Provider value={value}>
        {props.children}
      </NotificationContext.Provider>
    </>
  );
};

// NOTE: Convenience import hook to avoid importing context every time we need to use the notifications
export function useNotifications() {
  return useContext(NotificationContext);
}
