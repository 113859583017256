
/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
// import ModeEditIcon from '@mui/icons-material/ModeEdit';
// import DeleteIcon from '@mui/icons-material/Delete';
// import { useNavigate } from 'react-router';
import { ToogleButtonResponse, activeCouponCodes } from 'types/promoCode';
import { useMutation } from '@apollo/client';
import { ADMIN_Active_inActive_Coupon_Code } from 'queries';
import { useNotifications } from 'providers/Notification';
import { apolloClient } from 'providers/Apollo';
import { Button, CircularProgress } from '@mui/material';
// import useGetCouponsData from 'hooks/getCouponsList';
import './style.css';
import moment from 'moment';
import Pagination from '@mui/material/Pagination';
import { insertApiResponseDataDog, insertApiResponseDataDogError } from 'utils';


export default function StickyHeadTable(props: any) {
    const { data, page, handleChangePage, couponLoader } = props;
    // const navigate = useNavigate()
    const notify = useNotifications()
    // const [open, setOpen] = React.useState(false);
    // const [id, setId] = React.useState('');
    // const [deleteCoupon] = useMutation<deleteCouponResponse, delteCouponCodes>(
    //     ADMIN_Delete_Coupon_Code
    // );

    // const onGotoEdit = (item: any) => {
    //     navigate('/promoedit/', { state: { item: item } })
    // }

    // const onDeleteRecord = async (id: any) => {
    //     setOpen(true);
    //     setId(id)
    // }

    // const onDeleteCoupon = async () => {
    //     try {
    //         const res = await deleteCoupon({
    //             variables: {
    //                 couponCodeId: id,
    //             }
    //         });
    //         notify.success("Deleted coupon Successfully")
    //         setOpen(false);


    //     } catch (error) {

    //         notify.error(error);
    //     }
    //     apolloClient.cache.reset();
    // }

    // const handleClose = () => {
    //     setOpen(false);
    // };

    const [activeInactiveCouponCode] = useMutation<ToogleButtonResponse, activeCouponCodes>(
        ADMIN_Active_inActive_Coupon_Code
    );
    const onButtonInActive = async (item: any, buttonActivate: any) => {
        try {
            const res = await activeInactiveCouponCode({
                variables: {
                    couponCodeId: item.id,
                    isActive: buttonActivate
                }
            });
            insertApiResponseDataDog("activeInactiveCouponCode(success)", res, "info")
            notify.success("Deactivate coupon Successfully")
        } catch (error) {
            insertApiResponseDataDogError(error, "activeInactiveCouponCode(error)");
            insertApiResponseDataDog("activeInactiveCouponCode", error, "error")
            notify.error(error);
        }
        apolloClient.cache.reset();
    }

    const onButtonActive = async (item: any, buttonActivate: any) => {
        try {
            const res = await activeInactiveCouponCode({
                variables: {
                    couponCodeId: item.id,
                    isActive: buttonActivate
                }
            });
            insertApiResponseDataDog("activeInactiveCouponCode(success)", res, "info")

            notify.success("active coupon Successfully")
        } catch (error) {
            insertApiResponseDataDogError(error, "activeInactiveCouponCode(error)");
            insertApiResponseDataDog("activeInactiveCouponCode", error, "error")
            notify.error(error);
        }
        apolloClient.cache.reset();
    }
    return (<>
        <Paper sx={{ width: '100%', marginTop: '20px', marginBottom: '20px' }}>


            {/* <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you want delete the Coupon ?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button onClick={() => onDeleteCoupon()} autoFocus>
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog> */}
            {couponLoader ? <CircularProgress /> : ""}

            <TableContainer sx={{ maxHeight: 440 }}>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            <TableCell>First Name</TableCell>
                            <TableCell>Last Name</TableCell>
                            <TableCell>Email</TableCell>
                            <TableCell>Coupon</TableCell>
                            <TableCell>Athlete Share</TableCell>
                            <TableCell>Mnp Share</TableCell>
                            <TableCell>Status</TableCell>

                            <TableCell>Active Date</TableCell>
                            <TableCell>In Active Date</TableCell>
                            <TableCell></TableCell>

                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data && data?.getCouponCodes?.items.map((items: any) => {
                            return (<TableRow key={items.id}>
                                <TableCell>{items.firstName}</TableCell>
                                <TableCell>{items.lastName}</TableCell>
                                <TableCell>{items.email}</TableCell>
                                <TableCell>{items.coupon}</TableCell>
                                <TableCell>{items.vendorShare}</TableCell>
                                <TableCell>{items.mnpShare}</TableCell>
                                <TableCell>{items.isActive ? <span style={{ color: 'blue' }}>Active</span> : <span style={{ color: 'red' }}>In Active</span>}</TableCell>

                                <TableCell>{items.activatedDate ? moment(items.activatedDate).format('DD-MM-YYYY') : ""}</TableCell>
                                <TableCell>{items.deactivatedDate ? moment(items.deactivatedDate).format('DD-MM-YYYY') : ""}</TableCell>
                                <TableCell>
                                    {items.isActive ? <Button onClick={() => onButtonInActive(items, false)} type="submit"
                                        variant="contained" style={{ width: 120, fontSize: 10 }}>
                                        DeActive Coupon
                                    </Button> : <Button onClick={() => onButtonActive(items, true)} type="submit"
                                        variant="contained" style={{ width: 120, fontSize: 10 }}> Active Copon</Button>}
                                </TableCell>
                            </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </Paper>
        <Pagination count={Math.ceil(data?.getCouponCodes.itemTotal / 5) || 0} page={page} onChange={handleChangePage} />
    </>
    );
}
