import { gql } from "@apollo/client";

export const FAN_SPLASH_IMAGES = gql`
query activeSplashImageDetails($group: String!, $screen: String!){  
    activeSplashImageDetails(group: $group, screen: $screen){
    id,
    expiryDate,
    imageUrl,
    screen
    }
  
  }
`;