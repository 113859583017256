import React from "react";
import { ImagePlaceholder } from "./ImagePlaceholder";

interface Props {
  imageUrl: string | null;
  sport: string;
}
export const AthleteImage = ({ imageUrl, sport }: Props) => (
  <img
    width={140}
    height={"auto"}
    src={imageUrl || ImagePlaceholder(sport)}
    alt="Athlete profile image"
  />
);
