/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from "react";
import {
  useForm,
  Controller,
  UseFormReturn
} from "react-hook-form";
import { EDU_MAIL, PASSWORD_REGEX } from "constant";
import { LogoHeader } from "./LogoHeader";
import { SIGN_UP } from "queries";
import { SSOIcons } from "../Login/SSOIcons";
import { useMutation } from "@apollo/client";
import { useNavigate } from "react-router-dom";
import { useNotifications } from "providers/Notification";
import BackButton from "components/BackButton/BackButton";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import CircularProgress from "@mui/material/CircularProgress";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid";
import PasswordField from "components/PasswordField";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { differenceInYears, format } from "date-fns";
import TermsAndCondition from "components/TermsAndCondition/TermsAndCondition";

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { formatPhoneNumber, insertApiResponseDataDog, insertApiResponseDataDogError } from "utils";
import { isValid, parse } from 'date-fns';

import moment from "moment";
import './fanSign.css'


interface SignUpFormValues {
  firstName: string;
  lastName: string;
  birthday: string;
  email: string;
  password: string;
  confirmPassword: string;
  zipCode: string;
  phoneNumber: string;
  terms: boolean;
}


export default function FanSignUpForm() {
  const notify = useNotifications();
  const navigate = useNavigate();
  const [step, setStep] = useState(1);
  const [signUp] = useMutation(SIGN_UP);

  const form = useForm<SignUpFormValues>({
    defaultValues: {
      firstName: "",
      lastName: "",
      birthday: "",
      email: "",
      password: "",
      confirmPassword: "",
      zipCode: "",
      phoneNumber: "",
      terms: false,
    },
    mode: "all",
  });

  const onSubmit = async ({
    firstName,
    lastName,
    birthday,
    email,
    password,
    zipCode,
    phoneNumber,
  }: SignUpFormValues) => {
    // NOTE: The role and birthday are placeholder with fake data
    // We need these fields in the mutation to be accepted by the backend
    // TODO: delete placeholder role and birthday after the backend is updated.
    const role = "temp";
    // const birthday = "1980-01-01";

    try {
      const response = await signUp({
        variables: {
          data: { firstName, lastName, email, password, birthday, role, phoneNumber, zipCode },
        },
      });
      if (response) {
        insertApiResponseDataDog("SignUpApi(success)", response, "info")
        navigate('/verifyEmail', { state: { email: email, password: password } });
      }
    } catch (error) {
      insertApiResponseDataDogError(error, "SignUpApi(error)");
      insertApiResponseDataDog("SignUpApi(error)", error, "error")
      notify.error(error);
    }
  };

  const handleClose = () => {
    navigate("/login");
  };

  const props = {
    form,
    setStep,
    handleClose,
  };

  if (step === 3) {
    return (
      <Grid container spacing={2} justifyContent="center">
        <LogoHeader />

        <Grid item xs={10} md={4} lg={3} marginTop={8}>
          {/* <Step3 watch={form.watch} /> */}
        </Grid>
      </Grid>
    );
  }

  return (
    <form onSubmit={form.handleSubmit(onSubmit)}>
      {step === 1 && (
        <Grid container spacing={2} justifyContent="center">
          <LogoHeader />
          <Grid item xs={10} md={4} lg={3} marginTop={8}>
            <Step1 {...props} />
          </Grid>
        </Grid>
      )}
      {step === 2 && (
        <Grid container spacing={2} justifyContent="center">
          <LogoHeader />
          <Grid item xs={10} md={4} lg={3} marginTop={8}>
            <BackButton onClick={() => setStep(1)} />
            <Step2 {...props} />
          </Grid>
        </Grid>
      )}
    </form>
  );
}

interface FormStepProps {
  form: UseFormReturn<SignUpFormValues>;
  setStep: React.Dispatch<React.SetStateAction<number>>;
  handleClose: () => void;
}

const Step1 = ({ form, setStep, handleClose }: FormStepProps) => {
  const { isDirty, errors, isSubmitting, touchedFields } = form.formState;
  const passwordValue = form.watch("password");
  const confirmPasswordValue = form.watch("confirmPassword");
  const checkedTermsAndConditions = form.watch("terms");


  useEffect(() => {
    // NOTE - trigger validation when any of the password fields change
    if (passwordValue && confirmPasswordValue) {
      form.trigger(["password", "confirmPassword"]);
    }
  }, [passwordValue, confirmPasswordValue]);

  const isFirstStepValid = () => {
    const noErrors = () => {
      const { email, password, confirmPassword, terms } = errors;
      return !email && !password && !confirmPassword && !terms;
    };

    const touchedAllFields = () => {
      const { email, password, confirmPassword } = touchedFields;
      return email && password && confirmPassword;
    };

    return Boolean(
      noErrors() && touchedAllFields() && checkedTermsAndConditions
    );
  };


  const [error, setError] = React.useState('')
  function handleChange(event: any) {
    // localStorage.setItem("fanBirthDate", '')
    if (/\.edu$/i.test(event.target.value)) {
      setError('.edu email id’s are not accepted, please try another one');
    }
    else if (!(/\.edu$/i.test(event.target.value))) {
      setError("Invalid email address");
    } else if (event.target.value === "") {
      setError("Email is required");
    } else {
      setError('')
    }
  }

  return (
    <>
      <Stack direction="column" alignItems="flex-start" spacing={1}>
        <Typography marginBottom={2} fontWeight="light">
          Enter your info below to get started!
        </Typography>
        <TextField
          autoFocus
          margin="dense"
          inputProps={{ "data-testid": "email" }}
          label="Email"
          placeholder="Enter email"
          InputLabelProps={{
            shrink: true,
          }}
          type="text"
          fullWidth
          autoComplete="none"
          error={Boolean(isDirty && errors.email)}
          disabled={isSubmitting}
          helperText={(isDirty && errors.email?.message) || " "}
          {...form.register("email", {
            required: error,
            pattern: {
              value: EDU_MAIL,
              message: error,
            }

          })}

          onChange={handleChange}
        />
        <Controller
          name="password"
          control={form.control}
          rules={{
            validate: (value) =>
              !confirmPasswordValue ||
              value === confirmPasswordValue ||
              "The passwords do not match",
            required: "Password is required",
            minLength: {
              value: 8,
              message: "Minimum password length 8 characters",
            },
            pattern: {
              value: PASSWORD_REGEX,
              message:
                "Please use uppercase letters, lowercase letters, special characters, and numbers",
            },
          }}
          render={({ field }) => (
            <PasswordField
              inputProps={{ "data-testid": "password" }}
              label="Password"
              placeholder="Enter password"
              error={isDirty && Boolean(errors.password)}
              disabled={isSubmitting}
              helperText={(isDirty && errors.password?.message) || " "}
              {...field}
              ref={null}

            />
          )}
        />
        <Stack width="100%">
          <Controller
            name="confirmPassword"
            control={form.control}
            rules={{
              required: "Confirmed Password is required",
              validate: (value) =>
                value === passwordValue || "The passwords do not match",
            }}
            render={({ field }) => (
              <PasswordField
                inputProps={{ "data-testid": "confirmPassword" }}
                label="Confirm Password"
                placeholder="Enter password"
                error={isDirty && Boolean(errors.confirmPassword)}
                disabled={isSubmitting}
                helperText={(isDirty && errors.confirmPassword?.message) || " "}
                {...field}
                ref={null}

              />
            )}
          />
          <FormControlLabel
            checked={checkedTermsAndConditions}
            control={<Checkbox data-testid="terms" size="small" />}
            label={
              <Typography component={'div'} fontSize={13} textAlign={"left"}>
                <TermsAndCondition />
              </Typography>
            }
            {...form.register("terms", { required: true })}
          />
        </Stack>
      </Stack>
      <Stack margin={2}>
        <SSOIcons />
        <Stack
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={0}
          width="100%"
        >
          <Box marginBottom="24px" display="flex" alignItems="center">
            <Typography>Already have an account?</Typography>
            <Button variant="text" onClick={handleClose}>
              Sign In
            </Button>
          </Box>
        </Stack>
        <Button
          data-testid="nextButton"
          type="button"
          variant="contained"
          onClick={() => setStep(2)}
          disabled={!isFirstStepValid()}
        >
          Continue
        </Button>
      </Stack>
      <Stack
        marginTop="10px"
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing={0}
        width="100%"
      >
        <Box marginBottom="0px" display="flex" alignItems="center">
          <Typography>Trouble signing up?</Typography>
          <Button variant="text" onClick={() => window.zE.activate()}>
            <Typography fontSize={16} fontWeight={500}>
              Chat with us
            </Typography>
          </Button>
        </Box>
      </Stack>
    </>
  );
};

const Step2 = ({ form }: FormStepProps) => {
  // const { isDirty, errors, isSubmitting, isValid } = form.formState;
  const { isDirty, errors, isSubmitting } = form.formState;
  const [dateValue, setDateValue] = React.useState<unknown>("");
  const [phoneNumber, setPhoneNumber] = useState<any>('');
  const [zipCode, setZipCode] = useState<any>("");
  const [error, setError] = useState(false);
  const [ageError, setAgeError] = useState(false);
  const [maxAgeError, setMaxAgeError] = useState(false);

  const handleMaxDateChange = (newValue: Date | null) => {
    setDateValue(newValue);

    if (newValue) {
      const currentDate = new Date();
      const age = differenceInYears(currentDate, newValue);
      const years = 105;
      const daysInYear = 365.25;
      const days = years * daysInYear;
      if (age > days) {
        setMaxAgeError(true);
      } else {
        setMaxAgeError(false);
      }
    } else {
      setMaxAgeError(false);
    }
  };
  const handleDateChange = (newValue: Date | null) => {
    setDateValue(newValue);
    if (newValue) {
      const currentDate = new Date();
      const age = differenceInYears(currentDate, newValue);

      if (age < 18) {
        setAgeError(true);
      } else {
        setAgeError(false);
      }
    } else {
      setAgeError(false);
    }
  };

  const handleManualDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputDate = event.target.value;
    const parsedDate = parse(inputDate, 'MM/dd/yyyy', new Date());

    // if (inputDate.length === 10 && isValid(parsedDate)) {
    if (inputDate.length === 10 && isValid(parsedDate)) {
      setDateValue(parsedDate);
      setError(false);
      handleDateChange(parsedDate);
      handleMaxDateChange(parsedDate)
    } else {
      setDateValue(null);
      setError(true);
      handleDateChange(null);
      handleMaxDateChange(null)
    }
  };
  // const appState = useAppState()
  useEffect(() => {
    const zip_Code = localStorage.getItem('zipCode');
    const mobile = localStorage.getItem('phone');


    if (localStorage.getItem('fanBirthDate') !== "") {
      setDateValue(localStorage.getItem('fanBirthDate'))
    }
    if (zip_Code !== "") {
      setZipCode(zip_Code)
    }
    if (mobile !== "") {
      setPhoneNumber(mobile)
    }

  }, [])
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleChangePhoneNumber = (e: any) => {
    const arr = Array.from(e.target.value)
    if (arr[0] !== "0") {
      const formattedPhoneNumber = formatPhoneNumber(e.target.value);
      setPhoneNumber(formattedPhoneNumber);
      localStorage.setItem("phone", formattedPhoneNumber)

    }
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleChangeZip = (e: any) => {

    const regex = /^[0-9\b]+$/;
    if (e.target.value === "" || regex.test(e.target.value)) {
      if (e.target.value.length <= 5) {
        setZipCode(e.target.value);
        localStorage.setItem("zipCode", e.target.value)

      }
    }
  }
  const currentDate = new Date();
  const DateValidation = currentDate.setFullYear(currentDate.getFullYear() - 18)
  const minDateValidation = currentDate.setFullYear(currentDate.getFullYear() - 87)
  const minformateDate = moment(minDateValidation).format('MM/DD/YYYY')
  const formateDate = moment(DateValidation).format('yyyy-MM-DD');

  return (
    <>
      <Stack direction="column" alignItems="flex-start" spacing={1}>
        <Typography marginBottom={2}>
          Next, let’s collect some details to create your profile.
        </Typography>

        <TextField
          autoFocus
          margin="dense"
          inputProps={{ "data-testid": "firstName" }}
          label="First Name"
          placeholder="Enter First Name"
          InputLabelProps={{
            shrink: true,
          }}
          type="text"
          fullWidth
          autoComplete="none"
          error={Boolean(isDirty && errors.firstName)}
          disabled={isSubmitting}
          helperText={(isDirty && errors.firstName?.message) || " "}
          {...form.register("firstName", {
            required: "First Name is required",
          })}
        />
        <TextField
          margin="dense"
          inputProps={{ "data-testid": "lastName" }}
          label="Last Name"
          placeholder="Enter Last Name"
          InputLabelProps={{
            shrink: true,
          }}
          type="text"
          fullWidth
          autoComplete="none"
          error={isDirty && Boolean(errors.lastName)}
          disabled={isSubmitting}
          helperText={(isDirty && errors.lastName?.message) || " "}
          {...form.register("lastName", { required: "Last Name is required" })}
        />
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DesktopDatePicker
            inputFormat={"MM/DD/YYYY"}
            disabled={isSubmitting}
            value={dateValue}
            minDate={minformateDate}
            maxDate={formateDate}
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            onChange={(newValue: any) => {
              localStorage.setItem("fanBirthDate", newValue)
              setDateValue(newValue);
              setError(false);
              setMaxAgeError(false);
              setAgeError(false);
              // form.setValue('birthday', format(new Date(newValue), "MM-dd-yyyy"), { shouldValidate: true })
              form.setValue('birthday', format(new Date(newValue), "MM/dd/yyyy"), { shouldValidate: true })
            }}
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            renderInput={(params: any) => {
              return <TextField error={isDirty && Boolean(errors.birthday) || error || ageError || maxAgeError} label="Birthday" inputProps={{
                placeholder: "MM/DD/YYYY", "data-testid": "birthday",
                readOnly: false
              }} InputProps={params.InputProps}
                onError={() => isDirty && Boolean(errors.birthday)}
                inputRef={params.inputRef}
                {...form.register("birthday", {
                  required: "Date of birth is required",
                })}
                fullWidth
                autoComplete="none"
                InputLabelProps={{
                  shrink: true,
                }}
                //helperText={(isDirty && errors.birthday?.message) || error ? 'Date should be MM\DD\YYYY' : ''}
                helperText={(isDirty && errors.birthday?.message) || error ? ' Date format should be MM/DD/YYYY' : ageError ? ' Must be at least 18 years old' : maxAgeError ? ' Age should not be greater than 105 years' : ''}
                onChange={handleManualDateChange}
              />
            }}
          />
        </LocalizationProvider>
        <br />
        <TextField
          margin="dense"
          inputProps={{ "data-testid": "zipCode" }}
          label="Zip Code"
          placeholder="Enter Zip Code"
          InputLabelProps={{
            shrink: true,
          }}
          type="text"
          value={zipCode}
          fullWidth
          autoComplete="none"
          error={isDirty && Boolean(errors.zipCode)}
          disabled={isSubmitting}
          helperText={(isDirty && errors.zipCode?.message) || " "}
          {...form.register("zipCode", {
            required: "zipCode is required",
            minLength: { value: 5, message: "Please enter 5 minimum digits number" }
          })}
          onChange={(e) => handleChangeZip(e)}
        />
        <br />
        <TextField
          margin="dense"
          value={phoneNumber}
          inputProps={{ "data-testid": "phoneNumber" }}
          label="Phone Number"
          placeholder="Enter Phone Number"
          InputLabelProps={{
            shrink: true,
          }}
          type="text"
          fullWidth
          autoComplete="none"
          error={isDirty && Boolean(errors.phoneNumber)}
          disabled={isSubmitting}
          helperText={(isDirty && errors.phoneNumber?.message) || " "}
          {...form.register("phoneNumber", {
            minLength: {
              value: 12,
              message: "Phone number should be 10 digits"
            },
          })}
          onChange={(e) => handleChangePhoneNumber(e)}
        />
      </Stack>
      <Stack margin={6}>
        <Button
          startIcon={isSubmitting ? <CircularProgress size={20} /> : null}
          data-testid="submitButton"
          type="submit"
          variant="contained"

          // onClick={gotoVerify}
          disabled={!isDirty || isSubmitting || error || ageError || maxAgeError || !form.formState.isValid}
        >
          Complete
        </Button>
      </Stack>
      <Stack
        marginTop="-30px"
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing={0}
        width="100%"
      >
        <Box marginBottom="0px" display="flex" alignItems="center">
          <Typography>Trouble signing up?</Typography>
          <Button variant="text" onClick={() => window.zE.activate()}>
            <Typography fontSize={16} fontWeight={500}>
              Chat with us
            </Typography>
          </Button>
        </Box>
      </Stack>
    </>
  );
};



