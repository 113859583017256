import React, { useState } from "react";
import { FILTER_DISPLAY } from "constant";
import { ReactComponent as ChevronUp } from "assets/icons/chevron_up.svg";
import { School, Sport, SportsResponse } from "types";
import { useQuery } from "@apollo/client";

import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import Divider from "@mui/material/Divider";
import FormControlLabel from "@mui/material/FormControlLabel";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import { SPORTS } from "queries";
import SportIcon from "../SportIcon";

interface Props {
  isSelected: (x: School | Sport) => boolean;
  handleCheckbox: (x: Sport) => void;
}

function SportList({ isSelected, handleCheckbox }: Props) {
  const [show, setShow] = useState(true);

  const { data } = useQuery<SportsResponse>(SPORTS);

  return (
    <>
      <ListItemButton onClick={() => setShow(!show)}>
        <ListItemText primary="Sports" />
        <Box
          sx={{
            transition: "transform 300ms",
            transform: show ? "rotate(0deg)" : "rotate(180deg)",
          }}
        >
          <ChevronUp />
        </Box>
      </ListItemButton>
      <Divider />
      {show && (
        <List
          component="div"
          sx={{
            maxHeight: "50vh",
            overflow: "scroll",
            paddingBottom: "100px",
          }}
        >
          {data?.sports.map((sport) => (
            <ListItem
              key={sport.id}
              sx={{
                paddingTop: "0",
                paddingBottom: "0",
                "&:hover": {
                  backgroundColor: "secondary.main",
                },
              }}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isSelected(sport)}
                    onChange={() => handleCheckbox(sport)}
                  />
                }
                labelPlacement="start"
                label={
                  <Box display="flex" alignItems="center">
                    <SportIcon sportName={sport.name} />
                    <Typography
                      fontSize={"14px"}
                      fontWeight={"500"}
                      marginLeft={1}
                      sx={{ textTransform: "capitalize" }}
                    >
                      {sport[FILTER_DISPLAY].toLocaleLowerCase()}
                    </Typography>
                  </Box>
                }
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              />
            </ListItem>
          ))}
        </List>
      )}
    </>
  );
}

export default SportList;
