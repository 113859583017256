/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import Header from "components/Header";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import { useLocation, useNavigate } from 'react-router';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';

import './billing.css'
import { Box } from '@mui/system';
import { savedCardsData } from 'types/savedCards';
import { ApolloError, useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { cardsSaving } from 'queries/cardsSaving';
import { useNotifications } from 'providers/Notification';
import { checkoutInput, checkoutMultiAthlete, checkoutMultiAthleteResponse, checkoutResponse } from 'types/checkoutPayment';
import { checkoutPayment, deleteCards } from 'queries/checkoutPayment';
import { useAppState } from "providers/AppState";
import { checkoutMultiAthletePayments } from 'queries/multiathletecheckoutPayment';
import { MemorabuilaErrorModel } from "components/MemorabuilaErrorModel";
import { CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText } from '@mui/material';
import Button from "@mui/material/Button";
import { insertApiResponseDataDog, insertApiResponseDataDogError } from 'utils';

type cardDetails = {
    cardLastFourDigits: string,
    cardType: string,
    id: string
}

const BillingContactInfo = () => {
    const appState = useAppState();
    const location = useLocation();
    const navigate = useNavigate()
    const notify = useNotifications();
    const athleteData: any = location.state;
    const [savedCheckBox, setSavedCheckBox] = React.useState(false);
    const [savedCreditCheck, setSavedCreditCheck] = React.useState(false);
    const tax: any = Number(athleteData.tax) || 0
    const userId: any = athleteData.id || '';
    const amount: number = athleteData.amount || 0;
    const [memorabuilaErrorModel, setmemorabuilaErrorModel] = React.useState(false);
    const [currentStatus, setCurrentStatus] = React.useState('');
    const [toogleButton, setToggleButton] = React.useState(false)
    const [currentCardData, setCurrentCardData] = React.useState<cardDetails>({
        cardLastFourDigits: '',
        cardType: '',
        id: ""
    })
    const [open, setOpen] = React.useState(false);
    const [cardId, setCardId] = React.useState<any>('');

    const { data, loading, refetch } = useQuery<savedCardsData>(cardsSaving, {
        onError: (error: ApolloError) => {
            insertApiResponseDataDogError(error, "getVaultedShopperApi(error)");
            notify.error(error.message);
            insertApiResponseDataDog("getVaultedShopperApi(error)", error, "error")
        },
        onCompleted: (data) => {
            insertApiResponseDataDog("getVaultedShopperApi(success)", data, "info")
        }
    });
    const [getDeletedCards, { loading: deleteCardLoader }] = useMutation(deleteCards, {
        onCompleted(data) {
            insertApiResponseDataDog("SearchfansListApi(success)", data, "info");
        },
        onError(error) {
            insertApiResponseDataDogError(error, "SearchfansListApi(error)");
            insertApiResponseDataDog("SearchfansListApi(error)", error, "error");
            notify.error(error.message)
        }
    });

    const [setPaySingleAthlete, { loading: singleathlete }] = useMutation<checkoutResponse, checkoutInput>(checkoutPayment, {
        onError(error) {
            insertApiResponseDataDogError(error, "purchaseMemorabiliaApi(error)");
            insertApiResponseDataDog("purchaseMemorabilia(error)", error, "error")
        },
        onCompleted(data) {
            insertApiResponseDataDog("purchaseMemorabilia(success)", data, "info")
        }
    });
    const [setmultiAthletePayMents, { loading: multiAthleteLoader }] = useMutation<checkoutMultiAthleteResponse, checkoutMultiAthlete>(checkoutMultiAthletePayments, {
        onError(error) {
            insertApiResponseDataDogError(error, "purchaseMemorabiliaMultiApi(error)");
            insertApiResponseDataDog("purchaseMemorabiliaMulti(error)", error, "error");
        },
        onCompleted(data) {
            insertApiResponseDataDog("purchaseMemorabiliaMulti(success)", data, "info");
        },
    });
    if (singleathlete || multiAthleteLoader || loading || deleteCardLoader) { return <CircularProgress />; }
    const onChangeSavedCards = (e: any, item: any, key: any, savedIitem: any) => {
        setToggleButton(true)
        setSavedCheckBox(key);
        setCurrentStatus(savedIitem);
        setSavedCreditCheck(false)
        setCurrentCardData({
            cardLastFourDigits: item.last4,
            cardType: item.instrumentType,
            id: item.id
        })
    }
    const onSubmitPay = () => {
        if (currentStatus === "savedCards") {
            if (typeof userId === 'string' && userId !== "") {
                const setSingleAthletePayment = async () => {
                    try {
                        const res = await setPaySingleAthlete({
                            variables: {
                                athleteUserId: userId,
                                newCardTokenId: "",
                                paymentInfo: {
                                    amount: amount,
                                    tax: tax
                                },
                                savedCardId: currentCardData.id
                            }
                        });

                        if (res?.data?.purchaseMemorabiliaWithCko.status === 200) {
                            appState.setAmount(null);
                            localStorage.setItem("valid", "");
                            navigate(`/fanhome?transaction=completed&id=${userId}`);

                        } else {
                            setmemorabuilaErrorModel(true)
                        }
                    } catch (error) {
                        setmemorabuilaErrorModel(true)
                    }
                };
                setSingleAthletePayment();
            } else {
                const setMultiAthletePayment = async () => {
                    try {
                        const res = await setmultiAthletePayMents({
                            variables: {
                                athleteUserIds: userId,
                                newCardTokenId: "",
                                paymentInfo: {
                                    amount: amount,
                                    tax: tax
                                },
                                savedCardId: currentCardData.id
                            }

                        });

                        if (res?.data?.purchaseMemorabiliaMultiWithCko.status === 200) {
                            appState.setAmount(null);
                            localStorage.setItem("valid", "");
                            navigate('/fanhome?transaction=completed&multi=true');
                        } else {
                            setmemorabuilaErrorModel(true)
                        }
                    } catch (error) {
                        setmemorabuilaErrorModel(true)
                    }
                };
                setMultiAthletePayment();
            }
        } else if (currentStatus === "creditcard") {
            navigate('/cardPayment', { state: { id: athleteData.id, amount: amount, tax: tax } });
        }
    }
    let obj;
    if (typeof (data?.getFanCkoPaymentDetails.data) === "string") {
        if (data?.getFanCkoPaymentDetails.data == "") {
            obj = "empty"
        } else {
            obj = JSON.parse(data?.getFanCkoPaymentDetails.data);

        }
    }

    const onchangeCreditCard = (item: any) => {
        setSavedCheckBox(false);
        setSavedCreditCheck(true)
        setToggleButton(true)
        setCurrentStatus(item)
    }

    const handleDelete = async () => {
        setOpen(false)
        setToggleButton(false)

        try {
            const res = await getDeletedCards({ variables: { cardId: cardId } })
            if (res.data.deleteFanCkoPaymentDetail.status === 200) {
                notify.success(res.data.deleteFanCkoPaymentDetail.data);
                setCurrentCardData({
                    cardLastFourDigits: '',
                    cardType: '',
                    id: ''
                })
                refetch()

            } else {
                notify.error(res.data.deleteFanCkoPaymentDetail.data)
            }
        } catch (error: any) {
            notify.error(error)
        }
    }

    const handleClose = () => {
        setOpen(false)
    }

    const handleDeleteOpen = (item: any) => {
        setOpen(true);
        setCardId(item?.id)
    }

    return (<div className='mainContaner'>

        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    Are you want delete the Card ?
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button onClick={() => handleDelete()} autoFocus>
                    Confirm
                </Button>
            </DialogActions>
        </Dialog>
        {memorabuilaErrorModel && (
            <MemorabuilaErrorModel
                open={memorabuilaErrorModel}
                setOpen={setmemorabuilaErrorModel}
                amount={amount}
                id={userId}
                tax={tax}
            />
        )}
        <Header text="Payment Methods Info" />
        <p className='savingcards'> Your saved cards</p>
        {obj && obj === "empty" ? <Box textAlign={"left"} className="noSavedCards">
            No cards available
        </Box> : obj?.map((item: any, key: any) => {
            return <Box textAlign={"left"} className="cardscontainer" key={key}>
                <FormControlLabel value="savedCards" control={<Radio />} label={`**** **** **** ${item.last4}`} onChange={(e) => onChangeSavedCards(e, item, key, "savedCards")} checked={key === savedCheckBox ? true : false} /> <DeleteOutlinedIcon className={item?.id !== currentCardData?.id ? "disabled-icon" : "deleteIcon"} onClick={() => handleDeleteOpen(item)} />
            </Box>
        })}
        <p className='userCard'>Use another card</p>
        <Box textAlign={"left"} className="cardscontainer">
            <FormControlLabel value="CreditCard" control={<Radio />} label="Credit Card / Debit Card" onChange={() => onchangeCreditCard("creditcard")} checked={savedCreditCheck ? true : false} />
        </Box>
        <Box sx={{ alignContent: 'center' }}>
            {toogleButton && <Button
                onClick={onSubmitPay}
                data-testid="send-payment"
                variant="contained"
                sx={{
                    width: '100%',
                    height: '56px',
                    marginTop: 5
                }}
            >
                Pay
            </Button>}
        </Box>
    </div >)
}

export default BillingContactInfo;
