import { gql } from "@apollo/client";

export const TERMS_CONDITION = gql`
query getPrivacyPolicy($name: String!,$isActive: Boolean!) { 
    getPrivacyPolicy(name: $name, isActive: $isActive){
    id
    name
    content
    isActive
    updatedAt
 }
    }

`;
