/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import { ATHLETE_SIGN_UP } from "queries";
import { athleteSignUpResponse } from "types";
import { PASSWORD_REGEX } from "constant";
import { SearchResult } from "./index";
import { useForm, Controller } from "react-hook-form";
import { useMutation } from "@apollo/client";
import { useNotifications } from "providers/Notification";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import CircularProgress from "@mui/material/CircularProgress";
import FormControlLabel from "@mui/material/FormControlLabel";
import PasswordField from "components/PasswordField";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";
import { insertApiResponseDataDog, insertApiResponseDataDogError } from "utils";

export interface RegisterForm {
  id: string;
  password: string;
  confirmPassword: string;
  terms: boolean;
}

interface athleteSignUpArgs {
  id: string;
  password: string;
}

interface RegisterStepProps {
  email: string;
  result: SearchResult;
  setRegisterData: React.Dispatch<React.SetStateAction<RegisterForm | null>>;
}

const RegisterStep = ({
  result,
  email,
  setRegisterData,
}: RegisterStepProps) => {
  const notify = useNotifications();
  const navigate = useNavigate()

  const [athleteSignUp] = useMutation<athleteSignUpResponse, athleteSignUpArgs>(
    ATHLETE_SIGN_UP
  );

  const { register, handleSubmit, formState, control, watch } =
    useForm<RegisterForm>({
      defaultValues: {
        id: result.id,
        password: "",
        confirmPassword: "",
        terms: false,
      },
      mode: "all",
    });

  const { isDirty, errors, isSubmitting } = formState;
  const onSubmit = async (form: RegisterForm) => {
    try {
      const response = await athleteSignUp({
        variables: { id: form.id, password: form.password },
      });
      insertApiResponseDataDog("athleteSignUpApi(success)", response, "info")

      if (response.data?.athleteSignUp) {
        setRegisterData(form);
        navigate('/verifyEmail', { state: { email: email, password: form.password } });

      }
    } catch (error) {
      insertApiResponseDataDogError(error, "athleteSignUpApi(error)");
      insertApiResponseDataDog("athleteSignUpApi(error)", error, "error")
      notify.error("Registration Failed");
    }
  };

  const passwordValue = watch("password");
  const checkedTermsAndConditions = watch("terms");

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack direction="column" alignItems="flex-start" spacing={1}>
        <TextField
          value={email}
          margin="dense"
          data-testid="email"
          label="Email"
          placeholder="Enter email"
          InputLabelProps={{
            shrink: true,
          }}
          type="text"
          fullWidth
          autoComplete="none"
          disabled
          helperText={" "}
        />
      </Stack>

      <Stack direction="column" alignItems="flex-start" spacing={1}>
        <Controller
          name="password"
          control={control}
          rules={{
            required: "Password is required",
            minLength: {
              value: 8,
              message: "Minimum password length 8 characters",
            },
            pattern: {
              value: PASSWORD_REGEX,
              message:
                "Please use uppercase letters, lowercase letters, special characters, and numbers",
            },
          }}
          render={({ field }) => (
            <PasswordField
              inputProps={{ "data-testid": "password" }}
              label="Password"
              placeholder="Enter password"
              error={isDirty && Boolean(errors.password)}
              disabled={isSubmitting}
              helperText={(isDirty && errors.password?.message) || " "}
              {...field}
              ref={null}
            />
          )}
        />
        <Stack width="100%">
          <Controller
            name="confirmPassword"
            control={control}
            rules={{
              required: "Confirmed Password is required",
              validate: (value) =>
                value === passwordValue || "The passwords do not match",
            }}
            render={({ field }) => (
              <PasswordField
                inputProps={{ "data-testid": "confirmPassword" }}
                label="Verify Password"
                placeholder="Enter password"
                error={isDirty && Boolean(errors.confirmPassword)}
                disabled={isSubmitting}
                helperText={(isDirty && errors.confirmPassword?.message) || " "}
                {...field}
                ref={null}
              />
            )}
          />

          <FormControlLabel
            checked={checkedTermsAndConditions}
            control={<Checkbox data-testid="terms" size="small" />}
            label={

              <Typography component={'div'} fontSize={13}>
                <div style={{ float: "left", width: 124, fontSize: 13, marginTop: 10 }}>  Please accept the</div>
                <div style={{ float: "left" }}> <Button variant="text" onClick={() => { window.open('https://www.mynilpay.org/termsandconditions', '_blank'); }}>
                  terms &amp; conditions.
                </Button></div>
                <div style={{ float: 'left' }}>
                  I agree to Terms &amp; Conditions &amp; Privacy Policy
                </div>

              </Typography>

            }
            {...register("terms", { required: true })}
          />
        </Stack>
      </Stack>

      <Box marginTop={2}>
        <Button
          startIcon={
            formState.isSubmitting ? <CircularProgress size={20} /> : null
          }
          data-testid="submit-button"
          type="submit"
          variant="contained"
          disabled={!formState.isValid || formState.isSubmitting}
          fullWidth
        >
          Next
        </Button>
      </Box>
    </form >
  );
};

export default RegisterStep;
