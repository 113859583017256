import React from "react";
import { Route } from "react-router-dom";
import { RequireAuth } from "./";
import IdentityVerification from "pages/Athlete/IdentityVerification";
import PaymentAccount from "pages/Athlete/PaymentAccount";
import { Earnings } from "pages/Athlete/Earnings/Earnings";
import { NotFound } from "pages/Common/NotFound/NotFound";
import PersonalDetails from "pages/Athlete/PersonalDetails";
import PayoutInfo from "pages/Athlete/PayoutInfo";


export const AthleteRoutes = () => (
  <Route element={<RequireAuth userTypes={["athlete"]} />}>
    <Route path="/home/identity" element={<IdentityVerification />} />
    <Route path="/home/payment" element={<PaymentAccount />} />
    <Route path="/earnings" element={<Earnings />} />
    <Route path="/home/PersonalDetails" element={<PersonalDetails />} />
    <Route path="/home/PayoutInfo" element={<PayoutInfo />} />
    <Route path="*" element={<NotFound />} />
  </Route>
);
