import { gql } from "@apollo/client";
export const checkoutPayment = gql`
mutation purchaseMemorabiliaWithCko($athleteUserId:String!, $newCardTokenId:String!, $paymentInfo:PaymentInfoInput!,$savedCardId:String!) {
    purchaseMemorabiliaWithCko (athleteUserId:$athleteUserId, newCardTokenId:$newCardTokenId, paymentInfo: $paymentInfo,savedCardId:$savedCardId) 
    { 
        status 
        data  
    }
}`;

export const deleteCards = gql`
mutation deleteFanCkoPaymentDetail($cardId: String!) {
     deleteFanCkoPaymentDetail(cardId: $cardId) { data status}
     }
`;

