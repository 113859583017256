import React, { useEffect } from "react";
import useOnScreen from "../../hooks/useOnScreen";
import useThrottle from "../../hooks/useThrottle";

interface Props {
  children: React.ReactNode;
  // eslint-disable-next-line
  fetchMore: (x: Record<any, any>) => Promise<any>;
  page: number;
  scrollGetNotificationCount: () => void
}

function AlertsInfiniteScroll({ children, fetchMore, page, scrollGetNotificationCount }: Props) {
  const ref = React.useRef<HTMLDivElement>(null);
  const isVisible = useOnScreen(ref);
  const throttledFetch = useThrottle((page: number) => {
    fetchMore({ variables: { page } });
    scrollGetNotificationCount()
  });

  useEffect(() => {
    if (isVisible) throttledFetch(page);
  });

  return (
    <div>
      {children}
      <div ref={ref}></div>
    </div>
  );
}

export default AlertsInfiniteScroll;

