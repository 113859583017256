import React from "react";
import { Button, Grid as MatrielGrid, Typography } from "@mui/material";
import { ReactComponent as Close } from "../../assets/icons/close.svg";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';


type Props = {
    acceptFileType?: string;
    setFile: (src: File | null) => void;
    file: File | null
    formatErrorMessage?: string
    maxFileSize?: number // Kb format only allowed Ex: 4000kB = 4MB
    btnName?: string
    disabled?: boolean
}
export const FileUpload: React.FC<Props> = (props: Props) => {
    const { setFile, file, acceptFileType = '*', formatErrorMessage = '', maxFileSize = 0, btnName = ' Upload file', disabled = false } = props;
    const [fileFormatErrorMessage, setFileFormatErrorMessage] = React.useState<string>('');
    const [sizeErrorMessage, setSizeErrorMessage] = React.useState<string>('');

    const fileFormatChange = async (file: File) => {
        const fileName = file.name;
        const idxDot = fileName.lastIndexOf(".") + 1;
        const extFile = fileName.substring(idxDot, fileName.length).toLowerCase();
        const acceptFiles = [...acceptFileType.replace(/\./g, '').split(',')];
        if (acceptFileType !== '*' && ((file.type && !acceptFiles.includes(file.type.split('/')[1].toLowerCase()))
            || !acceptFiles.includes(extFile))) {
            setFileFormatErrorMessage(`${formatErrorMessage} format only allowed`);
            throw Error(`${formatErrorMessage} format only allowed`);
        }
    }
    const fileSizeCheck = async (file: File) => {
        const size = parseFloat(String(file.size));
        const maxSizeKB = maxFileSize; //Size in KB.
        const maxSize = maxSizeKB * 1024; //File size is returned in Bytes.
        if (size > maxSize) {
            const MB = Math.round(maxSize / (1024 ** 2));
            setSizeErrorMessage(`Max file size ${MB} MB allowed`);
            throw Error(`Max file size ${MB} MB allowed`);
        }

    }
    const fileOnchange = async (file: File | null) => {
        if (!file) return;
        setFileFormatErrorMessage('');
        setSizeErrorMessage('');
        try {
            await fileFormatChange(file);
            await fileSizeCheck(file);
            setFile && setFile(file);
        } catch (error) {
            setFile && setFile(null);
            console.log(error);
        }

    }
    return (
        <MatrielGrid
            container
            spacing={1}
            marginTop={3}
            alignItems={'center'}
        >
            <MatrielGrid item md={5} sm={12} xs={12}>
                <Button
                    sx={{
                        width: '100%',
                        padding: '12px 15px',
                        borderRadius: ' 25px'
                    }}
                    component="label"
                    variant="outlined"
                    disabled={disabled}
                    startIcon={<CloudUploadIcon />}>
                    <input
                        accept={acceptFileType}
                        style={{ display: 'none' }}
                        id="raised-button-file"
                        type="file"
                        onChange={(e) => {
                            fileOnchange(e.target.files ? e.target.files[0] : null)
                            setTimeout(() => {
                                e.target.value = ''
                            });
                        }}
                    />
                    {btnName}
                </Button>
            </MatrielGrid>
            <MatrielGrid item md={7} sm={12} xs={12}>
                {file ? <Typography component={'div'} sx={{
                    display: 'flex',
                    alignItems: 'baseline',
                    justifyContent: 'space-evenly'
                }}>
                    <Typography component={'span'} sx={{ wordBreak: 'break-all', width: '80%' }}>
                        {file?.name}
                    </Typography>
                    <Close style={{ cursor: 'pointer' }} onClick={() => setFile && setFile(null)} />
                </Typography> : ''}
            </MatrielGrid>
            <MatrielGrid item md={12} sm={12} xs={12} >
                <MatrielGrid
                    container
                    spacing={1}
                    alignItems={'center'}
                    sx={{
                        color: 'rgb(157, 157, 157)',
                        fontWeight: '500'
                    }}
                >
                    <MatrielGrid item md={5} sm={12} xs={12} >
                        {formatErrorMessage ?
                            <Typography component={'span'} sx={{ fontSize: '13px' }}>
                                Valid file formats: {formatErrorMessage}
                            </Typography>
                            : ''
                        }
                    </MatrielGrid>
                    <MatrielGrid item md={4} sm={12} xs={12} >
                        {maxFileSize ?
                            <Typography component={'span'} sx={{ fontSize: '13px' }}>
                                File size : {` < ${Math.round(maxFileSize * 1024 / (1024 ** 2))}`} MB
                            </Typography>
                            : ''
                        }
                    </MatrielGrid>
                </MatrielGrid>
            </MatrielGrid>
            {fileFormatErrorMessage ?
                <MatrielGrid item md={12} sm={12} xs={12}
                    sx={{ color: '#d32f2f', fontWeight: 400, fontSize: '0.75rem' }}>
                    {fileFormatErrorMessage}
                </MatrielGrid>
                : ''
            }
            {sizeErrorMessage ?
                <MatrielGrid item md={12} sm={12} xs={12}
                    sx={{ color: '#d32f2f', fontWeight: 400, fontSize: '0.75rem' }}>
                    {sizeErrorMessage}
                </MatrielGrid>
                : ''
            }
        </MatrielGrid>
    )
}