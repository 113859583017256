import React from "react";
import { Outlet, Route } from "react-router-dom";
import { RequireAuth } from "./";
import AdminHome from "pages/Admin/Home/Home";
import UniversitiesHome from "pages/Universities/Home/Home";
import AthleteHome from "pages/Athlete/AthleteHome";
import PersonalInfo from "pages/Fan/PersonalInfo";
import ContactSupport from "pages/Fan/ContactSupport";
import Settings from "pages/Common/Settings";
import useUserType from "hooks/useUserType";
import Alert from "components/Alerts";
import PersonalInfoChange from "pages/Fan/PersonalInfoChange";
import Aboutmnp from "pages/Fan/AboutMyNilPay";
import PrivacyPolicy from "components/PrivacyPolicy";
import FanHomePage from "pages/Fan/FanHomePage";
import CheckoutError from "pages/Fan/CheckoutError";
import BankAccount from 'pages/Athlete/BankAccount';

function HomeWrapper() {
  const userType = useUserType();

  return (
    <>
      {userType === "fan" && <FanHomePage />}
      {userType === "athlete" && <AthleteHome />}
      {userType === "admin" && <AdminHome />}
      {userType === "school_faculty" && <UniversitiesHome />}

    </>
  );
}

export const CommonRoutes = () => (
  <Route element={<RequireAuth userTypes={["fan", "athlete", "admin", "school_faculty"]} />}>
    <Route path="/home" element={<HomeWrapper />} />
    <Route path="/home/:errorCode" element={<HomeWrapper />} />
    <Route path="/personalinformation" element={<React.Fragment><Outlet /></React.Fragment>} >
      <Route path="/personalinformation" element={<PersonalInfo />} />
      <Route path="/personalinformation/requestchanges" element={<PersonalInfoChange />} />
    </Route>
    <Route path="/contactsupport" element={<ContactSupport />} />
    <Route path="/bankAccountUpdate" element={<BankAccount />} />
    <Route path="/aboutmynilpay" element={<Aboutmnp />} />
    <Route path="/settings" element={<Settings />} />
    <Route path="/alerts" element={<Alert />} />
    <Route path="/privacy" element={<PrivacyPolicy />} />
    <Route path="/home/error" element={<CheckoutError />} />


  </Route>
);
