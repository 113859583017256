import React from "react";
import { CircularProgress, Grid } from "@mui/material";
import { FAVORITES_TEAMS } from "queries";
import { FavoriteTeamsResponse } from "types";
import { TeamCard } from "components/TeamCard/TeamCard";
import { useNavigate } from "react-router-dom";
import { useNotifications } from "providers/Notification";
import { useQuery } from "@apollo/client";
import Box from "@mui/material/Box";
import InfiniteScroll from "components/InfiniteScroll";
import List from "@mui/material/List";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import MNPLogo from "assets/images/crown_logo.svg";
import Typography from "@mui/material/Typography";
import { insertApiResponseDataDog, insertApiResponseDataDogError } from "utils";

export function FavoritesTeams() {
  const notify = useNotifications();
  const navigate = useNavigate();

  const { data, loading, fetchMore } = useQuery<FavoriteTeamsResponse>(
    FAVORITES_TEAMS,
    {
      fetchPolicy: "no-cache",
      onError: (error) => {
        insertApiResponseDataDog("favoriteTeamsApi(error)", error, "error")
        insertApiResponseDataDogError(error, "favoriteTeamsApi(error)");
        notify.error("Something went wrong. Try again later")
      },
      onCompleted: (data) => {
        insertApiResponseDataDog("favoriteTeamsApi(success)", data, "info")
      }
    }
  );

  const handleClick = (schoolId: string, sportId: string) =>
    navigate(`/favorites/school/${schoolId}/sport/${sportId}`);

  if (loading) return <CircularProgress />;

  return (
    <Box>
      {data && (
        <>
          <Typography fontWeight="600" textAlign={"left"} marginTop={2}>
            Favorite Teams ({data.favoriteTeams.itemTotal})
          </Typography>
          {data.favoriteTeams.itemTotal == 0 ?
            <Grid container justifyContent="center">
              <Grid item >
                <Box marginTop={6} >
                  <img src={MNPLogo} alt="myNILpay logo" />
                </Box>
                <Typography component={'h3'} fontWeight="900" color='secondary.dark' textAlign={"center"} marginTop={1}>
                  Setup Favorites.
                </Typography>
                <Typography component={'h3'} fontWeight="400" color='secondary.dark' textAlign={"center"} marginTop={1}>
                  You haven’t favorited any teams yet.
                </Typography>
              </Grid>
            </Grid> : ''}
          <Box textAlign="left" marginTop={2}>
            <InfiniteScroll
              fetchMore={fetchMore}
              page={data.favoriteTeams.pageNumber + 1}
            >
              <List>
                {data.favoriteTeams.items.map((item) => (
                  <TeamCard
                    key={item.id}
                    schoolId={item.schoolId}
                    sportId={item.sportId}
                    schoolName={item.school.name}
                    sportName={item.sport.name}
                    handleClick={handleClick}
                  ></TeamCard>
                ))}
              </List>
            </InfiniteScroll>
            {data.favoriteTeams.pageNumber < data.favoriteTeams.pageTotal && (
              <Stack spacing={1} marginBottom={4}>
                <Skeleton variant="rectangular" height={50} />
                <Skeleton variant="rectangular" height={50} />
                <Skeleton variant="rectangular" height={50} />
              </Stack>
            )}
          </Box>
        </>
      )}
    </Box>
  );
}
