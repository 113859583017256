import React from "react";
import { ReactComponent as HeartIcon } from "../../assets/icons/red_heart.svg";
import Box from "@mui/material/Box";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import SportIcon from "../../components/SportIcon";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

interface Props {
  schoolName: string;
  sportName: string;
  schoolId: string;
  sportId: string;
  handleClick: (schoolId: string, sportId: string) => void;
}

export function TeamCard({
  schoolName,
  sportName,
  schoolId,
  sportId,
  handleClick,
}: Props) {
  return (
    <ListItem
      sx={{
        paddingRight: 0,
        paddingLeft: 0,
      }}
    >
      <ListItemButton
        onClick={() => handleClick(schoolId, sportId)}
        sx={{
          backgroundColor: "background.paper",
          padding: "11px 24px",
          "&:hover": {
            backgroundColor: "secondary.light",
          },
        }}
      >
        <Stack
          direction="row"
          spacing={2}
          justifyContent="space-between"
          sx={{
            width: "100%",
          }}
        >
          <Stack direction="row" alignItems={"center"}>
            <SportIcon sportName={sportName} />
            <Box marginLeft={2}>
              <Typography fontSize={14} fontWeight="600" color="primary.main">
                {schoolName}
              </Typography>
              <Typography fontSize={12} fontWeight="300">
                {sportName}
              </Typography>
            </Box>
          </Stack>
        </Stack>
        <Box
          textAlign="right"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <HeartIcon />
        </Box>
      </ListItemButton>
    </ListItem>
  );
}
