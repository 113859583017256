import React from "react";
import { ReactComponent as Back } from "assets/icons/back.svg";
import { Link } from "react-router-dom";
import background from "assets/images/light_wave_background.png";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import MNPLogo from "assets/images/crown_logo.svg";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

export const NotFound = () => (
  <Stack direction="column" alignItems="flex-start">
    <Box
      sx={{
        position: "absolute",
        top: "0px",
        bottom: "0px",
        left: "0px",
        right: "0px",
        backgroundImage: `url(${background})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        zIndex: "-1",
      }}
    />
    <Button component={Link} to="/home" startIcon={<Back />}>
      Go Back
    </Button>
    <Box width={"100%"} marginTop={6}>
      <Box margin={2}>
        <img src={MNPLogo} alt="myNILpay logo" />
      </Box>
      <Typography
        variant="h1"
        fontSize={30}
        fontWeight="600"
        textAlign={"center"}
        color="secondary.dark"
      >
        Page not found!
      </Typography>
    </Box>
  </Stack>
);
