import { useQuery } from "@apollo/client";
import { ME, MeResponse } from "../queries";

function useUserType() {
  const { loading, data, error } = useQuery<MeResponse>(ME);
  if (data) return data.me.userType;
  if (loading || error) return null;
  return null;
}

export default useUserType;
