/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from "react";
import { apolloClient } from "providers/Apollo";
import { Auth } from "aws-amplify";
import { useNavigate } from "react-router-dom";
import { useNotifications } from "providers/Notification";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import DeleteAccountModal from "components/DeleteAccountModal";
import Stack from "@mui/material/Stack";
import Header from "components/Header";
import { insertApiResponseDataDog, insertApiResponseDataDogError } from "utils";
import useUserType from "hooks/useUserType";

const Settings = () => {
  const [openModal, setOpenModal] = useState(false);
  const navigate = useNavigate();
  const notify = useNotifications();
  const userType = useUserType();

  const settingsOptions = [
    { name: "Personal Information", route: "/personalinformation" },
    (userType === "athlete" ? { name: "Bank Account Information", route: "/bankAccountUpdate" } : {}),
    { name: "About MyNiLpay", route: "/aboutmynilpay" },
    { name: "Contact Support", route: "/contactsupport" },
    { name: "Legal/Privacy", route: "/privacy" },
    { name: "Logout", route: "#" },
    (userType === "school_faculty" ? {} : { name: "Delete Account", route: "#" }),
  ]

  async function logout() {
    try {
      const res = await Auth.signOut();
      insertApiResponseDataDog("authSignOut(success)", res, "info")

      window.localStorage.removeItem("token");
      window.localStorage.removeItem("LoginUserObject");
      window.localStorage.removeItem("loginUserId");
      window.localStorage.removeItem("loginUserName");
      window.localStorage.removeItem("loginUserEmail");
      apolloClient.cache.reset();
      return navigate("/login", { replace: true });
    } catch (error) {
      insertApiResponseDataDogError(error, "authSignOut(error)");
      insertApiResponseDataDog("authSignOut(error)", error, "error")
      notify.error("error signing out");
    }
  }

  const handleClick = (page: { name: string, route: string }) => {
    switch (page.name) {
      case "Logout":
        logout();
        break;
      case "Delete Account":
        setOpenModal(true);
        break;
      default:
        navigate(page?.route)
        break;
    }
  };

  return (
    <Box>
      <DeleteAccountModal open={openModal} setOpen={setOpenModal} />
      <Header text="Account Settings" />
      <Stack sx={{ marginLeft: 2 }} spacing={1} alignItems="flex-start">
        {settingsOptions.map((ele: { name: string, route: string } | any) => ele.name && (
          <Button key={ele.name} onClick={() => handleClick(ele)}>
            {ele?.name}
          </Button>
        ))}
      </Stack>
    </Box>
  );
};

export default Settings;
