import { useQuery } from "@apollo/client";
import { SCHOOL } from "../queries";
import { SchoolResponse } from "../types";

export function useSchool(id: string) {
  const { loading, data, error } = useQuery<SchoolResponse>(SCHOOL, {
    variables: { id },
  });
  if (data) return data.school;
  if (loading || error) return null;
  return null;
}
