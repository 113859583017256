import React, { useEffect, useState } from "react";

import { ApolloError, useMutation, useQuery } from "@apollo/client";
import { Cancel_Transaction_By_Fan, FAN_TRANSACTIONS } from "queries";
import { format } from "date-fns";
import { ReactComponent as ChevronUp } from "assets/icons/chevron_up.svg";
import { ListTransactionsResponse, Transaction } from "types";
import { useNotifications } from "providers/Notification";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Button, { ButtonProps } from "@mui/material/Button";
import FilterTopBar from "components/FilterTopBar";
import Grid from "@mui/material/Grid";
import Header from "components/Header";
import List from "@mui/material/List";
import Pagination from "@mui/material/Pagination";
import ProfileHeader from "./Header";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import styled from "@emotion/styled";
import TransactionIcon from "components/TransactionIcon";
import Typography from "@mui/material/Typography";
import SideNavDetails from "./SideNavDetails";
import { formatCurrency, insertApiResponseDataDog, insertApiResponseDataDogError } from "utils";
import ListItem from "@mui/material/ListItem";
import CircularProgress from "@mui/material/CircularProgress";
import Dialog from "@mui/material/Dialog";
import IconButton from "@mui/material/IconButton";
import DialogActions from "@mui/material/DialogActions";
import CloseIcon from '@mui/icons-material/Close';
import useMediaQuery from "@mui/material/useMediaQuery";

type Order = "asc" | "desc";

enum SortOptions {
  date = "date",
  name = "name",
  amount = "amount",
}

const filterOptions = ["all", "successful", "expired", "refunded", "pending"];

interface ArrowProp {
  active: boolean;
  direction: Order;
}

const Arrow = ({ active, direction }: ArrowProp) => {
  return (
    <Box
      sx={{
        opacity: active ? 1 : 0,
        transition: "transform 300ms",
        transform: direction === "asc" ? "rotate(0deg)" : "rotate(180deg)",
      }}
    >
      <ChevronUp width={10} height={10} />
    </Box>
  );
};

const HeaderButton = styled(Button)<ButtonProps>(() => ({
  color: "secondary.dark",
  fontSize: 10,
  fontWeight: 600,
  padding: "2px 15px",
}));

const LoadingBoxes = (props: { count: number }) => {
  const array = [];

  for (let step = 0; step < props.count; step++) {
    array.push(
      <Skeleton
        key={step}
        sx={{ bgcolor: "#FFF", borderRadius: "8px" }}
        variant="rectangular"
        height={56}
      />
    );
  }

  return <>{array}</>;
};

const Profile = () => {
  const navigate = useNavigate();

  const isMobile = useMediaQuery("(max-width:600px)");
  const notify = useNotifications();
  const [tableData, setTableData] = useState<Transaction[]>([]);
  const [transactionId, setTransactionId] = useState<string | null>(null);
  const [sortBy, setSortBy] = useState<SortOptions>(SortOptions.date);
  const [order, setOrder] = useState<Order>("desc");
  const [filterBy, setFilterBy] = useState(filterOptions[0]);
  const [cancelLoader, setCancelLoader] = useState({ loader: false, id: "" })
  const [open, setOpen] = React.useState(false);
  const [tempId, setTempId] = React.useState('');
  const [onCancel_Transaction_By_Fan] = useMutation(Cancel_Transaction_By_Fan);

  const { data, loading, refetch } = useQuery<ListTransactionsResponse>(
    FAN_TRANSACTIONS,
    {
      fetchPolicy: "no-cache",
      onError: (error: ApolloError) => {
        notify.error(error.message);
        insertApiResponseDataDogError(error, "listFanTransactionsApi(error)");
        insertApiResponseDataDog("listFanTransactionsApi(error)", error, "error")
      },
      onCompleted: (response) => {
        setTableData(response.listFanTransactions.items);
        insertApiResponseDataDog("listFanTransactionsApi(success)", data, "info")
      },
    }
  );

  const pageTotal = data?.listFanTransactions.pageTotal || 0;
  const pageNumber = data?.listFanTransactions.pageNumber;

  useEffect(() => {
    const status = filterBy === "all" ? "" : filterBy;
    refetch({ status, page: 1 });
  }, [filterBy]);

  useEffect(() => {
    const status = filterBy === "all" ? "" : filterBy;
    const page = pageNumber || 1;
    const sort = {
      column: sortBy,
      order,
    };
    refetch({ status, page, sort });
  }, [sortBy, order]);

  const handleGotoHome = () => {
    navigate('/home');
    localStorage.setItem('setClearData', "clearData")

  }
  const updateSort = (value: SortOptions) => {
    if (sortBy === value) {
      const isAsc = order === "asc";
      setOrder(isAsc ? "desc" : "asc");
    } else {
      setOrder("asc");
      setSortBy(value);
    }
  };

  const handlePagination = (
    event: React.ChangeEvent<unknown>,
    page: number
  ) => {
    refetch({ page });
  };
  async function refundHandle(id: string) {
    try {
      setCancelLoader({ loader: true, id: id })
      const response = await onCancel_Transaction_By_Fan({
        variables: {
          transactionId: id
        },
      })
      if (response) {
        if (response.data.cancelFanTransaction) {
          insertApiResponseDataDog("cancelFanTransaction(success)", JSON.stringify(response), "info")
          refetch();
          notify.success('Transaction cancelled Successfully');
        } else {
          notify.error("Something went wrong. Try again later");
          insertApiResponseDataDog("cancelFanTransaction(error)", JSON.stringify(response), "error")
        }
        setCancelLoader({ loader: false, id: id })
      } else {
        setCancelLoader({ loader: false, id: id })
      }
    } catch (error) {
      setCancelLoader({ loader: false, id: "" })
      notify.error(error);
    }
  };
  const handleClickOpen = (id: any) => {
    setTempId(id)
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const on = () => {
    refundHandle(tempId)
    setOpen(false)
  }

  return (
    <Box>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <div style={{ margin: '20px', color: '#592EE5', marginTop: '40px' }}>You are about to cancel a transaction.</div>
        <div style={{ margin: '20px', fontSize: '13px', textAlign: 'center', color: '#592EE5' }}>Do you want to procced?</div>
        <DialogActions style={{ justifyContent: 'center', lineHeight: '0.75' }} >
          <Button className="btn-action" onClick={() => on()} autoFocus>
            Yes
          </Button>
          <Button className="btn-action" onClick={handleClose}
            variant={"contained"}>No</Button>
        </DialogActions>
      </Dialog>

      <SideNavDetails
        transactionId={transactionId}
        onCancel={() => refetch()}
        onClose={() => setTransactionId(null)}
      />
      <Box
        sx={{
          position: "absolute",
          top: "0px",
          bottom: "0px",
          left: "0px",
          right: "0px",
          backgroundColor: "#F8F7FD",
        }}
      ></Box>

      <Box
        sx={{
          position: "absolute",
          top: "0px",
          left: "0px",
          right: "0px",
          display: "flex",
          justifyContent: "center",
          backgroundColor: "#FFF",
        }}
        paddingTop={{ xs: "30px", md: "80px" }}
      >
        <Grid
          container
          justifyContent={"center"}
          sx={{
            padding: "20px 20px 0px 20px",
          }}
        >
          <Grid item xs={12} md={6} maxWidth="800px">
            <Header text="Transactions" />

            <ProfileHeader />
          </Grid>
        </Grid>
      </Box>

      <Box marginTop="270px" sx={{
        ...(isMobile ? { position: 'absolute', width: '100%', } : {})
      }}>
        <FilterTopBar options={filterOptions} onSelect={setFilterBy} />
        <List>
          {loading && !tableData.length && (
            <Stack spacing={2}>
              <LoadingBoxes count={6} />
            </Stack>
          )}
          {Boolean(tableData.length) && (
            <ListItem className="earnings"
              sx={{
                borderRadius: "8px",
                padding: "16px 24px",
                marginBottom: 2,
              }}
            >
              <Stack
                direction={"row"}
                justifyContent={"space-between"}
                sx={{
                  width: isMobile ? 'auto' : "85%"
                }}
              >
                <Stack direction={"row"} alignItems={"center"}>
                  <Box className="earnings__date">
                    <HeaderButton
                      onClick={() => updateSort(SortOptions.date)}
                      variant="text"
                      endIcon={
                        <Arrow
                          direction={order}
                          active={sortBy === SortOptions.date}
                        />
                      }
                    >
                      Date
                    </HeaderButton>
                  </Box>
                  <Box className="earnings__name">
                    <HeaderButton
                      onClick={() => updateSort(SortOptions.name)}
                      variant="text"
                      endIcon={
                        <Arrow
                          direction={order}
                          active={sortBy === SortOptions.name}
                        />
                      }
                    >
                      Name
                    </HeaderButton>
                  </Box>
                </Stack>
                <Box
                  className="earnings__amount"
                >
                  <HeaderButton
                    onClick={() => updateSort(SortOptions.amount)}
                    variant="text"
                    endIcon={
                      <Arrow direction={order} active={sortBy === SortOptions.amount} />
                    }
                  >
                    Amount
                  </HeaderButton>
                </Box>
              </Stack>
            </ListItem>
          )}

          {Boolean(tableData.length) &&
            tableData.map((t) => (
              <React.Fragment key={t.id}>
                <ListItem className="social-main"
                  onClick={() => setTransactionId(t.id)}
                >
                  <Stack
                    direction={"row"}
                    justifyContent={"space-between"}
                    width={"100%"}
                    className="earnings"
                    sx={{
                      cursor: 'pointer',
                      backgroundColor: "#FFF",
                      borderRadius: "8px",
                      padding: "22px 24px",
                    }}
                  >
                    <Stack direction={"row"} alignItems={"center"}>
                      <TransactionIcon status={t.status} />
                      <Typography className="earnings__date">
                        {format(new Date(t.updatedAt), "MMM d, yyyy")}
                      </Typography>

                      <Typography className="earnings__name"
                        color="primary.main"
                      >
                        {t.athlete
                          ? `${t.athlete.firstName} ${t.athlete.lastName}`
                          : "Multiple Athletes"}
                      </Typography>
                    </Stack>
                    <Box>
                      <Typography className="earnings__amount"
                        color="secondary.dark"
                        sx={{ padding: "0 12px" }}
                      >
                        {formatCurrency(t.amount)}
                      </Typography>
                    </Box>
                  </Stack>
                  <Stack
                    className="earnings-icons" sx={{
                      margin: '2px',
                      width: "15%",
                      position: 'relative',
                    }}>
                    <Box sx={{ position: 'relative' }} onClick={(e) => {
                      e.stopPropagation();
                      t.cancellationStatus ? handleClickOpen(t.id) : ''
                    }}>
                      <Button className="earnings-icons__btn"
                        variant={"contained"}
                        disabled={(cancelLoader.id == t.id && cancelLoader.loader) || !t.cancellationStatus}
                        sx={{ float: 'right', fontSize: '12px' }}
                      >
                        {!(cancelLoader.id == t.id && cancelLoader.loader) && <span>Cancel</span>}{(cancelLoader.id == t.id && cancelLoader.loader) && <CircularProgress size={18} sx={{ marginLeft: 0.2 }} thickness={5} color={"secondary"} />}
                      </Button>
                    </Box>
                  </Stack>
                </ListItem>
              </React.Fragment>
            ))}

          {!loading && !tableData.length && (<Stack sx={{ backgroundColor: "#FFF", paddingTop: 5, paddingBottom: 5 }}>
            <Stack
              direction={"row"}
              justifyContent="center"

            >
              <Typography
                fontSize={25}
                fontWeight={600}
                sx={{ color: '#592EE5' }}
                align="center"
                textAlign={'center'}

              >
                Get Started
              </Typography>
            </Stack>
            <Stack
              direction={"row"}
              justifyContent="center"
            >
              <Typography
                fontSize={12}
                fontWeight={500}
                color={"text.secondary"}
                align="center"
                textAlign={'center'}

              >
                you haven&#39;t made any
              </Typography>
            </Stack>
            <Stack
              direction={"row"}
              justifyContent="center"
            >
              <Typography
                fontSize={12}
                fontWeight={500}
                color={"text.secondary"}
                align="center"
                textAlign={'center'}

              >
                direct payments yet              </Typography>
            </Stack>
            <Stack
              direction={"row"}
              justifyContent="center"
            >
              <Button variant="contained" sx={{ marginTop: 2, width: 200, height: 40 }} onClick={handleGotoHome}>Search Athlete</Button>
            </Stack>
          </Stack>
          )}
        </List>
        {pageTotal > 1 && (
          <Pagination
            sx={{ display: "flex", justifyContent: "center" }}
            size="small"
            count={pageTotal}
            page={pageNumber}
            onChange={handlePagination}
          />
        )}
      </Box>
    </Box>
  );
};

export default Profile;
