/* eslint-disable @typescript-eslint/no-explicit-any */
import { useQuery } from "@apollo/client";
import { ADMIN_ImageDelete } from "../queries";
import { ImageDeleteArgs } from "../types";
import { useNotifications } from "../providers/Notification";

function useDeleteImage(id: any) {
    const notify = useNotifications();
    const { data } = useQuery<ImageDeleteArgs>(ADMIN_ImageDelete, {
        variables: { id: id },
        onCompleted: () => { notify.success("File deleted Successfully") },
    });
    if (data) return data
}

export default useDeleteImage;
