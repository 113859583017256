import React, { ChangeEvent, useEffect, useState } from "react";

import { ReactComponent as Search } from "assets/icons/search.svg";
import { ReactComponent as FilterIcon } from "assets/icons/filter.svg";

import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import { SEARCH_DELAY } from "constant";

interface SearchInputProps {
  value: string;
  onSearch: (text: string) => void;
  placeholder?: string;
  onClear?: () => void;
  onFilter?: () => void;
}

function SearchInput({
  value,
  placeholder,
  onSearch,
  onClear,
  onFilter,
}: SearchInputProps) {
  const [text, setText] = useState(value || "");
  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    setText(e.target.value);
  };

  const handleClear = () => {
    setText("");
    if (onClear) {
      onClear();
    }
  };

  useEffect(() => {
    function setEmptyText() {
      const res = localStorage.getItem("setClearData");
      if (res === "clearData") {
        setText("");
      }
    }
    setEmptyText()
  }, [])

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      onSearch(text);
    }, SEARCH_DELAY);

    return () => clearTimeout(delayDebounceFn);
  }, [text]);


  return (
    <TextField
      onChange={handleSearch}
      autoFocus
      placeholder={placeholder || "Search"}
      fullWidth
      margin="normal"
      value={text}
      InputProps={{
        autoComplete: "off",
        startAdornment: (
          <InputAdornment position="start">
            <Search />
          </InputAdornment>
        ),
        endAdornment: (
          <InputAdornment position="end">
            {onClear && (
              <Button aria-label="clear" onClick={handleClear}>
                Clear
              </Button>
            )}
            {onFilter && (
              <IconButton aria-label="open filters" onClick={onFilter}>
                <FilterIcon />
              </IconButton>
            )}
          </InputAdornment>
        ),
      }}
    />
  );
}

export default SearchInput;
