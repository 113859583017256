import React, { useState } from "react";
import Box from "@mui/material/Box";
import Header from "components/Header";
import FilterTopBar from "components/FilterTopBar";
import { FavoritesAthletes } from "./FavoritesAthletes";
import { FavoritesTeams } from "./FavoritesTeams";

function Favorites() {
  const tabs = ["athletes", "teams"];
  const [currentTab, setCurrentTab] = useState(tabs[0]);

  return (
    <Box>
      <Header text="My Favorites" />
      <FilterTopBar
        selected={currentTab}
        options={tabs}
        onSelect={(tab: string) => setCurrentTab(tab)}
      />
      {currentTab === "athletes" && <FavoritesAthletes />}
      {currentTab === "teams" && <FavoritesTeams />}
    </Box>
  );
}

export default Favorites;
