
import React from 'react'
import { Box, Button, Typography } from '@mui/material'
import { useNavigate } from "react-router-dom";
import "./findAthlete.css";
function findAthlete() {
  const navigate = useNavigate();
  return (
    <Box className="boxStyle">
      <Box>
        <Typography className="noRecieptTypograpy" color="primary.main" gutterBottom>
          No Receipts
        </Typography>
        <Typography className="contentTypograpy" color="primary.main">
          Send your first direct-payment
        </Typography>
      </Box>
      <Button variant="contained" fullWidth onClick={() => navigate('/home')}>Search Athlete</Button>
    </Box>
  )
}

export default findAthlete