import { gql } from "@apollo/client";

export const FAN_TRANSACTIONS = gql`
  query listFanTransactions(
    $status: String = ""
    $limit: Int = 6
    $page: Int = 1
    $sort: TransactionSort = { column: "date", order: "desc" }
  ) {
    listFanTransactions(
      status: $status
      limit: $limit
      page: $page
      sort: $sort
    ) {
      itemTotal
      pageTotal
      pageNumber
      limit
      items {
        id
        receiptUrl
        amount
        athlete {
          id
          firstName
          lastName
        }
        status
        cancellationStatus
        createdAt
        updatedAt
      }
    }
  }
`;

export const TRANSACTION_SUMMARY = gql`
  query {
    transactionSummary {
      athletesPaid
      memorabiliaCollected
      completedTransactions
      totalPaid
    }
  }
`;

export const TRANSACTION = gql`
  query transaction($transactionId: String!) {
    transaction(transactionId: $transactionId) {
      id
      receiptUrl
      amount
      status
      createdAt
      athlete {
        id
        firstName
        lastName
      }
      athletes {
        id
        firstName
        lastName
      }
    }
  }
`;

export const ATHLETE_TRANSACTIONS = gql`
  query listAthleteTransactions(
    $limit: Int = 5
    $page: Int = 1
    $sort: TransactionSort = { column: "date", order: "desc" }
  ) {
    listAthleteTransactions(limit: $limit, page: $page, sort: $sort) {
      itemTotal
      pageTotal
      pageNumber
      limit
      items {
        id
        isRefundTimeExpired
        athleteAmount
        updatedAt
        fanName
        status
      }
    }
  }
`;

export const ATHLETE_EARNINGS_SUMMARY = gql`
  query athleteTransactionSummary {
    athleteTransactionSummary {
      totalEarnings
      totalPending
      totalSuccessfulTransactionCount
      athletePayoutStatus
    }
  }
`;
export const Cancel_And_Refund_Success_Transaction_By_Athlete = gql`
  mutation cancelAndRefundSuccessTransactionByAthleteCko($transactionId: String!){
    cancelAndRefundSuccessTransactionByAthleteCko(transactionId: $transactionId){
      status
      data
    }
  }
`

export const Cancel_Transaction_By_Fan = gql`
mutation cancelFanTransaction($transactionId: String!){
  cancelFanTransaction(transactionId: $transactionId)
}
`

export const ATHLETE_ALL_ACTIVITY = gql`
query getNotifications($deviceType: String!, $dismissed: String, $sortByDesc: Boolean, $athleteEarningActivities: Boolean!) {
   getNotifications(deviceType: $deviceType, dismissed: $dismissed, sortByDesc: $sortByDesc, athleteEarningActivities: $athleteEarningActivities) {
   data,
   createdAt,
   }
  }
`;
export const TRANSACTION_INVOICE = gql`
mutation retrieveReceiptCko($transactionId : String!) {
  retrieveReceiptCko(transactionId: $transactionId) {
      amountWithoutTax
      cardLastFourDigits
      tax
      amountWithTax
     transactionApprovalDate
     cardTransactionType
     athleteCount
     userInfo {
      firstName
      lastName
     }
    }
  }
`;