import React, { useState } from "react";
import TextField, { TextFieldProps } from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import { ReactComponent as Eye } from "../../assets/icons/eye.svg";
import { ReactComponent as EyeOff } from "../../assets/icons/eye_off.svg";
import { ControllerRenderProps } from "react-hook-form";

const PasswordField = (props: ControllerRenderProps | TextFieldProps) => {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <TextField
      margin="dense"
      fullWidth
      autoComplete="none"
      label="Password"
      placeholder="Enter password"
      type={showPassword ? "text" : "password"}
      InputLabelProps={{
        shrink: true,
      }}
      InputProps={{
        endAdornment: (
          <IconButton
            aria-label="toggle password visibility"
            onClick={() => setShowPassword(!showPassword)}
          >
            {showPassword && <EyeOff />}
            {!showPassword && <Eye />}
          </IconButton>
        ),
      }}
      {...props}

    />
  );
};

export default PasswordField;
