import React from "react";

import { MultiPaymentReview } from "pages/Fan/Home/MultiPaymentReview";
import { NotFound } from "pages/Common/NotFound/NotFound";
import { RequireAuth } from ".";
import { Route } from "react-router-dom";
import { School } from "pages/Fan/Home/School";
import { Sponsorship } from "pages/Fan/Sponsorship/Sponsorship";
import { Team } from "pages/Fan/Home/Team";
import AthleteProfile from "pages/Fan/AthleteProfile/AthleteProfile";
import Favorites from "pages/Fan/Favorites/Favorites";
import Pay from "pages/Fan/Pay";
import PaymentReview from "pages/Fan/PaymentReview";
import Profile from "pages/Fan/Profile";
import Search from "pages/Fan/Pay/Search";
import SponsorshipSuccess from "pages/Fan/Sponsorship/Success";
import Vault from "pages/Fan/Vault";
// import Coupon from "pages/Fan/Coupon/Index";
import MissingAthlete from "pages/Fan/MissingAthlete";
import MissingAthleteSuccess from "pages/Fan/MissingAthlete/missingAthleteSuccess";
import Home from "pages/Fan/Home/Home";
import CheckoutDetails from "pages/Fan/CheckoutDetails";
import CardPayment from "pages/Fan/CardPayment";
import BillingContactInfo from 'pages/Fan/BillingContactInfo';
export const FanRoutes = () => (
  <Route element={<RequireAuth userTypes={["fan"]} />}>
    <Route path="/fanhome" element={<Home />} />
    <Route path="/fanhome/athlete/:id" element={<AthleteProfile />} />
    <Route path="/fanhome/athlete/:id/review" element={<PaymentReview />} />
    <Route path="/sponsorship/:id" element={<Sponsorship />} />
    <Route path="/sponsorship/:id/success" element={<SponsorshipSuccess />} />
    <Route path="/fanhome/school/:schoolId" element={<School />} />
    <Route path="/fanhome/school/:schoolId/sport/:sportId" element={<Team />} />
    <Route
      path="/fanhome/school/:schoolId/sport/:sportId/multiPayReview"
      element={<MultiPaymentReview />}
    />
    <Route path="/pay" element={<Pay />} />
    <Route path="/billingContact" element={<BillingContactInfo />} />
    <Route path="/pay/search" element={<Search />} />
    <Route path="/pay/search/:id" element={<AthleteProfile />} />
    <Route path="/pay/search/:id/review" element={<PaymentReview />} />
    <Route path="/favorites" element={<Favorites />} />
    <Route path="/favorites/athlete/:id" element={<AthleteProfile />} />
    <Route
      path="/favorites/school/:schoolId/sport/:sportId"
      element={<Team />}
    />
    <Route path="/transactions" element={<Profile />} />
    <Route path="/missingAthlete" element={<MissingAthlete />} />
    <Route path="/missingAthlete/success" element={<MissingAthleteSuccess />} />
    <Route path="/vault" element={<Vault />} />
    <Route path="/CheckoutDetails" element={<CheckoutDetails />} />
    <Route path="/cardPayment" element={<CardPayment />} />
    <Route path="*" element={<NotFound />} />
  </Route>
);
