/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from "react";

import { ApolloError, useMutation, useQuery } from "@apollo/client";
import { format } from "date-fns";
import { ReactComponent as Back } from "assets/icons/back.svg";
import { ReactComponent as Edit } from "assets/icons/edit.svg";
import {
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import AmountInput from "components/AmountInput";
import ModalWrapper from "components/ModalWrapper";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import PlaceholderImage from "assets/images/receipt_placeholder.jpg";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import useUserProfile from "hooks/useUserProfile";
import { useAppState } from "providers/AppState";
import { getFormatedCurrency, insertApiResponseDataDog, insertApiResponseDataDogError } from "utils";
import CircularProgress from "@mui/material/CircularProgress";
import { useNotifications } from "providers/Notification";
import { filedPaymentResponse } from "types/createPaymentToken";
import { createFiledTokens } from "queries/createFiledToken";
import { ME, MeResponse, UPDATE_USER } from "queries";

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import TextField from "@mui/material/TextField";
import "./paymentReview.scss";
import { useForm } from "react-hook-form";

interface PaymentZipCode {
  zipCode: string;
}

function PaymentReview() {
  const notify = useNotifications();
  const navigate = useNavigate();
  const params = useParams();
  const [searchParams] = useSearchParams();
  const [showModal, setShowModal] = useState(false);
  const appState = useAppState();
  const [zip, setZip] = useState("")
  const defaultAmount =
    parseInt(searchParams.get("amount") || "0", 10) || appState.amount;
  const [amount, setAmount] = useState(defaultAmount || 0);
  const athleteId = params.id || "";
  const userProfile = useUserProfile(athleteId);
  const [createPayMentToken, { loading }] = useMutation<filedPaymentResponse>(
    createFiledTokens, {
    onError(error) {
      insertApiResponseDataDog("getPrePaymentDetails(error)", error, "info")
      insertApiResponseDataDogError(error, "getPrePaymentDetails(error)");
    },
    onCompleted(data) {
      insertApiResponseDataDog("getPrePaymentDetails(success)", data, "info")

    }
  }
  );
  const { data, refetch } = useQuery<MeResponse>(ME, {
    onError: (error: ApolloError) => {
      notify.error(error.message);
      insertApiResponseDataDogError(error, "MeApi(error)");
      insertApiResponseDataDog("MeApi(error)", error, "error")
    },
    onCompleted(data) {
      insertApiResponseDataDog("MeApi(success)", data, "info")
    },
  });
  const [updateUserInfo] = useMutation(UPDATE_USER);
  const { register, handleSubmit, formState: { errors, isSubmitting } } = useForm<PaymentZipCode>({
    defaultValues: {
      zipCode: "",
    },
    mode: "all",
  });
  const { ref, ...inputProps } = register("zipCode", {
    required: "Zip Code is required",
    minLength: { value: 5, message: "Please enter 5 minimum digits number" }
  });

  const handlePayment = async () => {
    if (!data?.me.zipCode || !(data?.me.zipCode)?.length) {
      handleClickOpen()
      return
    }
    localStorage.setItem("athleteId", athleteId)
    try {
      const res = await createPayMentToken({
        variables: { "amount": `${"" + amount + ""}` }
      });
      localStorage.setItem("pulickey", `${res?.data?.getPrePaymentDetailsCko.ckoPublicKey}`);
      navigate('/CheckoutDetails/', { state: { athleteId: athleteId, amount: amount, tax: res?.data?.getPrePaymentDetailsCko.tax } })

    } catch (error) {
      notify.error("Something went wrong");
    }

  };
  const onSubmited = async ({
    zipCode
  }: PaymentZipCode) => {
    handleClose()
    const dataUser = {
      firstName: data?.me.firstName,
      lastName: data?.me.lastName,
      birthday: data?.me.birthday,
      zipCode: zipCode,
      phoneNumber: data?.me.phoneNumber,
    }
    try {
      const result = await updateUserInfo({
        variables: {
          ...dataUser,
        },
      });
      insertApiResponseDataDog("updateUserInfoApi(success)", data, "info")

      if (result) {
        notify.success("Sucessfully Updated")
        refetch();
      } else {
        notify.error("Something went wrong. Try again later");
      }
    } catch (error) {
      insertApiResponseDataDogError(error, "updateUserInfoApi(error)");
      insertApiResponseDataDog("updateUserInfoApi(error)", error, "error")
      notify.error(error);
    }
  };


  const handleEditAmount = (newAmount: number) => {
    setAmount(newAmount);
    setShowModal(false);
  };

  const handleBack = () => {
    navigate(appState.from || "/home");
  };
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleChangeZip = (e: any) => {
    const regex = /^[0-9\b]+$/;
    if (e.target.value === "" || regex.test(e.target.value)) {
      if (e.target.value.length <= 5) {
        setZip(e.target.value);
      }
    }
  }

  return (
    <Box>
      <ModalWrapper open={showModal} setOpen={setShowModal}>
        <AmountInput previousValue={amount} onSubmit={handleEditAmount} />
      </ModalWrapper>
      <Stack textAlign={"left"} maxWidth="600px">
        <Stack direction="column" alignItems="flex-start" marginBottom={2}>
          <Button startIcon={<Back />} onClick={handleBack}>
            Back
          </Button>
        </Stack>
        <Stack>
          <Typography fontSize={12} color={"text.secondary"}>
            Date
          </Typography>
          <Typography fontSize={14} fontWeight={600}>
            {format(new Date(), "MMMM d, yyy")}
          </Typography>
          <Box margin="50px auto" position="relative">
            <figure>
              <img
                height={310}
                width={310}
                src={PlaceholderImage}
                alt="After the athlete accepts the direct payment, the receipt will be created"
              />
              <figcaption
                style={{
                  position: "absolute",
                  width: "310px",
                  top: "35%",
                  padding: "25px",
                }}
              >
                <Typography color="primary.main">
                  Once the athlete accepts your direct-payment, your digital receipt is created
                </Typography>
              </figcaption>
            </figure>
          </Box>
        </Stack>

        <Stack direction={"row"} justifyContent={"space-between"}>
          <Typography fontSize={12} color={"text.secondary"}>
            Paying
          </Typography>
          <Typography fontSize={12} color={"text.secondary"}>
            Direct-Payment Subtotal
          </Typography>
        </Stack>

        <Stack direction={"row"} justifyContent={"space-between"}>
          <Typography fontSize={16} fontWeight={600}>
            {userProfile.data?.userProfile.firstName}{" "}
            {userProfile.data?.userProfile.lastName}
          </Typography>
          <Stack direction={"row"} alignItems="center">
            <Typography fontSize={16} fontWeight={600}>
              {getFormatedCurrency(amount)}
            </Typography>
            <IconButton
              aria-label="edit amount"
              onClick={() => setShowModal(true)}
            >
              <Edit />
            </IconButton>
          </Stack>
        </Stack>
        <Stack marginTop={0.5} marginBottom={0.5} display={'flex'} alignItems={'end'}>
          <Typography fontSize={12} borderRadius={1} sx={{ padding: '2px 6px', backgroundColor: '#EEE4FA' }} fontWeight={300}>
            Fees & Taxes calculated at checkout
          </Typography>
        </Stack>

        <Stack marginTop={2} marginBottom={2}>
          <Typography fontSize={12} color="primary.dark" fontWeight={600}>
            Payment Approval &amp; Expiration
          </Typography>
          <Typography fontSize={12} fontWeight={300}>
            Direct-payment will be sent to the athlete and once  accepted, the transaction will complete. If not accepted within 7 days, the transaction will expire and you will not be charged or receive a receipt.
          </Typography>
        </Stack>

        <Box textAlign={"center"}>
          <Button
            startIcon={loading ? <CircularProgress size={20} /> : null}
            disabled={loading}
            fullWidth
            data-testid="send-payment"
            variant="contained"
            onClick={handlePayment}
          >
            Send Direct Payment
          </Button>
        </Box>
      </Stack>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="paymentReviewDialog"
        sx={{
          height: "480px",
          margin: "auto",
          padding: "5px"
        }}
      >
        <form onSubmit={handleSubmit(onSubmited)}>
          <DialogContent
            className="paymentReviewDialogContent"
            sx={{
              display: "block",
              textAlign: "center",
              width: "415px",
              margin: 0,
              padding: "0 24px",
            }}>
            <DialogTitle id="alert-dialog-title">
              <InfoOutlinedIcon sx={{ color: "primary.dark", fontSize: "3rem" }} />
            </DialogTitle>
            <DialogContentText sx={{
              textAlign: "start", color: "#111", fontSize: "0.9rem", padding: "0 1rem", paddingBottom: "0.8rem", fontWeight: 650
            }} id="alert-dialog-description">
              Zip Code is required for calculating taxes.
            </DialogContentText>
            <Typography sx={{
              textAlign: "start", color: "#111", fontSize: "0.7rem", padding: "0 1rem", paddingBottom: "0.8rem"
            }}>

              Unfortunately, we missed getting that information from you when you signed up.
            </Typography>
            <Typography sx={{
              textAlign: "start", color: "#111", fontSize: "0.7rem", padding: "0 1rem", paddingBottom: "0.8rem"
            }}>
              Please enter your Billing Zip Code to continue:
            </Typography>
            <TextField
              margin="dense"
              value={zip}
              inputProps={{ "data-testid": "zipcode", maxLength: 5, }}
              label="Zip Code"
              placeholder="Enter Zip Code"
              InputLabelProps={{
                shrink: true,
              }}
              disabled={isSubmitting}
              fullWidth
              error={!!errors.zipCode}
              helperText={errors?.zipCode?.message}
              inputRef={ref}
              {...inputProps}
              onChange={(e) => handleChangeZip(e)}
            />
          </DialogContent>
          <DialogActions sx={{ justifyContent: "center" }} className="paymentReviewDialogActions">
            <Button type="submit" disabled={isSubmitting} sx={{ borderRadius: "20px", marginBottom: "10px" }} variant={"contained"} autoFocus>
              Submit
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </Box>
  );
}

export default PaymentReview;
