import React from "react";

import { PublicProfile } from "pages/Fan/AthleteProfile/PublicProfile";
import { RequestSuccess } from "pages/Public/Register/AthleteSignUp/RequestSuccess";
import { Route } from "react-router-dom";
import { SSO } from "pages/Public/Login/SSO";
import AthleteSignUp from "pages/Public/Register/AthleteSignUp";
import FanSignUpForm from "pages/Public/Register/FanSignUp";
import ForgotPassword from "pages/Public/ForgotPassword";
import Login from "pages/Public/Login";
import Request from "pages/Public/Register/AthleteSignUp/Request";
import UserTypeSelection from "pages/Public/Register/UserTypeSelection";
import LandingPage from "pages/Public";
import ConfirmUser from "components/VerifyAccount";
import Invoice from "pages/Fan/Profile/invoiceContent";
import VerifyEmail from "pages/Public/Register/VerifyEmail";
import SchoolForgotPassword from "pages/Public/SchoolForgotPassword";
import ReportLogin from "pages/Public/ReportLogin";
import AthleteEmailVerification from '../pages/Athlete/AthleteEmailVerification'


export const PublicRoutes = () => (
  <React.Fragment>
    <Route path="/" element={<LandingPage />} />
    <Route path="/reporting/login" element={<ReportLogin />} />
    <Route path="/login" element={<Login />} />
    <Route path="/register" element={<UserTypeSelection />} />
    <Route path="/register/fan" element={<FanSignUpForm />} />
    <Route path="/register/athlete" element={<AthleteSignUp />} />
    <Route path="/register/athlete/request" element={<Request />} />
    <Route
      path="/register/athlete/request/success"
      element={<RequestSuccess />}
    />
    <Route path="/forgotPassword" element={<ForgotPassword />} />
    <Route path="/schoolsForgotPassword" element={<SchoolForgotPassword />} />

    <Route path="/sso" element={<SSO />} />
    <Route path="/public/:id" element={<PublicProfile />} />
    <Route path="/confirmUser" element={<ConfirmUser />} />
    <Route path="/invoice/:id" element={<Invoice />} />
    <Route path="/verifyEmail" element={<VerifyEmail />} />
    <Route path="/athleteEmailVerification" element={<AthleteEmailVerification />} />

  </React.Fragment>
);
