/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import Button, { ButtonProps } from "@mui/material/Button";
import ListItemText from "@mui/material/ListItemText";

import './style.css'

interface Props {
  children?: React.ReactNode;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  page: any;
  isSelected?: boolean;
  onClick: () => void;
}

const StyledButton = ({
  page,
  isSelected,
  onClick,
  children,
}: Props & ButtonProps) => {

  const isDelete = page.menuItem === "Delete account";
  const selectedColor = isSelected ? "primary.main" : "#202020";
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const capitalizeFirst = (str: any) => {
    if (str) {
      return str.charAt(0).toUpperCase() + str.slice(1);

    }
  };
  return (
    <Button
      sx={{
        textAlign: "left",
        color: isDelete ? "#E85008" : selectedColor,
        borderRadius: 0,
        borderLeft: isSelected ? "4px solid" : "none",
        borderLeftColor: "primary.main",
        paddingLeft: isSelected ? "10px" : 0,
        fontWeight: isSelected ? 600 : 500,
        transition: "all 0.25s  ease-out",
      }}
      // data-testid={`${page?.menuItem.replace(/\s/g, "")}Button`}
      fullWidth
      onClick={onClick}
      className={page.isMenuActive ? "menuItemActive" : ""}
    >
      <span className="nv-icon"><img src={page?.icon} /></span>
      <ListItemText primary={capitalizeFirst(page?.menuItem)} />
      {children}
    </Button>
  );
};

export default StyledButton;
