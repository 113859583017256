import React from "react";
import { ReactComponent as Edit } from "assets/icons/edit.svg";
import { useAppState } from "providers/AppState";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Header from "components/Header";
import IconButton from "@mui/material/IconButton";
import SearchAthlete from "components/SearchAthlete";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { getFormatedCurrency } from "utils";

const Search = () => {
  const navigate = useNavigate();
  const appState = useAppState();

  const handleBack = () => {
    navigate("/pay");
  };

  return (
    <Box>
      <Header text="Send Direct Payment" showBackButton />
      <Stack
        direction="row"
        justifyContent={"space-between"}
        alignItems={"center"}
        sx={{
          backgroundColor: "primary.light",
          color: "secondary.dark",
          padding: "18px 40px",
          borderRadius: "8px",
        }}
      >
        <Typography fontSize="14" fontWeight="500">
          Amount entered
        </Typography>
        <Stack direction={"row"} alignItems="center">
          <Typography fontSize="14" fontWeight="600">
            {getFormatedCurrency(appState.amount || 0)}
          </Typography>
          <IconButton aria-label="edit amount" onClick={handleBack}>
            <Edit />
          </IconButton>
        </Stack>
      </Stack>

      <Box textAlign={"center"}>
        <SearchAthlete
          searchString={appState.searchString}
          setSearchString={appState.setSearchString}
        />
      </Box>
    </Box>
  );
};

export default Search;
