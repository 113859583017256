import React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import mnpLogo from "assets/images/mnp_logo.svg";

export const LogoHeader = () => (
  <Grid item xs={12}>
    <Box textAlign="left" marginTop={8} marginLeft={{ xs: 6, md: 15 }}>
      <img src={mnpLogo} alt="myNILpay logo" />
    </Box>
  </Grid>
);
