import React, { useEffect } from "react";
import { pages } from "./SideNav";
import { ReactComponent as FavoritesIcon } from "../../assets/icons/nav/favorites.svg";
import { ReactComponent as HomeIcon } from "../../assets/icons/nav/home.svg";
import { ReactComponent as PayIcon } from "../../assets/icons/nav/pay.svg";
import { ReactComponent as SettingsIcon } from "../../assets/icons/nav/settings.svg";
import { ReactComponent as TransactionIcon } from "../../assets/icons/nav/transaction.svg";
import { ReactComponent as VaultIcon } from "../../assets/icons/nav/vault.svg";
import { ReactComponent as PromoCodeIcon } from '../../assets/icons/nav/promocodeIcon.svg';
import { ReactComponent as ReportsIcon } from '../../assets/icons/nav/report_menu_icon.svg';
import NotificationsIcon from '@mui/icons-material/Notifications';

import { useLocation, useNavigate } from "react-router-dom";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import useUser from "../../hooks/useUser";
import Badge from "@mui/material/Badge";
import { notifcationsCountResponse } from "types";

type Props = {
  notificationData: notifcationsCountResponse | undefined
}
const GetIcon = (page: string) => {
  switch (page) {
    case "pay":
      return <PayIcon />;
    case "earnings":
    case "transactions":
      return <TransactionIcon />;
    case "favorites":
      return <FavoritesIcon />;
    case "vault":
      return <VaultIcon />;
    case "promo code":
    case "sponsorship details":
    case "manage school user":
      return <PromoCodeIcon />;
    case "settings":
      return <SettingsIcon />;
    case "school reports":
    case "reports":
      return <ReportsIcon />;
    case "alerts":
      return <NotificationsIcon />;
    default:
      return <HomeIcon />;
  }
}
const BottomNav = (props: Props) => {
  const { notificationData } = props;
  const [value, setValue] = React.useState(0);
  const navigate = useNavigate();
  const location = useLocation();
  const user = useUser();
  const userType = user?.userType;
  const userPages = userType ? [...(userType ? pages[userType] : []), { menuItem: 'alerts' }, { menuItem: "settings" }] : [];
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    navigate(`/${(userPages[newValue])?.menuItem.replace(/\s/g, "")}`, { replace: true });
    setValue(newValue);
  };
  useEffect(() => {
    const currentIndex = userPages.findIndex(el => {
      return el.menuItem.replace(/\s/g, "") === location.pathname.replace(/\//g, '')
    })
    setValue(currentIndex !== -1 ? currentIndex : userPages.length - 1)
  }, [location.pathname, userPages])
  return (
    <Tabs
      value={value}
      aria-label="navigation"
      sx={{
        background: "#FFF",
        ".MuiTabs-flexContainer": {
          justifyContent: "center",
        },
        ".MuiTabs-indicator": {
          top: 0,
          height: "4px",
        },
        ".MuiTab-root": {
          minWidth: 0,
        },
      }}
    >
      {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
      {userPages.map((page: any, index: number) => (
        <Tab
          key={page.menuItem}
          onClick={(e) => handleChange(e, index)}
          icon={
            page.menuItem === 'alerts' ?
              <Badge badgeContent={
                notificationData && notificationData.getNonDismissedNotificationsCount
                  ? notificationData.getNonDismissedNotificationsCount
                  : 0
              } color="error" >
                {GetIcon(String(page.menuItem).toLowerCase())}
              </Badge>
              :
              GetIcon(String(page.menuItem).toLowerCase())
          }
          aria-label={page}
        />
      ))}
    </Tabs>
  );
};

export default BottomNav;
