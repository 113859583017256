/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import './imageupload.css'
import useImageData from "hooks/useImageData";
import useImageDelete from "hooks/useImageDelete"
import { useNavigate } from "react-router-dom";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText } from '@mui/material';
import { useEffect } from 'react';
import { apolloClient } from 'providers/Apollo';



function ImageDataList() {
    const navigate = useNavigate();
    const imageInfo = useImageData();
    const [open, setOpen] = React.useState(false);
    const [imageId, setImageId] = React.useState<any>('');
    const [imagedata, setImageData] = React.useState<any>('');
    const deleteAPi = useImageDelete(imagedata);

    const handleDetail = (id: any) => {
        navigate('/ImageEdit/', { state: { id: id } })
    }
    useEffect(() => {
        if (imagedata) {
            apolloClient.cache.reset();
        }
    }, [imagedata])
    const handleClickOpen = (id: any) => {
        setOpen(true);
        setImageId(id)
    };
    const onDeleteImageRow = () => {
        setImageData(imageId)
        deleteAPi
        setOpen(false)
    }

    const handleClose = () => {
        setOpen(false);
    };
    return (
        <Paper sx={{ width: 950 }}>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you want delete the Image ?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button onClick={() => onDeleteImageRow()} autoFocus>
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
            <TableContainer sx={{ maxHeight: 1000, width: 950 }}>
                <Table sx={{ minWidth: 950, minHeight: 100 }} aria-label="simple table" stickyHeader>
                    <TableHead>
                        <TableRow>
                            <TableCell>Image </TableCell>
                            <TableCell>Carosel Image</TableCell>
                            <TableCell align="right">Upload Date</TableCell>
                            <TableCell align="right">Start Date</TableCell>
                            <TableCell align="right">Expire Date</TableCell>
                            <TableCell align="right">Screen type</TableCell>
                            <TableCell align="right"></TableCell>
                            <TableCell align="right"></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {imageInfo && imageInfo?.map((item, index) => (
                            <TableRow
                                key={index}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell component="th" scope="row">
                                    <img src={item.imageUrl} width="150" height="100" />
                                </TableCell>
                                <TableCell component="th" scope="row">
                                    <img src={item.carouselImage} width="150" height="100" />
                                </TableCell>
                                <TableCell align="right">{(new Date(item.updatedAt)).toLocaleDateString()}</TableCell>
                                <TableCell align="right">{(new Date(item.startDate)).toLocaleDateString()}</TableCell>
                                <TableCell align="right">{(new Date(item.expiryDate)).toLocaleDateString()}</TableCell>
                                <TableCell align="right">{item.screen}</TableCell>
                                <TableCell align="right" onClick={() => handleDetail(item.id)} className="deleteCursor">Edit</TableCell>
                                <TableCell align="right" onClick={() => handleClickOpen(item.id)} className="deleteCursor">Delete</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Paper >
    );
}
export default ImageDataList