import { SchoolDisplay } from "types";
export const EMAIL_REGEX = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
export const EDU_MAIL = /^[\w-.+]+@([\w-]+\.(?!.*edu))+[\w-]{2,20}$/i;
export const EDU_EMAIL_REGEX = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.edu|mynilpay.com$/i;
// eslint-disable-next-line
export const URl_VALIDATION = /^((https?):\/\/)?([w|W]{3}\.)+[a-zA-Z0-9\-\.]{3,}\.[a-zA-Z]{2,}(\.[a-zA-Z]{2,})?$/;

// Password policy: uppercase letters, lowercase letters, special characters, numbers
export const PASSWORD_REGEX =
  /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/;

export const PHONE_REGEX = /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;

export const PHONE_REGEX_ZERO = /^[1-9]{1}\d{2}-\d{3}-\d{4}$/;

export const PHONE_REGEX_HYPHEN = /^\d{ 3}-\d{ 3 } -\d{ 4 }$/;

export const EIN_REGEX = /^\d{2}-\d{7}$/;

export const SEARCH_DELAY = 500;

export const FILTER_DISPLAY = SchoolDisplay.name;

export const MINIMUM_PAYMENT = 20;

export const MAX_LIMIT = 900000;

const ONE_SECOND = 1000;

export const POLLING_MS = ONE_SECOND * 3;
export const CANCEL_POLLING_MS = ONE_SECOND * 60;
export const NOTIFICATIONS_POLLING_MS = ONE_SECOND * 30;
export const SUPPORTLINK_URL = "https://www.mynilpay.com/support";
export const EMAIL_SUPPORTLINK_URL = `mailto:${process.env.REACT_APP_EMAIL_FAN_CONTACT_OTHER_SUPPORT}`;

