import React, { useState } from "react";
import { Grid } from "semantic-ui-react";
const TermsandConditions = () => {
    return (<Grid className="landing-page__termslink" container spacing={2} justifyContent="center">
        <span>
            <a href="https://www.mynilpay.org/privacypolicy" target="_blank" rel="noopener noreferrer">Privacy Policy</a>
        </span>
        <span className="landing-page__seprator">|</span>
        <span>
            <a href="https://www.mynilpay.org/termsandconditions" target="_blank" rel="noopener noreferrer">myNILpay Terms and Conditions</a>
        </span>
        <span className="landing-page__seprator">|</span>

        <span>
            <a href="https://www.mynilpay.org/digitalassettermsandconditions" target="_blank" rel="noopener noreferrer"> Digital Asset Terms and Conditions</a>
        </span>
    </Grid>)
}

export default TermsandConditions;