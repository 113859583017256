import { gql } from "@apollo/client";

export const bankAccountDetails = gql`
  query getAthleteAccountDetailsCko {
    getAthleteAccountDetailsCko {
      data
      status
    }
  }
`;
