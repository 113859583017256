import React from "react";
import { TransactionStatus } from "../../types";
import Successful from "../../assets/icons/transaction/successful.svg";
import Cancelled from "../../assets/icons/transaction/cancelled.svg";
import Pending from "../../assets/icons/transaction/pending.svg";
import Expired from "../../assets/icons/transaction/expired.svg";
import refund from "../../assets/icons/transaction/refund.png";

const icons = {
  successful: Successful,
  cancelled: Cancelled,
  pending: Pending,
  expired: Expired,
  refunded: refund,
};

interface Props {
  status: TransactionStatus;
}

const TransactionIcon = ({ status }: Props) => {
  const src = icons[status];
  const alt = "transaction " + status.toLocaleLowerCase();
  const title = alt;
  const width = 15;
  const height = 15;

  if (!src) return <></>;

  const props = { src, alt, width, height, title };
  return <img {...props} />;
};

export default TransactionIcon;
