import { useQuery } from "@apollo/client";
import { SPORTS } from "../queries";
import { SportsResponse } from "../types";

export function useSport(id: string) {
  const { loading, data, error } = useQuery<SportsResponse>(SPORTS);
  if (loading || error) return null;

  if (data) {
    return data.sports.find((s) => s.id === id);
  }

  return null;
}
