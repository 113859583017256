import React from 'react';
import './checkoutErrors.css'
const CheckoutError = () => {
    localStorage.setItem("success", "");

    return (<div>
        <div className="card">
            <div style={{ borderRadius: 200, height: 200, width: 200, background: '#e71313', margin: '0 auto' }}>
                <i className="checkmark">X</i>
            </div>
            <h1>Unable to Process the paymemnt, please try later</h1>
        </div>
    </div >)
}

export default CheckoutError;