/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useContext } from "react";
import { FilterOptions } from "types";
import { Athlete } from "../types/athlete";
interface StateType {
  searchString: string;
  setSearchString: React.Dispatch<React.SetStateAction<string>>;

  tabs: string[];
  currentTab: string;
  setCurrentTab: React.Dispatch<React.SetStateAction<string>>;

  amount: number | null;
  setAmount: React.Dispatch<React.SetStateAction<number | null>>;

  from: string;
  setFrom: React.Dispatch<React.SetStateAction<string>>;

  multiPayAthletes: Athlete[];
  setMultiPayAthletes: React.Dispatch<React.SetStateAction<Athlete[]>>;

  publicProfile: string | null;
  setPublicProfile: React.Dispatch<React.SetStateAction<string | null>>;

  filters: FilterOptions[];
  setFilters: React.Dispatch<React.SetStateAction<FilterOptions[]>>;
}

const AppStateContext = React.createContext<StateType>({} as StateType);

export const AppStateProvider = (props: { children: React.ReactNode }) => {
  const tabs = ["athletes", "schools"];
  const [from, setFrom] = useState("");
  const [searchString, setSearchString] = useState<any>("");
  const [amount, setAmount] = useState<number | null>(null);
  const [currentTab, setCurrentTab] = useState("athletes");
  const [multiPayAthletes, setMultiPayAthletes] = useState<Athlete[]>([]);
  const [publicProfile, setPublicProfile] = useState<string | null>(null);
  const [filters, setFilters] = useState<FilterOptions[]>([]);

  return (
    <>
      <AppStateContext.Provider
        value={{
          tabs,
          currentTab,
          from,
          setFrom,
          searchString,
          setSearchString,
          amount,
          setAmount,
          setCurrentTab,
          multiPayAthletes,
          setMultiPayAthletes,
          publicProfile,
          setPublicProfile,
          filters,
          setFilters,

        }}
      >
        {props.children}
      </AppStateContext.Provider>
    </>
  );
};

export function useAppState() {
  return useContext(AppStateContext);
}
