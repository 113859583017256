import {
  ApolloClient,
  HttpLink,
  ApolloLink,
  InMemoryCache,
  FetchResult,
} from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { Auth } from "aws-amplify";
import { SignInUserSession } from "types/cognito";
import { insertApiResponseDataDogError } from "utils";
import { Observable } from '@apollo/client/utilities';
import jwtDecode, { JwtPayload } from "jwt-decode";
// import { createUploadLink } from 'apollo-upload-client';

let refreshTokenPromise: Promise<unknown> | undefined = undefined;

const httpLink = new HttpLink({
  uri: `${process.env.REACT_APP_SERVER_URI}/graphql`,
});


const authMiddleware = new ApolloLink((operation, forward) => {
  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      authorization: `Bearer ${localStorage.getItem("token")}` || null,
    },
  }));

  return forward(operation);
});
function tokenIsExpierd() {
  const token = localStorage.getItem("token");

  if (token) {
    const decoded = jwtDecode<JwtPayload>(token);
    const expiration = decoded.exp || 0;
    return expiration * 1000 <= Date.now()
  }
  return true
}
const getNewToken = async (): Promise<any> => {
  try {
    // eslint-disable-next-line no-async-promise-executor
    refreshTokenPromise = new Promise(async (resolve, reject) => {
      const cognitoUser = await Auth.currentAuthenticatedUser();
      const currentSession = await Auth.currentSession();
      if (tokenIsExpierd()) {
        const token = currentSession?.getRefreshToken();
        cognitoUser.refreshSession(
          token,
          (err: unknown, session: SignInUserSession) => {
            if (err) {
              reject(err)
            } else {
              const idToken = session?.idToken;
              localStorage.setItem("token", idToken?.jwtToken);
              resolve(idToken)
            }
          }
        );
      } else {
        resolve(localStorage.getItem("token"))
      }
    })
    return await refreshTokenPromise;
  } catch (error) {
    Auth.signOut();
    window.localStorage.removeItem("token");
    window.localStorage.removeItem("LoginUserObject");
    window.localStorage.removeItem("loginUserId");
    window.localStorage.removeItem("loginUserName");
    window.localStorage.removeItem("loginUserEmail");
    window.location.replace("/login");
  }
};
const errorLink = onError(
  ({ graphQLErrors, networkError, operation, forward }) => {
    if (graphQLErrors) {
      for (const err of graphQLErrors) {
        const code = err.extensions.code;
        if (code === 'FORBIDDEN') {
          // ignore 401 error for a refresh request
          const observable = new Observable<FetchResult<Record<string, any>>>(
            (observer) => {
              // used an annonymous function for using an async function
              (async () => {
                setTimeout(async () => {
                  try {
                    await refreshTokenPromise;
                    await getNewToken();
                    // Retry the failed request
                    const subscriber = {
                      next: observer.next.bind(observer),
                      error: observer.error.bind(observer),
                      complete: observer.complete.bind(observer),
                    };

                    forward(operation).subscribe(subscriber);

                  } catch (err) {
                    observer.error(err);
                  }
                });

              })();
            }
          );
          return observable;
        } else if (code === "UNAUTHENTICATED") {
          Auth.signOut();
          window.localStorage.removeItem("token");
          window.localStorage.removeItem("LoginUserObject");
          window.localStorage.removeItem("loginUserId");
          window.localStorage.removeItem("loginUserName");
          window.localStorage.removeItem("loginUserEmail");
          window.location.replace("/login");
        }
        insertApiResponseDataDogError(err.message, "graphQLErrors");
      }
    }
    if (networkError) console.log(`[Network error]: ${networkError}`);
  }
);
export const apolloClient = new ApolloClient({
  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          // merge old and new items to accomplish infinite scroll
          searchSchools: {
            keyArgs: ["searchString"],
            merge(existing = { items: [] }, incoming) {
              const items = [...existing.items, ...incoming.items];
              return { ...incoming, items };
            },
          },
          filterAthletes: {
            keyArgs: ["filterParams"],
            merge(existing = { items: [] }, incoming) {
              const items = [...existing.items, ...incoming.items];
              return { ...incoming, items };
            },
          },
          listNFTs: {
            keyArgs: false,
            merge(existing = { items: [] }, incoming) {
              const items = [...existing.items, ...incoming.items];
              return { ...incoming, items };
            },
          },
          getNotifications: {
            keyArgs: ["deviceType"],
            merge(existing = { items: [] }, incoming) {
              const items = [...existing.items, ...incoming.items];
              return { ...incoming, items };
            },
          }
        },
      },
    },
  }),
  link: ApolloLink.from([errorLink, authMiddleware, httpLink]),
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //@ts-ignore
  // link: authMiddleware.concat(errorHandler).concat(createUploadLink({
  //   uri: `${process.env.REACT_APP_SERVER_URI}/graphql`,
  //   headers: { "Apollo-Require-Preflight": "true" },
  // }))
});
