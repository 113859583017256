import { gql } from "@apollo/client";

export const FILTER_ATHLETE = gql`
  query filterAthletes(
    $filterParams: UserFilterParams!
    $limit: Int = 25
    $page: Int = 1
  ) {
    filterAthletes(filterParams: $filterParams, limit: $limit, page: $page) {
      itemTotal
      pageTotal
      pageNumber
      limit
      items {
        id
        firstName
        lastName
        athleteOnboarded
        isFavorite
        athleteSport {
          sport {
            id
            name
          }
        }
        school {
          id
          name
          nickname
          mascot
        }
      }
    }
  }
`;

export const ATHLETE_ONBOARDED = gql`
  query user($id: String!) {
    user(id: $id) {
      id
      athleteOnboarded
    }
  }
`;

export const ATHLETE_PROFILE = gql`
  query UserProfile($id: String!) {
    userProfile(id: $id) {
      firstName
      lastName
      imageUrl
      nickname
      isActive
      isAlumni
      highSchool
      hometown
      school {
        id
        name
        donationLink
      }
      isFavorite
      socialMedia {
        facebook_link
        instagram_link
        snapchat_link
        twitter_link
      }
      athleteProfileStats {
        supporters
        favorites
      }
      athleteSports {
        id
        jerseyNumber
        sportDetails {
          bio
          position
        }
        sport {
          id
          name
        }
      }
      athleteSport {
        jerseyNumber
        sportDetails {
          bio
          position
          athlete_class
        }
        sport {
          id
          name
        }
        
      }
    }
  }
`;

export const ATHLETE_PUBLIC_PROFILE = gql`
  query athleteProfilePublic($id: String!) {
    athleteProfilePublic(id: $id) {
      firstName
      lastName
      imageUrl
      nickname
      school {
        id
        name
      }
      athleteProfileStats {
        supporters
        favorites
      }
      athleteSports {
        id
        jerseyNumber
        sportDetails {
          bio
          position
        }
        sport {
          id
          name
        }
      }
      athleteSport {
        jerseyNumber
        sportDetails {
          bio
          position
        }
        sport {
          id
          name
        }
      }
    }
  }
`;

export const ATHLETE_EMAIL_SEARCH = gql`
  query athleteEmailSearch($email: String!) {
    athleteEmailSearch(email: $email) {
      id
      firstName
      lastName
      school {
        id
        name
      }
      sports {
        id
        name
      }
    }
  }
`;

export const ATHLETE_SIGN_UP = gql`
  mutation athleteSignUp($id: String!, $password: String!) {
    athleteSignUp(id: $id, password: $password)
  }
`;
export const CUSTOM_CONFIRM_SIGN_UP = gql`
mutation customConfirmSignup($username: String!, $clientId:String!, $confirmationCode: String!) {
  customConfirmSignup(username:$username, clientId:$clientId, confirmationCode:$confirmationCode){message status}
  }
`;

export const ATHLETE_HEAD_SHOT = gql`
  mutation updateHeadshotDetails($imageUrl: String!) {
    updateHeadshotDetails(imageUrl: $imageUrl )
  }
`;

export const MISSING_ATHLETE = gql`
mutation reportMissingAthlete($name: String,$email:String, $school: String,$sport: String,$athleteName: String,$athleteEmail: String) {
  reportMissingAthlete(name: $name,email:$email, school: $school,sport: $sport,athleteName:$athleteName,athleteEmail:$athleteEmail)
  }
`;

export const IDENTITY_VERIFICATION = gql`
  mutation initiateIdentityVerification($returnUrl: String!) {
    initiateIdentityVerification(returnUrl: $returnUrl)
  }
`;

export const ONBOARD_USER_ATHLETE = gql`
  mutation onboardUserAccount($refreshUrl: String!, $returnUrl: String!) {
    onboardUserAccount(refreshUrl: $refreshUrl, returnUrl: $returnUrl)
  }
`;

export const ONBOARD_ATHLETE = gql`
  mutation onboardAthleteWithCko($data: OnBoardAthleteRequestCko!) {
    onboardAthleteWithCko(data: $data){
      status
      data
    }
  }
`

export const REQUEST_ATHLETE = gql`
  mutation addRequestedAthlete($requestedAthlete: RequestedAthleteInput!) {
    addRequestedAthlete(requestedAthlete: $requestedAthlete)
  }
`;

export const REQUEST_SPONSORSHIP = gql`
  mutation addRequestedSponsorship(
    $requestedSponsorship: RequestedSponsorshipInput!
  ) {
    addRequestedSponsorship(requestedSponsorship: $requestedSponsorship)
  }
`;

export const FAVORITES_ATHLETES = gql`
  query favoriteAthletes($limit: Int = 25, $page: Int = 1) {
    favoriteAthletes(limit: $limit, page: $page) {
      itemTotal
      pageTotal
      pageNumber
      limit
      items {
        id
        firstName
        lastName
        athleteOnboarded
        athleteSport {
          sport {
            id
            name
          }
        }
        school {
          id
          name
          nickname
          mascot
        }
      }
    }
  }
`;

export const ATHLETES_BY_TEAM = gql`
  query athletesByTeam(
    $onlyActive: Boolean = false
    $schoolId: String!
    $sportId: String!
  ) {
    athletesByTeam(
      onlyActive: $onlyActive
      schoolId: $schoolId
      sportId: $sportId
    ) {
      id
      firstName
      lastName
      athleteOnboarded
      athleteSport {
        jerseyNumber
        sportDetails {
          bio
          position
        }
        sport {
          id
          name
        }
      }
      school {
        id
        name
        nickname
        mascot
      }
    }
  }
`;

export const GET_STRIPE_LINK = gql`
  query getStripeLoginLink {
    getStripeLoginLink
  }
`;

export const UPDATE_BANK_ACCOUNT_INFO = gql`
  mutation updateAthleteBankAccountInfoCko($data: UpdateAthleteBankInfoRequest!) {
    updateAthleteBankAccountInfoCko(data: $data){
      status
      data
    }
  }
`
export const ATHLETE_EMAIL_CODE_VERIFY = gql`
mutation confirmRequestedAthlete($code: String!, $email: String!) {
  confirmRequestedAthlete(code: $code, email: $email)
}
`
export const ATHLETE_RESEND_EMAIL_VERIFY_CODE = gql`
mutation resendRequestedAthleteCode($email: String!) {
  resendRequestedAthleteCode(email: $email)
}
`
