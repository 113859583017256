/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import { getFormatedCurrency, insertApiResponseDataDog, insertApiResponseDataDogError } from "utils";
import { Navigate } from "react-router-dom";
import { PlayerCard } from "./PlayerCard";
import { useAppState } from "providers/AppState";
import { useNavigate, useParams } from "react-router-dom";
import { useSport } from "hooks/useSport";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import List from "@mui/material/List";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { TeamHeader } from "./TeamHeader";
import { useSchool } from "hooks/useSchool";
import { filedPaymentResponse } from "types/createPaymentToken";
import { createFiledTokens } from "queries/createFiledToken";
import { useMutation } from "@apollo/client";
import { useNotifications } from "providers/Notification";

export function MultiPaymentReview() {
  const appState = useAppState();
  const notify = useNotifications();
  const params = useParams();
  const navigate = useNavigate();
  const schoolId = params.schoolId || "";
  const school = useSchool(schoolId);
  const schoolName = school?.name || "Loading...";
  const sportId = params.sportId || "";
  const sport = useSport(sportId);
  const sportName = sport?.name || "Loading...";
  const athletes = appState.multiPayAthletes;
  const athletesCount = athletes.length;
  const amount = appState.amount || 0;
  const totalAmount = athletesCount * amount
  const [createPayMentToken, { loading }] = useMutation<filedPaymentResponse>(
    createFiledTokens
  );
  const res = appState.multiPayAthletes;

  const multipileatheletes: any = [];
  res?.forEach((item: any) => {
    multipileatheletes.push(item.id)
  })

  const handlePayment = async () => {
    try {
      const res = await createPayMentToken({
        variables: { "amount": `${"" + Number(totalAmount.toFixed(2)) + ""}` }
      });
      insertApiResponseDataDog("getPrePaymentDetailsApi(success)", res, "info")
      localStorage.setItem("pulickey", `${res?.data?.getPrePaymentDetailsCko.ckoPublicKey}`);

      navigate('/CheckoutDetails/', { state: { athleteId: multipileatheletes, amount: Number(totalAmount.toFixed(2)), tax: res?.data?.getPrePaymentDetailsCko.tax } })
    } catch (error) {
      insertApiResponseDataDog("getPrePaymentDetailsApi(error)", error, "error")
      insertApiResponseDataDogError(error, "getPrePaymentDetailsApi(error)");
      notify.error("Something went wrong");
    }
  };

  if (!athletesCount || !amount) {
    return <Navigate to={`/fanhome/school/${schoolId}/sport/${sportId}`} />;
  }

  return (
    <Box>
      <TeamHeader
        schoolName={schoolName}
        sportName={sportName}
        schoolId={schoolId}
        sportId={sportId}
      />
      <Stack
        direction={"row"}
        justifyContent={"space-between"}
        marginBottom={2}
      >
        <Typography fontWeight="300" color="secondary.dark" textAlign={"left"} marginTop={2}>
          Paying <strong>{athletesCount} Athletes</strong>{" "}
          {getFormatedCurrency(amount)} each
        </Typography>

        <Button
          variant="contained"
          color="secondary"
          sx={{ padding: "10px 25px" }}
          onClick={() => navigate(-1)}
          data-testid="edit"

        >
          Edit
        </Button>
      </Stack>

      <Stack marginTop={2} marginBottom={2} textAlign={"left"}>
        <Typography color="primary.dark" fontSize={12} fontWeight={600}>
          Payment Approval &amp; Expiration
        </Typography>
        <Typography fontSize={12} fontWeight={300}>
          Direct payment will be sent to the athlete and once accepted by the
          athlete, then the transaction will be complete. If not accepted within
          7 days, the transaction will expire and you will not be charged.
        </Typography>
      </Stack>

      <List
        sx={{
          overflow: "scroll",
          height: "75vh",
          maxHeight: "calc(100vh - 500px)",
        }}
      >
        {athletes.map((athlete) => (
          <PlayerCard
            key={athlete.id}
            athlete={athlete}
            sportName={sport?.name || "Loading..."}
            sportId={sportId}
            amount={amount}
          />
        ))}
      </List>

      <Stack textAlign={"left"} maxWidth="600px">
        <Box textAlign={"center"}>
          <Stack
            direction={"row"}
            alignItems={"center"}
            justifyContent={"center"}
            spacing={1}
            margin={3}
          >
            <Typography fontSize={14} color="secondary.dark" fontWeight={500}>
              Total Amount:
            </Typography>
            <Typography fontSize={20} color="secondary.dark" fontWeight={600}>
              {getFormatedCurrency(totalAmount)}
            </Typography>
          </Stack>
          <Button
            startIcon={loading ? <CircularProgress size={20} /> : null}
            disabled={loading}
            autoFocus
            fullWidth
            data-testid="send-payment"
            variant="contained"
            onClick={handlePayment}
          >
            Send
          </Button>
        </Box>
      </Stack>
    </Box>
  );
}
