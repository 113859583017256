import React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import styled from "@emotion/styled";

interface StyledTabsProps {
  children?: React.ReactNode;
  value: number;
  centered?: boolean;
  onChange: (event: React.SyntheticEvent, newValue: number) => void;
}

const StyledTabs = styled((props: StyledTabsProps) => (
  <Tabs
    {...props}
    variant="scrollable"
    TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
  />
))({
  backgroundColor: "#FAFAF7",
  borderRadius: "40px",
  justifyContent: "space-around",
  maxWidth: "90vw",
  "& .MuiTabs-indicator": {
    display: "flex",
    justifyContent: "space-around",
    backgroundColor: "transparent",
    height: "47px",
    borderRadius: "40px",
    top: "0px",
  },
  "& .MuiTabs-indicatorSpan": {
    width: "100%",
    display: "inline-block",
    backgroundColor: "#091F82",
    borderRadius: "40px",
    zIndex: 1,
  },
});

interface StyledTabProps {
  label: string;
}

const StyledTab = styled((props: StyledTabProps) => (
  <Tab disableRipple {...props} />
))(() => ({
  textTransform: "capitalize",
  color: "#091F82",
  zIndex: 2,
  "&.Mui-selected": {
    color: "#fff",
    transition: "color 0.25s ease",
  },
}));

interface Props {
  options: string[];
  onSelect: (x: string) => void;
  selected?: string;
}

const FilterTopBar = ({ options, onSelect, selected }: Props) => {
  const defaultValue = selected ? options.indexOf(selected) : 0;
  const [value, setValue] = React.useState(defaultValue);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
    onSelect(options[newValue]);
  };

  return (
    <StyledTabs value={value} onChange={handleChange}>
      {options.map((label) => (
        <StyledTab key={label} label={label} />
      ))}
    </StyledTabs>
  );
};

export default FilterTopBar;
