import React from "react";

import { ApolloProvider } from "@apollo/client";
import { apolloClient } from "./Apollo";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
import Notifications from "../components/Notifications";
import { NotificationProvider } from "./Notification";
import { theme } from "./Theme";
import { AppStateProvider } from "./AppState";

type AppProviderProps = {
  children: React.ReactNode;
};


const AppProvider = ({ children }: AppProviderProps) => {
  return (
    <AppStateProvider>
      <NotificationProvider>
        <ApolloProvider client={apolloClient}>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <Notifications />
            {children}
          </ThemeProvider>
        </ApolloProvider>
      </NotificationProvider>
    </AppStateProvider>
  );
};

export default AppProvider;
