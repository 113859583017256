import React from "react";

import Empty from "../../assets/icons/sports/Empty.svg";
import Baseball from "../../assets/icons/sports/Baseball.svg";
import Basketball from "../../assets/icons/sports/Basketball.svg";
import BeachVolleyball from "../../assets/icons/sports/BeachVolleyball.svg";
import Bowling from "../../assets/icons/sports/Bowling.svg";
import CrossCountry from "../../assets/icons/sports/CrossCountry.svg";
import Fencing from "../../assets/icons/sports/Fencing.svg";
import FieldHockey from "../../assets/icons/sports/FieldHockey.svg";
import Football from "../../assets/icons/sports/Football.svg";
import Golf from "../../assets/icons/sports/Golf.svg";
import Gymnastics from "../../assets/icons/sports/Gymnastics.svg";
import Hockey from "../../assets/icons/sports/Hockey.svg";
import Lacrosse from "../../assets/icons/sports/Lacrosse.svg";
import Rifle from "../../assets/icons/sports/Rifle.svg";
import Rowing from "../../assets/icons/sports/Rowing.svg";
import Skiing from "../../assets/icons/sports/Skiing.svg";
import Soccer from "../../assets/icons/sports/Soccer.svg";
import Softball from "../../assets/icons/sports/Softball.svg";
import SwimmingDiving from "../../assets/icons/sports/SwimmingDiving.svg";
import Tennis from "../../assets/icons/sports/Tennis.svg";
import TrackField from "../../assets/icons/sports/TrackField.svg";
import Volleyball from "../../assets/icons/sports/Volleyball.svg";
import WaterPolo from "../../assets/icons/sports/WaterPolo.svg";
import Wrestling from "../../assets/icons/sports/Wrestling.svg";

interface Props {
  sportName: string;
}

function SportIcon({ sportName }: Props) {
  let src = "";
  const alt = sportName.toLocaleLowerCase() || "empty icon";
  const width = 32;
  const height = 32;

  switch (true) {
    case /BASEBALL/i.test(sportName):
      src = Baseball;
      break;

    case /BASKETBALL/i.test(sportName):
      src = Basketball;
      break;

    case /BEACH VOLLEYBALL/i.test(sportName):
      src = BeachVolleyball;
      break;

    case /BOWLING/i.test(sportName):
      src = Bowling;
      break;

    case /CROSS COUNTRY/i.test(sportName):
      src = CrossCountry;
      break;

    case /FENCING/i.test(sportName):
      src = Fencing;
      break;

    case /FIELD HOCKEY/i.test(sportName):
      src = FieldHockey;
      break;

    case /FOOTBALL/i.test(sportName):
      src = Football;
      break;
    case /GOLF/i.test(sportName):
      src = Golf;
      break;

    case /GYMNASTICS/i.test(sportName):
      src = Gymnastics;
      break;

    case /ICE HOCKEY/i.test(sportName):
      src = Hockey;
      break;

    case /LACROSSE/i.test(sportName):
      src = Lacrosse;
      break;

    case /RIFLE/i.test(sportName):
      src = Rifle;
      break;

    case /ROWING/i.test(sportName):
      src = Rowing;
      break;

    case /SKIING/i.test(sportName):
      src = Skiing;
      break;

    case /SOCCER/i.test(sportName):
      src = Soccer;
      break;

    case /SOFTBALL/i.test(sportName):
      src = Softball;
      break;

    case /SWIMMING & DIVING/i.test(sportName):
      src = SwimmingDiving;
      break;

    case /TENNIS/i.test(sportName):
      src = Tennis;
      break;

    case /TRACK & FIELD/i.test(sportName):
      src = TrackField;
      break;

    case /VOLLEYBALL/i.test(sportName):
      src = Volleyball;
      break;

    case /WATER POLO/i.test(sportName):
      src = WaterPolo;
      break;

    case /WRESTLING/i.test(sportName):
      src = Wrestling;
      break;

    default:
      src = Empty;
      break;
  }

  const props = { src, alt, width, height };
  return <img {...props} />;
}

export default SportIcon;
