import React from "react";
import { ReactComponent as Back } from "../../assets/icons/back.svg";
import { useNavigate } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import { Typography } from "@mui/material";


interface BackButtonProps {
  onClick?: () => void;
}

const BackButton = ({ onClick }: BackButtonProps) => {
  const navigate = useNavigate();

  const handleClick = () => {
    if (onClick) onClick();
    else navigate(-1);

  };

  return (
    <Stack flexDirection={"row"} marginBottom={4}>
      <IconButton aria-label="go back" onClick={handleClick}>
        <Back />
      </IconButton>
      <Typography sx={{ marginTop: '9px' }}>Back</Typography>
    </Stack>
  );
};

export default BackButton;
