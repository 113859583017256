/* eslint-disable @typescript-eslint/no-explicit-any */
import Box from "@mui/material/Box";
import React, { useEffect } from "react";
import { MemorabuilaErrorModel } from "components/MemorabuilaErrorModel";
import { Backdrop, CircularProgress } from "@mui/material";
import { ApolloError, useMutation, useQuery } from "@apollo/client";
import Header from "components/Header";

// import { checkoutInput, checkoutResponse } from "types/checkoutPayment";
import { checkoutInput, checkoutResponse, checkoutMultiAthlete, checkoutMultiAthleteResponse } from "types/checkoutPayment";
import { checkoutPayment } from "queries/checkoutPayment";
import { useNotifications } from "providers/Notification";
import { useLocation, useNavigate } from "react-router-dom";
import { checkoutMultiAthletePayments } from "queries/multiathletecheckoutPayment";
import { ME, MeResponse } from "queries";
import { useAppState } from "providers/AppState";
import { insertApiResponseDataDog, insertApiResponseDataDogError } from "utils";
const CardPayment = () => {
  const notify = useNotifications();
  const location = useLocation();
  // eslint-disable-next-line
  const athleteData: any = location.state;
  // const [credit, setCreditCard] = React.useState(true);
  // const [gpayToogle, setGpayToogle] = React.useState(false);
  // const [page, setPage] = React.useState<any>(null)
  const navigate = useNavigate();
  const [iframLoader, setIframeLoader] = React.useState(true)
  // eslint-disable-next-line
  const [localData, setLocalData] = React.useState<any>('');
  const appState = useAppState();
  const [memorabuilaErrorModel, setmemorabuilaErrorModel] = React.useState(false);
  const tax: number = athleteData.tax || 0;
  const userId: any = athleteData.id || '';
  const amount: number = athleteData.amount || 0;
  const { data } = useQuery<MeResponse>(ME, {
    onError: (error: ApolloError) => {
      notify.error(error.message);
      insertApiResponseDataDog("error", error, "error");
      insertApiResponseDataDog("MEApi(error)", error, "error")
      insertApiResponseDataDogError(error, "MEApi(error)");
    },
    onCompleted(data) {
      insertApiResponseDataDog("MEApi(success)", data, "info")
    }
  });
  const [checkoutCardPaymentToken, setcheckoutCardPaymentToken] = React.useState<any>('')
  const [setPaySingleAthlete, { loading }] = useMutation<checkoutResponse, checkoutInput>(checkoutPayment);
  const [setmultiAthletePayMents, { loading: multiAthleteLoader }] = useMutation<checkoutMultiAthleteResponse, checkoutMultiAthlete>(checkoutMultiAthletePayments);
  window.addEventListener("storage", (e) => {
    const token = localStorage.getItem('cardPaymentToken');
    const validDate = localStorage.getItem('valid')
    setcheckoutCardPaymentToken(token)
    setLocalData(validDate)
  });

  useEffect(() => {
    const handleContextMenu = (e: any) => {
      e.preventDefault()
    }
    const onkeydownHideDeveloper = (e: any) => {
      if (e.keyCode == 123) {
        e.preventDefault()
      }
      if (e.ctrlKey && e.shiftKey && e.keyCode == 'I'.charCodeAt(0)) {
        e.preventDefault()
      }
      if (e.ctrlKey && e.shiftKey && e.keyCode == 'J'.charCodeAt(0)) {
        e.preventDefault()
      }
      if (e.ctrlKey && e.keyCode == 'U'.charCodeAt(0)) {
        e.preventDefault()
      }
    }
    document.addEventListener("keydown", onkeydownHideDeveloper);
    document.addEventListener("contextmenu", handleContextMenu)
    return () => {
      document.removeEventListener("contextmeiframe-componentnu", handleContextMenu);
      document.removeEventListener("keydown", onkeydownHideDeveloper);
    }
  }, [])

  useEffect(() => {
    if (localData === "valid") {
      if (typeof userId === 'string' && userId !== "") {
        const setSingleAthletePayment = async () => {
          try {
            const res = await setPaySingleAthlete({
              variables: {
                athleteUserId: userId,
                newCardTokenId: checkoutCardPaymentToken || "",
                paymentInfo: {
                  amount: amount,
                  tax: tax
                },
                savedCardId: ""
              }

            });
            insertApiResponseDataDog("purchaseMemorabiliaApi(success)", res, "info")
            if (res?.data?.purchaseMemorabiliaWithCko.status === 200) {
              appState.setAmount(null);
              localStorage.setItem("valid", "");
              navigate(`/fanhome?transaction=completed&id=${userId}`);
            } else {
              insertApiResponseDataDog("purchaseMemorabiliaApi(success)", res, "info")
              setmemorabuilaErrorModel(true)
            }
          } catch (error) {
            insertApiResponseDataDogError(error, "purchaseMemorabiliaApi(error)");
            insertApiResponseDataDog("purchaseMemorabiliaApi(error)", error, "error")
            setmemorabuilaErrorModel(true)
          }
        };
        setSingleAthletePayment();
      } else {
        const setMultiAthletePayment = async () => {
          try {
            const res = await setmultiAthletePayMents({
              variables: {
                athleteUserIds: userId,
                newCardTokenId: checkoutCardPaymentToken || "",
                paymentInfo: {
                  amount: amount,
                  tax: tax
                },
                savedCardId: ""
              }
            });
            insertApiResponseDataDog("purchaseMemorabiliaMulti(success)", res, "info")
            if (res?.data?.purchaseMemorabiliaMultiWithCko.status === 200) {
              appState.setAmount(null);
              localStorage.setItem("valid", "");
              navigate('/fanhome?transaction=completed&multi=true');
            } else {
              setmemorabuilaErrorModel(true)
            }
          } catch (error) {
            insertApiResponseDataDog("purchaseMemorabiliaMulti(error)", error, "error")
            insertApiResponseDataDogError(error, "purchaseMemorabiliaApi(error)");
            setmemorabuilaErrorModel(true)
          }
        };
        setMultiAthletePayment();
      }

    } else if (localData === "error") {
      localStorage.setItem("valid", "");
      setmemorabuilaErrorModel(true)
    }
  }, [localData, checkoutCardPaymentToken])

  { loading && <CircularProgress /> }
  { multiAthleteLoader && <CircularProgress /> }

  const hideSpinner = () => {
    setIframeLoader(false)
  }
  return (
    <Box className="billing-contact">


      {memorabuilaErrorModel && (
        <MemorabuilaErrorModel
          open={memorabuilaErrorModel}
          setOpen={setmemorabuilaErrorModel}
          amount={amount}
          id={userId}
          tax={tax}
        />
      )}

      <Header text="Credit Card" />
      <Box textAlign={"left"} className="billing-contact__info">
        <Box>
          <span>Name</span><strong> : {data?.me?.firstName} {data?.me?.lastName}</strong>
        </Box>
        <Box sx={{ mt: 1 }}>
          <span>Country</span><strong> : United States</strong>
        </Box>
        <Box sx={{ mt: 1 }}>
          <span>ZIP</span><strong> : {data?.me?.zipCode}</strong>
        </Box>
        <Box sx={{ mt: 1 }}>
          <span>phone</span><strong> : {data?.me?.phoneNumber}</strong>
        </Box>
        <Box sx={{ mt: 1 }}>
          <span>Shopper Currency</span><strong> : USD</strong>
        </Box>
      </Box>
      <Box sx={{ mt: 4 }} textAlign={"left"} className="billing-contact__payment">

        <Box sx={{ marginBottom: 2 }}>Credit Card / Debit Card</Box>
        <Backdrop
          sx={{ color: '#592EE5', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={iframLoader}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <iframe className="iframe-component" src={"web/checkoutCardPayment/index.html"}
          id="iframeid" style={{ border: 'none' }}
          onLoad={hideSpinner}
        ></iframe>
      </Box>

    </Box >
  )
}
export default CardPayment;