import React from "react";
import Modal from "@mui/material/Modal";
import { Box, IconButton } from "@mui/material";
import { ReactComponent as Close } from "../../assets/icons/close.svg";
import { MAIN_GRADIENT } from "../../providers/Theme";
import backgroundImage from "../../assets/images/modal_background.png";

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  maxWidth: "98vw",
  textAlign: "center",
  borderRadius: "8px",
  padding: 6,
  minHeight: "400px",
  background: "#FFF",
  color: "primary.main",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
};

const darkStyle = {
  color: "#FFF",
  background: MAIN_GRADIENT,
  backgroundImage: `url(${backgroundImage})`,
  backgroundPosition: "bottom",
};

interface Props {
  children: JSX.Element;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onClose?: () => void;
  dark?: boolean;
}

function ModalWrapper({ open, setOpen, onClose, dark, children }: Props) {
  const handleClose = () => setOpen(false);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={{ ...modalStyle, ...(dark && darkStyle) }}>
        {onClose && (
          <Box position="absolute" top="15px" right="15px">
            <IconButton aria-label="close" onClick={onClose}>
              <Close />
            </IconButton>
          </Box>
        )}

        <div>{children}</div>
      </Box>
    </Modal>
  );
}

export default ModalWrapper;
