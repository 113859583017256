import { useQuery } from "@apollo/client";
import { ADMIN_ImageData } from "../queries";
import { ImageDataResponse } from "../types/admin";

function useImageData() {
    const { loading, data, error } = useQuery<ImageDataResponse>(ADMIN_ImageData);
    if (data) return data.splashImages;
    if (loading || error) return null;
    return null;

}

export default useImageData;