import React, { useState } from "react";
import { formatCurrency } from "../../../utils";
import Box from "@mui/material/Box";
// import info from ''
import info from '../../../assets/images/info.png';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import info_main from '../../../assets/images/info_main.png'


interface Props {
  totalvalue: number,
  totalItemvalue: number,
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const EarningsHeader = ({ totalvalue, totalItemvalue }: Props) => {

  const total = totalvalue;
  const totalItems = totalItemvalue;

  const [open, setOpen] = useState(false)
  const handleClose = () => {
    setOpen(false)
  };

  const handleListItemClick = () => {
    setOpen(true)

  };


  return (
    <React.Fragment>

      <Dialog onClose={handleClose} open={open}>

        <img src={info_main} className="headericon" />
        <DialogTitle className="infoText">A Successful Payment will take 24-72 hours for funds to be deposited into your account.</DialogTitle>
        <Button
          onClick={handleClose}
          className="bt"

        >
          close        </Button>
      </Dialog>

      <Box className="total-earnings infomain">
        <img src={info} className="infoIcon" onClick={handleListItemClick} />
        <span><small>Total Earnings </small><small>Transactions Completed</small></span>
        <Box className="total-earnings__total">
          <strong>{formatCurrency(total)}</strong>
          <strong>{totalItems} <small>completed</small></strong>
        </Box>
      </Box>
    </React.Fragment>
  );
};
