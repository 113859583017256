import React from "react";
import { RequireAuth } from ".";
import { Route } from "react-router-dom";
import ImageUploadForm from "pages/Admin/ImageUpload/ImageUploadForm";
import ImageEdit from "pages/Admin/ImageUpload/ImageEdit";
import PromoCodeForm from "pages/Admin/Promocode/PromoCodeForm";
import PromoCodeEdit from "pages/Admin/Promocode/PromoCodeEdit";
import SponsorshipDetailsData from "pages/Admin/Sponsorshipdetails/sponsorshipDetailsData";
import ManageSchoolUser from "pages/Admin/ManageSchoolUser";
import Reports from "pages/Universities/Reports/Reports";


export const AdminRoutes = () => (
    <Route element={<RequireAuth userTypes={["admin"]} />}>
        <Route path="/imageupload" element={<ImageUploadForm />} />
        <Route path="/imageedit" element={<ImageEdit />} />
        <Route path="/promocode" element={<PromoCodeForm />} />
        <Route path="/promoedit" element={<PromoCodeEdit />} />
        <Route path="/manageSchoolUser" element={<ManageSchoolUser />} />
        <Route path="/SponsorshipDetails" element={<SponsorshipDetailsData />} />
        <Route path="/schoolreports" element={<Reports />} />
    </Route>
);
