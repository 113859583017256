import { gql } from "@apollo/client";
export const schoolFacultySignUp = gql`
mutation schoolFacultySignUp($data: SchoolUserInput!) {
schoolFacultySignUp(data: $data){
 id 
 email 
 firstName 
 isActive 
 lastName 
}
}
`

export const getSchoolFacultiesList = gql`
query users($type: String!,$limit:Int=5, $page:Int = 1)
{ users(type:$type, limit:$limit, page:$page)
     {  pageNumber 
        pageTotal 
        itemTotal 
        limit  
        items{
        id    
        email   
        firstName 
        lastName
        highSchool
    }  
    }}
`

