import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import styles from "./About.module.css"
import MNPLogo from "assets/images/crown_logo.svg";


const Aboutmnp = () => {

    const data = [{ text: "Love and Serve Student-Athletes" }, { text: "Be a Resource for Student-Athletes" }, { text: "Be a Leader for Student-Athletes" }]

    return (
        <Box>
            <Typography
                fontSize="20"
                fontWeight="600"
                flex={{
                    xs: "auto",
                    md: "none",
                }}
                color="secondary.dark"
            >
                {'about myNILpay'}
            </Typography>
            <Box marginTop={6}>
                <img src={MNPLogo} alt="myNILpay logo" />
                <Typography component={"span"} sx={{ marginLeft: 1, position: 'relative', bottom: 6, fontWeight: 700 }}>{'myNILpay'}</Typography>
                <br />
                <p>Student-athletes have long been the backbone of school spirit and pride, but they have struggled with limited opportunities for financial gain despite their contributions to the school community. And while the National Collegiate Athletic Association’s (NCAA) made a rule change in 2021 that allowed student-athletes to profit off their Name, Image, and Likeness (NIL), many athletes still struggle to capitalize on their NIL.</p>
                <Box className="total-about__total">
                    <p className={styles.content}>myNILpay founder Brent Chapman first learned about NIL at a national football camp with his oldest son in 2021. After listening to several athletes talk about their experiences, he knew there had to be a better way to use NIL to positively impact more students. Using his experience as a technologist and corporate executive, he set out to better fulfill the needs of all student-athletes</p>
                </Box>
                <br />
                <p>Leveraging his experience as a technologist and corporate executive, Brent set out to create a platform that could better fulfill the needs of all student-athletes. The result was myNILpay, a platform that supports athletes and provides fans with a way to show their appreciation. At myNILpay, we believe that every student-athlete deserves fair compensation and recognition for their contributions to the school community.</p>
                <p className={styles.mission}>myNILpay’s Mission Is To:</p>
                <Box marginBottom={20} >
                    {data.map((obj, key) => (<Typography key={key} sx={{ padding: 2, paddingLeft: 10 }} className={styles.listelements}>
                        <img src={MNPLogo} alt="myNILpay logo" />
                        <Typography component={"span"} sx={{ marginLeft: 2, position: 'relative', bottom: 5 }}>{obj.text}</Typography></Typography>
                    ))}
                </Box>
            </Box>
        </Box >

    )
}

export default Aboutmnp;
