import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import useUser from "hooks/useUser";
import React from "react";
import MNPLogo from "assets/images/crown_logo.svg";
import email from "assets/icons/identity/email.svg";
import phone from "assets/icons/identity/phone.svg";
// import message from "assets/icons/identity/message.svg";
import './contact.css'
import Chat from "ZendexConfig";
import useUserType from "hooks/useUserType";
const ContactSupport = () => {
    const schoolNumber = '+1 (469) 946-9816';
    const number = '+1 (510) 369-5137';
    const user = useUser();
    const userType = user?.userType || "fan";
    const EMAIL = userType == "fan" ? process.env.REACT_APP_EMAIL_FAN_CONTACT_SUPPORT : userType == "athlete" ? process.env.REACT_APP_EMAIL_ATHLETE_CONTACT_SUPPORT : process.env.REACT_APP_EMAIL_School_CONTACT_SUPPORT;
    const [contactNumber, setContactNumber] = React.useState('call Us');
    const hrefPhoneNumber = userType == "school_faculty" ? schoolNumber : number;

    const onClickCallUs = () => {
        if (userType == "school_faculty") {
            setContactNumber(schoolNumber);
        } else {
            setContactNumber(number);
        }
    }
    const href = `mailto:${EMAIL}?subject=General Support`;
    const userTypeInfo = useUserType();
    return (
        <Box>
            <Typography color={'#202020'} className="mainheader" fontSize={'20'} fontWeight={'600'}>
                contact support
            </Typography>
            <Typography color={'#808080'}>
                Submit a support request to myNILpay
            </Typography>
            <Box className="conatactSupportHeader">
                <img src={MNPLogo} alt="myNILpay logo" className="conatactSupport_img" />
            </Box>
            <Chat />
            <br />
            <br />
            {userTypeInfo === "school_faculty" ? '' :
                <Typography className="contactHeader">
                    Other ways to contact us
                </Typography>}
            <a href={href}> <Box className="conactusButton">
                <img src={email} width={20} height={20} className="imgIcon" />
                <Typography className="contactUsText">
                    Email Us
                </Typography>
            </Box></a >
            <a href={`tel:${hrefPhoneNumber}`} onClick={() => onClickCallUs()}>
                <Box className="mobileButton">
                    <img src={phone} width={20} height={20} className="imgIcon" />

                    <Typography className="contactUsText">
                        <u>{contactNumber}</u> </Typography>
                </Box>
            </a>
        </Box >

    )
}

export default ContactSupport;
