import React, { useEffect } from "react";
import { format } from "date-fns";
import { NFT_DETAILS, NFT_Sponsor } from "queries";
import { NftDetailsResponse } from "types";
import { ReactComponent as Close } from "assets/icons/close.svg";
import { useLazyQuery } from "@apollo/client";
import { useNotifications } from "providers/Notification";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { Button, CircularProgress } from "@mui/material";
import { formatCurrency, insertApiResponseDataDog, insertApiResponseDataDogError } from "utils";

interface Props {
  fanMemorabiliaId: string | null;
  transactionType: string | null;
  onClose: () => void;
}

const NftSideNav = ({ fanMemorabiliaId, transactionType, onClose }: Props) => {
  const notify = useNotifications();
  const [getNft, { data, loading }] = useLazyQuery<NftDetailsResponse>(
    NFT_DETAILS,
    {
      onError: (error) => {
        notify.error("Something went wrong. Try again later");
        insertApiResponseDataDog("nftApi(error)", error, "error")
        insertApiResponseDataDogError(error, "nftApi(error)");
      },
      onCompleted(data) {
        insertApiResponseDataDog("nftApi(success)", data, "info")
      },
    }
  );
  const [getSponsorNft, { data: SponsorData, loading: SponsorLoading }] = useLazyQuery(NFT_Sponsor);

  useEffect(() => {
    if (fanMemorabiliaId) {
      if (transactionType === "Sponsorship") {
        getSponsorNft({ variables: { fanMemorabiliaId } });
      } else {
        getNft({ variables: { fanMemorabiliaId } });
      }
    }
  }, [fanMemorabiliaId, transactionType]);

  const handleClick = () => {
    if (fanMemorabiliaId && transactionType !== "Sponsorship") {
      window.open(`/invoice/${data?.nft?.transaction?.id}`, `_blank`);
    }
    if (transactionType === "Sponsorship" && fanMemorabiliaId) {
      window.open(`/invoice/${SponsorData?.nftSponsorship?.id}`, `_blank`);
    }
  };
  return (
    <Drawer anchor={"right"} open={fanMemorabiliaId !== null} onClose={onClose}>
      <Box width={"400px"} padding={"32px"}>
        <Stack
          direction={"row"}
          spacing={10}
          alignItems={"center"}
          justifyContent="space-between"
          marginBottom={2}
        >
          <Typography fontSize={14} fontWeight={600}>
            Receipt Details
          </Typography>
          <IconButton aria-label="close nft" onClick={onClose}>
            <Close />
          </IconButton>
        </Stack>
        {loading && <CircularProgress />}
        {(data && transactionType === "Fan") ? (
          <>
            <img alt={`nft`} src={data?.nft.imageUrl} width={"340px"} />
            <Stack spacing={4} marginTop={4}>
              <Box>
                <Typography
                  fontSize={12}
                  fontWeight={500}
                  color={"text.secondary"}
                >
                  Transaction Date
                </Typography>
                <Typography>
                  {data?.nft.createdAt &&
                    format(new Date(data?.nft.createdAt), "MMMM d, yyyy")}
                </Typography>
              </Box>
              <Box>
                <Typography
                  fontSize={12}
                  fontWeight={500}
                  color={"text.secondary"}
                >
                  Transaction Amount
                </Typography>
                <Typography>
                  {formatCurrency(data?.nft.transaction.amount)}
                </Typography>
              </Box>
              <Box>
                <Typography
                  fontSize={12}
                  fontWeight={500}
                  color={"text.secondary"}
                >
                  Transaction ID
                </Typography>
                <Typography>

                  {data?.nft.transaction.paymentGatewayTransactionId}
                </Typography>
              </Box>
              <Button
                variant="outlined"
                onClick={handleClick}
                component={"a"}
                target="_blank"
              >
                View Transaction Details
              </Button>
            </Stack>
          </>
        ) :
          (SponsorData && transactionType === "Sponsorship") ? (
            <>
              <img alt={`nft`} src={SponsorData?.nftSponsorship.imageUrl} width={"340px"} />
              <Stack spacing={4} marginTop={4}>
                <Box>
                  <Typography
                    fontSize={12}
                    fontWeight={500}
                    color={"text.secondary"}
                  >
                    Transaction Date
                  </Typography>
                  <Typography>
                    {SponsorData?.nftSponsorship?.createdAt &&
                      format(new Date(SponsorData?.nftSponsorship?.createdAt), "MMMM d, yyyy")}
                  </Typography>
                </Box>
                <Box>
                  <Typography
                    fontSize={12}
                    fontWeight={500}
                    color={"text.secondary"}
                  >
                    Transaction Amount
                  </Typography>
                  <Typography>
                    {formatCurrency(SponsorData?.nftSponsorship?.amount)}
                  </Typography>
                </Box>
                <Box>
                  {/* <Typography
                    fontSize={12}
                    fontWeight={500}
                    color={"text.secondary"}
                  >
                    Transaction ID
                  </Typography> */}
                  {/* <Typography>

                  {data?.nft.transaction.paymentGatewayTransactionId}
                </Typography> */}
                </Box>
                {/* <Button
                  variant="outlined"
                  onClick={handleClick}
                  component={"a"}
                  target="_blank"
                >
                  View Transaction Details
                </Button> */}
              </Stack>
            </>) : ""
        }
      </Box>
    </Drawer>
  );
};

export default NftSideNav;
