/* eslint-disable @typescript-eslint/no-explicit-any */

import React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import ModalWrapper from "../TermsandConditionsWrapper";
import { useMutation } from "@apollo/client";
import { CircularProgress } from "@mui/material";
import { termsAccepted } from "queries/terms";
import { apolloClient } from "providers/Apollo";
import { insertApiResponseDataDog, insertApiResponseDataDogError } from "utils";
interface Props {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;

}
export function SSOModel({ open, setOpen }: Props) {
  const [isChecked, setIsChecked] = React.useState(true)
  const [termsAccepedApi, { loading }] = useMutation(
    termsAccepted, {
    onCompleted(data) {
      insertApiResponseDataDog("updateTermsAcceptedApi(success)", data, "info");
    },
    onError(error) {
      insertApiResponseDataDog("updateTermsAcceptedApi(error)", error, "error");
      insertApiResponseDataDogError(error, "updateTermsAcceptedApi(error)");
    },
  }
  );
  if (loading) { return <CircularProgress />; }
  const CloseAgree = async () => {
    termsAccepedApi();
    setOpen(false)
    apolloClient.cache.reset();
  }
  const checkBoxHandler = () => {
    setIsChecked(!isChecked)
  }
  return (
    <ModalWrapper open={open} setOpen={setOpen} dark>
      <>
        <Box marginBottom={4}>
          <Box>
            <Typography fontSize={24} fontWeight={600}>
              <u>Terms and  Conditions</u>
            </Typography>

          </Box>

          <Box margin="1rem auto">
            <Box sx={{ float: 'left', width: '10%' }}>
              <input type="checkBox" onChange={checkBoxHandler} />
            </Box>
            <Box sx={{ float: 'right', width: "90%", color: '#202020', textAlign: 'left' }}>
              <Typography fontSize={12} fontWeight={300}>
                Check here to indicate that you have read and agree to the myNILpay <span onClick={() => { window.open('https://www.mynilpay.org/termsandconditions', '_blank'); }} style={{ color: '#471BDA', fontWeight: 500, cursor: 'pointer' }}>Terms and Conditions</span></Typography>
            </Box>
          </Box>
        </Box>


        <Button className="primaty_btn_custom"
          onClick={CloseAgree}
          color="secondary"
          variant="contained"
          disabled={isChecked}
          sx={{ marginTop: 20, backgroundColor: '#BDA9FE !important' }}

        >
          I Agree
        </Button>
      </>
    </ModalWrapper >
  );
}
