import React, { useState } from "react";
import { useMutation } from "@apollo/client";
import { forgotPasswordArgs, forgotPasswordResponse } from "types";
import { useNotifications } from "providers/Notification";
import { FORGOT_PASSWORD } from "queries";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import mnpLogo from "assets/images/mnp_logo.svg";
import ResetPassword from "./ResetPassword";
import SubmitRequest from "./SubmitRequest";
import { insertApiResponseDataDog, insertApiResponseDataDogError } from "utils";

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const notify = useNotifications();

  const [forgotPassword] = useMutation<
    forgotPasswordResponse,
    forgotPasswordArgs
  >(FORGOT_PASSWORD);

  const sendCode = async (userEmail: string) => {
    try {
      const response = await forgotPassword({
        variables: { email: userEmail },
      });
      insertApiResponseDataDog("forgotPasswordApi(success)", response, "info")

      if (response.data?.forgotPassword) {
        return true;
      } else {
        notify.error("Something went wrong. Try again later");
      }
    } catch (error) {
      insertApiResponseDataDogError(error, "forgotPasswordApi(error)");
      insertApiResponseDataDog("forgotPasswordApi(error)", error, "error")
      notify.error(error);
    }
  };

  return (
    <Grid container spacing={2} justifyContent="center">
      <Grid item xs={12}>
        <Box textAlign="left" marginTop={8} marginLeft={{ xs: 6, md: 15 }}>
          <img src={mnpLogo} alt="myNILpay logo" />
        </Box>
      </Grid>

      <Grid item xs={10} md={4} lg={3} marginTop={8}>
        {!email && <SubmitRequest setEmail={setEmail} sendCode={sendCode} />}
        {email && <ResetPassword email={email} sendCode={sendCode} />}
      </Grid>
    </Grid>
  );
};

export default ForgotPassword;
