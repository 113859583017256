/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from "react";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { useForm } from "react-hook-form";
import TextField from "@mui/material/TextField";
import { format } from "date-fns";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { Button, CircularProgress } from "@mui/material";
import { useMutation } from "@apollo/client";
import { ADMIN_ImageEdit } from "../../../queries";
import { ImageEditResponse, ImageEditArgs } from "../../../types"
import { useNotifications } from "../../../providers/Notification";
import { apolloClient } from "../../../providers/Apollo";
import useSingleData from "hooks/useSingleData";
import { useLocation, useNavigate } from 'react-router-dom';
import Alert from '@mui/material/Alert';
import { insertApiResponseDataDog, insertApiResponseDataDogError } from "utils";

interface FormSumbitValues {
    imageUrl: string;
    screenValue: string;
    startDate: string;
    endDate: string;
    id: string;
    editGroupValue: string;
}
function BasicAlerts() {
    return (
        <Stack sx={{ width: '100%' }} spacing={2}>
            <Alert severity="success">This is a success alert — check it out!</Alert>
        </Stack>
    );
}

function ImageEdit() {
    const navigate = useNavigate();
    const notify = useNotifications();
    const [editStartDate, setEditStartDate] = React.useState("");
    const [editEndDate, setEditEndDate] = React.useState("");
    const [errText, setErrText] = React.useState('');
    const [editImageUrl, setEditImageUrl] = useState<string>('');
    const [editGroupValue, setEditGroupValue] = useState<string>('');
    const [editScreenValue, setEditScreenValue] = useState<string>('');

    const location = useLocation();
    const imageid: any = location.state
    const imageInfo = imageid?.id
    const data = useSingleData(imageid);
    useEffect(() => {
        if (data != undefined) {
            setEditScreenValue(data.splashImageDetails.screen || '')
            setEditStartDate(data.splashImageDetails.startDate || '')
            setEditEndDate(data.splashImageDetails.expiryDate || '')
            setEditImageUrl(data.splashImageDetails.imageUrl || '')
            setEditGroupValue(data.splashImageDetails.group || '')
        }
    }, [data])
    const form = useForm<FormSumbitValues>({
        defaultValues: {
            imageUrl: "",
            startDate: "",
            endDate: "",
            screenValue: "",
            editGroupValue: "",
        },
        mode: "all",
    });
    const { isDirty, errors, isSubmitting } = form.formState;
    const { reset } = useForm();
    const [UploadImage] = useMutation<ImageEditResponse, ImageEditArgs>(
        ADMIN_ImageEdit, {
        onCompleted(data) {
            insertApiResponseDataDog("addorUpdateSplashImageDetailsApi(success)", data, "info")
        },
        onError(error) {
            insertApiResponseDataDogError(error, "addorUpdateSplashImageDetailsApi(error)");
            insertApiResponseDataDog("addorUpdateSplashImageDetailsApi(error)", error, "error")

        }
    }
    );
    const onSubmit = async () => {
        try {
            await UploadImage({
                variables: {
                    imageUrl: editImageUrl,
                    startDate: editStartDate,
                    expiryDate: editEndDate,
                    screen: editScreenValue,
                    id: imageInfo,
                    group: editGroupValue
                },
            });
            BasicAlerts()
            notify.success("File Updated Successfully")
            apolloClient.cache.reset();

        } catch (error) {

            notify.error("Upload Failed");
        }
        navigate('/imageupload')
    };

    const hadleChangeStartDate = (newValue: string | number | Date) => {
        setEditStartDate(format(new Date(newValue), "yyyy-MM-dd"));
        setErrText("")

    }
    const handleChangeEndDate = (newValue: string | number | Date) => {
        setEditEndDate(format(new Date(newValue), "yyyy-MM-dd"));
        setErrText("")
    }
    function screenHandler(e: { target: { value: React.SetStateAction<string>; }; }) {
        if (e.target.value) {
            setEditScreenValue(e.target.value)
        }
    }
    function groupHandler(e: { target: { value: React.SetStateAction<string>; }; }) {
        if (e.target.value) {
            setEditGroupValue(e.target.value)
        }
    }
    return (<form onSubmit={form.handleSubmit(onSubmit)}>
        <Stack direction="column" alignItems="flex-start" spacing={1}>
            <Typography marginBottom={2}>
                Update Image Data
            </Typography>
            <div>
                <img src={editImageUrl} width="150" height="100" />
                <img src={data?.splashImageDetails.carouselImage} width="150" height="100" />
            </div>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopDatePicker
                    inputFormat={"MM/DD/YYYY"}
                    disabled={isSubmitting}
                    value={editStartDate || null}
                    onChange={(newValue: any) => {
                        hadleChangeStartDate(newValue)
                        form.setValue('startDate', format(new Date(newValue), "yyyy-MM-dd"), { shouldValidate: true })
                    }}
                    renderInput={(params: any) => {
                        return <TextField label="Start Date" inputProps={{
                            placeholder: "MM/DD/YYYY", "data-testid": "startDate",
                            readOnly: true, value: params?.inputProps.value
                        }} InputProps={params.InputProps}
                            inputRef={params.inputRef}
                            fullWidth
                            InputLabelProps={{
                                shrink: true,
                            }}
                            helperText={(isDirty && errors.startDate?.message || errText) || " "}
                        />
                    }}
                />
            </LocalizationProvider>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopDatePicker
                    inputFormat={"MM/DD/YYYY"}
                    disabled={isSubmitting}
                    value={editEndDate || null}
                    minDate={format(new Date(), "yyyy-MM-dd")}
                    onChange={(newValue: any) => {
                        handleChangeEndDate(newValue)
                        form.setValue('endDate', format(new Date(newValue), "yyyy-MM-dd"),)
                    }}
                    renderInput={(params: any) => {
                        return <TextField
                            label="Expire Date" inputProps={{
                                placeholder: "MM/DD/YYYY", "data-testid": "endDate",
                                readOnly: true, value: params?.inputProps.value
                            }} InputProps={params.InputProps}
                            inputRef={params.inputRef}
                            fullWidth
                            InputLabelProps={{
                                shrink: true,
                            }}
                            helperText={(isDirty && errors.endDate?.message) || " "}
                        />
                    }}
                />
            </LocalizationProvider>
            <TextField
                autoFocus
                margin="dense"
                disabled={isSubmitting}
                value={editScreenValue || ''}
                inputProps={{ "data-testid": "Screen", }}
                label="Screen:"
                InputLabelProps={{
                    shrink: true,
                }}
                type="text"
                fullWidth
                error={Boolean(isDirty && errors.screenValue)}
                onChange={screenHandler}
            />
            <TextField
                autoFocus
                margin="dense"
                disabled={isSubmitting}
                value={editGroupValue || null}
                inputProps={{ "data-testid": "Group", }}
                label="Group:"
                InputLabelProps={{
                    shrink: true,
                }}
                type="text"
                fullWidth
                error={Boolean(isDirty && errors.editGroupValue)}
                onChange={groupHandler}
            />
            <Button
                startIcon={isSubmitting ? <CircularProgress size={20} /> : null}
                data-testid="submitButton"
                type="submit"
                variant="contained"
                onClick={() => {
                    reset(({
                        defaultValues: {
                            imageUrl: "",
                            startDate: "",
                            endDate: "",
                            screenValue: ""
                        },
                        mode: "all",
                    }))
                }}
            >
                Submit
            </Button>
            <div>
            </div>
        </Stack>
    </form>)

}

export default ImageEdit