import React, { useEffect } from "react";
import { Auth } from "aws-amplify";
import { SignInUserSession } from "types";
import { useNavigate } from "react-router-dom";
import { useNotifications } from "providers/Notification";
import { insertApiResponseDataDog, insertApiResponseDataDogError } from "utils";

export const SSO = () => {
  const navigate = useNavigate();
  const notify = useNotifications()

  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then((response) => {
        insertApiResponseDataDog("AuthcurrentAuthenticatedUserApi(success)", response, "info")

        const signInUserSession: SignInUserSession =
          response?.signInUserSession;
        const token = signInUserSession?.idToken?.jwtToken;
        localStorage.setItem("token", token);
        navigate("/home");

      })
      .catch((error) => {
        // NOTE: there is a known issue with Cognito SSO
        // the first time a user registers will always throw an error
        // the work around is to call again to bypass this error
        // The following ticket has all the information about it
        // https://mynilpay.atlassian.net/browse/MNP-227
        insertApiResponseDataDogError(error, "AuthcurrentAuthenticatedUserApi(error)");
        insertApiResponseDataDog("AuthcurrentAuthenticatedUserApi(error)", error, "error")
        if (error == "The user is not authenticated") {
          notify.error(".edu email id's are not accepted through social login and signup.");
          navigate('/');
        }
      });
  }, []);

  return <></>;
};
