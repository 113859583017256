/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Checkbox from '@mui/material/Checkbox';
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { Button, CircularProgress, Stack, TextField, TextFieldProps, Typography, useMediaQuery } from '@mui/material';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import axios from 'axios'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { format } from 'date-fns';
import dayjs from 'dayjs';
import { Pagination } from '@mui/material';
import { schoolRecords } from 'queries/SchoolReports';
import { ApolloError, useLazyQuery } from '@apollo/client';
import { insertApiResponseDataDog, insertApiResponseDataDogError } from 'utils';
import moment from 'moment';
import { useNotifications } from 'providers/Notification';
import { SchoolsResponse } from 'types';
import { SEARCH_ALL_SCHOOLS } from 'queries';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
export default function Reports() {
  const isMobile = useMediaQuery("(max-width:600px)");
  const [schoolsList, setSchoolsList] = React.useState<any>([])
  const [schoolId, setSchoolId] = React.useState<any>([]);
  const [searchSchool, { loading: schoolLoader }] = useLazyQuery<SchoolsResponse>(SEARCH_ALL_SCHOOLS, {
    onCompleted(data) {
      insertApiResponseDataDog("SearchSchoolsApi(success)", data, "info");
    },
    onError(error) {
      insertApiResponseDataDogError(error, "SearchSchoolsApi(error)");
      insertApiResponseDataDog("SearchSchoolsApi(error)", error, "error");
    },
  });

  const [page, setPage] = React.useState(1);

  const notify = useNotifications();
  const [month, setMonth] = React.useState<any>('');
  const [year, setYear] = React.useState<any>('');
  const [LoginUserObject] = React.useState(JSON.parse(localStorage.getItem('LoginUserObject') || ""))
  const [date, setDate] = React.useState<any>(
    dayjs(new Date()),
  );
  const currentdate = new Date();
  const currentMonth = currentdate.getMonth() + 1;
  const currentYear = currentdate.getFullYear();
  const currentDay = currentdate.getDate();

  const [getSchoollsReports, { data, loading }] = useLazyQuery(
    schoolRecords,
    {
      onError: (error: ApolloError) => {
        insertApiResponseDataDog("getSchoolTxsApi(error)", error, "info");
        notify.error(error.message);
        insertApiResponseDataDogError(error, "getSchoolTxsApi(error)");

      },
      onCompleted: (data) => {
        insertApiResponseDataDog("getSchoolTxs(success)", data, "info")
      },
      fetchPolicy: "no-cache"

    }
  );

  const handleSearch = async (value: any) => {
    // setSchoolName(schoolName)
    const res = await searchSchool({
      variables: {
        searchString: value
      }
    }
    )
    setSchoolsList(res?.data?.searchSchools?.items)
  };

  React.useEffect(() => {
    if (date) {
      const month = format(new Date(date), "MM");
      const year = format(new Date(date), "yyyy");
      localStorage.setItem('schoolYear', year)
      localStorage.setItem('schoolMonth', month)
      setMonth(month)
      setYear(year)
      getSchoollsReports({
        variables: {
          limit: 5,
          page: 1,
          year: Number(year),
          month: Number(month),
          school: schoolId
        }
      })
    }
  }, [date]);

  const handleChangePage = (event: unknown, newPage: number) => {
    listOfAllSchoolRports(schoolId, newPage)
    setPage(newPage);
  };

  const downloadPdf = () => {
    const res = process.env.REACT_APP_SERVER_URI?.replace('/graphql', '');
    axios({
      url: `${res}/bluesnap/school-report?m=${month}&y=${year}&school=${schoolId.join(',')}`,
      method: 'GET',
      responseType: 'blob', // Important
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('token') // Replace with your authorization token
      }
    })
      .then((response: { data: BlobPart; }) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `ComplianceReports-${month}-${year}.pdf`);
        document.body.appendChild(link);
        link.click();
      })
      .catch((error: any) => {
        console.error('Error occurred while downloading the PDF file:', error);
      });
  }

  const handleChangeDate = (newValue: any | null) => {
    if (newValue) {
      setDate(newValue)
      localStorage.setItem('schoolYear', format(new Date(newValue), "yyyy"))
      localStorage.setItem('schoolMonth', format(new Date(newValue), "MM"));
      setMonth(format(new Date(newValue), "MM"))
      setYear(format(new Date(newValue), "yyyy"))
      listOfAllSchoolRports(schoolId, page)
    }
  };
  const onkeydown = (e: any) => {
    e.preventDefault();
  }
  const listOfAllSchoolRports = async (school_Ids: any, page: any) => {
    try {
      await getSchoollsReports({
        variables: {
          limit: 5,
          page: page,
          year: Number(localStorage.getItem('schoolYear')),
          month: Number(localStorage.getItem('schoolMonth')),
          school: school_Ids
        }
      })
    } catch (error) {

      notify.error(error);
    }


  }

  const handleSelectionChange = (event: any, newValue: any | null) => {
    if (newValue) {
      const schoolIds = [...(newValue || []).map((item: any) => item.id)];
      listOfAllSchoolRports(schoolIds, page);
      setSchoolId(schoolIds);
    }
  };

  return (
    <>
      <Stack direction={isMobile ? "column" : 'row'} spacing={2} className={isMobile ? 'reportsMainContainerMobilePdf' : 'reportsMainContainerPdf'}>
        <Stack
          direction="row"
          justifyContent="flex-start"
          spacing={2}
          sx={{ width: '100%' }}
        >
          {LoginUserObject.userType === "admin" ?
            <Autocomplete
              multiple
              loading={schoolLoader}
              id="checkboxes-tags-demo"
              options={schoolsList || []}
              disableCloseOnSelect
              getOptionLabel={(option) => option.name}
              onChange={handleSelectionChange}
              renderOption={(props, option, { selected }) => (
                <li {...props} key={option.id}>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected}

                  />
                  {option.name}
                </li>
              )}
              style={{ width: '100%' }}
              renderInput={(params) => (
                <TextField {...params} label="Schools" placeholder="Search Schools" onChange={(e) => handleSearch(e.target.value)}
                />
              )}
            /> : ""
          }
        </Stack>
        <Stack
          direction="row"
          justifyContent={data && data?.getSchoolTxs?.items.length ? "flex-end" : "flex-start"}
          spacing={2}
          sx={{ width: '100%' }}
        >
          <Stack>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DesktopDatePicker
                label="Month and year"
                views={['year', 'month']}
                inputFormat="MM/YYYY"
                value={date}
                onChange={handleChangeDate}
                renderInput={(params: TextFieldProps) => <TextField onKeyDown={onkeydown} {...params} />}
              />
            </LocalizationProvider>
          </Stack>
          {data && data?.getSchoolTxs?.items.length ? <Stack>
            <Button variant="outlined" startIcon={<PictureAsPdfIcon />} style={{
              width: 150,
              height: 55
            }}
              onClick={downloadPdf}
            >
              Download
            </Button>
          </Stack> :
            ""
          }
        </Stack>
      </Stack>

      <Stack
        direction="row"
        justifyContent="flex-start"
        spacing={2} marginBottom={1} marginTop={1}>
        {moment(month, 'MM').format('MMMM')} Report contains transactions as of {moment(month, 'MM').subtract(1, 'months').format('MMMM')}

      </Stack>
      <Stack>
        <Paper className='reportsMainContainer' sx={{ ...(isMobile ? { width: '100%', position: 'absolute', marginLeft: '0px !important' } : {}) }}>
          {loading ? <CircularProgress /> : <><TableContainer sx={{ maxHeight: 440 }}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <TableCell>
                    School Name
                  </TableCell>
                  <TableCell>
                    Athlete Name
                  </TableCell>
                  <TableCell>
                    Sport Name
                  </TableCell>
                  <TableCell>
                    Amount
                  </TableCell>
                  <TableCell>
                    Transaction Date
                  </TableCell>
                  <TableCell>
                    Fan Name
                  </TableCell>
                  <TableCell>
                    Fan Domain
                  </TableCell>

                </TableRow>
              </TableHead>
              <TableBody>
                {data?.getSchoolTxs.items?.map((row: any, key: any) => {
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={key}>
                      <TableCell>
                        {row.universityName}
                      </TableCell>
                      <TableCell>
                        {row.athleteName}
                      </TableCell>
                      <TableCell>
                        {row.sportName}
                      </TableCell>
                      <TableCell>
                        {row.amount}
                      </TableCell>
                      <TableCell>
                        {row.transactionDate}
                      </TableCell>
                      <TableCell>
                        {row.fanName}
                      </TableCell>
                      <TableCell>
                        {row.fanDomain}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
            {data && data?.getSchoolTxs?.items.length === 0
              ?
              ((moment(moment().format('MM/YYYY'), "MM/YYYY").isAfter(moment(`${month}/${year}`, "MM/YYYY"))
                || (year == currentYear && month == currentMonth && currentDay >= 10))
                ?
                <Typography fontWeight={300} marginTop={5}>
                  There are no transactions for the month you selected
                </Typography>
                : <Typography fontWeight={300} marginTop={5}>
                  The report month you selected {moment(month, 'MM').format('MMMM')} {year} will be available on the 10th of {moment(month, 'MM').format('MMMM')} {year}
                </Typography>
              )
              : ''
            }
            <Pagination count={Math.ceil(data?.getSchoolTxs.itemTotal / 5) || 0} page={page} onChange={handleChangePage} /></>}
        </Paper>
      </Stack>
    </>
  );
}


