/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import {
  ApolloQueryResult,
  OperationVariables,
  useMutation,
} from "@apollo/client";
import { MeResponse, UPDATE_USER } from "queries";
import { useNotifications } from "providers/Notification";
import { formatPhoneNumber, insertApiResponseDataDog, insertApiResponseDataDogError } from "utils";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import moment from "moment";
import { differenceInDays, differenceInYears, format, isValid, parse } from "date-fns";

interface PersonalInfo {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  dob: string;
  zipCode: string;
  phoneNumber: string;
  refetch: (
    variables?: Partial<OperationVariables> | undefined
  ) => Promise<ApolloQueryResult<MeResponse>>;

}

interface FormValues {
  firstName: string;
  lastName: string;
  dob: string;
  zipCode: string;
  phoneNumber: string;

}


function Form({ firstName, lastName, email, dob, zipCode, phoneNumber, refetch }: PersonalInfo) {
  const currentDate = new Date();
  const DateValidation = currentDate.setFullYear(currentDate.getFullYear() - 18);
  const minDateValidation = currentDate.setFullYear(currentDate.getFullYear() - 87);
  const notify = useNotifications();
  const tempDisplay = dob.split("-").reverse().join("/")
  const tempBackend = dob.split("-").reverse().join("-")
  const [dateValue, setDateValue] = React.useState<unknown>(tempBackend);
  const [dateCheck, setDateCheck] = useState(false)
  const minformateDate = moment(minDateValidation).format('MM/DD/YYYY');
  const formateDate = moment(DateValidation).format('yyyy-MM-DD');
  const [updateUserInfo] = useMutation(UPDATE_USER);
  const [error, setError] = useState(false);
  const [ageError, setAgeError] = useState(false);
  const [check, setcheck] = useState(false);
  const [maxAgeError, setMaxAgeError] = useState(false);
  const { register, handleSubmit, formState, reset, setValue } = useForm<FormValues>({
    defaultValues: {
      firstName,
      lastName,
      dob: tempDisplay,
      zipCode,
      phoneNumber,
    },
    mode: "all",
  });
  const [phone, setPhone] = useState(phoneNumber);
  const [zip, setZip] = useState(zipCode)
  useEffect(() => {
    reset({ firstName, lastName });
  }, [firstName, lastName, zipCode, phoneNumber]);

  const { isDirty, errors, isSubmitting } = formState;
  const onSubmit = async (form: FormValues) => {
    setcheck(false)
    let Tempdob = ""
    if (dateCheck) {
      Tempdob = moment(form.dob).format('DD-MM-YYYY');
    } else {
      Tempdob = moment(tempBackend).format('DD-MM-YYYY');
    }
    try {
      const result = await updateUserInfo({
        variables: {
          firstName: form.firstName,
          lastName: form.lastName,
          dob: Tempdob,
          zipCode: form.zipCode,
          phoneNumber: form.phoneNumber
        },
      });
      if (result) {
        notify.success("Sucessfully Updated");
        refetch();
        insertApiResponseDataDog("updateUserInfoApi(success)", result, "info")
      } else {
        notify.error("Something went wrong. Try again later");
      }
    } catch (error: any) {
      insertApiResponseDataDogError(error, "updateUserInfoApi(error)");
      insertApiResponseDataDog("updateUserInfoApi(error)", error, "error")
      notify.error(error);
    }
    setDateCheck(false)
  };
  const handleChangePhoneNumber = (e: any) => {
    setcheck(true)
    const arr = Array.from(e.target.value)
    if (arr[0] !== "0") {
      const formattedPhoneNumber = formatPhoneNumber(e.target.value);
      setPhone(formattedPhoneNumber);
    }
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleChangeZip = (e: any) => {

    const regex = /^[0-9\b]+$/;
    if (e.target.value === "" || regex.test(e.target.value)) {
      if (e.target.value.length <= 5) {
        setZip(e.target.value);
        setcheck(true)
      }
    }
  }

  const handleMaxDateChange = (newValue: Date | null) => {
    setDateValue(newValue);

    if (newValue) {
      const currentDate = new Date();
      const age = differenceInDays(currentDate, newValue);
      const years = 105;
      const daysInYear = 365.25;
      const days = years * daysInYear;
      if (age > days) {
        setMaxAgeError(true);
      } else {
        setMaxAgeError(false);
      }
    } else {
      setMaxAgeError(false);
    }
  };
  const handleDateChange = (newValue: Date | null) => {
    setDateValue(newValue);
    if (newValue) {
      const currentDate = new Date();
      const age = differenceInYears(currentDate, newValue);

      if (age < 18) {
        setAgeError(true);
      } else {
        setAgeError(false);
      }
    } else {
      setAgeError(false);
    }
  };

  const handleManualDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setcheck(true)
    const inputDate = event.target.value;
    const parsedDate = parse(inputDate, 'MM/dd/yyyy', new Date());

    // if (inputDate.length === 10 && isValid(parsedDate)) {
    if (inputDate.length === 10 && isValid(parsedDate)) {
      setDateCheck(true)
      setDateValue(parsedDate);
      setError(false);
      handleDateChange(parsedDate);
      handleMaxDateChange(parsedDate)
    } else {
      setDateValue(null);
      setError(true);
      handleDateChange(null);
      handleMaxDateChange(null)
    }
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack direction="column" alignItems="flex-start" spacing={2}>
        <TextField
          margin="dense"
          inputProps={{ "data-testid": "firstName" }}
          label="First Name"
          placeholder="Enter First Name"
          InputLabelProps={{
            shrink: true,
          }}
          type="text"
          fullWidth
          autoComplete="none"
          error={Boolean(isDirty && errors.firstName)}
          disabled={isSubmitting}
          helperText={(isDirty && errors.firstName?.message) || " "}
          {...register("firstName", {
            required: "First Name is required",
          })}
          onChange={() => setcheck(true)}
        />
        <TextField
          margin="dense"
          inputProps={{ "data-testid": "lastName" }}
          label="Last Name"
          placeholder="Enter Last Name"
          InputLabelProps={{
            shrink: true,
          }}
          type="text"
          fullWidth
          autoComplete="none"
          error={isDirty && Boolean(errors.lastName)}
          disabled={isSubmitting}
          helperText={(isDirty && errors.lastName?.message) || " "}
          {...register("lastName", { required: "Last Name is required" })}
          onChange={() => setcheck(true)}
        />
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DesktopDatePicker
            inputFormat={"MM/DD/YYYY"}
            disabled={isSubmitting}
            value={dateValue}
            minDate={minformateDate}
            maxDate={formateDate}
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            onChange={(newValue: any) => {
              localStorage.setItem("fanBirthDate", newValue)
              setValue('dob', format(new Date(newValue), "MM/dd/yyyy"), { shouldValidate: true, shouldTouch: true })
              setDateCheck(true)
              setcheck(true)
              setDateValue(newValue);
              setError(false);
              setMaxAgeError(false);
              setAgeError(false);
            }}
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            renderInput={(params: any) => {
              return <TextField error={isDirty && Boolean(errors.dob) || error || ageError || maxAgeError} label="Birthday" inputProps={{
                placeholder: "MM/DD/YYYY", "data-testid": "dob",
                readOnly: false
              }} InputProps={params.InputProps}
                onError={() => isDirty && Boolean(errors.dob)}
                inputRef={params.inputRef}
                {...register("dob", {
                  required: "Date of birth is required",
                })}
                fullWidth
                autoComplete="none"
                disabled={isSubmitting}
                InputLabelProps={{
                  shrink: true,
                }}
                //helperText={(isDirty && errors.birthday?.message) || error ? 'Date should be MM\DD\YYYY' : ''}
                helperText={(isDirty && errors.dob?.message) || error ? ' Date format should be MM/DD/YYYY' : ageError ? ' Must be at least 18 years old' : maxAgeError ? ' Age should not be greater than 105 years' : ''}
                onChange={handleManualDateChange}
              />
            }}
          />
        </LocalizationProvider>
        {/* <div> sssss {dateValue}</div> */}
        <TextField
          value={email}
          disabled
          margin="dense"
          inputProps={{ "data-testid": "email" }}
          label="Email"
          placeholder="Enter email"
          InputLabelProps={{
            shrink: true,
          }}
          type="text"
          fullWidth
          autoComplete="none"
          helperText={" "}
        />
      </Stack>
      <TextField
        margin="dense"
        value={zip}
        inputProps={{ "data-testid": "zipcode" }}
        label="Zip Code"
        placeholder="Enter Zip Code"
        InputLabelProps={{
          shrink: true,
        }}
        type="text"
        fullWidth
        autoComplete="none"
        error={Boolean(isDirty && errors.zipCode)}
        disabled={isSubmitting}
        helperText={(isDirty && errors.zipCode?.message) || " "}
        {...register("zipCode", {
          required: "zipCode is required",
          minLength: { value: 5, message: "Please enter 5 minimum digits number" }
        })}
        onChange={(e) => handleChangeZip(e)}
      />
      <TextField
        margin="dense"
        value={phone || ''}
        inputProps={{ "data-testid": "phonenumber" }}
        label="phone Number"
        placeholder="Enter phone Number"
        InputLabelProps={{
          shrink: true,
        }}
        type="text"
        fullWidth
        autoComplete="none"
        error={Boolean(isDirty && errors.phoneNumber)}
        helperText={(isDirty && errors.phoneNumber?.message) || " "}
        {...register("phoneNumber", {
          minLength: {
            value: 12,
            message: "Phone number should be 10 digits"
          },
        })}
        onChange={(e) => handleChangePhoneNumber(e)}
      />

      <Button
        startIcon={isSubmitting ? <CircularProgress size={20} /> : null}
        data-testid="submitButton"
        type="submit"
        variant="contained"
        fullWidth
        disabled={!isValid || !isDirty || isSubmitting || error || ageError || maxAgeError || !check}
      >
        Save Changes
      </Button>
    </form>
  );
}

export default Form;
