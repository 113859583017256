import Box from "@mui/material/Box";
import React from "react";
import Header from "components/Header";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
const PayoutInfo = () => {
  return (
    <Box className="ath-personal-details">
      <Header text="Payout info" />
      <Box sx={{ mt: 4 }} textAlign={"left"} className="personal-details__form">
        <form>
          <Stack sx={{ mt: 2 }} direction="column" alignItems="flex-start">
            <TextField
              autoFocus
              margin="dense"
              data-testid="PayoutType"
              label="Payout Type"
              placeholder="Payout Type"
              InputLabelProps={{
                shrink: true,
              }}
              type="text"
              fullWidth
              autoComplete="none"
            />
            <TextField sx={{ mt: 3 }}
              autoFocus
              margin="dense"
              data-testid="BankAccountType"
              label="Bank Account Type"
              placeholder="Bank Account Type"
              InputLabelProps={{
                shrink: true,
              }}
              type="text"
              fullWidth
              autoComplete="none"
            />
            <TextField sx={{ mt: 3 }}
              autoFocus
              margin="dense"
              data-testid="NameOnAccount"
              label="Name On Account"
              placeholder="Name On Account"
              InputLabelProps={{
                shrink: true,
              }}
              type="text"
              fullWidth
              autoComplete="none"
            />
            <TextField sx={{ mt: 3 }}
              autoFocus
              margin="dense"
              data-testid="BankName"
              label="Bank Name"
              placeholder="Bank Name"
              InputLabelProps={{
                shrink: true,
              }}
              type="text"
              fullWidth
              autoComplete="none"
            />
            <TextField sx={{ mt: 3 }}
              autoFocus
              margin="dense"
              data-testid="BankAccountClass"
              label="Bank Account Class"
              placeholder="Bank Account Class"
              InputLabelProps={{
                shrink: true,
              }}
              type="text"
              fullWidth
              autoComplete="none"
            />
            <TextField sx={{ mt: 3 }}
              autoFocus
              margin="dense"
              data-testid="BankId"
              label="Bank Id"
              placeholder="Bank Id"
              InputLabelProps={{
                shrink: true,
              }}
              type="text"
              fullWidth
              autoComplete="none"
            />
            <TextField sx={{ mt: 3 }}
              autoFocus
              margin="dense"
              data-testid="State"
              label="State"
              placeholder="State"
              InputLabelProps={{
                shrink: true,
              }}
              type="text"
              fullWidth
              autoComplete="none"
            />
            <TextField sx={{ mt: 3 }}
              autoFocus
              margin="dense"
              data-testid="City"
              label="City"
              placeholder="City"
              InputLabelProps={{
                shrink: true,
              }}
              type="text"
              fullWidth
              autoComplete="none"
            />
            <TextField sx={{ mt: 3 }}
              autoFocus
              margin="dense"
              data-testid="Address"
              label="Address"
              placeholder="Address"
              InputLabelProps={{
                shrink: true,
              }}
              type="text"
              fullWidth
              autoComplete="none"
            />
            <TextField sx={{ mt: 3 }}
              autoFocus
              margin="dense"
              data-testid="Zip"
              label="Zip"
              placeholder="Zip"
              InputLabelProps={{
                shrink: true,
              }}
              type="text"
              fullWidth
              autoComplete="none"
            />
          </Stack>
        </form>
      </Box>
      <Button sx={{ mt: 8 }}
        type="submit"
        fullWidth
        color={"primary"}
        variant="contained"
      >
        Submit Request
      </Button>
    </Box>
  )
}
export default PayoutInfo;