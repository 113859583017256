import { gql } from "@apollo/client";

export const ALERTS = gql`
query getNotifications($athleteEarningActivities: Boolean!, $deviceType: String!, $sortByDesc: Boolean, $dismissed: String, $page: Int, $limit: Int) {
   getNotifications(athleteEarningActivities: $athleteEarningActivities, deviceType: $deviceType, sortByDesc:$sortByDesc, dismissed:$dismissed, page:$page, limit:$limit) {
   pageNumber,
   pageTotal,
   itemTotal,
   limit,
   items {
   data,
   title,
   createdAt,
   id,
   dismissed,
   },
   }
  }
`;

export const getNotificationsCount = gql`
query getNonDismissedNotificationsCount($deviceType: String!){
  getNonDismissedNotificationsCount(deviceType: $deviceType)

} 
`;
