import { useQuery } from "@apollo/client";
import { FEATURE_FLAGS } from "../queries/features";
import { FeatureFlagsResponse as Response, Features } from "../types";

function useFeature(featureName: Features) {
  const { loading, data, error } = useQuery<Response>(FEATURE_FLAGS);
  const features = data?.featureFlags;

  if (loading || error || !features || !features.length) return null;

  const feature = features.find((f) => f.featureName === featureName);

  if (feature) return feature.enabled;

  return null;
}

export default useFeature;
