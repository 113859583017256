import React, { useState, useEffect } from "react";
import { ApolloError, useLazyQuery } from "@apollo/client";
import { FILTER_DISPLAY } from "constant";
import { ReactComponent as Close } from "assets/icons/close.svg";
import { ReactComponent as DeleteIcon } from "assets/icons/delete.svg";
import { FilterSchoolsResponse, School, FilterOptions } from "types";
import { FILTER_SCHOOL } from "queries";
import { useNotifications } from "providers/Notification";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Chip from "@mui/material/Chip";
import Drawer from "@mui/material/Drawer";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import SearchInput from "components/SearchInput";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import SchoolList from "./SchoolList";
import SportList from "./SportList";
import { insertApiResponseDataDog, insertApiResponseDataDogError } from "utils";

interface FiltersProps {
  values: FilterOptions[];
  showFilter: boolean;
  closeFilter: () => void;
  setFilters: (filters: FilterOptions[]) => void;
}

function FiltersSideNav({
  values,
  showFilter,
  closeFilter,
  setFilters,
}: FiltersProps) {
  const notify = useNotifications();
  const [searchString, setSearchString] = useState("");
  const [selections, setSelections] = useState<FilterOptions[]>(values || []);
  const [searchResults, setSearchResults] = useState<School[]>([]);

  const [searchSchools] = useLazyQuery<FilterSchoolsResponse>(FILTER_SCHOOL, {
    onError: (error: ApolloError) => {
      insertApiResponseDataDogError(error, "filterSchoolsApi(error)");
      insertApiResponseDataDog("filterSchoolsApi(error)", error, "error")
      notify.error(error.message);
    },
    onCompleted: (response) => {
      insertApiResponseDataDog("filterSchoolsApi(success)", response, "info")
      if (response) {
        const result = response.filterSchools.items;
        setSearchResults(result);
      }
    },
  });

  useEffect(() => {
    setSelections(values);
  }, [values]);

  const searchCall = (searchString: string) => {
    setSearchString(searchString);
    if (searchString) {
      searchSchools({
        variables: {
          filterParams: {
            name: searchString,
            orderBy: "name",
          },
        },
      });
    } else {
      setSearchResults([]);
    }
  };

  const handleCheckbox = (filter: FilterOptions) => {
    if (isSelected(filter)) {
      deleteFilter(filter);
    } else {
      addFilter(filter);
    }
  };

  const handleReset = () => {
    setSelections([]);
    setSearchResults([]);
  };

  const isSelected = (filter: FilterOptions) => {
    return Boolean(selections.find((item) => item.id === filter.id));
  };

  const addFilter = (filter: FilterOptions) =>
    setSelections([...selections, filter]);

  const deleteFilter = (filter: FilterOptions) => {
    const filteredList = selections.filter((item) => item.id !== filter.id);
    setSelections([...filteredList]);
  };

  const applyFilters = () => {
    localStorage.setItem("setClearData", "")

    setFilters(selections);
    closeFilter();
  };

  const close = () => {
    closeFilter();
    setSelections(values);
  };

  return (
    <Drawer anchor={"right"} open={showFilter} onClose={close}>
      <Box width={"400px"} maxWidth={"95vw"} margin={1}>
        <Box
          sx={{
            height: "92vh",
            overflow: "scroll",
          }}
        >
          <Stack
            direction={"row"}
            spacing={10}
            alignItems={"center"}
            justifyContent="space-between"
          >
            <IconButton aria-label="close filter" onClick={close}>
              <Close />
            </IconButton>
            <Typography>{`Filters (${selections?.length})`}</Typography>
            <Button onClick={handleReset}>Reset</Button>
          </Stack>

          <SearchInput
            value={searchString}
            onSearch={searchCall}
            placeholder="Search Schools"
            onClear={() => setSearchResults([])}
          />

          {/* selected filters */}
          <Grid
            container
            wrap="wrap"
            spacing={1}
            marginTop={1}
            marginBottom={1}
          >
            {selections?.map((item) => (
              <Grid item key={item.id}>
                <Chip
                  label={item[FILTER_DISPLAY]}
                  deleteIcon={<DeleteIcon aria-label="delete filter" />}
                  onDelete={() => deleteFilter(item)}
                  sx={{
                    borderRadius: "4px",
                    backgroundColor: "primary.dark",
                    color: "white",
                    "& .MuiChip-deleteIcon": {
                      marginRight: "8px",
                    },
                  }}
                />
              </Grid>
            ))}
          </Grid>

          {/* filters search result */}
          <Grid
            container
            wrap="wrap"
            spacing={1}
            marginTop={1}
            marginBottom={1}
          >
            {searchResults.map((item) => {
              const selected = selections.find((x) => x.id === item.id);

              if (selected) return null;

              return (
                <Grid item key={item.id}>
                  <Chip
                    sx={{
                      borderRadius: "4px",
                    }}
                    label={item[FILTER_DISPLAY]}
                    onClick={() => addFilter(item)}
                  />
                </Grid>
              );
            })}
          </Grid>

          <SchoolList isSelected={isSelected} handleCheckbox={handleCheckbox} />
          <SportList isSelected={isSelected} handleCheckbox={handleCheckbox} />
        </Box>
        <Button
          variant="contained"
          onClick={applyFilters}
          sx={{
            margin: "5px",
            position: "absolute",
            bottom: "5px",
            right: "5px",
            left: "5px",
          }}
        >
          Apply
        </Button>
      </Box>
    </Drawer>
  );
}

export default FiltersSideNav;
