import { gql } from "@apollo/client";

export const schoolRecords = gql`
query getSchoolTxs($year:Int!,$month:Int!, $limit: Int = 25, $page: Int = 1,$school: [String!]) {
  getSchoolTxs(year: $year,month:$month, limit: $limit, page: $page,school: $school)
   {pageNumber 
    pageTotal 
    itemTotal 
    limit 
    items{ 
      
     universityName,     
  athleteName,      
  sportName,     
   amount,      
   transactionDate,      
   fanName,      
   fanDomain } 
  }
}
`;