import { ApolloError } from "@apollo/client";
import { useNotifications } from "../providers/Notification";
import { useQuery } from "@apollo/client/react/hooks";
import { ATHLETE_PROFILE } from "../queries";
import { UserProfileResponse } from "../types";

function useUserProfile(athleteId: string) {
  const notify = useNotifications();

  const data = useQuery<UserProfileResponse>(ATHLETE_PROFILE, {
    variables: { id: athleteId },
    onError: (error: ApolloError) => {
      notify.error(error.message || "Something went wrong. Try again later");
    },
  });

  return data;
}

export default useUserProfile;
