import React, { useEffect } from "react";
import { ATHLETE_ONBOARDED } from "../../queries";
import { AthleteOnboardedResponse } from "../../types";
import { useLazyQuery } from "@apollo/client";
import { useNavigate, useSearchParams } from "react-router-dom";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import DoneImage from "../../assets/images/done_all.png";
import Typography from "@mui/material/Typography";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import ModalWrapper from "../ModalWrapper";
import { insertApiResponseDataDog, insertApiResponseDataDogError } from "utils";

function SingleTransaction() {
  const [searchParams] = useSearchParams();
  const id = searchParams.get("id") || null;

  const [getAthleteOnboarded, { data, loading }] = useLazyQuery<AthleteOnboardedResponse>(ATHLETE_ONBOARDED, {
    onError(error) {
      insertApiResponseDataDogError(error, "userApi(error)");

      insertApiResponseDataDog("userApi(error)", error, "error")
    },
    onCompleted(data) {
      insertApiResponseDataDog("userApi(success)", data, "info")
    }
  });

  useEffect(() => {
    if (id) getAthleteOnboarded({ variables: { id } });
  }, [id]);

  const athleteNotOnboarded = id && !data?.user.athleteOnboarded;

  return (
    <>
      {loading ? (
        <Stack spacing={1} marginBottom={4}>
          <Skeleton variant="rectangular" height={70} />
          <Skeleton variant="rectangular" height={70} />
          <Skeleton variant="rectangular" height={70} />
        </Stack>
      ) : (
        <Box marginBottom={4}>
          <Box>
            <Typography fontSize={34} fontWeight={600}>
              CONGRATS!
            </Typography>
            <Typography fontSize={16} fontWeight={500}>
              {athleteNotOnboarded
                ? "Your direct payment request has been sent"
                : "Your direct payment request has been sent and accepted!"}
            </Typography>
          </Box>

          <Box margin="1rem auto">
            <img
              src={DoneImage}
              alt="check mark"
              height={126}
              width={126}
            ></img>
          </Box>
          {athleteNotOnboarded && (
            <Typography fontSize={20} fontWeight={600}>
              You will be notified as soon as the athlete accepts your request.
            </Typography>
          )}
        </Box>
      )}
    </>
  );
}

function MultiTransaction() {
  return (
    <Box marginBottom={4}>
      <Box>
        <Typography fontSize={34} fontWeight={600}>
          THANK YOU
        </Typography>
        <Typography fontSize={16} fontWeight={500}>
          Active team athletes have received your direct payment.
        </Typography>
      </Box>

      <Box margin="1rem auto">
        <img src={DoneImage} alt="check mark" height={126} width={126}></img>
      </Box>
      <Typography fontSize={20} fontWeight={600}>
        You will be notified as soon as the other athletes accept your direct
        payment.
      </Typography>
    </Box>
  );
}

interface Props {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  multi?: string | null;
}

export function FeedbackModal({ open, setOpen, multi }: Props) {
  const navigate = useNavigate()
  const onGotoHome = () => {
    setOpen(false);
    navigate('/home');
    localStorage.setItem('setClearData', "clearData")
  }
  return (
    <ModalWrapper open={open} setOpen={setOpen} dark>
      <>
        {multi ? <MultiTransaction /> : <SingleTransaction />}
        <Button
          onClick={onGotoHome}
          color="secondary"
          variant="contained"
        >
          Back To Home
        </Button>
      </>
    </ModalWrapper>
  );
}
