import React, { useEffect } from "react";
import Grid from "@mui/material/Grid";
import { Link } from "react-router-dom";
import mnpLogo from "assets/images/mnp-logo-land.svg";
import instagram_land from "assets/images/instagram_land.png";
import twitter_land from "assets/images/twitter_land.png"
import linkedin_landed from "assets/images/linkedin-landed.png";

import Button from "@mui/material/Button";
import TermsandConditions from "./TermsandConditions";
function LandingPage() {
  return (<Grid className="landing-page" alignItems="center" >

    <Grid className="landing-page__info" container spacing={2} justifyContent="center">
      <Grid item md={4} className="container">
        <div>          <img src={mnpLogo} alt="myNILpay logo" className="landingpageimg" />
        </div>
        <p className="direct">  Direct-pay any student athlete at all levels and all sports.</p>
        <Button
          data-testid="submit-button"
          type="submit"
          variant="contained"
          fullWidth
          component={Link} to="/register"
        >
          Register
        </Button>
        <Grid className="landing-page__link" item md={12} marginTop={2} style={{ fontSize: 16 }}>
          Already have an account? <Link to="/login">Sign In</Link>
        </Grid>
        <p className="learn">
          myNILpay is compliant with NCAA NIL rules and regulations </p>

        <p className="learn">To learn more about myNILpay visit</p>
        <Grid container
          direction="column"
          alignItems="center"
          justifyContent="center" className="socialContainer"><div><span>            <img src={instagram_land} onClick={() => window.open('https://www.instagram.com/mynilpay/', "_blank")} style={{ float: 'left', marginRight: 14 }} alt="instagram" />
          </span><span>            <img src={twitter_land} onClick={() => window.open('https://twitter.com/mynilpay', "_blank")} style={{ float: 'left', marginRight: 14 }} alt="twitter" />
            </span><span>            <img src={linkedin_landed} onClick={() => window.open("https://www.linkedin.com/company/79448436/", "_blank")} style={{ float: 'left', width: 40, height: 40 }} alt="facebook" />
            </span></div>
        </Grid>
        <div className="mnplink">
          <a href="#" onClick={() => window.open('https://www.mynilpay.org/', "_blank")} target="_blank"> www.mynilpay.org</a>

        </div>
      </Grid>
    </Grid>
    <Grid className="landing-page__termslink" container spacing={2} justifyContent="center" marginTop={2}>
      <TermsandConditions />
    </Grid>
  </Grid>
  )

}
export default LandingPage;