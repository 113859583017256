import { gql } from "@apollo/client";
import { identityVerification, UserType } from "../types";

interface Me {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  userType: UserType;
  nickname: string;
  school: string;
  phoneNumber: string;
  birthday: string;
  zipCode: string;
  termsAccepted: string;
  athleteOnboarded?: boolean;
  identityVerification: null | identityVerification;
  forceResetPassword: boolean;
  athleteCheckoutOnboarded?: boolean;
  ckoSubEntityDdStatus?: string;
  ckoSubEntityDdStatusReason?: string;
  ckoSubEntityId?: string;
  ckoSubEntityBankStatus?: string;
  ckoFirstName?: string;
  ckoLastName?: string;
  ckoAddressLine1?: string;
  ckoAddressLine2?: string;
  ckoState?: string;
  ckoCity?: string;
  ckoZip?: string;
  ckoDob?: string;
  ckoPid?: string;
  ckoPhone?: string;
  ckoBankAccountType?: string;
  ckoRoutingNumber?: string;
}

export interface MeResponse {
  me: Me;
}

export const ME = gql`
  query Me {
    me {
      id
      email
      firstName
      lastName
      userType
      nickname
      phoneNumber
      birthday
      zipCode
      athleteOnboarded,
      termsAccepted,
      forceResetPassword,
      school,
      athleteCheckoutOnboarded,
      ckoSubEntityDdStatus,
      ckoSubEntityDdStatusReason,
      ckoSubEntityBankStatus,
      ckoSubEntityStatus,
      ckoSubEntityId,
      ckoFirstName,
      ckoLastName,
      ckoAddressLine1,
      ckoAddressLine2,
      ckoState,
      ckoCity,
      ckoZip,
      ckoDob,
      ckoPid,
      ckoPhone,
      ckoBankAccountType,
      ckoRoutingNumber,
      identityVerification {
        status
      }
    }
  }
`;

export const MeMockResponse = {
  request: {
    query: ME,
  },
  result: {
    data: {
      me: {
        id: "123",
        firstName: "Johnny",
        lastName: "Lawrence",
        nickname: "Sensei",
        email: "Johnny@kobra.com",
        userType: "fan",
        athleteOnboarded: true,
        identityVerification: {
          status: "success",
        },
      },
    },
  },
};

export const MeMockError = {
  request: {
    query: ME,
  },
  error: new Error("An error occurred"),
};
export const schoolUserGetRoleByEmail = gql`
query getRoleByEmail($email:String!){
  getRoleByEmail(email:$email){
      email,
    userType
    }
}
`;
