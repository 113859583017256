import React from "react";
import { Athlete, AthleteSport } from "types";
import { getFormatedCurrency } from "utils";
import Box from "@mui/material/Box";
import ListItem from "@mui/material/ListItem";
import SportIcon from "components/SportIcon";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Checkbox from "@mui/material/Checkbox";

function getCurrentSport(sportId: string, sports?: AthleteSport[]) {
  return sports?.find((item) => item.sport.id === sportId);
}

interface Props {
  athlete: Athlete;
  sportId: string;
  sportName: string;
  amount?: number;
  handleCheckbox?: (athlete: Athlete) => void;
  isSelected?: boolean;
}

export function PlayerCard({
  athlete,
  sportId,
  sportName,
  amount,
  handleCheckbox,
  isSelected,
}: Props) {
  return (
    <ListItem
      sx={{
        backgroundColor: "background.paper",
        padding: "11px 24px",
        marginTop: 1,
      }}
    >
      <Stack
        direction="row"
        justifyContent="space-between"
        spacing={2}
        sx={{
          width: "100%",
        }}
      >
        <Stack direction="row" alignItems={"center"}>
          <Box>
            <SportIcon sportName={sportName} />
          </Box>
          <Box marginLeft={2}>
            <Typography fontSize={14} fontWeight="600" color={"primary.main"}>
              {athlete.firstName} {athlete.lastName}
            </Typography>
            <Typography fontSize={12} fontWeight="300">
              {
                getCurrentSport(sportId, athlete.athleteSport)?.sportDetails
                  .position
              }
            </Typography>
          </Box>
        </Stack>
        <Box
          textAlign="right"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          {amount && <Typography>{getFormatedCurrency(amount)}</Typography>}

          {handleCheckbox && (
            <Checkbox
              checked={isSelected}
              onChange={() => handleCheckbox(athlete)}
            />
          )}
        </Box>
      </Stack>
    </ListItem>
  );
}
